import { makeStyles } from '@material-ui/core';
import { Address } from 'features/addresses/types';
import { useState } from 'react';
import { AddressCard } from './AddressCard/AddressCard';
import { EmptyList } from './EmptyList';

interface AddressesListProps {
  items: Address[];
}

export const AddressesList = ({ items }: AddressesListProps) => {
  const [activeMenu, setActiveMenu] = useState<number | undefined>();
  const classes = useStyle();

  const handleCloseMenu = () => setActiveMenu(undefined);

  if (items.length === 0) {
    return <EmptyList />;
  }

  return (
    <div className={classes.root}>
      {items.map((address) => (
        <AddressCard
          key={address.id}
          data={address}
          onOpenMenu={setActiveMenu}
          onCloseMenu={handleCloseMenu}
          isOpenMenu={activeMenu === address.id}
        />
      ))}
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    alignItems: 'start',

    [theme.breakpoints.down('sm')]: {
      gap: '1.5rem'
    },

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
      gap: '1.875rem'
    }
  }
}));
