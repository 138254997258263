import { makeStyles, ButtonBase } from '@material-ui/core';

import clsx from 'clsx';
import { CalendarDate } from 'common/types';
import { DayTileStyle } from '../../types';

export interface DayTileProps {
  locale: string;
  value: CalendarDate;
  onClick: (value: CalendarDate) => void;
  disabled?: boolean;
  styles?: DayTileStyle;
}

export const DayTile = ({ value, onClick, locale, styles = {}, disabled }: DayTileProps) => {
  const classes = useStyle();
  const handleClick = () => onClick(value);

  const { button, label } = styles;

  return (
    <ButtonBase
      classes={{ root: clsx(classes.container, button) }}
      onClick={handleClick}
      disabled={disabled}
      focusRipple
    >
      <abbr
        aria-label={value.locale(locale).format('D MMMM YYYY')}
        className={clsx(classes.label, label, { [classes.disabled]: disabled })}
      >
        {value.format('D')}
      </abbr>
    </ButtonBase>
  );
};

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    flex: 1,
    height: 49
  },
  label: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black'
  },
  disabled: {
    opacity: 0.3
  }
}));
