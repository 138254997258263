import { Container, makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { memo } from 'react';
import dayjs from 'dayjs';
import visa from './images/verified-by-visa.png';
import masterCard from './images/mc-sc.png';
import sslSecuredMobile from './images/ssl-secured.png';
import fiveStarMobile from './images/five-star.png';

export const Footer = memo((): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();
  const currentYear = dayjs().format('YYYY');

  return (
    <Box className={classes.box}>
      <Container maxWidth="lg" className={classes.content}>
        <div className={classes.firstRow}>{t('layout.footer.allRightsReserved', { year: currentYear })}</div>
        <div className={classes.secondRow}>{t('layout.footer.paymentsService')}</div>
        <div className={classes.imagesContainer}>
          <img src={visa} alt="" />
          <img src={masterCard} alt="" />
          <img src={sslSecuredMobile} alt="" />
          <img src={fiveStarMobile} alt="" />
        </div>
      </Container>
    </Box>
  );
});

const useStyle = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.secondary.main,
    padding: '2rem 0 1rem',
    position: 'relative'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 'max-content'
  },
  firstRow: {
    fontSize: '1rem',
    letterSpacing: '0.013rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.customColors.white,
    marginBottom: '1rem',
    textAlign: 'center'
  },
  secondRow: {
    fontSize: '0.75rem',
    lineHeight: 1.5,
    color: theme.customColors.white,
    marginBottom: '0.5rem',
    textAlign: 'center'
  },
  imagesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  actionButtonLeft: {
    position: 'absolute',
    left: '1.5rem',
    top: '-2.25rem',
    width: '4.5rem',
    height: '4.5rem',

    '& img': {
      width: '2.5rem',
      height: '2.5rem',

      filter: theme.filters.black
    },

    [theme.breakpoints.down('sm')]: {
      width: '3.5rem',
      height: '3.5rem',
      top: '-1.75rem',
      left: '0.5rem'
    }
  }
}));
