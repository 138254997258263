import { Country, country } from 'config';
import { ResponsiveImage, ResponsiveImageProps } from './ResponsiveImage';
import logoPL from './logo-pl.svg';
import logoDE from './logo-de.svg';
import logoCZ from './logo-cz.svg';
import logoMobile from './logo-mobile.svg';
import logoMobileDE from './logo-mobile-de.svg';

interface LogoProps extends Omit<ResponsiveImageProps, 'desktopSrc' | 'mobileSrc'> {}

const logoMap: Record<Country, { mobile: string; desktop: string }> = {
  poland: {
    mobile: logoMobile,
    desktop: logoPL
  },
  germany: {
    mobile: logoMobileDE,
    desktop: logoDE
  },
  czech: {
    mobile: logoMobile,
    desktop: logoCZ
  }
};

export const Logo = (props: LogoProps) => {
  return <ResponsiveImage desktopSrc={logoMap[country].desktop} mobileSrc={logoMap[country].mobile} {...props} />;
};
