import { Grid, makeStyles, Typography, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SelectInput } from 'common/components';

import trashIcon from 'common/icons/icon-trash.svg';

import { MealOption } from '../types';

interface SingleMealConfiguratorProps {
  options: MealOption[];
  mealName: string;
  position: number;
  id: string;
  mealId: number;
  onRemove: (id: string) => void;
  onChange: (id: string, newMealId: number) => void;
}

export const SingleMealConfigurator = ({
  mealName,
  options,
  id,
  mealId,
  position,
  onRemove,
  onChange
}: SingleMealConfiguratorProps): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();

  const price = options.find((elem) => elem.value === mealId)?.price;

  const handleRemove = () => onRemove(id);

  const handleChange = (selected: MealOption | null) => {
    if (selected !== null) {
      onChange(id, Number(selected.value));
    }
  };

  return (
    <Grid container className={classes.container} role="listitem">
      <Grid item xs={7} md={6}>
        <SelectInput
          aria-label={t('singleMealConfigurator.sizeLabel', { position })}
          options={options}
          onChange={handleChange}
          value={options.find((option) => option.value === mealId)}
        />
      </Grid>
      <Grid item xs={3} md={5} className={classes.contentContainer}>
        <Typography variant="body1" className={classes.priceValue}>
          {!!price && t('formatters.price', { price })}
        </Typography>
      </Grid>
      <Grid item xs={2} md={1} className={classes.contentContainer}>
        <IconButton onClick={handleRemove}>
          <img src={trashIcon} alt={t('singleMealConfigurator.removeMeal', { mealName })} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const useStyle = makeStyles(() => ({
  container: {
    alignItems: 'center',

    '& > .MuiGrid-item': {
      padding: '0 0.5rem',

      '&:first-of-type': {
        paddingLeft: 0
      },

      '&:last-of-type': {
        paddingRight: 0
      }
    },

    marginBottom: '1rem'
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  priceValue: {
    fontWeight: 'bold',
    letterSpacing: '0.013rem'
  },
  trashButton: {
    padding: 0,
    minWidth: 'unset',

    '&:hover': {
      background: 'transparent'
    }
  }
}));
