import { Button, FormPasswordTextField } from 'common/components';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangePasswordFormData, changePasswordFormSchema } from '../types';

interface ChangePasswordFormProps {
  onSubmit: (value: ChangePasswordFormData) => void;
  isLoading: boolean;
}

export const ChangePasswordForm = ({ onSubmit, isLoading }: ChangePasswordFormProps) => {
  const { t } = useTranslation();

  const formMethods = useForm<ChangePasswordFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(changePasswordFormSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      repeatPassword: ''
    }
  });

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormPasswordTextField
              name="currentPassword"
              placeholder={t('changePasswordPage.changePasswordForm.currentPassword.placeholder')}
              label={t('changePasswordPage.changePasswordForm.currentPassword.label')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormPasswordTextField
              name="newPassword"
              placeholder={t('changePasswordPage.changePasswordForm.newPassword.placeholder')}
              label={t('changePasswordPage.changePasswordForm.newPassword.label')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormPasswordTextField
              name="repeatPassword"
              placeholder={t('changePasswordPage.changePasswordForm.repeatPassword.placeholder')}
              label={t('changePasswordPage.changePasswordForm.repeatPassword.label')}
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled={isLoading} fullWidth type="submit">
              {t('changePasswordPage.changePasswordForm.submitButton')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
