import { camelCase, replace } from 'lodash';
import { useCallback, useMemo } from 'react';

export const useExperimentsFromUrl = () => {
  const searchParams = useMemo(() => new URLSearchParams(document.location.search), []);

  const getVariant = useCallback(
    (args: { experimentId: string }) => {
      const variant = searchParams.get(args.experimentId);
      if (variant) {
        appendVariantToLocalStorage({ key: args.experimentId, variant });
      }
      return variant;
    },
    [searchParams]
  );

  const appendVariantToLocalStorage = (experiment: { key: string; variant: string }) => {
    if (experiment) {
      const key = camelCase(experiment.key);
      const variant = camelCase(experiment.variant);
      const currentExperiments = localStorage.getItem('amp_testID');
      const experimentGAFormat = `${key}_${variant}`;
      let newValue = '';

      // There already exists an entry for this experiment
      if (currentExperiments?.includes(experimentGAFormat)) {
        return;
      }

      // Experiment value changed for this user
      if (currentExperiments?.includes(key)) {
        newValue = replace(currentExperiments, RegExp(`${key}_\\w+`), experimentGAFormat);
        // there are other experiments
      } else if (currentExperiments) {
        newValue = `${currentExperiments},${experimentGAFormat}`;
        // this is the first experiment
      } else {
        newValue = experimentGAFormat;
      }

      localStorage.setItem('amp_testID', newValue);
    }
  };

  return {
    getVariant
  };
};
