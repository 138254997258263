import { useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';

import { Button } from 'common/components';
import type { SelectedMeal } from 'common/types';

import { useDietConfiguration } from 'features/orders/contexts';
import { convertKcalToKj } from 'common/utils/convertKcalToKj';

import type { SizeData } from 'features/orders/api/fetchSizesData';
import plusIcon from '../images/icon-plus.svg';

import { AddMealHandler, ChangeMealHandler, RemoveMealHandler, MealOption } from '../types';

import { SingleMealConfigurator } from '../SingleMealConfigurator';
import { useFormContext } from 'react-hook-form';
import { CreateDietFormData } from 'features/orders/types';

interface MealTypeConfiguratorProps {
  selectedMeals: SelectedMeal[];
  onAdd: AddMealHandler;
  onRemove: RemoveMealHandler;
  onChange: ChangeMealHandler;
  sizeData: SizeData;
}

export const MealTypeConfigurator = ({
  selectedMeals,
  onAdd,
  onRemove,
  onChange,
  sizeData
}: MealTypeConfiguratorProps): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { mealPrices } = useDietConfiguration();
  const { getValues } = useFormContext<CreateDietFormData>();

  const selectedPackage = getValues('dietPackage');

  const mealSizesOptions: MealOption[] = useMemo(() => {
    return sizeData.sizes
      .sort((a, b) => a.calorific - b.calorific)
      .map((size) => ({
        value: size.id,
        label: t('mealSize', {
          size: size.nameForClient,
          calorific: size.calorific,
          kj: convertKcalToKj(size.calorific)
        }),
        price: mealPrices.find(({ id, packageId }) => packageId === selectedPackage && id === size?.id)?.price || 0
      }));
  }, [t, sizeData, mealPrices, selectedPackage]);

  const handleCreateNewMeal = () => {
    onAdd({
      typeId: sizeData.id,
      meal: {
        id: nanoid(),
        mealId: mealSizesOptions[0].value
      }
    });
  };

  const handleRemoveMeal = (id: string) => {
    onRemove({
      typeId: sizeData.id,
      id
    });
  };

  const handleMealChange = (id: string, newMealId: number) => {
    onChange({
      id,
      typeId: sizeData.id,
      newMealId
    });
  };

  return (
    <div className={classes.container} role="group" aria-labelledby={`meal-name-${sizeData.id}`}>
      <Grid container className={classes.titleRow}>
        <Grid item xs={10} md={11} className={classes.titleContainer} id={`meal-name-${sizeData.id}`}>
          {sizeData.name}
        </Grid>
        <Grid item xs={2} md={1} className={classes.addContainer}>
          <Button mode="secondary" onClick={handleCreateNewMeal}>
            <img src={plusIcon} alt={t('mealTypeConfigurator.addMeal', { mealName: sizeData.name })} />
          </Button>
        </Grid>
      </Grid>
      <div role="list">
        {selectedMeals.map(({ id, mealId }, index) => (
          <SingleMealConfigurator
            key={id}
            id={id}
            position={index}
            mealId={mealId}
            mealName={sizeData.name}
            options={mealSizesOptions}
            onRemove={handleRemoveMeal}
            onChange={handleMealChange}
          />
        ))}
      </div>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    '&:not(:last-of-type)': {
      marginBottom: '1rem',
      borderBottom: `1px solid ${theme.customColors.textLightGrey}`
    }
  },
  titleRow: {
    marginBottom: '1rem'
  },
  titleContainer: {
    fontSize: '1rem',
    letterSpacing: '0.013rem',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  addContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
