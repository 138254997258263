import {
  OTHER_PACKAGE_LEAVING_OPTION,
  PACKAGE_LEAVING_OPTION_AT_THE_RECEPTION,
  PACKAGE_LEAVING_OPTION_UNDER_THE_DOOR
} from '../constants/packageLeavingOptions';
import { UNDER_THE_DOOR_TYPE_CODE, UNDER_THE_DOOR_TYPE_OTHER } from '../constants/underTheDoorTypes';
import { Country } from '../../../config';

interface PackageLeavingConfig {
  otherPackageLeavingOptionRequired: boolean;
  intercomRequired: boolean;
}

export function getPackageLeavingConfig(
  packageLeavingOption: number,
  underTheDoorType: string | null,
  country: Country
): PackageLeavingConfig {
  const config = {
    otherPackageLeavingOptionRequired: false,
    intercomRequired: false
  };
  if (country === 'czech') {
    switch (packageLeavingOption) {
      case PACKAGE_LEAVING_OPTION_UNDER_THE_DOOR:
        config.intercomRequired = true;
        break;
      default:
        break;
    }
  }

  if (country === 'germany') {
    config.intercomRequired = true;

    switch (packageLeavingOption) {
      case PACKAGE_LEAVING_OPTION_AT_THE_RECEPTION:
      case OTHER_PACKAGE_LEAVING_OPTION:
        config.otherPackageLeavingOptionRequired = true;
        break;
      default:
    }
  } else {
    switch (packageLeavingOption) {
      case PACKAGE_LEAVING_OPTION_UNDER_THE_DOOR:
        switch (underTheDoorType) {
          case UNDER_THE_DOOR_TYPE_CODE:
            config.intercomRequired = true;
            break;
          case UNDER_THE_DOOR_TYPE_OTHER:
            config.otherPackageLeavingOptionRequired = true;
            break;
          default:
        }
        break;
      case PACKAGE_LEAVING_OPTION_AT_THE_RECEPTION:
      case OTHER_PACKAGE_LEAVING_OPTION:
        config.otherPackageLeavingOptionRequired = true;
        break;
      default:
    }
  }

  return config;
}
