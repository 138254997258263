import { makeStyles, SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SummarySectionHeader } from 'common/components';
import * as React from 'react';
import { ReactNode, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as CardIcon } from './images/card.svg';
import { ReactComponent as PayUIcon } from './images/payu.svg';
import { ReactComponent as PaypalIcon } from './images/paypal.svg';
import { FormRadioGroupField } from '../Form/FormRadioGroupField';
import { CreateDietFormData, PaymentType, useOrderPrice } from '../../../features/orders';
import { DictionaryTag } from '../../types/dictionaryTag';

interface PaymentTypeSectionProps {
  orderPaymentTypes: DictionaryTag[];
  divider?: ReactNode;
}

interface PaymentTypeOption {
  value: string;
  systemValue: PaymentType;
  icon: ReactNode;
}

export const PaymentTypeSection = ({ divider, orderPaymentTypes }: PaymentTypeSectionProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { setValue, watch } = useFormContext<CreateDietFormData>();
  const { needsPayment } = useOrderPrice();
  const paymentType = watch('paymentType');

  const options: PaymentTypeOption[] = useMemo(
    (): PaymentTypeOption[] =>
      orderPaymentTypes.reduce(
        (acc: PaymentTypeOption[], orderPaymentType: DictionaryTag) => {
          switch (orderPaymentType.systemValue) {
            case PaymentType.PAYPAL:
              acc.push({
                value: 'Paypal',
                systemValue: PaymentType.PAYPAL,
                icon: <SvgIcon component={PaypalIcon} viewBox="0 -6 34 20" />
              });
              break;
            default:
          }

          return acc;
        },
        [
          {
            value: 'PayU',
            systemValue: PaymentType.ONLINE,
            icon: <SvgIcon component={PayUIcon} />
          }
        ]
      ),
    [orderPaymentTypes]
  );

  const showSection = useMemo(() => paymentType !== PaymentType.MONEYBOX && options.length > 1, [paymentType, options]);

  useEffect(() => {
    if (!needsPayment) {
      setValue('paymentType', PaymentType.MONEYBOX);
    } else if (needsPayment && paymentType === PaymentType.MONEYBOX) {
      setValue('paymentType', options[options.length - 1].systemValue);
    }
  }, [needsPayment, paymentType, options, setValue]);

  useEffect(() => {
    if (!paymentType) {
      setValue('paymentType', defaultValue);
    }
  });

  const defaultValue = options.some((option) => option.systemValue === PaymentType.PAYPAL)
    ? PaymentType.PAYPAL
    : PaymentType.ONLINE;

  return showSection ? (
    <>
      {divider}
      <SummarySectionHeader
        className={classes.header}
        icon={CardIcon}
        title={t('creatDietPage.summaryBox.sections.paymentTypeSummary')}
      />
      <FormRadioGroupField<PaymentTypeOption>
        name="paymentType"
        className={classes.formRadioGroupField}
        defaultValue={defaultValue}
        getOptionAlt={(option: PaymentTypeOption) => option.value}
        getOptionLabel={(option: PaymentTypeOption) => (
          <>
            {option.value}
            {option.icon}
          </>
        )}
        getOptionValue={(option: PaymentTypeOption) => option.systemValue}
        options={options}
      />
    </>
  ) : null;
};

const useStyle = makeStyles(() => ({
  formRadioGroupField: {
    '& .MuiFormControlLabel-label': {
      width: '100%',
      '& > svg': {
        float: 'right',
        width: 'auto'
      }
    }
  },
  header: {
    marginBottom: '1rem'
  }
}));
