import { Country } from 'config/types/country';
import { country } from './country';

const localeCodeMap: Record<Country, 'pl' | 'de' | 'cs'> = {
  poland: 'pl',
  germany: 'de',
  czech: 'cs'
};

export const localeCode = localeCodeMap[country];
