import dayjs from 'dayjs';

import { TFunction } from 'react-i18next';
import type { OrderHistoryItem } from 'services/orderHistory/fetchOrderHistory';

const getTranslationFromCount = (count: number, t: TFunction) => {
  if (!count || count == 0 || count >= 5) {
    return t('orderHistoryPage.item.dietLength_other', { count });
  }
  if (count >= 2) {
    return t('orderHistoryPage.item.dietLength_few', { count });
  }
  return t('orderHistoryPage.item.dietLength_one', { count });
};

export const getDietLength = (item: OrderHistoryItem, t: TFunction) => {
  let text = '';
  if (!!item.bagDetails.count) {
    text += getTranslationFromCount(item.bagDetails.count || 0, t);
  }
  if (!!item.bagDetails.count && !!item.bagDetails.firstDeliveryDate && !!item.bagDetails.lastDeliveryDate) {
    text += ' ';
  }
  if (!!item.bagDetails.firstDeliveryDate && !!item.bagDetails.lastDeliveryDate) {
    text += `(${dayjs(item.bagDetails.firstDeliveryDate).format('DD.MM.YYYY')} - ${dayjs(
      item.bagDetails.lastDeliveryDate
    ).format('DD.MM.YYYY')})`;
  }
  if (!text.length) {
    text = t('orderHistoryPage.item.dietLengthUnknown');
  }

  return text;
};
