import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  status: 'active' | 'inactive' | 'cancelled' | 'partial' | undefined;
}

const paymentStatusColorMap: Record<
  'active' | 'inactive' | 'cancelled' | 'partial',
  { background: string; color: string }
> = {
  active: { background: '#ECF8F0', color: '#3CB76D' },
  inactive: { background: '#F8F8F8', color: '#C2C2C2' },
  partial: { background: '#FFF5E0', color: '#FFAE00' },
  cancelled: { background: '#FDDDDE', color: '#F34D52' }
};

export const StatusBadge = ({ status }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!status) {
    return null;
  }

  return (
    <div
      className={classes.root}
      style={{
        background: paymentStatusColorMap[status].background,
        borderColor: paymentStatusColorMap[status].color,
        color: paymentStatusColorMap[status].color
      }}
    >
      {t(`orderHistoryPage.item.status.${status}`)}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    borderRadius: '0.75rem',
    borderWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.75rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem'
  }
});
