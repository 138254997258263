import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

interface OrderSummaryWrapperProps {
  children: ReactNode;
  footer?: ReactNode;
}

export const SummaryWrapper = ({ children, footer }: OrderSummaryWrapperProps) => {
  const classes = useStyle();

  return (
    <aside className={classes.root}>
      <div className={classes.container}>
        <div className={classes.wrapper}>{children}</div>
        {footer && <div className={classes.footer}>{footer}</div>}
      </div>
    </aside>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    flex: 1
  },
  container: {
    position: 'sticky',
    top: '1rem',
    [theme.breakpoints.down('sm')]: {
      position: 'static'
    }
  },
  wrapper: {
    backgroundColor: theme.customColors.white,
    padding: '1rem 1rem 1.5rem 1rem',
    fontSize: '1rem',
    borderRadius: '0.25rem',

    [theme.breakpoints.down('md')]: {
      boxShadow: `0 -0.125rem 0.375rem -0.125rem ${theme.customColors.textLightGrey}`
    },
    [theme.breakpoints.up('md')]: {
      boxShadow: theme.boxShadow.lightGrey
    }
  },
  divider: {
    margin: '1rem 0'
  },
  footer: {
    marginTop: '1rem'
  }
}));
