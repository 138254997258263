import { makeStyles } from '@material-ui/core';
import { NotifyDialog } from 'common/components';
import { BaseDashboardHeader, BaseLayout } from 'layouts';
import { Footer } from 'layouts/components';
import { Logo } from 'layouts/components/Logo';

interface ErrorPageProps {
  error?: Error;
}

export const ErrorPage = ({ error }: ErrorPageProps) => {
  const classes = useStyle();
  const chunkFailedMessage = /Loading chunk [\d]+ failed/;
  const isChunkError = error?.message && chunkFailedMessage.test(error.message);
  const handleClose = () => {
    /**
     * We don't useHistory here as Sentry is above Router context
     */
    if (isChunkError) {
      window.location.reload();
    } else {
      window.location.replace(window.location.origin);
    }
  };
  return (
    <BaseLayout
      footer={<Footer />}
      header={
        <BaseDashboardHeader>
          <Logo
            classes={{
              img: classes.img
            }}
          />
        </BaseDashboardHeader>
      }
    >
      <NotifyDialog
        isOpen
        variant="error"
        onClose={handleClose}
        message={isChunkError ? 'errorPage.chunkNotFound' : 'errorPage.general'}
      />
    </BaseLayout>
  );
};

const useStyle = makeStyles(() => ({
  img: {
    marginRight: '1.5rem',
    maxHeight: '3rem'
  }
}));
