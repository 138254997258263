import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import mastercardImage from './images/mastercard.png';
import pciImage from './images/pci.png';
import sslImage from './images/ssl.png';
import visaImage from './images/visa.png';

export const PaymentInformationSection = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <div>
      <p className={classes.title}>{t('creatDietPage.summaryBox.paymentInformation.title')}</p>
      <div className={classes.images}>
        <img
          src={visaImage}
          alt={t('creatDietPage.summaryBox.paymentInformation.visaIcon')}
          className={classes.image}
        />
        <img
          src={mastercardImage}
          alt={t('creatDietPage.summaryBox.paymentInformation.mastercardIcon')}
          className={classes.image}
        />
        <img src={sslImage} alt={t('creatDietPage.summaryBox.paymentInformation.sslIcon')} className={classes.image} />
        <img src={pciImage} alt={t('creatDietPage.summaryBox.paymentInformation.pciIcon')} className={classes.image} />
      </div>
    </div>
  );
};

const useStyle = makeStyles(() => ({
  wrapper: {
    width: '100%'
  },
  title: {
    textAlign: 'center',
    fontSize: '0.75rem'
  },
  images: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '1.5rem',
    alignItems: 'center'
  },
  image: {
    width: '100%',
    maxHeight: '3rem',
    objectFit: 'contain'
  }
}));
