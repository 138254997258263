import { useTheme } from '@material-ui/core';
import ReactSelect, { Props as ReactSelectProps, StylesConfig } from 'react-select';
import { SelectOption } from './SelectOption';
import { IsMultiSelect } from './types';

interface SelectInputProps<OptionType> extends ReactSelectProps<OptionType, IsMultiSelect> {
  error?: boolean;
  readOnly?: boolean;
}

export const SelectInput = <OptionType,>(props: SelectInputProps<OptionType>) => {
  const theme = useTheme();
  const customStyles: StylesConfig<OptionType, IsMultiSelect> = {
    control: (provided, state) => {
      return {
        ...provided,
        boxShadow: state.isDisabled || props.readOnly ? 'none' : theme.boxShadow.lightGrey,
        borderColor: props.error ? theme.customColors.red : 'transparent',
        cursor: 'pointer',
        fontSize: '1rem',
        zIndex: state.menuIsOpen ? 10 : 1,
        '&:hover': {
          borderColor: props.error ? theme.customColors.red : 'transparent'
        }
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      padding: '0.5rem 0.9rem'
    }),
    singleValue: (provided) => ({
      ...provided
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: theme.palette.primary.main,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '0',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      width: '100%',
      cursor: 'pointer',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
      minHeight: '2rem',
      '&:focus, &:active, &:hover': {
        opacity: '0.6',
        backgroundColor: 'transparent'
      },
      '&:not(:last-child)': {
        marginBottom: '0.5rem'
      }
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      zIndex: 25
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '1rem',
      boxShadow: theme.boxShadow.lightGrey,
      backgroundColor: theme.customColors.white
    })
  };

  return (
    <ReactSelect
      {...props}
      components={{
        IndicatorSeparator: () => null,
        Option: SelectOption
      }}
      menuShouldBlockScroll
      menuShouldScrollIntoView
      isSearchable={false}
      styles={customStyles}
    />
  );
};
