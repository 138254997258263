import { convertKcalToKj } from 'common/utils/convertKcalToKj';
import { AvailableBaskets } from '../../../types';

export interface CalorificOptions {
  label: string;
  value: number;
  id: number;
}

/**
 * This method is used to provide a points for calories slider.
 *
 * We are extracting available baskets and sort them by order.
 * Then we are assigning them rail value to keep them in same distance beetween each other.
 * We are doing this that way because materialUI select place on rail based on value.
 * To keep left and right rail like on a design we start from 5 and end o 95.
 * Points beetween are calculated.
 */
export const mapCalorificsToOptions = (baskets: AvailableBaskets, energyType: 'kcal' | 'kj') => {
  const options: CalorificOptions[] = [];
  const last = baskets.size - 1;
  let index = 0;

  baskets.forEach((basket) => {
    let optionValue = index * (90 / last) + 5;
    if (index === 0) {
      optionValue = 5;
    }

    if (index === last) {
      optionValue = 95;
    }

    options.push({
      label: energyType === 'kcal' ? basket.calorific.toString() : convertKcalToKj(basket.calorific).toString(),
      value: optionValue,
      id: basket.id
    });
    index += 1;
  });
  return options;
};
