import { useTranslation } from 'react-i18next';
import { IconButton, makeStyles } from '@material-ui/core';
import iconEye from './icon-eye.svg';
import iconEyeOff from './icon-eye-off.svg';

interface ShowPasswordButtonProps {
  isVisible: boolean;
  onClick: VoidFunction;
}

export const ShowPasswordButton = ({ isVisible, onClick }: ShowPasswordButtonProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <IconButton
      className={classes.root}
      aria-label={isVisible ? t('passwordInput.ariaLabelVisible') : t('passwordInput.ariaLabelHidden')}
      onClick={onClick}
    >
      {isVisible ? (
        <img src={iconEyeOff} alt={t('passwordInput.visibleAlt')} />
      ) : (
        <img src={iconEye} alt={t('passwordInput.hiddenAlt')} />
      )}
    </IconButton>
  );
};

const useStyle = makeStyles({
  root: {
    padding: 0
  }
});
