import { DietLengthOption } from '../types';

export const dietLenghtOptions: DietLengthOption[] = [
  {
    id: 1,
    withoutWeekends: false,
    type: 'continuous',
    weeks: 1,
    days: 7
  },
  {
    id: 2,
    withoutWeekends: false,
    type: 'continuous',
    weeks: 2,
    days: 14
  },
  {
    id: 3,
    withoutWeekends: false,
    type: 'continuous',
    weeks: 4,
    days: 28
  },
  {
    id: 4,
    withoutWeekends: false,
    type: 'custom'
  },
  {
    id: 5,
    withoutWeekends: true,
    type: 'continuous',
    weeks: 1,
    days: 5
  },
  {
    id: 6,
    withoutWeekends: true,
    type: 'continuous',
    weeks: 2,
    days: 10
  },
  {
    id: 7,
    withoutWeekends: true,
    type: 'continuous',
    weeks: 4,
    days: 20
  },
  {
    id: 8,
    withoutWeekends: true,
    type: 'custom'
  }
];
