import { useLayoutEffect } from 'react';

/** clear scroll on entry page */
export const useScrollToTop = () => {
  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);
};
