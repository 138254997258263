import { makeStyles, Typography } from '@material-ui/core';

interface ResetPasswordHeadingProps {
  title: string;
  subtitle?: string;
}

export const ResetPasswordHeading = ({ title, subtitle }: ResetPasswordHeadingProps) => {
  const classes = useStyle();

  return (
    <div className={classes.headingWrapper}>
      <Typography className={classes.title} variant="h1">
        {title}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        {subtitle}
      </Typography>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  headingWrapper: {
    whiteSpace: 'pre-line',
    margin: `0 0 ${theme.spacing(6)} 0`,
    '& > *': {
      textAlign: 'center'
    }
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  subtitle: {
    color: theme.customColors.grey
  }
}));
