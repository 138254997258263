import { MoneyBox } from 'layouts/components';
import { User } from './User';
import { CreateDiet } from './CreateDiet';
import { HiddenOnMobile } from 'common/components';

export const RightSection = (): JSX.Element => {
  return (
    <>
      <MoneyBox />
      <HiddenOnMobile>
        <User />
        <CreateDiet />
      </HiddenOnMobile>
    </>
  );
};
