import { z } from 'zod';
import { country } from 'config';

export const cityFieldsLength = {
  min: 2,
  max: 255
};

export const streetFieldsLength = {
  min: 2,
  max: 255
};

export const buildingNrFieldsLength = {
  max: 10
};

export const placeNrFieldsLength = {
  max: 10
};

const getPostCodeRegex = () => {
  switch (country) {
    case 'germany':
      return /\d{5}/;
    case 'poland':
      return /\d{2}-\d{3}/;
    case 'czech':
      return /\d{3} \d{2}/;
    default:
      throw new Error('Country not supported');
  }
};
const regexLanguageChoose = getPostCodeRegex();
export const addressVerificationFormSchema = z.object({
  street: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const streetFieldValidationResult = z
        .string()
        .nonempty('addresses.addressVerification.street.validation.required')
        .min(streetFieldsLength.min, 'addresses.addressVerification.street.validation.min')
        .safeParse(val);

      if (!streetFieldValidationResult.success) {
        ctx.addIssue(streetFieldValidationResult.error.issues[0]);
      }
    }),
  buildingNr: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const buildingNrValidationResult = z
        .string()
        .nonempty('addresses.addressVerification.buildingNr.validation.required')
        .safeParse(val);

      if (!buildingNrValidationResult.success) {
        ctx.addIssue(buildingNrValidationResult.error.issues[0]);
      }
    }),
  city: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const cityFieldValidationResult = z
        .string()
        .nonempty('addresses.addressVerification.city.validation.required')
        .min(cityFieldsLength.min, 'addresses.addressVerification.city.validation.min')
        .safeParse(val);

      if (!cityFieldValidationResult.success) {
        ctx.addIssue(cityFieldValidationResult.error.issues[0]);
      }
    }),
  postCode: z.string().superRefine((val, ctx) => {
    const postCodeValidationResult = z
      .string()
      .nonempty('addresses.addressVerification.postCode.validation.required')
      .regex(regexLanguageChoose, 'addresses.addressVerification.postCode.validation.format')
      .safeParse(val);

    if (!postCodeValidationResult.success) {
      ctx.addIssue(postCodeValidationResult.error.issues[0]);
    }
  }),
  placeNr: z.string()
});

export type AddressVerificationFormData = z.infer<typeof addressVerificationFormSchema>;
