import { Country } from 'config/types/country';
import { country } from './country';

const currencyMap: Record<Country, 'EUR' | 'PLN' | 'CZK'> = {
  czech: 'CZK',
  poland: 'PLN',
  germany: 'EUR'
};

export const currency = currencyMap[country];
