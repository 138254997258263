import { useController, useFormContext } from 'react-hook-form';
import { FormErrorMessage } from '../FormErrorMessage';
import { MaskTextField, MaskTextFieldProps } from '../MaskTextField';

interface FormMaskTextFieldProps extends Omit<MaskTextFieldProps, 'onChange' | 'onBlur' | 'value' | 'error'> {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
}

export const FormMaskTextField = ({ name, defaultValue, mask, ...rest }: FormMaskTextFieldProps): JSX.Element => {
  const {
    formState: { errors }
  } = useFormContext();
  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    defaultValue
  });
  // https://github.com/sanniassin/react-input-mask#autofill
  const inputMask = field.value ? mask : '';
  return (
    <MaskTextField
      {...rest}
      {...field}
      mask={inputMask}
      error={!!error}
      helperText={<FormErrorMessage errors={errors} name={name} />}
    />
  );
};
