import { makeStyles } from '@material-ui/core';
import { Button } from 'common/components/Button';
import { useTranslation } from 'react-i18next';
import LoopIcon from '@material-ui/icons/Loop';

interface ReloadButtonProps {
  onClick: VoidFunction;
  label?: string;
}

export const RetryButton = ({ onClick, label }: ReloadButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button className={classes.refreshButton} onClick={onClick}>
      {label || t('notificationDialog.error.refreshButtonTitle')}
      <LoopIcon />
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  refreshButtonBox: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
      padding: '1rem',
      boxShadow: theme.boxShadow.lightGrey
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(5)
    },
    width: '100%',
    textAlign: 'center'
  },
  refreshButton: {
    [theme.breakpoints.up('md')]: {
      width: '25rem'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    '& svg': {
      marginLeft: '0.5rem'
    }
  }
}));
