import * as z from 'zod';
import { ApiClient } from 'features/apiProvider';
import { DictionaryTag, dictionaryTagSchema, DictionaryTagType } from '../types/dictionaryTag';

const dictionaryTagsResponse = z.array(dictionaryTagSchema);

export const fetchDictionaryTags = async (
  type: DictionaryTagType,
  { apiClient }: ApiClient
): Promise<DictionaryTag[]> => {
  const data = await apiClient.get(`v3/dictionary/${type}`).json();
  return dictionaryTagsResponse.parse(data);
};
