import { country } from 'config';
import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { NewOrderAddresses } from '../types';

export type DeliveryVariant = 'delivery_point' | 'address';
export interface CreateDeliveryAddressesContextShape {
  deliveryAddressConfig: NewOrderAddresses;
  setDeliveryAddressConfig: (deliveryAddressConfig: NewOrderAddresses) => void;
  setDeliveryVariant: Dispatch<SetStateAction<DeliveryVariant | null>>;
  deliveryVariant: DeliveryVariant | null;
}

interface CreateDeliveryAddressesProps {
  children: React.ReactNode;
}

const CreateDeliveryAddressesContext = createContext<CreateDeliveryAddressesContextShape | undefined>(undefined);

export const CreateDeliveryAddressesProvider = ({ children }: CreateDeliveryAddressesProps): JSX.Element => {
  const [deliveryAddressConfig, setDeliveryAddressConfig] = useState<NewOrderAddresses>({
    standard: null,
    festive: null
  });
  const [deliveryVariant, setDeliveryVariant] = useState<DeliveryVariant | null>(null);

  const location = useLocation();
  if (location.pathname === RoutesDefinition.deliveryAddressesOrder && country === 'czech' && !deliveryVariant) {
    return <Redirect to={RoutesDefinition.deliveryTypeSelect} />;
  }

  return (
    <CreateDeliveryAddressesContext.Provider
      value={{
        deliveryAddressConfig,
        setDeliveryAddressConfig,
        setDeliveryVariant,
        deliveryVariant
      }}
    >
      {children}
    </CreateDeliveryAddressesContext.Provider>
  );
};

export const useCreateDeliveryAddresses = () => {
  const context = useContext(CreateDeliveryAddressesContext);
  if (context === undefined) {
    throw new Error('useCreateDeliveryAddresses must be used within a CreateDeliveryAddressesContext');
  }
  return context;
};
