import { Divider, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { ReactNode, useEffect, useMemo } from 'react';
import { MobileBottomBar } from 'common/components';
import clsx from 'clsx';
import { useQuery } from 'react-query';
import { fetchDictionaryTags } from 'common/api/fetchDictionaryTags';
import { useApi } from 'features/apiProvider';
import { convertKcalToKj } from 'common/utils/convertKcalToKj';
import { useOrderPrice, useDietConfiguration } from '../../contexts';

import { CreateDietFormData, PaymentType } from '../../types';
import { MobileSummaryRow } from './MobileSummaryRow';
import { calculateCalorific } from '../../utils/calculateCalorifics';

interface MobileOrderSummaryProps {
  button: ReactNode;
}

const MobileOrderSummary = ({ button }: MobileOrderSummaryProps) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { getApiClient } = useApi();

  const { pricePerDay, priceToPay, discounts, isLoading, needsPayment } = useOrderPrice();

  const orderPaymentTypes = useQuery({
    queryKey: 'orderPaymentTypes',
    queryFn: () => fetchDictionaryTags('ecommerce_order_payment_type', { apiClient: getApiClient() })
  });

  const { sizesData } = useDietConfiguration();

  const selectedMeals = useWatch<CreateDietFormData, 'meals'>({ name: 'meals' });
  const { setValue, watch } = useFormContext<CreateDietFormData>();

  const paymentType = watch('paymentType');

  useEffect(() => {
    if (!orderPaymentTypes.isLoading) {
      const defaultPaymentType = orderPaymentTypes.data?.some((option) => option.systemValue === PaymentType.PAYPAL)
        ? PaymentType.PAYPAL
        : PaymentType.ONLINE;

      if (!needsPayment) {
        setValue('paymentType', PaymentType.MONEYBOX);
      } else if (needsPayment && paymentType === PaymentType.MONEYBOX) {
        setValue('paymentType', defaultPaymentType);
      } else {
        setValue('paymentType', defaultPaymentType);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsPayment, orderPaymentTypes.data]);

  const priceWithDiscount = [discounts.code, discounts.delivery, discounts.moneybox].some((value) => value > 0);

  const calorific = useMemo(() => calculateCalorific(selectedMeals, sizesData), [sizesData, selectedMeals]);

  return (
    <MobileBottomBar>
      <div className={classes.container}>
        <div>
          <MobileSummaryRow label={t('creatDietPage.summaryBox.calories')}>
            {t('creatDietPage.summaryBox.caloriesSmall', { kj: convertKcalToKj(calorific), calories: calorific })}
          </MobileSummaryRow>
          <Divider variant="middle" className={classes.divider} />
          <MobileSummaryRow label={t('creatDietPage.summaryBox.pricePerDay')} isLoading={isLoading}>
            <strong>{t('formatters.price', { price: pricePerDay })}</strong>
          </MobileSummaryRow>
          <MobileSummaryRow
            classes={{
              label: clsx({
                [classes.discountPrice]: priceWithDiscount
              })
            }}
            label={t('creatDietPage.summaryBox.totalPrice')}
            isLoading={isLoading}
          >
            <strong
              className={clsx({
                [classes.discountPrice]: priceWithDiscount
              })}
            >
              {t('formatters.price', { price: priceToPay })}
            </strong>
          </MobileSummaryRow>
        </div>
        <div className={classes.buttonBox}>{button}</div>
      </div>
    </MobileBottomBar>
  );
};

export default MobileOrderSummary;

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gap: '1.5rem'
  },

  divider: {
    [theme.breakpoints.down('sm')]: {
      margin: '0.5rem 0'
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0.875rem 0'
    }
  },
  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > button': {
      padding: '0.75rem 1.5rem'
    }
  },
  discountPrice: {
    color: theme.customColors.green
  }
}));
