import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import { Basket, CreateDietFormData } from 'features/orders/types';

import { MealModes } from 'common/types';
import { FormRow } from 'common/components';

import { mapBasketMealsToSelectedMeals } from 'features/orders/utils';
import { useDietConfiguration } from 'features/orders/contexts';

import { maximumCalorificValueToDisplay } from 'features/orders';
import { country } from 'config';
import { makeStyles, Typography } from '@material-ui/core';
import { convertKcalToKj } from 'common/utils/convertKcalToKj';
import { mapCalorificsToOptions, calculateSelectedMealsTypes, matchSelectedMealsToPredefiniedBasket } from './utils';

import { CaloriesSlider } from './CaloriesSlider';
import { ResetSelectedMeals } from './ResetSelectedMeals';
import { calculateCalorific } from '../../utils/calculateCalorifics';
import { CaloricCalculator } from './CaloricCalculator';

export const CaloriesSection = (): JSX.Element => {
  const [individualCalorifics, setIndividualCalorifics] = useState<number | null>(null);
  const [basektToResetCalorifics, setBasektToResetCalorifics] = useState<Basket | null>(null);
  const [energyType, setEnergyType] = useState<'kcal' | 'kj'>('kcal');
  const classes = useStyle();

  const { t } = useTranslation();
  const {
    sizesData,
    config: { baskets }
  } = useDietConfiguration();

  const { setValue } = useFormContext<CreateDietFormData>();

  const {
    field: { onChange, value }
  } = useController<CreateDietFormData, 'basket'>({
    name: 'basket'
  });

  const currentSelectedMeals = useWatch<CreateDietFormData, 'meals'>({
    name: 'meals'
  });

  const mealMode = useWatch<CreateDietFormData, 'mealMode'>({
    name: 'mealMode'
  });

  useEffect(() => {
    if (mealMode !== MealModes.flexi) {
      setIndividualCalorifics(null);
      return;
    }

    const predefiniedBasket = matchSelectedMealsToPredefiniedBasket(currentSelectedMeals, baskets);

    if (!predefiniedBasket) {
      setIndividualCalorifics(calculateCalorific(currentSelectedMeals, sizesData));

      return;
    }

    onChange(predefiniedBasket.id);
    setIndividualCalorifics(null);
  }, [mealMode, currentSelectedMeals, baskets, value, sizesData, onChange]);

  const caloriesOptions = mapCalorificsToOptions(baskets, energyType);

  const selectedCaloriesOption = caloriesOptions.find((singleOpt) => singleOpt.id === value)?.value || 0;

  const getIndividualCalorificsDescription = () => {
    if (individualCalorifics === null) {
      return undefined;
    }

    if (individualCalorifics > maximumCalorificValueToDisplay) {
      return t('creatDietPage.caloriesSection.individualCalorificsDescriptionHuge', {
        kj: convertKcalToKj(maximumCalorificValueToDisplay),
        calorifics: maximumCalorificValueToDisplay
      });
    }

    return t('creatDietPage.caloriesSection.individualCalorificsDescription', {
      calorifics: individualCalorifics,
      kj: convertKcalToKj(individualCalorifics)
    });
  };

  const handleChangeBasket = (basket: Basket) => {
    onChange(basket.id);
    setValue(
      'meals',
      calculateSelectedMealsTypes(
        mapBasketMealsToSelectedMeals(basket),
        currentSelectedMeals,
        mealMode === MealModes.flexi
      )
    );
  };

  const handleChange = (newValue: number) => {
    const selectedOptionId = caloriesOptions.find((singleOpt) => singleOpt.value === newValue)?.id ?? -1;
    const selectedBasket = baskets.get(selectedOptionId);
    if (selectedBasket) {
      if (individualCalorifics) {
        setBasektToResetCalorifics(selectedBasket);
      } else {
        handleChangeBasket(selectedBasket);
      }
    }
  };
  const handleCancelIndividualCalorifics = () => {
    setBasektToResetCalorifics(null);
  };
  const handleConfirmIndividualCalorifics = () => {
    if (basektToResetCalorifics === null) {
      return;
    }
    handleChangeBasket(basektToResetCalorifics);
    setBasektToResetCalorifics(null);
  };
  const handleCalculatorSelect = (calorific: number) => {
    let selectedBasket: Basket | undefined;
    baskets.forEach((basket) => {
      if (basket.calorific === calorific) {
        selectedBasket = basket;
      }
    });
    if (selectedBasket !== undefined) {
      handleChangeBasket(selectedBasket);
    }
  };
  return (
    <FormRow
      title={
        country === 'czech' ? (
          <div className={classes.header}>
            <Typography variant="h3" component="h2" gutterBottom>
              {t('creatDietPage.caloriesSection.title')}
            </Typography>
            <div className={classes.energySwitch}>
              <button
                type="button"
                className={classes.energySwitchButton}
                {...(energyType === 'kcal' && { style: { fontWeight: 700, color: 'black' } })}
                onClick={() => setEnergyType('kcal')}
              >
                {t('kcal')}
              </button>
              <div className={classes.divider} />
              <button
                type="button"
                className={classes.energySwitchButton}
                {...(energyType === 'kj' && { style: { fontWeight: 700, color: 'black' } })}
                onClick={() => setEnergyType('kj')}
              >
                {t('kj')}
              </button>
            </div>
          </div>
        ) : (
          t('creatDietPage.caloriesSection.title')
        )
      }
      TitleProps={{ id: 'calorific-slider-header' }}
      hidden={country === 'germany'}
    >
      <CaloriesSlider
        options={caloriesOptions}
        value={selectedCaloriesOption}
        onChange={handleChange}
        individualCalorific={getIndividualCalorificsDescription()}
      />
      <CaloricCalculator onSelect={handleCalculatorSelect} />
      <ResetSelectedMeals
        open={basektToResetCalorifics !== null}
        onCancel={handleCancelIndividualCalorifics}
        onConfirm={handleConfirmIndividualCalorifics}
      />
    </FormRow>
  );
};

const useStyle = makeStyles((theme) => ({
  header: { display: 'flex', justifyContent: 'space-between' },
  energySwitchButton: {
    all: 'unset'
  },
  energySwitch: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1.5rem',
    cursor: 'pointer',
    color: theme.customColors.grey
  },
  divider: {
    display: 'flex',
    alignSelf: 'center',
    width: '1px',
    height: '66%',
    backgroundColor: theme.customColors.grey,
    opacity: 0.6
  }
}));
