import * as z from 'zod';

import { addressSchema, Address } from 'features/addresses/types';

import { ApiClient } from 'features/apiProvider';

const addressesResponseSchema = z.array(addressSchema);

const addressesTransformer = (addresses: Address[]) => {
  return addresses.sort((a, b) => {
    if ((a.isStandardByDefault || a.isSaturdayByDefault) && !b.isStandardByDefault && !b.isSaturdayByDefault) {
      return -1;
    }
    if (!a.isStandardByDefault && !a.isSaturdayByDefault && (b.isStandardByDefault || b.isSaturdayByDefault)) {
      return 1;
    }
    return 0;
  });
};

export const fetchAddresses = async (apiClient: ApiClient['apiClient']): Promise<Address[]> => {
  const response = await apiClient.get('v3/addresses').json();
  return addressesTransformer(addressesResponseSchema.parse(response));
};
