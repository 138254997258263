import { ReactNode } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MobileBottomBar } from 'common/components';
import { useOrderPrice } from '../../contexts';

interface PriceSummaryProps {
  button: ReactNode;
}

export const PriceSummary = ({ button }: PriceSummaryProps) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { priceToPay, isLoading } = useOrderPrice();

  return (
    <MobileBottomBar>
      <div className={classes.contentBox}>
        <p className={classes.title}>{t('creatDietPage.summaryBox.toPay')}</p>
        <p className={classes.price}>
          {isLoading ? <CircularProgress size={14} /> : t('formatters.price', { price: priceToPay })}
        </p>
      </div>
      <div className={classes.buttonBox}>{button}</div>
    </MobileBottomBar>
  );
};

const useStyle = makeStyles((theme) => ({
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',

    '& > p': {
      margin: '0'
    }
  },
  title: {
    fontSize: '1rem',
    color: theme.orderSummaryBox.color
  },
  price: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.dark
  },
  buttonBox: {
    '& > a,button': {
      width: '100%'
    }
  }
}));
