import type { SelectedMeals } from 'common/types';
import type { SizeData } from 'features/orders/api/fetchSizesData';
import { flatMap } from 'lodash';

interface GetMealsNamesForSummaryProps {
  selectedMeals: SelectedMeals;
  sizesData: SizeData[];
}

export const getMealsNames = ({ selectedMeals, sizesData }: GetMealsNamesForSummaryProps) => {
  const mealsNames: string[] = [];
  const allSelectedPortions = flatMap(Array.from(selectedMeals.values()));

  const portionsBySizeId = sizesData.reduce((acc: Map<number, string>, currentPortion) => {
    currentPortion.sizes.forEach((size) => {
      acc.set(size.id, currentPortion.name);
    });
    return acc;
  }, new Map());

  allSelectedPortions.forEach(({ mealId }) => {
    const portion = portionsBySizeId.get(mealId);
    if (portion) {
      mealsNames.push(portion);
    }
  });

  return mealsNames;
};
