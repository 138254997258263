import { Divider, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MobileBottomBar } from 'common/components';
import { MobileSummaryRow } from 'features/orders/components/Summary/MobileSummaryRow';
import { useHomePageConfigurator } from '../contexts/HomePageConfiguratorContext';

export const MobileHomePageConfiguratorSummary = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { priceToPay, pricePerDay, calories, isLoading } = useHomePageConfigurator();

  return (
    <MobileBottomBar>
      <div className={classes.container}>
        <div className={classes.summaryContainer}>
          <MobileSummaryRow label={t('creatDietPage.summaryBox.calories')}>{calories} kcal</MobileSummaryRow>
          <Divider variant="middle" className={classes.divider} />
          <MobileSummaryRow label={t('creatDietPage.summaryBox.pricePerDay')} isLoading={isLoading}>
            <strong>{t('formatters.price', { price: pricePerDay })}</strong>
          </MobileSummaryRow>
          <MobileSummaryRow label={t('creatDietPage.summaryBox.totalPrice')} isLoading={isLoading}>
            <strong>{t('formatters.price', { price: priceToPay })}</strong>
          </MobileSummaryRow>
        </div>
      </div>
    </MobileBottomBar>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex'
  },
  summaryContainer: {
    margin: '0.5rem',
    marginRight: '1.5rem',
    flexGrow: 1,
    flexShrink: 0
  },
  divider: {
    [theme.breakpoints.down('sm')]: {
      margin: '0.5rem 0'
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0.875rem 0'
    }
  },
  buttonBox: {
    flex: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > button': {
      width: '100%',
      padding: '0.875rem 2rem'
    },
    [theme.breakpoints.up('sm')]: {
      '& > button': {
        padding: '1rem 2rem'
      }
    }
  },
  discountPrice: {
    color: theme.customColors.green
  }
}));
