import { Button, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export interface ButtonGroupProps<OptionType, Value> {
  value: Value;
  options: OptionType[];
  onChangeValue: (newValue: OptionType) => void;
  getOptionLabel: (option: OptionType) => string;
  getOptionValue: (option: OptionType) => Value;
}

export const ButtonGroup = <OptionType, Value>({
  value,
  options,
  onChangeValue,
  getOptionLabel,
  getOptionValue
}: ButtonGroupProps<OptionType, Value>) => {
  const classes = useStyle();

  const handleModeClick = (newValue: OptionType) => () => onChangeValue(newValue);

  return (
    <>
      <Grid container spacing={2}>
        {options.map((singleOption) => (
          <Grid item key={getOptionLabel(singleOption)} xs>
            <Button
              variant="contained"
              className={clsx({
                [classes.button]: true,
                active: value === getOptionValue(singleOption)
              })}
              onClick={handleModeClick(singleOption)}
              aria-pressed={value === getOptionValue(singleOption)}
            >
              {getOptionLabel(singleOption)}
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  button: {
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 1.19,
    letterSpacing: 'normal',
    boxShadow: theme.boxShadow.lightGrey,
    width: '100%',
    height: '2.5rem',
    backgroundColor: theme.customColors.white,
    textTransform: 'none',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      lineHeight: 'normal'
    },
    '&.active': {
      backgroundColor: theme.palette.primary.main,
      position: 'relative'
    }
  }
}));
