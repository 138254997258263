import { Country } from 'config/types';
import { country } from './country';

export const countryPrefixMap: Record<Country, string> = {
  poland: '+48',
  germany: '+49',
  czech: '+420'
};

export const currentCountryPrefix = countryPrefixMap[country];
