import { useController, useFormContext } from 'react-hook-form';
import { TFuncKey, useTranslation } from 'react-i18next';
import { FormRow } from 'common/components';

import { country } from 'config';
import { useDietConfiguration } from '../contexts';

import { CreateDietFormData } from '../types';

import { ButtonGroupWithTooltip } from './ButtonGroupWithTooltip';

interface DietPackageOption {
  label: string;
  value: number;
  description: string;
}

export const PackageSection = ({ alternate }: { alternate?: boolean }) => {
  const { t } = useTranslation();
  const {
    config: { packages }
  } = useDietConfiguration();

  const {
    field: { onChange, value }
  } = useController<CreateDietFormData, 'dietPackage'>({
    name: 'dietPackage'
  });
  const { setValue } = useFormContext<CreateDietFormData>();

  const getOptions = () => {
    const availableOptions: DietPackageOption[] = [];

    packages.forEach((dietPackage) => {
      availableOptions.push({
        label: alternate
          ? (t(`creatDietPage.packagesLabels.${dietPackage.name}` as TFuncKey) as string)
          : dietPackage.name,
        value: dietPackage.id,
        description: alternate ? t('creatDietPage.packagesDescription') : dietPackage.description
      });
    });
    return availableOptions;
  };

  const handlePackageChange = (newPackageId: number) => {
    const newDietPackage = packages.get(newPackageId);
    if (newDietPackage) {
      onChange(newPackageId);
      setValue('dietVariant', newDietPackage.defaultVariant);
    }
  };

  return (
    <FormRow
      title={t(alternate ? 'creatDietPage.packageSectionTitleAlternate' : 'creatDietPage.packageSectionTitle')}
      subtitle={alternate ? undefined : t('creatDietPage.packageSubtitle')}
      hidden={country === 'germany'}
    >
      <ButtonGroupWithTooltip
        options={getOptions()}
        onChangeValue={handlePackageChange}
        value={value}
        descriptionTestId="package-description"
      />
    </FormRow>
  );
};
