import * as z from 'zod';
import { CalendarDateSchema } from 'common/types';

export const DeliveryTimeConfigSchema = z.object({
  firstDeliveryAt: CalendarDateSchema,
  disabledDays: z.preprocess((data) => {
    if (Array.isArray(data)) {
      return data.filter((d) => !z.number().safeParse(d).success);
    }
    return [];
  }, z.array(CalendarDateSchema))
});

export type DeliveryTimeConfig = z.output<typeof DeliveryTimeConfigSchema>;
