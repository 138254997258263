import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { OTHER_PACKAGE_LEAVING_OPTION } from '../constants/packageLeavingOptions';
import { Address } from '../types';

interface LeavingBagSummaryProps {
  data: Address;
  className?: string;
}

export const LeavingBagSummary = ({
  data: { packageLeavingOption, otherPackageLeavingOption },
  className
}: LeavingBagSummaryProps) => {
  const leavingOption =
    packageLeavingOption.id === OTHER_PACKAGE_LEAVING_OPTION ? otherPackageLeavingOption : packageLeavingOption.value;
  const classes = useStyle();
  return <Typography className={clsx(classes.root, className)}>{leavingOption}</Typography>;
};

const useStyle = makeStyles((theme) => ({
  root: {
    color: theme.customColors.textDarkGrey,
    '&:first-letter': {
      textTransform: 'uppercase'
    },
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 300
  }
}));
