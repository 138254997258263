import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { GridCalendar, CheckIfDayIsDisabledHandler, CalculateDayStyleHandler } from 'common/components';
import { CalendarDate } from 'common/types';
import { maxDaysIntervalToStartOrder, maxDaysToMarkInOrderCalendar } from 'features/orders';
import { useDietConfiguration } from 'features/orders/contexts';
import { CreateDietFormData } from 'features/orders/types';
import { useCallback, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { useAllowWeekends } from 'features/orders/hooks/useAllowWeekends';
import { i18n } from 'common/utils/i18n';
import { CustomDeliveryCalendarFooter } from './CustomDeliveryCalendarFooter';

interface CustomDeliveryDatesCalendarProps {
  currentVisibleMonth: CalendarDate;
  onMonthChange: (newMonth: CalendarDate) => void;
}

export const CustomDeliveryDatesCalendar = ({
  currentVisibleMonth,
  onMonthChange
}: CustomDeliveryDatesCalendarProps) => {
  const classes = useStyle();
  const {
    deliveryTimeConfig: { disabledDays, firstDeliveryAt },
    daysWithDeliveries
  } = useDietConfiguration();
  const allowWeekends = useAllowWeekends();

  const {
    field: { value, onChange }
  } = useController<CreateDietFormData, 'customDeliveryDates'>({
    name: 'customDeliveryDates'
  });

  const maxDate = useMemo(() => firstDeliveryAt.add(maxDaysIntervalToStartOrder, 'd'), [firstDeliveryAt]);
  const checkIfDayIsDisabled = useCallback(
    (day: CalendarDate) => {
      const isDisabledByAPI = disabledDays.some((disabledDay) => disabledDay.isSame(day, 'd'));
      if (!allowWeekends) {
        return isDisabledByAPI || day.isWeekend();
      }
      return isDisabledByAPI;
    },
    [disabledDays, allowWeekends]
  );
  const calculatedDisabledDates: CheckIfDayIsDisabledHandler = useCallback(
    (day) => checkIfDayIsDisabled(day),
    [checkIfDayIsDisabled]
  );

  const calculateDayStyle: CalculateDayStyleHandler = (day) => {
    const isDisabledDate = checkIfDayIsDisabled(day);
    const isDayWithDelivery = daysWithDeliveries.some((dayWithDelivery) => dayWithDelivery.isSame(day, 'd'));
    if (isDisabledDate) {
      return {};
    }
    if (value.some((v) => v.isSame(day, 'd'))) {
      return {
        button: classes.dateInRangeButton,
        label: isDayWithDelivery ? clsx(classes.dayWithDelivery, classes.inversedDayWithDelivery) : undefined
      };
    }
    return {
      label: isDayWithDelivery ? classes.dayWithDelivery : undefined
    };
  };

  const handleClick = (selectedDate: CalendarDate) => {
    const selectedDateIndex = value.findIndex((day) => day.isSame(selectedDate));
    if (selectedDateIndex !== -1) {
      const newValue = [...value.slice(0, selectedDateIndex), ...value.slice(selectedDateIndex + 1)];
      onChange(newValue);
    } else if (value.length < maxDaysToMarkInOrderCalendar) {
      onChange([...value, selectedDate]);
    }
  };

  const handleReset = () => onChange([]);

  return (
    <GridCalendar
      minDate={firstDeliveryAt}
      maxDate={maxDate}
      visibleDate={currentVisibleMonth}
      onIntervalChange={onMonthChange}
      locale={i18n.language}
      calculateDisabledDates={calculatedDisabledDates}
      calculateDayStyle={calculateDayStyle}
      onClick={handleClick}
      footer={<CustomDeliveryCalendarFooter selectedDaysNumber={value.length} onCalendarReset={handleReset} />}
    />
  );
};

const useStyle = makeStyles((theme) => ({
  firstDeliveryDateButton: {
    backgroundColor: theme.customColors.deliveryCalendar.firstDeliveryDate.background
  },
  dateInRangeButton: {
    backgroundColor: theme.customColors.deliveryCalendar.inDateRangeDate.background
  },
  dayWithDelivery: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 12,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: '#FFE123'
    }
  },
  inversedDayWithDelivery: {
    '&:after': {
      backgroundColor: theme.customColors.black
    }
  }
}));
