import { makeStyles, Typography } from '@material-ui/core';
import { ReactNode } from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';

export interface OnboardStepProps {
  icon: string;
  title: TFuncKey;
  content: TFuncKey;
  cta?: ReactNode;
}

export const OnboardStep = ({ icon, title, content, cta }: OnboardStepProps): JSX.Element => {
  const classes = useStyle();

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img className={classes.icon} src={icon} alt="" />
        <Typography component="h2" className={classes.title}>
          {t(title) as string}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {t(content) as string}
        </Typography>
        {cta}
      </div>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    width: '100%',
    marginBottom: '0.5rem',
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '600px'
  },
  icon: {
    width: '4rem',
    marginBottom: '1rem'
  },
  title: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 'bold',
    marginBottom: '1.5rem',
    textAlign: 'center'
  },
  description: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: 0.3,
    textAlign: 'center'
  }
});
