import MaterialPaper from '@material-ui/core/Paper';
import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

interface PaperProps {
  children: ReactNode;
  className?: string;
}

export const Paper = ({ children, className }: PaperProps) => {
  const classes = useStyle();

  return <MaterialPaper className={clsx(className, classes.paper)}>{children}</MaterialPaper>;
};

const useStyle = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.boxShadow.lightGrey,
    padding: '1.5rem'
  }
}));
