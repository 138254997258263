import { z } from 'zod';

import { Id } from 'common/types';

import { ApiClient } from 'features/apiProvider';

interface GetPaymentUrlRequest extends ApiClient {
  orderId: Id;
  continueUrl: string;
  cancelUrl: string;
}

const getPaymentUrlSchema = z.object({
  redirectUri: z.string()
});

export const getPaymentUrl = async ({ apiClient, orderId, continueUrl, cancelUrl }: GetPaymentUrlRequest) => {
  const response = await apiClient
    .get(`frontend/secure/order/${orderId}/payment/details`, {
      searchParams: {
        continueUrl,
        cancelUrl
      }
    })
    .json();

  const { redirectUri } = getPaymentUrlSchema.parse(response);

  return redirectUri;
};
