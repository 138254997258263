import { makeStyles, Paper } from '@material-ui/core';
import { ReactNode } from 'react';
import noop from 'lodash/noop';
import clsx from 'clsx';
import { Navigation, DaysGrid } from '../components';
import { CalendarProps } from '../types';
import { useCalendarControl } from '../useCalendarControl';
import { getMonthRange } from '../getMonthRange';

interface GridCalendarProps extends CalendarProps {
  footer?: ReactNode;
}

export const GridCalendar = ({
  locale,
  onClick = noop,
  visibleDate,
  minDate,
  maxDate,
  className,
  calculateDisabledDates: outerCalculateDisabledDates,
  calculateDayStyle,
  footer,
  onIntervalChange
}: GridCalendarProps) => {
  const classes = useStyle();
  const { start, end } = getMonthRange({ visibleDate, locale, showFullWeeks: true });
  const { calculateDisabledDates, canNavigateToNexInterval, canNavigateToPreviousInterval } = useCalendarControl({
    firstDate: start,
    lastDate: end,
    calculateDisabledDates: outerCalculateDisabledDates,
    maxDate,
    minDate
  });

  const handlePrevMonth = () => {
    const newCurrentVisibleMonth = visibleDate.subtract(1, 'M');
    onIntervalChange(newCurrentVisibleMonth);
  };

  const handleNextMonth = () => {
    const newCurrentVisibleMonth = visibleDate.add(1, 'M');
    onIntervalChange(newCurrentVisibleMonth);
  };

  return (
    <Paper className={clsx(classes.container, className)}>
      <Navigation
        locale={locale}
        currentDate={visibleDate}
        onPrevButtonClick={handlePrevMonth}
        onNextButtonClick={handleNextMonth}
        disablePrevButton={!canNavigateToPreviousInterval}
        disableNextButton={!canNavigateToNexInterval}
        dateFormat="MMMM YYYY"
      />
      <DaysGrid
        current={visibleDate}
        locale={locale}
        onClick={onClick}
        checkIfDayIsDisabled={calculateDisabledDates}
        calculateDayStyle={calculateDayStyle}
        showFullWeeks
      />
      {footer}
    </Paper>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    paddingTop: '0.5rem',
    boxShadow: `0 0.125rem 0.375rem 0 ${theme.customColors.textLightGrey}`
  }
}));
