import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { useButtonStyles } from './styles';

interface ButtonLinkProps {
  to: string;
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  external?: boolean;
  mode?: 'primary' | 'secondary';
  onClick?: VoidFunction;
}

export const ButtonLink = ({
  className,
  children,
  to,
  fullWidth,
  external,
  onClick,
  mode = 'primary'
}: ButtonLinkProps): JSX.Element => {
  const classes = useButtonStyles();

  return (
    <Button
      variant="outlined"
      className={clsx(
        {
          [classes.button]: true,
          [classes.primary]: mode === 'primary',
          [classes.secondary]: mode === 'secondary'
        },
        className
      )}
      component={external ? 'a' : Link}
      {...(external ? { href: to } : { to })}
      onClick={onClick}
      fullWidth={fullWidth}
    >
      {children}
    </Button>
  );
};
