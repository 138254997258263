import { Button, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSession } from 'features/authentication';
import { useTranslation } from 'react-i18next';

export const ImpersonatorWarning = () => {
  const { isImpersonated, logout } = useSession();
  const classes = useStyle();
  const { t } = useTranslation();
  if (!isImpersonated()) {
    return null;
  }
  return (
    <Alert
      severity="error"
      className={classes.root}
      action={
        <Button color="inherit" size="small" onClick={logout}>
          {t('menuItems.logout')}
        </Button>
      }
    >
      {t('impersonatorWarning')}
    </Alert>
  );
};

const useStyle = makeStyles({
  root: {
    marginBottom: '1rem'
  }
});
