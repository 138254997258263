import { ApiClient } from 'features/apiProvider';

import { ManageProfileFormData, Profile, profileSchema } from '../types';

interface CreateProfileRequest extends ApiClient {
  data: ManageProfileFormData;
}

export const createProfile = async ({ data, apiClient }: CreateProfileRequest): Promise<Profile> => {
  const response = await apiClient
    .post('v3/diet/create', {
      json: data
    })
    .json();

  return profileSchema.parse(response);
};
