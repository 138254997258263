import { Id } from 'common/types';
import { ApiClient } from 'features/apiProvider';
import { z } from 'zod';

const hasPlannedDeliveriesSchema = z.object({
  has_planned_deliveries: z.boolean()
});

interface HasPlannedDeliveriesRequest extends ApiClient {
  id: Id;
}

export const hasPlannedDeliveries = async ({ id, apiClient }: HasPlannedDeliveriesRequest): Promise<boolean> => {
  const response = await apiClient.get(`v3/addresses/has-planned-deliveries/${id}`).json();
  const parsedResponse = hasPlannedDeliveriesSchema.parse(response);
  return parsedResponse.has_planned_deliveries;
};
