import { makeStyles } from '@material-ui/core';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { BaseDashboardLayout } from 'layouts';
import { Footer } from 'layouts/components';
import { ReactNode } from 'react';
import { Header } from './Header';

interface DietVariantLayoutProps {
  children: NonNullable<ReactNode>;
  variantName: string;
  mobileSelectVariantButton: ReactNode | null;
  isLoading?: boolean;
}

export const DietVariantLayout = ({
  children,
  variantName,
  isLoading,
  mobileSelectVariantButton
}: DietVariantLayoutProps): JSX.Element => {
  const isMobile = useMobileScreenSizeDetector();
  const classes = useStyle();

  const getFooter = () => {
    if (isMobile) {
      if (mobileSelectVariantButton) {
        return <div className={classes.buttonWrapper}>{mobileSelectVariantButton}</div>;
      }
      return undefined;
    }
    return <Footer />;
  };
  return (
    <BaseDashboardLayout
      header={<Header name={variantName} />}
      footer={getFooter()}
      fullHeight={isMobile}
      isLoading={isLoading}
    >
      {children}
    </BaseDashboardLayout>
  );
};

const useStyle = makeStyles((theme) => ({
  buttonWrapper: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      background: theme.customColors.white,
      boxShadow: theme.boxShadow.lightGrey
    }
  }
}));
