import { ApiClient } from 'features/apiProvider';

interface UpdateInvoicesParams extends ApiClient {
  requestData: {
    needFv: boolean;
    nip: string;
  };
}

export const updateInvoices = ({ requestData, apiClient }: UpdateInvoicesParams) => {
  return apiClient.patch('v4/secure/client/update-fv-and-nip', { json: { data: requestData } });
};
