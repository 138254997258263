import { Paper, Typography, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { Button } from 'common/components';
import { OrderHistoryItem } from 'services/orderHistory/fetchOrderHistory';
import { StatusBadge } from '../StatusBadge';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { getStatus } from './utils/getStatus';

import { getDietLength } from './utils/getDietLength';
import { getSelectedDietName } from './utils/getDietName';

interface Props {
  item: OrderHistoryItem;
  onCancelOrder: VoidFunction;
}

export const ListItem = ({ item, onCancelOrder }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const status = getStatus(item.paymentStatus);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography className={clsx(classes.bold, status && !['active', 'partial'].includes(status) && classes.gray)}>
          {item.dietName || t('orderHistoryPage.item.dietNameUnknown')}
        </Typography>
        <StatusBadge status={status} />
      </div>
      <div className={classes.details}>
        <Typography>{getSelectedDietName(item, t)}</Typography>
        {!isMobile && <Typography>•</Typography>}
        <Typography>{getDietLength(item, t)}</Typography>
      </div>
      <div className={classes.buttonWrapper}>
        {item.paymentStatus.systemValue === 'ORDER_PARTIAL_PAID' && (
          <Button className={classes.button} mode="secondary" onClick={onCancelOrder}>
            {t('orderHistoryPage.item.cancel.cta')}
          </Button>
        )}
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: 'auto auto',
    gap: '0.5rem',
    padding: '1rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto auto auto'
    }
  },
  gray: {
    color: theme.customColors.dimGrey
  },
  bold: {
    fontWeight: 700,
    fontSize: '1rem'
  },
  header: {
    display: 'flex',
    gap: '0.5rem',
    gridColumn: '1',
    alignItems: 'center'
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  details: {
    display: 'flex',
    gap: '0.5rem',
    color: theme.customColors.dimGrey,
    fontSize: '0.875rem',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  buttonWrapper: {
    gridRow: '1 / span 2',
    gridColumn: '2',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1',
      gridRow: 'auto',
      justifyContent: 'center'
    }
  }
}));
