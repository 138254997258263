import { Grid } from '@material-ui/core';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { germanWordpressRoutes } from 'routing/constants/germanWordpressRoutes';
import { country } from 'config';
import { useSession } from 'features/authentication';
import { BackLink } from './BackLink';
import { MoneyBox } from './Moneybox';

interface OrderHeaderProps {
  backUrl: RouterLinkProps['to'];
  onClick?: VoidFunction;
}
/**
 * @deprecated - Use SubPageLayout instead
 */
export const OrderHeader = ({ backUrl, onClick }: OrderHeaderProps) => {
  const { t } = useTranslation();
  const { logout } = useSession();

  const handleClick = () => {
    if (country === 'germany') {
      logout();
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <Grid container alignItems="center">
      <Grid item xs={6}>
        <BackLink
          onClick={handleClick}
          external={country === 'germany'}
          to={country === 'germany' ? germanWordpressRoutes.configurator : backUrl}
          label={t('backButton')}
        />
      </Grid>
      <Grid item container justify="flex-end" xs={6}>
        <MoneyBox />
      </Grid>
    </Grid>
  );
};
