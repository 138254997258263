import { BaseLayout, BaseLayoutProps } from 'layouts';
import { Container, makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';
import { ImpersonatorWarning } from 'layouts/components';
import { BaseDashboardHeader } from './BaseDashboardHeader';

export interface BaseDashboardLayoutProps extends Omit<BaseLayoutProps, 'header'> {
  header: NonNullable<ReactNode>;
}

export const BaseDashboardLayout = ({ children, header, isLoading, footer, fullHeight }: BaseDashboardLayoutProps) => {
  const classes = useStyle();
  return (
    <BaseLayout
      header={<BaseDashboardHeader>{header}</BaseDashboardHeader>}
      isLoading={isLoading}
      footer={footer}
      fullHeight={fullHeight}
    >
      <Container maxWidth="lg" className={classes.container}>
        <ImpersonatorWarning />
        {children}
      </Container>
    </BaseLayout>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    marginTop: '2.5rem',
    marginBottom: '3.25rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5)
    }
  }
}));
