import { ApiClient } from 'features/apiProvider';
import { AddressVerificationFormData } from 'features/addresses/types';
import { verifyAddress } from 'features/addresses/api';
import { NewOrderAddresses } from '../types';

interface GetDeliveryAddressConfigRequest extends ApiClient {
  addresses: {
    standard: AddressVerificationFormData;
    festive: AddressVerificationFormData | null;
  };
}

export const getDeliveryAddressConfig = async ({
  apiClient,
  addresses
}: GetDeliveryAddressConfigRequest): Promise<NewOrderAddresses> => {
  const promisesQueue = [
    verifyAddress({
      data: addresses.standard,
      apiClient
    })
  ];

  if (addresses.festive) {
    promisesQueue.push(
      verifyAddress({
        data: addresses.festive,
        apiClient
      })
    );
  }

  const [standard, festive] = await Promise.all(promisesQueue);

  return {
    standard,
    festive: addresses.festive ? festive : null
  };
};
