import { IconButton, makeStyles, MobileStepper } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useTranslation } from 'react-i18next';

interface SteperProps {
  steps: number;
  activeStep: number;
  onNext: VoidFunction;
  onBack: VoidFunction;
}

export const Stepper = ({ steps, activeStep, onNext, onBack }: SteperProps): JSX.Element => {
  const { t } = useTranslation();

  const classes = useStyle();

  return (
    <MobileStepper
      variant="dots"
      steps={steps}
      position="static"
      activeStep={activeStep}
      backButton={
        <IconButton className={classes.button} aria-label={t('onboarding.stepperPreviousStepImage')} onClick={onBack}>
          <ChevronLeftIcon />
        </IconButton>
      }
      nextButton={
        <IconButton className={classes.button} aria-label={t('onboarding.stepperNextStepImage')} onClick={onNext}>
          <ChevronRightIcon />
        </IconButton>
      }
      classes={{
        root: classes.stepper,
        dot: classes.dot,
        dotActive: classes.dotActive
      }}
    />
  );
};

const useStyle = makeStyles((theme) => ({
  button: {
    padding: '0.5rem',
    color: theme.customColors.black
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: 0,
    flex: 1
  },
  dot: {
    margin: '0 0.2rem',
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:first-child': {
      /* do not display first step dot */
      display: 'none !important'
    }
  },
  dotActive: {
    backgroundColor: theme.palette.secondary.main
  }
}));
