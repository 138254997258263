import { NavLink } from 'react-router-dom';
import { TFuncKey, useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useNavigationElementsStyle } from 'common/styles/navigationElementsStyle';

interface NavigationLinkProps {
  icon?: string;
  path: string;
  label: string;
  wrapperClassName?: string;
  exact?: boolean;
}

export const NavigationLink = ({
  path,
  icon,
  label,
  wrapperClassName,
  exact = true
}: NavigationLinkProps): JSX.Element => {
  const classes = useNavigationElementsStyle();
  const { t, i18n } = useTranslation();

  const translatedLabel = i18n.exists(label) ? t(label as TFuncKey) : label;

  return (
    <NavLink exact={exact} to={path} activeClassName="active" className={clsx(classes.basicLink, wrapperClassName)}>
      <>
        {icon && <img src={icon} alt="" className={classes.menuElementIcon} />}
        {translatedLabel}
      </>
    </NavLink>
  );
};
