import { country } from 'config';

const polishTaxNumberValidation = (taxNumber: string) => {
  const parsedNipDigits = taxNumber.split('').map((nipDigit) => Number(nipDigit));
  const controlNumber = parsedNipDigits.pop();
  const controlValues = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  const sum = parsedNipDigits.reduce((acc, nipDigit, index) => {
    return acc + nipDigit * controlValues[index];
  }, 0);

  return controlNumber === sum % 11;
};

export const germanyTaxNumberValidation = (taxNumber: string) => {
  const variant1 = /^(?=.*\d)([\d/]{10,20})(?! )$/;
  const variant2 = /^(?=.*\d)([\d ]{10,20})(?! )$/;
  if (variant1.test(taxNumber) || variant2.test(taxNumber)) {
    return true;
  }
  return false;
};

const czechTaxNumberValidator = (taxNumber: string) => {
  return /^\w{8,12}$/i.test(taxNumber);
};

export const taxNumberValidation = (taxNumber: string) => {
  if (taxNumber === '0000000000') {
    return false;
  }

  switch (country) {
    case 'czech':
      return czechTaxNumberValidator(taxNumber);
    case 'poland':
      return polishTaxNumberValidation(taxNumber);
    case 'germany':
      return germanyTaxNumberValidation(taxNumber);
    default:
      throw new Error(`unhandled tax number validation for ${country}`);
  }
};
