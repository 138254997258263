import { ApiClient } from 'features/apiProvider';
import { Aggregate, ClientData, clientDataSchema } from './types';

export const fetchClient = async ({
  apiClient,
  aggregates
}: ApiClient & { aggregates: Aggregate[] }): Promise<ClientData> => {
  const searchParams = new URLSearchParams();
  searchParams.set('aggregates__in', aggregates.join(','));

  const response = await apiClient.get(`v5/client?${searchParams.toString()}`).json();

  return clientDataSchema.parse(response);
};
