import { useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Theme } from 'common/components';
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

export interface ResponsiveImageProps
  extends Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'> {
  mobileSrc: string;
  desktopSrc: string;
  classes?: Partial<{
    root: string;
    img: string;
  }>;
}

export const ResponsiveImage = ({
  mobileSrc,
  desktopSrc,
  classes,
  className,
  alt,
  ...rest
}: ResponsiveImageProps): JSX.Element => {
  const theme = useTheme<Theme>();
  const mobileQuery = `(max-width: ${theme.breakpoints.values.md - 1}px)`;
  const desktopQuery = `(min-width: ${theme.breakpoints.values.md}px)`;

  return (
    <picture className={classes?.root}>
      <source media={mobileQuery} srcSet={mobileSrc} />
      <source media={desktopQuery} srcSet={desktopSrc} />
      <img src={desktopSrc} className={clsx(className, classes?.img)} alt={alt} {...rest} />
    </picture>
  );
};
