import { sortBy } from 'lodash';
import { dietLenghtOptions } from '../constants';

export const getDietLenghtOptions = (withoutWeekends: boolean) => {
  const filtredOptions = dietLenghtOptions.filter((o) => o.withoutWeekends === withoutWeekends);

  return sortBy(filtredOptions, (o) => {
    if (o.type === 'continuous') {
      return o.days;
    }
    // Custom options should be the first one
    return 0;
  });
};
