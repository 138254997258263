import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { useSession } from 'features/authentication';
import { fetchProfile, UserProfileResponse } from 'features/user/api';
import { useApi } from 'features/apiProvider';

import { Address } from 'features/addresses/types';

import { fetchAddresses } from 'features/addresses/api';
import { undefinedUserId } from 'common/constants';
import { useUserPreviousOrders } from 'common/hooks/useUserPreviousOrders';

import { Experiments } from 'services/experiments/types';
import { useExperimentsForUserQuery } from 'services/experiments';
import { UserProfile, Request } from '../types';

interface UserContextShape {
  profile: Request<UserProfileResponse> & UserProfile;
  addresses: Request<Address[]> & {
    data: Address[];
  };
  experiments: Experiments;
}

const UserContext = React.createContext<UserContextShape | undefined>(undefined);

interface UserContextProviderProps {
  children: React.ReactNode;
}

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const { token } = useSession();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getApiClient } = useApi();
  const client = useQueryClient();
  const apiClient = getApiClient();
  const hasPreviousOrders = useUserPreviousOrders();

  const profile = useQuery({
    queryKey: 'profile',
    queryFn: () => fetchProfile(apiClient),
    enabled: !!token,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['profile', profile.data?.moneybox] });
    },
    onError: () => {
      enqueueSnackbar(t('snackbarError.title'), {
        variant: 'refreshableError',
        onExited: () => profile.refetch()
      });
    }
  });

  const experiments = useExperimentsForUserQuery(profile.data?.id);

  const addresses = useQuery({
    queryKey: 'addresses',
    queryFn: () => fetchAddresses(apiClient),
    enabled: !!token,
    onError: () => {
      enqueueSnackbar(t('snackbarError.title'), {
        variant: 'refreshableError',
        onExited: () => addresses.refetch()
      });
    }
  });

  const ctxValue: UserContextShape = {
    profile: {
      firstName: profile.data?.firstName ?? '',
      lastName: profile.data?.lastName ?? '',
      phone: profile.data?.phone ?? '',
      username: profile.data?.username ?? '',
      email: profile.data?.email ?? '',
      id: profile.data?.id ?? undefinedUserId,
      subscriptionEmail: profile.data?.emailMarketingAgreement ?? false,
      subscriptionPhone: profile.data?.phoneMarketingAgreement ?? false,
      wallet: profile.data?.wallet ?? 0,
      moneybox: (profile?.data?.wallet ?? 0) + (profile.data?.moneybox ?? 0),
      enableInvoices: profile.data?.needFv ?? false,
      taxNumber: profile.data?.nip ?? '',
      discountCode: profile.data?.recommendedCode ?? '',
      isError: profile.isError,
      isLoading: profile.isLoading,
      isRefetching: profile.isRefetching,
      refetch: profile.refetch,
      hasPreviousOrders
    },
    addresses: {
      isError: addresses.isError,
      isLoading: addresses.isLoading,
      isRefetching: addresses.isRefetching,
      refetch: addresses.refetch,
      data: addresses.data ?? []
    },
    experiments: experiments.experiments || {}
  };

  return <UserContext.Provider value={ctxValue}>{children}</UserContext.Provider>;
};

export function useUser() {
  const userCtx = React.useContext(UserContext);
  if (userCtx === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return userCtx;
}
