import { z } from 'zod';

import { ApiClient } from 'features/apiProvider';
import { CalendarDateSchema, Id } from 'common/types';

const daysWithDeliveriesSchema = z.array(CalendarDateSchema);

interface FetchDaysWithDeliveriesRequest extends ApiClient {
  profileId: Id;
}

export const fetchDaysWithDeliveries = async ({ apiClient, profileId }: FetchDaysWithDeliveriesRequest) => {
  const response = await apiClient.get(`v3/client/delivery-days/${profileId}`).json();

  return daysWithDeliveriesSchema.parse(response);
};
