import { makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import { ReactComponent as caloricSummaryIcon } from 'common/icons/icon-caloric-summary.svg';
import { convertKcalToKj } from 'common/utils/convertKcalToKj';
import { useTranslation } from 'react-i18next';

interface CalorificSummaryProps {
  onSelect: VoidFunction;
  onBack: VoidFunction;
  calories: number;
}

export const CalorificSummary = ({ onBack, onSelect, calories }: CalorificSummaryProps) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <div>
      <div className={classes.summaryInfo}>
        <SvgIcon component={caloricSummaryIcon} viewBox="0 0 164 164" className={classes.caloricSummaryIcon} />
        <div className={classes.text}>
          <Typography variant="body1" color="textSecondary">
            {t('creatDietPage.caloriesSection.caloricCalculator.calorificSummary.dailyRequirement')}
          </Typography>
          <Typography variant="h1">
            {t('creatDietPage.summaryBox.caloriesSmall', { calories, kj: convertKcalToKj(calories) })}
          </Typography>
        </div>
      </div>
      <div className={classes.buttonsSummary}>
        <Button mode="secondary" onClick={onBack}>
          {t('creatDietPage.caloriesSection.caloricCalculator.calorificSummary.calculateAgain.buttonBack')}
        </Button>
        <Button mode="primary" onClick={onSelect}>
          {t('creatDietPage.caloriesSection.caloricCalculator.calorificSummary.calculateAgain.buttonSubmit')}
        </Button>
      </div>
    </div>
  );
};

const useStyle = makeStyles(() => ({
  summaryInfo: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    flexDirection: 'column',
    borderRadius: '0.5rem'
  },
  caloricSummaryIcon: {
    fontSize: '10.25rem',
    marginTop: '3rem'
  },
  buttonsSummary: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    marginTop: '4.8rem',
    marginBottom: '1.5rem'
  },
  text: {
    marginBottom: '3rem',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center'
  }
}));
