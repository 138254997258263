import ky, { Options } from 'ky';

import { AuthorizationResponse } from '../types';

interface RefreshAuthorizationTokenParams {
  refreshToken: string;
}

export const refreshAuthorizationToken = async ({ refreshToken }: RefreshAuthorizationTokenParams) => {
  /**
   * Creating ky request manually because its over the api provider. Api provider depends on sessionContext so we cant use it right here.
   * By default this request is try catched in sessionContext and if it fails it will logout user.
   */
  const config: Options = {
    prefixUrl: process.env.REACT_APP_API_URI as string
  };

  const response = await ky
    .create(config)
    .post('v3/token/refresh', { json: { refresh_token: refreshToken } })
    .json();

  return AuthorizationResponse.parse(response);
};
