import { Typography, IconButton, makeStyles } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { AddressSummary } from 'features/addresses';
import { Address } from 'features/addresses/types';
import { useTranslation } from 'react-i18next';
import { AddressStatus } from './AddressStatus';

interface AddressDetailsProps {
  data: Address;
  onOpenMenu: VoidFunction;
}

export const AddressDetails = ({ data, onOpenMenu }: AddressDetailsProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h4" className={classes.name} noWrap>
          {data.name}
        </Typography>
        <IconButton onClick={onOpenMenu} className={classes.icon} aria-label={t('addressesPage.addressCard.menu.open')}>
          <MoreHorizIcon />
        </IconButton>
      </div>
      <AddressSummary data={data} includeDeliveryHours />
      <AddressStatus isSaturdayByDefault={data.isSaturdayByDefault} isStandardByDefault={data.isStandardByDefault} />
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    padding: '1rem'
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    padding: '0.5rem',
    color: theme.customColors.black
  },
  name: {
    flex: 1
  }
}));
