import { CircularProgress, Dialog, makeStyles, Paper, Typography } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import { ReactComponent as CloseIcon } from 'common/icons/icon-times.svg';
import { ReactComponent as InfoIcon } from 'common/icons/icon-info-alt.svg';
import { Button } from 'common/components/Button';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  isPending: boolean;
}

export const ChangeUserDataConfirmationModal = ({ isOpen, onClose, onConfirm, isPending }: Props) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const handleCloseModal = () => {
    if (isPending) {
      return;
    }
    onClose();
  };

  const handleExited = useCallback(() => {
    const rootDiv = document.getElementById('root');
    rootDiv?.classList.remove(classes.rootBackdrop);
  }, [classes.rootBackdrop]);

  const handleEntering = () => {
    const rootDiv = document.getElementById('root');
    rootDiv?.classList.add(classes.rootBackdrop);
  };

  useEffect(() => {
    return () => {
      if (isOpen) {
        handleExited();
      }
    };
  }, [handleExited, isOpen]);
  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleCloseModal} onEntering={handleEntering} onExiting={handleExited}>
      <Paper className={classes.root}>
        <div className={classes.content}>
          <button className={classes.closeButton} onClick={handleCloseModal} type="button">
            <CloseIcon />
          </button>
          <InfoIcon className={classes.icon} />
          <Typography className={classes.text}>{t('personalDataPage.confirm-data-change-title')}</Typography>
          <div className={classes.buttons}>
            <Button mode="secondary" onClick={handleCloseModal} disabled={isPending}>
              {t('personalDataPage.cancel')}
            </Button>
            <Button mode="primary" onClick={onConfirm} disabled={isPending}>
              {isPending ? <CircularProgress size={16} /> : t('personalDataPage.confirm-data-change')}
            </Button>
          </div>
        </div>
      </Paper>
    </Dialog>
  );
};

const useStyle = makeStyles((theme) => ({
  rootBackdrop: {
    [theme.breakpoints.up('xs')]: {
      overflow: 'hidden',
      filter: 'blur(7px)'
    }
  },
  buttons: {
    display: 'grid',
    maxWidth: '38rem',
    width: '100%',
    gap: '1.25rem',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gap: '1rem'
    }
  },
  text: {
    color: theme.customColors.orangeLight,
    fontWeight: 700,
    fontSize: '1.25rem',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '1.5rem'
    }
  },
  root: {
    width: 'calc(100vw - 6rem)',
    maxWidth: '49rem',
    position: 'relative',
    padding: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      position: 'fixed',
      inset: 0,
      backgroundColor: theme.customColors.white
    }
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      justifyContent: 'center'
    }
  },
  icon: {
    width: '4rem',
    height: '4rem',
    transform: 'rotate(180deg)'
  },
  closeButton: {
    all: 'unset',
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 20,
    right: 0,
    top: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
