import * as z from 'zod';

import { ApiClient } from 'features/apiProvider';

interface UpdatePasswordRequest extends ApiClient {
  requestData: {
    password: string;
    token: string;
  };
}

const updatePasswordResponseSchema = z.object({
  messages: z.array(z.string())
});

type UpdatePasswordResponse = z.TypeOf<typeof updatePasswordResponseSchema>;

export const updatePasswordRequest = async ({
  apiClient,
  requestData
}: UpdatePasswordRequest): Promise<UpdatePasswordResponse> => {
  const response = await apiClient.post('v3/account/remind_password/update', { json: requestData }).json();

  return updatePasswordResponseSchema.parse(response);
};
