import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { CalendarDate } from 'common/types';
import { mergeWith } from 'lodash';
import { DayTile } from '../components';
import { CalculateDayStyleHandler, CheckIfDayIsDisabledHandler } from '../types';

interface DayTilesProps {
  dates: CalendarDate[];
  onClick: (selectedDate: CalendarDate) => void;
  locale: string;
  calculateDayStyle?: CalculateDayStyleHandler;
  checkIfDayIsDisabled: CheckIfDayIsDisabledHandler;
}

export const DayTiles = ({
  dates,
  onClick,
  locale,
  checkIfDayIsDisabled,
  calculateDayStyle: outsideCaclucateDayStyle = dummyCalculateDayStyle
}: DayTilesProps) => {
  const classes = useStyle();

  const calculateDayStyle: CalculateDayStyleHandler = (date) => {
    return mergeWith(
      {
        button: classes.dayTileButton
      },
      outsideCaclucateDayStyle(date),
      (base, additional) => clsx(base, additional)
    );
  };

  return (
    <div className={classes.root}>
      {dates.map((date) => (
        <DayTile
          key={date.unix()}
          value={date}
          onClick={onClick}
          locale={locale}
          styles={calculateDayStyle(date)}
          disabled={checkIfDayIsDisabled(date)}
        />
      ))}
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  dayTileButton: {
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.customColors.calendar.borderColor,
    '&:last-of-type': {
      borderRightWidth: 1
    }
  }
}));

const dummyCalculateDayStyle: CalculateDayStyleHandler = () => ({});
