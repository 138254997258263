import { useNotifyDialog } from 'common/contexts';
import { deleteAddress } from 'features/addresses/api';
import { useUser } from 'features/user';
import { HTTPError } from 'ky';
import { useMutation } from 'react-query';

const isDeleteAssignedAddressError = (error: unknown) => error instanceof HTTPError && error.response.status === 425;

export const useDeleteAddress = () => {
  const { addresses } = useUser();
  const { showSuccessDialog, showErrorDialog } = useNotifyDialog();
  return useMutation({
    mutationFn: deleteAddress,
    onSuccess: () => {
      addresses.refetch();
      showSuccessDialog({ message: 'addressesPage.successes.deleteAddress' });
    },
    onError: (error) => {
      const message = isDeleteAssignedAddressError(error)
        ? 'addressesPage.errors.deleteAssignedAddress'
        : 'notificationDialog.error.message';
      showErrorDialog({
        message
      });
    }
  });
};
