import { z } from 'zod';

import { Id } from 'common/types';

import { ApiClient } from 'features/apiProvider';

interface GetOrderDetailsRequest extends ApiClient {
  orderId: Id;
}

const getOrderResponseSchema = z.object({
  id: z.number(),
  priceToPay: z.number(),
  priceDiscount: z.number(),
  discountCode: z.object({ code: z.string(), discount: z.number() }).nullable(),
  priceAfterDiscount: z.number(),
  priceFromMoneybox: z.number(),
  priceFromWallet: z.number(),
  shouldGetPriceFromMoneybox: z.number(),
  shouldGetPriceFromWallet: z.number(),
  diet: z
    .object({
      bags: z.array(
        z.object({
          bagItems: z.array(
            z.object({
              mealType: z.object({ id: z.number(), name: z.string() }),
              size: z.object({ calorific: z.number(), nameForClient: z.string() }),
              priceBeforeDiscount: z.number()
            })
          ),
          bagItemsDelivery: z.array(
            z.object({
              priceAfterDiscount: z.number()
            })
          )
        })
      ),
      package: z.object({ id: z.number(), name: z.string() }),
      defaultVariant: z.object({ id: z.number(), name: z.string() })
    })
    .nullable()
});

type GetOrderResponse = z.infer<typeof getOrderResponseSchema>;

const orderDetailsTransformer = ({
  id,
  priceToPay,
  diet,
  shouldGetPriceFromMoneybox,
  shouldGetPriceFromWallet,
  discountCode,
  priceDiscount,
  priceFromMoneybox,
  priceFromWallet,
  priceAfterDiscount
}: GetOrderResponse) => {
  const finalPriceToPay = Math.max(priceToPay - shouldGetPriceFromMoneybox - shouldGetPriceFromWallet, 0);
  return {
    id,
    ordersPrice: priceToPay,
    priceToPay: finalPriceToPay,
    diet,
    discountCode,
    priceDiscount,
    priceFromMoneybox: priceFromMoneybox + priceFromWallet,
    priceAfterDiscount
  };
};

export const getOrderDetails = async ({ apiClient, orderId }: GetOrderDetailsRequest) => {
  const response = await apiClient.get(`frontend/secure/order/${orderId}`).json();

  return orderDetailsTransformer(getOrderResponseSchema.parse(response));
};
