import { Container, Grid, makeStyles } from '@material-ui/core';
import { HiddenOnMobile } from 'common/components';
import { useMobileScreenSizeDetector } from 'common/hooks';
import {
  DesktopHomePageConfiguratorSummary,
  MobileHomePageConfiguratorSummary,
  HomePageConfiguratorProvider
} from 'features/homePageConfigurator';
import { ReactNode } from 'react';
import { BaseLayout } from './BaseLayout';
import { Footer } from './components';

interface NewOrderDietGuestLayoutProps {
  children: ReactNode;
  isLoading?: boolean;
}

export const NewOrderDietGuestLayout = ({ children, isLoading }: NewOrderDietGuestLayoutProps) => {
  const classes = useStyle();
  const isMobile = useMobileScreenSizeDetector();
  return (
    <HomePageConfiguratorProvider>
      <BaseLayout
        isLoading={isLoading}
        fullHeight={isMobile}
        footer={isMobile ? <MobileHomePageConfiguratorSummary /> : <Footer />}
        className={classes.container}
      >
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12} md={8}>
              {children}
            </Grid>
            <HiddenOnMobile>
              <Grid item md={4}>
                <DesktopHomePageConfiguratorSummary />
              </Grid>
            </HiddenOnMobile>
          </Grid>
        </Container>
      </BaseLayout>
    </HomePageConfiguratorProvider>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    paddingTop: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1.5)
    }
  }
}));
