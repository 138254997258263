import { makeStyles, Typography } from '@material-ui/core';
import { Address } from 'features/addresses/types';
import { TFuncKey, useTranslation } from 'react-i18next';

interface AddressStatusProps extends Pick<Address, 'isStandardByDefault' | 'isSaturdayByDefault'> {}

export const AddressStatus = ({ isStandardByDefault, isSaturdayByDefault }: AddressStatusProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  if (!(isStandardByDefault || isSaturdayByDefault)) {
    return null;
  }
  let statusText: TFuncKey;
  if (isStandardByDefault && isSaturdayByDefault) {
    statusText = 'addressesPage.addressCard.status.default';
  } else if (isStandardByDefault) {
    statusText = 'addressesPage.addressCard.status.standard';
  } else {
    statusText = 'addressesPage.addressCard.status.festive';
  }

  return (
    <Typography className={classes.root} noWrap>
      {t(statusText)}
    </Typography>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: '0.5rem',
    color: theme.customColors.textDarkGrey
  }
}));
