import { makeStyles, Typography } from '@material-ui/core';

interface UserDataPageTitleProps {
  title: string;
}

export const UserDataPageTitle = ({ title }: UserDataPageTitleProps) => {
  const classes = useStyles();

  return (
    <Typography variant="h2" className={classes.title} component="h1">
      {title}
    </Typography>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    margin: '2.5rem 0 1.5rem'
  }
}));
