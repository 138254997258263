import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { ButtonLink } from 'common/components';
import { country } from 'config';
import { useSession } from 'features/authentication';
import { germanWordpressRoutes } from 'routing/constants/germanWordpressRoutes';

export const CreateDiet = (): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();

  const { logout } = useSession();

  const handleClick = () => {
    if (country === 'germany') {
      logout();
    }
  };

  return (
    <ButtonLink
      className={classes.creatDietElement}
      external={country === 'germany'}
      to={country === 'germany' ? germanWordpressRoutes.configurator : RoutesDefinition.createDiet}
      onClick={handleClick}
    >
      {t('menuItems.createDiet')}
    </ButtonLink>
  );
};

const useStyle = makeStyles((theme) => ({
  creatDietElement: {
    textTransform: 'none',
    fontSize: '1rem',
    fontWeight: 'bold',
    textDecoration: 'none',

    [theme.breakpoints.up('md')]: {
      marginLeft: '1.5rem'
    }
  }
}));
