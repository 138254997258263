import dayjs from 'dayjs';
import { YEAR_MONTH_DAY } from 'common/constants';
import { DeliveryTimeConfig, DietConfiguration } from '../../types';
import { baseCalculateInitialFormState } from './baseCalculateInitialFormState';
import { InitialFormState } from './initialFormState';
import { calculateInitialFormStateForAddBag } from './calculateInitialFormStateForAddBag';
import { calculateInitialFormStateForHomepageConfigurator } from './calculateInitialFormStateForHomepageConfigurator';
import { isHomepageConfiguratorRequest } from '../isHomepageConfiguratorRequest';
import { isAddBagToDateRequest } from '../isAddBagToDateRequest';

type CalculateInitialFormStateHandler = ({
  data,
  deliveryTimeConfig,
  urlSearchParams
}: {
  data: DietConfiguration;
  deliveryTimeConfig: DeliveryTimeConfig;
  urlSearchParams: URLSearchParams;
}) => InitialFormState;

export const calculateInitialFormState: CalculateInitialFormStateHandler = ({
  data,
  urlSearchParams,
  deliveryTimeConfig
}) => {
  const formData = baseCalculateInitialFormState(data, deliveryTimeConfig);

  if (isAddBagToDateRequest(urlSearchParams)) {
    return calculateInitialFormStateForAddBag({
      addBagToDate: dayjs(urlSearchParams.get('addBagTo'), YEAR_MONTH_DAY),
      disabledDays: deliveryTimeConfig.disabledDays,
      firstDeliveryAt: deliveryTimeConfig.firstDeliveryAt,
      formData
    });
  }
  if (isHomepageConfiguratorRequest(urlSearchParams)) {
    return calculateInitialFormStateForHomepageConfigurator({
      data,
      formData,
      deliveryTimeConfig,
      urlSearchParams
    });
  }

  return formData;
};
