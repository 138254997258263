import { Dialog, makeStyles, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { Id } from 'common/types';
import { useCallback, useEffect, forwardRef } from 'react';
import { AddressMenuList } from './AddressMenuList';

interface MobileAddressMenuProps {
  onClose: VoidFunction;
  name: string;
  id: Id;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Transition = forwardRef<unknown, TransitionProps & { children?: React.ReactElement<unknown, any> }>(
  (props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

export const MobileAddressMenu = ({ onClose, name, id }: MobileAddressMenuProps) => {
  const isMobile = useMobileScreenSizeDetector();
  const classes = useStyle();

  const handleExited = useCallback(() => {
    const rootDiv = document.getElementById('root');
    rootDiv?.classList.remove(classes.rootBackdrop);
  }, [classes.rootBackdrop]);

  const handleEntering = () => {
    const rootDiv = document.getElementById('root');
    rootDiv?.classList.add(classes.rootBackdrop);
  };

  useEffect(() => {
    return () => {
      if (isMobile) {
        handleExited();
      }
    };
  }, [handleExited, isMobile]);

  return (
    <Dialog
      open={isMobile}
      TransitionComponent={Transition}
      onClose={onClose}
      classes={{
        container: classes.container,
        paper: classes.paper
      }}
      onEntering={handleEntering}
      onExited={handleExited}
    >
      <AddressMenuList id={id} name={name} onClose={onClose} />
    </Dialog>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    alignItems: 'flex-end'
  },
  paper: {
    width: '100%',
    maxWidth: '100%',
    margin: 0
  },
  rootBackdrop: {
    [theme.breakpoints.up('xs')]: {
      overflow: 'hidden',
      filter: 'blur(7px)'
    }
  }
}));
