import { Grid, makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button, FormTextField, FormPasswordTextField, Checkbox } from 'common/components';

import { LoginFormData, loginFormSchema } from 'features/authentication/types';
import { useEffect } from 'react';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { checkFbExisting } from 'common/utils/checkFbExisting';
import { FacebookLogin } from './FacebookLogin';
import { GoogleLogin } from './GoogleLogin';

interface LoginFormProps {
  isProcessing?: boolean;
  onSubmit: SubmitHandler<LoginFormData>;
  isSubmitError: boolean;
  registerUrl: string;
}

export function LoginForm({ isProcessing, onSubmit, isSubmitError, registerUrl }: LoginFormProps): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();

  const formMethods = useForm<LoginFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(loginFormSchema),
    defaultValues: {
      email: '',
      password: '',
      rememberMe: false
    }
  });

  useEffect(() => {
    if (isSubmitError) {
      formMethods.setError('password', { message: 'errors.loginError' });
      formMethods.setError('email', { message: 'errors.loginError' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitError]);
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormTextField
              name="email"
              type="email"
              placeholder={t('loginPage.formFields.emailPlaceholder')}
              label={t('loginPage.formFields.emailLabel')}
            />
          </Grid>
          <Grid item xs={12}>
            <FormPasswordTextField
              name="password"
              placeholder={t('loginPage.formFields.passwordPlaceholder')}
              label={t('loginPage.formFields.passwordLabel')}
            />
          </Grid>
          <Grid item xs={7}>
            <Checkbox
              name="rememberMe"
              label={t('loginPage.formFields.rememberMe.label')}
              checkedAlt={t('loginPage.formFields.rememberMe.checked')}
              unCheckedAlt={t('loginPage.formFields.rememberMe.unChecked')}
            />
          </Grid>
          <Grid container item xs={5} justify="flex-end" alignItems="center">
            <NavLink className={classes.link} to={RoutesDefinition.resetPassword}>
              {t('loginPage.forgotPassword')}
            </NavLink>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth type="submit" disabled={isProcessing}>
              {t('loginPage.formFields.submitButton')}
            </Button>
          </Grid>
          <Grid item xs={12} className={classes.registerLinkContainer}>
            <Trans i18nKey="loginPage.registerDescription">
              To Twój pierwszy raz?
              <NavLink className={classes.link} to={registerUrl}>
                Zarejestruj się
              </NavLink>
            </Trans>
          </Grid>
          <Grid item xs={12}>
            <span className={classes.divider}>{t('loginPage.formFields.or')}</span>
          </Grid>
          <div className={classes.socialButtons}>
            <GoogleLogin />
            {checkFbExisting() && <FacebookLogin type="login" />}
          </div>
          <Grid item xs={12} className={classes.facebookDescriptionWrapper}>
            <Trans i18nKey="loginPage.socialDescription">
              Logując się przez Facebook, akceptujesz
              <a href={process.env.REACT_APP_TERMS_AND_CONDTIONS} target="_blank" rel="noreferrer">
                regulamin
              </a>
              serwisu
            </Trans>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}

const useStyle = makeStyles((theme) => ({
  link: {
    fontWeight: 'bold',
    color: theme.palette.secondary.dark
  },
  socialButtons: {
    display: 'grid',
    gap: '1rem',
    gridAutoFlow: 'column',
    flex: 1,
    padding: '0.75rem'
  },
  divider: {
    display: 'flex',
    width: '100%',
    padding: '0',
    textAlign: 'center',
    alignItems: 'center',
    '&:before, &:after': {
      display: 'inline-block',
      content: '""',
      width: '100%',
      height: '1px',
      textShadow: 'none',
      backgroundColor: theme.customColors.textLightGrey
    },
    '&:before': {
      marginRight: theme.spacing(3)
    },
    '&:after': {
      marginLeft: theme.spacing(3)
    }
  },
  registerLinkContainer: {
    textAlign: 'center'
  },
  facebookDescriptionWrapper: {
    textAlign: 'center',
    color: theme.customColors.textDarkGrey,
    fontSize: '0.75rem',
    '& > a': {
      fontWeight: 'bold',
      textDecoration: 'underline',
      color: theme.customColors.textDarkGrey
    }
  }
}));
