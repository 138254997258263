import { makeStyles, Typography } from '@material-ui/core';

import iconLogout from 'common/icons/icon-logout.svg';

import { useSession } from 'features/authentication';

import { NavigationButton, NavigationLink, Paper } from 'common/components';
import { userMenuRoutes } from 'routing/constants/userMenuRoutes';

import { ProfileManagment } from 'features/profiles';
import { useTranslation } from 'react-i18next';

export const SideNavigation = (): JSX.Element => {
  const classes = useStyle();
  const { logout } = useSession();
  const { t } = useTranslation();
  const version = process.env.REACT_APP_SENTRY_RELEASE;
  return (
    <Paper>
      <ProfileManagment />
      {userMenuRoutes.map(({ path, icon, label }, index) => (
        <NavigationLink
          path={path as string}
          icon={icon}
          label={label}
          wrapperClassName={classes.menuElement}
          key={(path as string) || `route-${index}`}
        />
      ))}

      <NavigationButton
        label="menuItems.logout"
        onClick={logout}
        icon={iconLogout}
        wrapperClassName={classes.menuElement}
      />
      <Typography color="textSecondary">{t('version', { version })}</Typography>
    </Paper>
  );
};

const useStyle = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.customColors.white,
    boxShadow: theme.boxShadow.lightGrey
  },
  cardContent: {
    padding: '24px'
  },
  menuElement: {
    fontSize: '1rem',
    lineHeight: 1.5,
    color: theme.customColors.textDarkGrey,

    '& > img': {
      marginRight: '1rem',
      filter: theme.filters.darkGrey
    },

    '&.active': {
      fontWeight: 'bold',
      color: theme.palette.secondary.main,

      '& > img': {
        marginRight: '1rem',
        filter: theme.filters.black
      }
    }
  }
}));
