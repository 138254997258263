import { useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';

export const useMobileScreenSizeDetector = () => {
  const theme = useTheme();

  const query = `( max-width: ${theme.breakpoints.values.md}px )`;
  const mediaQuery = window.matchMedia(query);

  const [match, setMatch] = useState(!!mediaQuery.matches);

  useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery.addListener(handler);
    return () => mediaQuery.removeListener(handler);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return match;
};
