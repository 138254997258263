import dayjs from 'dayjs';
import * as z from 'zod';

export const refineDateTime = (dateTime: string) => dayjs(dateTime).isValid();

export const dateTimeSchema = z
  .string()
  .refine(refineDateTime)
  .transform((dateTime: string) => dayjs(dateTime));

export type DateTime = z.output<typeof dateTimeSchema>;
