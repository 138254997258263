import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { country } from 'config';
import { Button, FormPasswordTextField } from 'common/components';

import { SetNewPasswordAfterResetFormData, setNewPasswordAfterResetFormSchema } from 'features/authentication/types';
import { useEffect } from 'react';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

interface SetNewPasswordAfterResetFormProps {
  isProcessing?: boolean;
  onSubmit: SubmitHandler<SetNewPasswordAfterResetFormData>;
  isApiError: boolean;
}

export function SetNewPasswordAfterResetForm({
  isProcessing,
  onSubmit,
  isApiError
}: SetNewPasswordAfterResetFormProps): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();

  const formMethods = useForm<SetNewPasswordAfterResetFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(setNewPasswordAfterResetFormSchema),
    defaultValues: {
      password: '',
      repeatPassword: ''
    }
  });

  useEffect(() => {
    if (isApiError) {
      formMethods.setError('password', { message: t('errors.passwordResetError') });
      formMethods.setError('repeatPassword', { message: t('errors.passwordResetError') });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiError]);

  return (
    <FormProvider {...formMethods}>
      <form className={classes.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <FormPasswordTextField
          name="password"
          placeholder={t('setNewPasswordAfterResetPage.formFields.password.placeholder')}
          label={t('setNewPasswordAfterResetPage.formFields.password.label')}
        />
        <FormPasswordTextField
          name="repeatPassword"
          placeholder={t('setNewPasswordAfterResetPage.formFields.password.placeholder')}
          label={t('setNewPasswordAfterResetPage.formFields.repeatPassword.label')}
        />
        <Button className={classes.submitButton} type="submit" disabled={isProcessing}>
          {t('setNewPasswordAfterResetPage.formFields.submitButton')}
        </Button>
        <div className={classes.subFormBox}>
          {country === 'germany' ? (
            <u>
              <Trans i18nKey="setNewPasswordAfterResetPage.loginDescription">
                Masz już konto? <NavLink to={RoutesDefinition.login}>Zaloguj się</NavLink>
              </Trans>
            </u>
          ) : (
            <Trans i18nKey="setNewPasswordAfterResetPage.loginDescription">
              Masz już konto? <NavLink to={RoutesDefinition.login}>Zaloguj się</NavLink>
            </Trans>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

const useStyle = makeStyles((theme) => ({
  form: {
    '& > div:nth-of-type(2)': {
      marginTop: theme.spacing(3)
    }
  },
  subFormBox: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1rem',
    whiteSpace: 'pre',
    '& a': {
      fontWeight: 'bold',
      textDecoration: 'none',
      color: theme.palette.secondary.dark
    }
  },
  submitButton: {
    margin: `${theme.spacing(5)} 0`,
    width: '100%'
  }
}));
