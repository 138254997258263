import { makeStyles } from '@material-ui/core';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

interface ButtonGroupDescriptionProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'dangerouslySetInnerHTML' | 'className' | 'children'
  > {
  content: string;
}

export const ButtonGroupDescription = ({ content, ...rest }: ButtonGroupDescriptionProps) => {
  const classes = useStyle();

  return (
    <div
      className={classes.tooltipWrapper}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
      {...rest}
    />
  );
};

const useStyle = makeStyles((theme) => ({
  tooltipWrapper: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.customColors.white,
    marginTop: '1.5rem',
    padding: '1rem 1.5rem',
    borderRadius: `0.313rem`,
    fontSize: '1rem',
    lineHeight: 1.19,
    textAlign: 'center',
    boxShadow: theme.boxShadow.lightGrey,
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      lineHeight: 1.29,
      letterSpacing: '0.013rem'
    }
  }
}));
