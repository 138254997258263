import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useSnackbarDismiss = () => {
  const { pathname } = useLocation();
  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    closeSnackbar();
  }, [pathname, closeSnackbar]);
};
