import { zodResolver } from '@hookform/resolvers/zod';
import { makeStyles } from '@material-ui/core';
import { Button, CheckboxBase, Dialog, FormMaskTextField } from 'common/components';
import { useVisibility } from 'common/hooks';
import { country } from 'config';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { invoicesFormSchema, InvoicesFormSchema } from '../types/invoicesFormSchema';

interface InvoicesFormProps {
  defaultValue: string;
  onSubmit: (value: InvoicesFormSchema & { enable: boolean }) => void;
  isLoading: boolean;
  enableInvoices: boolean;
}

const CZ_TAX_NO_PLACEHOLDER = 'CZ99999999999';

export const InvoicesForm = ({ defaultValue, isLoading, onSubmit, enableInvoices }: InvoicesFormProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [enableForm, setEnableForm] = useState(enableInvoices);

  const [isVisible, show, hide] = useVisibility();

  const formMethods = useForm<InvoicesFormSchema>({
    reValidateMode: 'onChange',
    resolver: zodResolver(invoicesFormSchema),
    defaultValues: {
      taxNumber: defaultValue === '0000000000' ? '' : defaultValue
    }
  });

  const handleEnableChange = () => {
    if (enableInvoices && enableForm) {
      show();
    } else {
      setEnableForm(!enableForm);
    }
  };

  const handleSubmit = ({ taxNumber }: InvoicesFormSchema) => {
    onSubmit({ taxNumber, enable: enableForm });
  };

  const mask = useMemo(() => {
    if (country === 'czech') {
      return [...Array.from({ length: 12 }, () => /\w/i)];
    }
    if (country === 'germany') {
      return Array.from({ length: 20 }, () => /(\d| |\/)/);
    }
    return '9999999999';
  }, []);

  return (
    <>
      <CheckboxBase
        label={t('invoicesPage.form.wantsInvoiceCheckbox.label')}
        checkedAlt={t('invoicesPage.form.wantsInvoiceCheckbox.checked')}
        unCheckedAlt={t('invoicesPage.form.wantsInvoiceCheckbox.unChecked')}
        value={enableForm}
        onChange={handleEnableChange}
      />
      <FormProvider {...formMethods}>
        {enableForm && (
          <form className={classes.form} onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <FormMaskTextField
              name="taxNumber"
              label={t('invoicesPage.form.taxNumber.label')}
              placeholder={country === 'czech' ? CZ_TAX_NO_PLACEHOLDER : t('invoicesPage.form.taxNumber.placeholder')}
              maskPlaceholder={null}
              mask={mask}
            />
            <Button
              type="submit"
              mode="secondary"
              className={classes.submit}
              disabled={isLoading || !formMethods.formState.isDirty}
            >
              {t('invoicesPage.form.submit')}
            </Button>
          </form>
        )}
      </FormProvider>

      <Dialog open={isVisible} onClose={hide}>
        <div className={classes.confirmDialog}>
          <div className={classes.infoIcon}>
            <span>!</span>
          </div>
          <p className={classes.description}>{t('invoicesPage.form.dialog.description')}</p>
          <div className={classes.confirmDialogButtons}>
            <Button type="button" mode="primary" onClick={hide}>
              {t('invoicesPage.form.dialog.undo')}
            </Button>
            <Button
              type="button"
              mode="secondary"
              onClick={() => {
                onSubmit({ enable: false, taxNumber: '0000000000' });

                hide();
              }}
            >
              {t('invoicesPage.form.dialog.submit')}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  form: {
    maxWidth: '21.25rem',
    marginTop: '1rem',
    display: 'grid',
    gridTemplateColumns: 'auto 6.25rem',
    gap: '1rem',
    alignItems: 'start'
  },
  submit: {
    transform: 'translateY(1.375rem)'
  },
  confirmDialog: {
    margin: '2rem 0 3rem'
  },
  confirmDialogButtons: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '4rem',
      display: 'flex',
      flexDirection: 'column',

      '& > button': {
        margin: '0.5rem 0'
      }
    },

    [theme.breakpoints.up('sm')]: {
      gap: '1rem',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      maxWidth: '35rem',
      margin: '0 auto'
    }
  },
  infoIcon: {
    display: 'flex',
    justifyContent: 'center',

    '& > span': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '3rem',
      height: '3rem',
      borderRadius: '50%',
      border: `solid 2px ${theme.customColors.orangeLight}`,
      color: theme.customColors.orangeLight,
      fontSize: '1.5rem',
      marginRight: '1px'
    }
  },
  description: {
    fontSize: '1.125rem',
    color: theme.customColors.orangeLight,
    fontWeight: 'bold',
    margin: '1.5rem 0',
    textAlign: 'center'
  }
}));
