import { Button, ButtonProps } from 'common/components';
import { useTranslation } from 'react-i18next';
import { useOrderPrice } from '../contexts';
import { OrderFlowStep } from '../types';

interface FlowStepButtonProps extends Omit<ButtonProps, 'mode' | 'disabled'> {
  orderFlowNextStep: OrderFlowStep;
  title?: string;
  isLoading?: boolean;
}

export const FlowStepButton = ({ orderFlowNextStep, title, isLoading, ...rest }: FlowStepButtonProps) => {
  const { t } = useTranslation();
  const { needsPayment } = useOrderPrice();

  let componentTitle = title;
  if (!title) {
    switch (orderFlowNextStep) {
      case OrderFlowStep.Payment:
        if (needsPayment) {
          componentTitle = t('ctaFlowStep.payByOnline');
        } else {
          componentTitle = t('ctaFlowStep.payByMoneybox');
        }
        break;
      case OrderFlowStep.DeliveryAddresses:
        componentTitle = t('ctaFlowStep.address');
        break;
      case OrderFlowStep.DeliveryInfo:
        componentTitle = t('ctaFlowStep.additionalAddressInformation');
        break;
      case OrderFlowStep.PersonalData:
        componentTitle = t('ctaFlowStep.personalData');
        break;
      default:
        componentTitle = t('ctaFlowStep.summary');
    }
  }
  return (
    <Button {...rest} isLoading={isLoading}>
      {componentTitle}
    </Button>
  );
};
