import {
  Dialog as MuiDialog,
  DialogClassKey,
  DialogProps as MuiDialogProps,
  IconButton,
  makeStyles,
  Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { ReactNode, useEffect, useCallback } from 'react';
import { mergeWith, omit } from 'lodash';
import clsx from 'clsx';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { ClassNameMap } from '@material-ui/styles';

interface DialogProps
  extends Omit<
    MuiDialogProps,
    'onClose' | 'fullScreen' | 'fullWidth' | 'onEntering' | 'onExited' | 'classes' | 'title'
  > {
  onClose?: VoidFunction;
  title?: ReactNode;
  footer?: ReactNode;
  classes?: Partial<ClassNameMap<DialogClassKey | 'footer' | 'content' | 'header'>> | undefined;
}

export const Dialog = ({
  open,
  onClose,
  children,
  footer,
  classes: dialogClasses = {},
  maxWidth = 'md',
  title,
  ...rest
}: DialogProps): JSX.Element => {
  const { t } = useTranslation();
  const isMobile = useMobileScreenSizeDetector();
  const classes = useStyle({ isMobile });
  const mergedClasses = mergeWith(
    {
      root: classes.root,
      paper: classes.paper
    },
    omit(dialogClasses, ['footer', 'header', 'content']),
    (base, additional) => clsx(base, additional)
  );

  const handleExited = useCallback(() => {
    const rootDiv = document.getElementById('root');
    rootDiv?.classList.remove(classes.rootBackdrop);
  }, [classes.rootBackdrop]);

  const handleEntering = () => {
    const rootDiv = document.getElementById('root');
    rootDiv?.classList.add(classes.rootBackdrop);
  };

  useEffect(() => {
    return () => {
      if (open) {
        handleExited();
      }
    };
  }, [handleExited, open]);

  return (
    <MuiDialog
      fullScreen={isMobile}
      open={open}
      classes={mergedClasses}
      maxWidth={maxWidth}
      fullWidth
      onEntering={handleEntering}
      onExiting={handleExited}
      onClose={onClose}
      {...rest}
    >
      <div className={clsx(classes.header, dialogClasses.header)}>
        {title && <header>{title}</header>}
        <IconButton className={classes.closeButton} onClick={onClose} aria-label={t('dialog.close')}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.sectionWrapper}>
        <section className={clsx(classes.section, dialogClasses.content)}>{children}</section>
      </div>
      {footer && <footer className={clsx(classes.footer, dialogClasses.footer)}>{footer}</footer>}
    </MuiDialog>
  );
};

const useStyle = makeStyles<Theme>((theme) => ({
  paper: {
    background: theme.customColors.mainBg,
    borderRadius: '0.625rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0
    }
  },
  root: {
    background: 'transparent'
  },
  header: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '1rem',
    '&> header': {
      flex: 1
    }
  },
  closeButton: {
    padding: '0.5rem'
  },
  sectionWrapper: {
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    flex: '1 1 auto',
    WebkitOverflowScrolling: 'touch',
    overflowY: 'auto'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100%'
  },
  footer: {
    flex: '0 0 auto',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      background: theme.customColors.white,
      boxShadow: theme.dialog.footer.boxShadow
    }
  },
  rootBackdrop: {
    [theme.breakpoints.up('xs')]: {
      overflow: 'hidden',
      filter: 'blur(7px)'
    }
  }
}));
