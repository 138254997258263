import type { DietPackage, DietVariant, SelectedMeals } from 'common/types';
import { currency } from 'config/constants/currency';
import { SizeData } from 'features/orders/api/fetchSizesData';
import { flatMap } from 'lodash';
import type { MealPrice } from 'services/mealPrices/fetchMealPrices';
import type { EcommerceData, ItemData } from '../types';
import { getBrand } from './getBrand';

export const parseEcommerce = (data: {
  selectedMeals: SelectedMeals;
  dietLength: number;
  selectedPackage?: DietPackage;
  selectedVariant?: DietVariant;
  sizesData: SizeData[];
  transactionValue: number;
  deliveryPrice: number;
  mealPrices: MealPrice[];
}): EcommerceData => {
  const brand = getBrand();

  const portionsBySizeId = data.sizesData.reduce((acc: Map<number, ItemData>, currentPortion) => {
    currentPortion.sizes.forEach((size) => {
      acc.set(size.id, {
        item_name: `${currentPortion.name}_${size.nameForClient}`,
        item_id: size.id,
        price: data.mealPrices.find(({ id }) => id === size?.id)?.price || 0,
        item_brand: brand,
        item_category: currentPortion.name,
        item_category2: data.selectedPackage?.name,
        item_category3: data.selectedVariant?.name,
        item_category4: size.calorific.toString(),
        item_category5: undefined,
        item_variant: size.nameForClient,
        quantity: data.dietLength
      });
    });
    return acc;
  }, new Map());

  const allSelectedPortions = flatMap(Array.from(data.selectedMeals.values()));

  const items = allSelectedPortions.reduce((acc: ItemData[], { mealId }) => {
    const item = portionsBySizeId.get(mealId);
    if (item) {
      acc.push(item);
    }

    return acc;
  }, []);

  return {
    items,
    shipping: data.deliveryPrice,
    currency,
    value: data.transactionValue
  };
};
