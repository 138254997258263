import { Typography } from '@material-ui/core';
import { useUser } from 'features/user';
import { useTranslation } from 'react-i18next';
import { Address } from '../types';
import { formatAddress } from '../utils';

interface AddressSummaryProps {
  data: Address;
  includeDeliveryHours?: boolean;
}

export const AddressSummary = ({
  data: { buildingNr, placeNr, street, postCode, city, deliveryHourTo, deliveryHourFrom, companyName, intercomName },
  includeDeliveryHours = true
}: AddressSummaryProps) => {
  const {
    profile: { firstName, lastName }
  } = useUser();
  const { t } = useTranslation();
  const showDeliveryHour = includeDeliveryHours && deliveryHourTo !== null;
  return (
    <>
      <Typography noWrap>{`${firstName} ${lastName}`}</Typography>
      <Typography noWrap>{formatAddress({ buildingNr, placeNr, street })}</Typography>
      <Typography noWrap>{`${postCode} ${city}`}</Typography>
      <Typography noWrap>{companyName}</Typography>
      <Typography noWrap>{intercomName}</Typography>
      {showDeliveryHour && (
        <Typography noWrap>
          {deliveryHourFrom
            ? t('addresses.addressSummary.deliveryHourSinceTo', { timeFrom: deliveryHourFrom, timeTo: deliveryHourTo })
            : t('addresses.addressSummary.deliveryHourTo', { time: deliveryHourTo })}
        </Typography>
      )}
    </>
  );
};
