import { Id } from 'common/types';
import { ApiClient } from 'features/apiProvider';

interface DeleteAddressRequest extends ApiClient {
  id: Id;
}

export const deleteAddress = async ({ apiClient, id }: DeleteAddressRequest): Promise<void> => {
  await apiClient.delete(`v3/addresses/${id}`);
};
