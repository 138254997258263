import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useNotifyDialog } from 'common/contexts';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDietLenght, useOrderFlow } from '../../hooks';
import { CreateDietFormData, OrderFlowStep } from '../../types';
import { FlowStepButton } from '../FlowStepButton';

interface ConfigurationPageSubmitButtonProps {
  onClick: VoidFunction;
  isLoading: boolean;
}

const isNonMealSelected = (selectedMeals: CreateDietFormData['meals']) => {
  const isNonMealsSelectedInType: boolean[] = [];
  selectedMeals.forEach((value) => {
    isNonMealsSelectedInType.push(value.length === 0);
  });
  return isNonMealsSelectedInType.every((isEmpty) => isEmpty);
};

export const ConfigurationPageSubmitButton = ({ onClick, isLoading }: ConfigurationPageSubmitButtonProps) => {
  const styles = useStyle();
  const { t } = useTranslation();
  const isMobile = useMobileScreenSizeDetector();
  const { calculateNextStep } = useOrderFlow();
  const { showErrorDialog } = useNotifyDialog();
  const nextOrderStep = calculateNextStep(OrderFlowStep.DietConfigurator);
  const selectedMeals = useWatch<CreateDietFormData, 'meals'>({ name: 'meals' });
  const dietLength = useDietLenght();

  const nonMealSelected = isNonMealSelected(selectedMeals);
  const nonDateSelected = dietLength === 0;

  const handleClick = () => {
    if (nonMealSelected) {
      showErrorDialog({ message: 'orderDeliveryPage.nonMealSelectedError' });
    } else if (nonDateSelected) {
      showErrorDialog({ message: 'orderDeliveryPage.nonDateSelectedError' });
    } else {
      onClick();
    }
  };

  const calculateTitle = () => {
    if (nonMealSelected) {
      return t('ctaFlowStep.nonMealSelected');
    }
    if (nonDateSelected) {
      return t('ctaFlowStep.nonDateSelected');
    }
    if (isMobile) {
      return t('ctaFlowStep.nextStepMobile');
    }
    return undefined;
  };
  return (
    <>
      <FlowStepButton
        className={clsx({
          [styles.inactiveButton]: nonMealSelected || nonDateSelected
        })}
        orderFlowNextStep={nextOrderStep}
        onClick={handleClick}
        title={calculateTitle()}
        isLoading={isLoading}
      />
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  inactiveButton: {
    backgroundColor: `${theme.customColors.button.primary.disabled.background} !important`,
    borderColor: theme.customColors.button.primary.disabled.borderColor,
    color: theme.customColors.button.primary.disabled.color
  }
}));
