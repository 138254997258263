import { SelectedMeals } from 'common/types';

interface GetMealsIdsProps {
  selectedMeals: SelectedMeals;
}

export const getMealsIDs = ({ selectedMeals }: GetMealsIdsProps) => {
  const mealsIDs: number[] = [];
  selectedMeals.forEach((values) => {
    values.forEach(({ mealId }) => {
      mealsIDs.push(mealId);
    });
  });

  return mealsIDs;
};
