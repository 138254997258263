import { ApiClient } from 'features/apiProvider';

import { Id } from 'common/types';
import { PaymentType } from '../types';

interface PayForOrderRequest extends ApiClient {
  orderId: Id;
  paymentType: PaymentType;
}

export const payForOrder = async ({ apiClient, orderId, paymentType }: PayForOrderRequest): Promise<void> => {
  await apiClient.patch(`frontend/secure/order/${orderId}/payment/type`, {
    json: {
      data: JSON.stringify({
        paymentType
      })
    }
  });
};
