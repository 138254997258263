import { ApiClient } from 'features/apiProvider';

import { sendUserComAddToCart } from 'services/userCom/sendUserComAddToCart';
import { OrderData, PaymentType } from '../types';

import { getOrderDetails } from './getOrderDetails';
import { createOrder } from './createOrder';
import { payForOrder } from './payForOrder';

interface PayForOrderProcessRequest extends ApiClient {
  orderData: OrderData;
  paymentType: PaymentType;
}

export const payForOrderProcess = async ({ apiClient, orderData, paymentType }: PayForOrderProcessRequest) => {
  const paymentUrl = '';

  const orderId = await createOrder({ apiClient, orderData });

  sendUserComAddToCart({ orderId });

  await getOrderDetails({ apiClient, orderId });
  await payForOrder({ paymentType, orderId, apiClient });

  return {
    paymentUrl,
    orderId,
    paymentType
  };
};
