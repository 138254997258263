import dayjs from 'dayjs';

import { YEAR_MONTH_DAY } from 'common/constants';

interface GetFormattedDateProps {
  date?: number | Date;
  dateFormat?: string;
}

export const getFormattedDate = (props?: GetFormattedDateProps) => {
  const { date = new Date(), dateFormat = YEAR_MONTH_DAY } = props || {};

  return dayjs(date).format(dateFormat);
};
