import * as z from 'zod';
import { ApiClient } from 'features/apiProvider';
import { PackageLeavingOption } from '../types';
import { dictionaryTagSchema } from '../../../common/types/dictionaryTag';

const fetchPackageLeavingOptionsResponse = z.array(dictionaryTagSchema);

export const fetchPackageLeavingOptions = async ({ apiClient }: ApiClient): Promise<PackageLeavingOption[]> => {
  const data = await apiClient.get('v3/address/package-leaving-options').json();
  return fetchPackageLeavingOptionsResponse.parse(data);
};
