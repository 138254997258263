import { makeStyles, Radio, RadioProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { MarkedCircle } from 'common/icons/MarkedCircle';

import checkboxEmpty from 'common/icons/icon-checkbox-empty.svg';

interface DietlengthRadioProps extends RadioProps {
  optionName: string;
}

export const DietlengthRadio = ({ optionName, ...rest }: DietlengthRadioProps) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const selectedAlt = t('creatDietPage.calendarSection.radioSelectedAlt', { name: optionName }) as string;
  const unselectedAlt = t('creatDietPage.calendarSection.radioUnselectedAlt', { name: optionName }) as string;
  return (
    <Radio
      disableRipple
      color="default"
      className={classes.radio}
      checkedIcon={<MarkedCircle imageAlt={selectedAlt} />}
      icon={<img className={classes.unselectedIcon} src={checkboxEmpty} alt={unselectedAlt} />}
      {...rest}
    />
  );
};

const useStyle = makeStyles(() => ({
  radio: {
    '&:hover': {
      background: 'transparent'
    }
  },
  unselectedIcon: {
    width: '1rem'
  }
}));
