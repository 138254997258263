import { Suspense } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import 'common/utils/i18n';
import 'common/utils/dayjsConfig';

import { SessionContextProvider } from 'features/authentication';
import { UserContextProvider } from 'features/user';
import { ApiContextProvider } from 'features/apiProvider';

import { ThemeProvider, SnackbarProvider } from 'common/components';

import { NotifyDialogProvider } from 'common/contexts';
import { BaseLayout } from 'layouts';
import { ErrorBoundary } from 'ErrorBoundary';
import { country } from 'config';
import App from './App';

const GTM_ID = process.env.REACT_APP_GTM ?? '';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false }
  }
});

const tagManagerArgs = {
  gtmId: GTM_ID
};

TagManager.initialize(tagManagerArgs);

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    ignoreErrors: [
      /**
       * Ignored because if the browser caches an old version for some user, the app will show a request to refresh the page,
       * and if something goes wrong with deploy we will see it instantly
       */
      'ChunkLoadError',
      /**
       * Ignored as it's connected to gtm (LINKEDIN ADS) internal implementation which is the source of error when the user's browser
       * has extensions that blocks tracking tools
       */
      'window.lintrk is not a function'
    ]
  });
  Sentry.setTag('country', country);
}

const container = document.getElementById('root');
if (!container) throw new Error('There is no root element in the index.html');
const root = createRoot(container);

root.render(
  <ThemeProvider>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <Router>
            <SessionContextProvider>
              <ApiContextProvider>
                <Suspense fallback={<BaseLayout isLoading />}>
                  <UserContextProvider>
                    <NotifyDialogProvider>
                      <App />
                    </NotifyDialogProvider>
                  </UserContextProvider>
                </Suspense>
              </ApiContextProvider>
            </SessionContextProvider>
          </Router>
        </SnackbarProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  </ThemeProvider>
);
