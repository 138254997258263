import { z } from 'zod';

export const heightFieldLength = {
  min: 2,
  max: 3
};
export const weightFieldLength = {
  min: 2,
  max: 3
};
export const ageFieldLength = {
  min: 1,
  max: 3
};

const fieldValidation = z
  .number({ invalid_type_error: 'creatDietPage.caloriesSection.caloricCalculator.form.validation.erorrType' })
  .min(1, 'creatDietPage.caloriesSection.caloricCalculator.form.validation.errorTooSmall');

export const caloricCalculatorDataFormSchema = z.object({
  sex: z.enum(['female', 'male']),
  goal: z.enum(['loseWeight', 'eatHealthyDiet', 'buildMuscleMass']),
  exerciseActivity: z.number(),
  height: z
    .string()
    .min(heightFieldLength.min, 'creatDietPage.caloriesSection.caloricCalculator.form.validation.errorRequiredField')
    .max(heightFieldLength.max)
    .transform((val) => Number(val))
    .superRefine((args, ctx) => {
      const fieldValidationResult = fieldValidation.safeParse(args);
      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    }),
  weight: z
    .string()
    .min(weightFieldLength.min, 'creatDietPage.caloriesSection.caloricCalculator.form.validation.errorRequiredField')
    .max(weightFieldLength.max)
    .transform((val) => Number(val))
    .superRefine((args, ctx) => {
      const fieldValidationResult = fieldValidation.safeParse(args);
      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    }),
  age: z
    .string()
    .min(ageFieldLength.min, 'creatDietPage.caloriesSection.caloricCalculator.form.validation.errorRequiredField')
    .max(ageFieldLength.max)
    .transform((val) => Number(val))
    .superRefine((args, ctx) => {
      const fieldValidationResult = fieldValidation.safeParse(args);
      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    })
});

export type CaloricCalculatorDataForm = z.infer<typeof caloricCalculatorDataFormSchema>;
