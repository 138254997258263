import { ApiClient } from 'features/apiProvider';

import { AuthorizationResponse } from '../types';

interface LoginParams extends ApiClient {
  email: string;
  password: string;
  rememberMe: boolean;
}

export const loginRequest = async ({ email, password, rememberMe, apiClient }: LoginParams) => {
  /**
   * API requires to add prefix brandId_ to the email address
   */
  const response = await apiClient
    .post('api/login_check', {
      json: { _username: `${process.env.REACT_APP_BRAND_ID}_${email}`, _password: password, _remember_me: rememberMe }
    })
    .json();

  return AuthorizationResponse.parse(response);
};
