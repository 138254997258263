import { YEAR_MONTH_DAY } from 'common/constants';
import dayjs from 'dayjs';
import * as z from 'zod';

export const refineDate = (date: string) => dayjs(date, YEAR_MONTH_DAY).isValid();

export const transformToDayjs = (date: string) => dayjs(date).startOf('day');

export const CalendarDateSchema = z.string().refine(refineDate).transform(transformToDayjs);

export type CalendarDate = z.output<typeof CalendarDateSchema>;
