import { useTranslation } from 'react-i18next';
import { Step, StepLabel, Stepper } from 'common/components';
import { OrderFlowStep, useCreateDeliveryAddresses } from 'features/orders';
import { useUser } from 'features/user';
import { useMobileScreenSizeDetector } from 'common/hooks/useMobileScreenSizeDetector';
import { country } from 'config';

interface PaymentDetailsStepperProps {
  currentStep: OrderFlowStep.PersonalData | OrderFlowStep.DeliveryAddresses | OrderFlowStep.DeliveryInfo;
}

export const PaymentDetailsStepper = ({ currentStep }: PaymentDetailsStepperProps) => {
  const { t } = useTranslation();
  const isMobile = useMobileScreenSizeDetector();
  const { deliveryVariant } = useCreateDeliveryAddresses();

  const { addresses } = useUser();

  const stepMap: Record<string, number> = {
    [OrderFlowStep.PersonalData]: 0,
    [OrderFlowStep.DeliveryAddresses]: 1,
    [OrderFlowStep.DeliveryInfo]: 2
  };

  const steps = [
    {
      key: OrderFlowStep.PersonalData,
      label: t('creatDietPage.processStepper.myData'),
      isCompleted: currentStep !== OrderFlowStep.PersonalData
    },
    {
      key: OrderFlowStep.DeliveryAddresses,
      label: t('creatDietPage.processStepper.deliveryAddress'),
      isCompleted: currentStep === OrderFlowStep.DeliveryInfo || !!addresses.data.length
    },
    {
      key: OrderFlowStep.DeliveryInfo,
      label: t('creatDietPage.processStepper.additionalInformation'),
      isCompleted: !!addresses.data.length
    }
  ];

  const filteredSteps =
    country === 'czech' && deliveryVariant !== 'address'
      ? steps.filter((step) => step.key !== OrderFlowStep.DeliveryInfo)
      : steps;

  return (
    <Stepper
      activeStep={stepMap[currentStep]}
      {...(filteredSteps.length === 2 && {
        style: {
          width: isMobile ? '65%' : '60%'
        }
      })}
    >
      {filteredSteps.map(({ label, isCompleted }) => {
        return (
          <Step key={label} completed={isCompleted}>
            <StepLabel>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
