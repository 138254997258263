import { Grid } from '@material-ui/core';
import { LeftSection } from './LeftSection';
import { RightSection } from './RightSection';

export const TopBarContent = (): JSX.Element => {
  return (
    <Grid container spacing={0}>
      <Grid container item xs={6} md={6} alignItems="center">
        <LeftSection />
      </Grid>
      <Grid container item xs={6} md={6} alignItems="center" justify="flex-end" wrap="nowrap">
        <RightSection />
      </Grid>
    </Grid>
  );
};
