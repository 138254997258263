import { Container, Grid, makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';

interface ResetPasswordWrapperProps {
  children: ReactNode;
}

export const ResetPasswordWrapper = ({ children }: ResetPasswordWrapperProps): JSX.Element => {
  const classes = useStyle();

  return (
    <Container className={classes.wrapper}>
      <Grid item className={classes.content} md={7}>
        {children}
      </Grid>
    </Container>
  );
};

const useStyle = makeStyles((theme) => ({
  wrapper: {
    width: '50rem',
    padding: `${theme.spacing(6)} 0`,
    backgroundColor: theme.customColors.mainBg,
    position: 'relative',
    zIndex: 1,
    borderRadius: '0.25rem',
    marginTop: 'calc(15vh + 2rem)',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      margin: '0'
    }
  },
  content: {
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      margin: 'unset',
      padding: '0 15%'
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'unset',
      padding: `0 ${theme.spacing(2)}`
    }
  }
}));
