import { FormMaskTextField } from 'common/components';
import { country } from 'config';

interface FormPostCodeFieldProps {
  disabled?: boolean;
  label?: string;
}
const getPostCodeMask = () => {
  switch (country) {
    case 'germany':
      return '99999';
    case 'poland':
      return '99-999';
    case 'czech':
      return '999 99';
    default:
      throw new Error('Country not supported');
  }
};
const getPostCodePlaceholder = () => {
  switch (country) {
    case 'germany':
      return 'Postleitzahl eingeben';
    case 'poland':
      return '__-___';
    case 'czech':
      return '___ __';
    default:
      throw new Error('Country not supported');
  }
};
const placeholder = getPostCodePlaceholder();
const mask = getPostCodeMask();

export const FormPostCodeField = ({ disabled, label }: FormPostCodeFieldProps) => {
  return (
    <FormMaskTextField
      label={label}
      name="postCode"
      mask={mask}
      placeholder={placeholder}
      alwaysShowMask
      inputMode="numeric"
      disabled={disabled}
    />
  );
};
