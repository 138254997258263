import TagManager from 'react-gtm-module';

export const sendLoginWithSocialGAEvent = (data: { type: 'login' | 'register'; medium: 'google' | 'facebook' }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: data.type === 'login' ? 'login' : 'sign_up',
      method: data.medium,
      sign_up_code: undefined,
      amp_testID: localStorage.getItem('amp_testID') || undefined
    }
  });
};
