import { ApiClient } from 'features/apiProvider';
import { PersonalData } from '../types';

interface UpdatePersonalDataRequest extends ApiClient {
  data: PersonalData;
}

export const updatePersonalData = async ({
  data: { email, ...data },
  apiClient
}: UpdatePersonalDataRequest): Promise<void> => {
  await apiClient.post('v3/account/edit_profile', {
    json: data
  });
};
