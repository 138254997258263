import { z } from 'zod';

export const changePasswordFormSchema = z
  .object({
    currentPassword: z
      .string()
      .transform((val) => val.trim())
      .superRefine((val, ctx) => {
        const fieldValidationResult = z
          .string()
          .nonempty('errors.passwordRequired')
          .min(6, { message: 'errors.incorrectPassword' })
          .safeParse(val);

        if (!fieldValidationResult.success) {
          ctx.addIssue(fieldValidationResult.error.issues[0]);
        }
      }),
    newPassword: z
      .string()
      .transform((val) => val.trim())
      .superRefine((val, ctx) => {
        const fieldValidationResult = z
          .string()
          .nonempty('errors.passwordRequired')
          .min(6, { message: 'errors.incorrectPassword' })
          .safeParse(val);

        if (!fieldValidationResult.success) {
          ctx.addIssue(fieldValidationResult.error.issues[0]);
        }
      }),
    repeatPassword: z
      .string()
      .transform((val) => val.trim())
      .superRefine((val, ctx) => {
        const fieldValidationResult = z
          .string()
          .nonempty('errors.passwordRequired')
          .min(6, { message: 'errors.incorrectPassword' })
          .safeParse(val);

        if (!fieldValidationResult.success) {
          ctx.addIssue(fieldValidationResult.error.issues[0]);
        }
      })
  })
  .refine((data) => data.newPassword === data.repeatPassword, {
    message: 'errors.passwordsDontMatch',
    path: ['repeatPassword']
  });

export type ChangePasswordFormData = z.infer<typeof changePasswordFormSchema>;
