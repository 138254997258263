import { z } from 'zod';

import { DietVariantResponseSchema } from './dietVariant';
import { HtmlText } from './htmlText';
import { Id } from './id';

export interface DietPackage {
  id: Id;
  name: string;
  description: HtmlText;
  variants: Id[];
  defaultVariant: Id;
}

export const DietPackageResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  variants: z.array(DietVariantResponseSchema),
  clientDescription: z
    .string()
    .nullable()
    .transform((val) => val ?? ''),
  order: z.number()
});
