import { ReactNode } from 'react';
import { Footer } from 'layouts/components';
import { TopBar } from './TopBar';
import { BaseDashboardLayout } from '../BaseDashboardLayout';

export interface MainDashboardLayoutProps {
  children: NonNullable<ReactNode>;
  isLoading?: boolean;
}

export const MainDashboardLayout = ({ children, isLoading }: MainDashboardLayoutProps) => {
  return (
    <BaseDashboardLayout header={<TopBar />} isLoading={isLoading} footer={<Footer />}>
      {children}
    </BaseDashboardLayout>
  );
};
