import { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';

interface MobileBottomBarProps {
  children: ReactNode;
}

export const MobileBottomBar = ({ children }: MobileBottomBarProps) => {
  const classes = useStyle();

  return <div className={classes.container}>{children}</div>;
};

const useStyle = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: theme.customColors.white,
    boxShadow: `0 -0.125rem 0.375rem -0.125rem ${theme.customColors.textLightGrey}`,
    padding: '1rem'
  }
}));
