import { useCallback, useEffect, ReactNode, useReducer, useContext, createContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { sessionKey, ONE_SECOND_IN_MILLISECONDS, refreshSessionKey, impersonatorTokenKey } from 'common/constants';

import { checkFbExisting } from 'common/utils/checkFbExisting';
import { getSessionDetails, removeSessionToken } from 'common/utils/session';

import { FullScreenLoader } from 'common/components';

import { refreshAuthorizationToken } from '../api';
import { decodeJwtToken } from '../utils';

interface LoginData {
  token: string;
  rememberMe: boolean;
  refreshToken: string;
  impersonatorToken?: string;
}

interface SessionContextShape {
  token: string | null;
  isLogged: boolean;
  isBootstrapCompleted: boolean;
  impersonatorToken: string | null;
  isImpersonated: () => boolean;
  login: (params: LoginData) => void;
  logout: () => void;
}

interface SessionContextProviderProps {
  children: ReactNode;
}

type ActionTypes = 'logout' | 'login';

const impersonatorTokenLenght = 168;
interface SessionAction {
  type: ActionTypes;
  params?: { token: string | null; impersonatorToken?: string };
}

interface SessionState {
  isLogged: boolean;
  token: string | null;
  isBootstrapCompleted: boolean;
  impersonatorToken: string | null;
}

const SessionContext = createContext<SessionContextShape | undefined>(undefined);

const sessionReducer = (state: SessionState, action: SessionAction): SessionState => {
  switch (action.type) {
    case 'logout':
      return {
        token: null,
        isLogged: false,
        isBootstrapCompleted: true,
        impersonatorToken: null
      };
    case 'login':
      return {
        isLogged: !!action.params?.token,
        token: action.params?.token || null,
        isBootstrapCompleted: true,
        impersonatorToken: action.params?.impersonatorToken ? action.params?.impersonatorToken : null
      };
    default: {
      throw new Error(`Unsupported action type: ${action.type}`);
    }
  }
};

const oneMinuteInMilliseconds = ONE_SECOND_IN_MILLISECONDS * 60;

/**
 * Initialize empty timer
 */
let refreshTokenTimer = setTimeout(() => {}, 0);

export const SessionContextProvider = ({ children }: SessionContextProviderProps): JSX.Element => {
  const [sessionState, sessionDispatcher] = useReducer(sessionReducer, {
    isLogged: false,
    token: null,
    impersonatorToken: null,
    isBootstrapCompleted: false
  });

  const { mutate: refreshTokenMutation } = useMutation({
    mutationFn: refreshAuthorizationToken,
    onSuccess: ({ refresh_token, token }) => {
      const { rememberMe, impersonatorToken } = getSessionDetails();

      loginHandler({
        token,
        refreshToken: refresh_token,
        rememberMe,
        impersonatorToken
      });
    },
    onError: () => {
      logoutHandler();
    }
  });

  const queryClient = useQueryClient();

  const getTokenExpiration = (token: string) => {
    /**
     * checking if token is correct
     */
    try {
      const { exp } = decodeJwtToken(token);
      const expirationDateInMilliseconds = exp * 1000;
      const nowInMilliseconds = Number(new Date());

      if (nowInMilliseconds >= expirationDateInMilliseconds) {
        /**
         * Token is expired
         */
        return null;
      }

      return expirationDateInMilliseconds;
    } catch (e) {
      /**
       * If error occurred it means that token is invalid
       */
      return null;
    }
  };

  // TODO: Change to object argument
  const setSessionTokens = (token: string, refreshToken: string, rememberMe: boolean, impersonatorToken?: string) => {
    /**
     * Set all required tokens for the correct working of the session
     */
    if (rememberMe) {
      localStorage.setItem(sessionKey, token);
    } else {
      sessionStorage.setItem(sessionKey, token);
    }
    localStorage.setItem(refreshSessionKey, refreshToken);
    if (impersonatorToken) {
      localStorage.setItem(impersonatorTokenKey, impersonatorToken);
    }
  };

  const setRefreshTokenTimer = useCallback(
    (tokenExpiration: number) => {
      /**
       * Setting a time to refresh token.
       * To get this time we subtract from current dateTime the expiration dateTime and one minute,
       * so we can try to refresh the token one minute before our current token expires
       */
      const timeToRefresh = tokenExpiration - Number(new Date()) - oneMinuteInMilliseconds;

      refreshTokenTimer = setTimeout(() => {
        refreshTokenMutation({
          refreshToken: localStorage.getItem(refreshSessionKey) || ''
        });
      }, timeToRefresh);
    },
    [refreshTokenMutation]
  );

  const logoutHandler = useCallback(() => {
    clearTimeout(refreshTokenTimer);
    if (checkFbExisting()) {
      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          FB.logout();
        }
      });
    }

    removeSessionToken();
    sessionDispatcher({ type: 'logout' });
    queryClient.clear();
  }, [queryClient]);

  const loginHandler = useCallback(
    ({ token, rememberMe, refreshToken, impersonatorToken }: LoginData) => {
      clearTimeout(refreshTokenTimer);

      const tokenExpiration = getTokenExpiration(token);

      if (!tokenExpiration) {
        /**
         * Token is incorrect or expired
         */
        logoutHandler();
        return;
      }

      setSessionTokens(token, refreshToken, rememberMe, impersonatorToken);
      sessionDispatcher({ type: 'login', params: { token, impersonatorToken } });
      setRefreshTokenTimer(tokenExpiration);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logoutHandler, setRefreshTokenTimer]
  );

  const checkStorageChange = useCallback(
    (ev: StorageEvent) => {
      if (ev.key !== sessionKey) {
        return;
      }
      const { token, rememberMe, refreshToken, impersonatorToken } = getSessionDetails();

      if (token === null) {
        logoutHandler();
      } else {
        loginHandler({
          rememberMe,
          token,
          refreshToken: refreshToken ?? '',
          impersonatorToken
        });
      }
    },
    [logoutHandler, loginHandler]
  );

  const isImpersonated = useCallback(() => {
    return sessionState.impersonatorToken?.length === impersonatorTokenLenght;
  }, [sessionState.impersonatorToken?.length]);

  /**
   * Set listeners for watch manual storage change
   */
  useEffect(() => {
    window.addEventListener('storage', checkStorageChange);

    return () => {
      window.removeEventListener('storage', checkStorageChange);
      clearTimeout(refreshTokenTimer);
    };
  }, [checkStorageChange]);

  /**
   * Bootstrap (after app initialization) token check
   */
  useEffect(() => {
    const { token, refreshToken, rememberMe, impersonatorToken } = getSessionDetails();
    if (token !== null) {
      loginHandler({
        rememberMe,
        token,
        refreshToken: refreshToken ?? '',
        impersonatorToken
      });
    } else {
      logoutHandler();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authContextValue: SessionContextShape = {
    ...sessionState,
    isImpersonated,
    login: loginHandler,
    logout: logoutHandler
  };

  return (
    <SessionContext.Provider value={authContextValue}>
      {sessionState.isBootstrapCompleted ? children : <FullScreenLoader />}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};
