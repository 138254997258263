import { PluginFunc } from 'dayjs';

const weekendDays = [0, 6];

const isWeekend: PluginFunc = (_, c) => {
  const proto = c.prototype;
  proto.isWeekend = function calculate() {
    return weekendDays.includes(this.day());
  };
};

export default isWeekend;
