import { SelectedMeals } from 'common/types';

export const calculateSelectedMealsTypes = (
  newAllSelectedMeals: SelectedMeals,
  previousSelectedMeals: SelectedMeals,
  isFlexi: boolean
) => {
  if (isFlexi) {
    return newAllSelectedMeals;
  }

  previousSelectedMeals.forEach((value, key) => {
    if (newAllSelectedMeals.get(key) && !value.length) {
      newAllSelectedMeals.set(key, []);
    }
  });

  return newAllSelectedMeals;
};
