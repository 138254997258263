import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { Paper } from 'common/components';
import { useTranslation } from 'react-i18next';
import { DietSummary } from './DietSummary';
import { PriceSummary } from './PriceSummary';
import { useHomePageConfigurator } from '../contexts/HomePageConfiguratorContext';

export const DesktopHomePageConfiguratorSummary = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { isLoading, calories, selectedMeals, selectedPackage, selectedVariant } = useHomePageConfigurator();

  return (
    <Paper className={classes.root}>
      <Typography variant="h2" align="center">
        {t('creatDietPage.summaryBox.title')}
      </Typography>
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <CircularProgress size={80} />
        </div>
      ) : (
        <>
          <DietSummary
            selectedPackage={selectedPackage}
            selectedVariant={selectedVariant}
            calorific={calories}
            selectedMeals={selectedMeals.map((m) => `${m.name} ${m.size.name}`)}
          />
          <PriceSummary />
        </>
      )}
    </Paper>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    flex: 1,
    boxShadow: '0px 10px 24px rgba(0, 0, 0, 0.04)'
  },
  loaderWrapper: {
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formCard: {
    backgroundColor: theme.customColors.white,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.customColors.mainBg
    }
  },
  priceSummaryHeader: {
    marginBottom: '1rem'
  },
  divider: {
    margin: '1rem 0'
  }
}));
