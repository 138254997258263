import { Country } from 'config/types';

const getCountry = (): Country => {
  if (['poland', 'germany', 'czech'].includes(process.env.REACT_APP_COUNTRY ?? '')) {
    return process.env.REACT_APP_COUNTRY as Country;
  }
  return 'poland';
};

export const country: Country = getCountry();
