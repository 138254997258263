import { z } from 'zod';

import { discountCodeTypeSchema } from './discountCodeType';

export const DiscountCodeSchema = z.object({
  id: z.number(),
  name: z.string(),
  type: discountCodeTypeSchema,
  discount: z.number()
});

export type DiscountCode = z.infer<typeof DiscountCodeSchema>;
