import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, CircularProgress } from '@material-ui/core';

interface SubmitSectionProps {
  price: number;
  cta?: ReactNode;
  isLoading?: boolean;
}

export const SubmitSection = ({ price, cta, isLoading = false }: SubmitSectionProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.group}>
        <span className={classes.title}>{t('creatDietPage.summaryBox.toPay')}</span>
        <div className={classes.priceValue}>
          {isLoading ? <CircularProgress size={16} /> : t('formatters.price', { price })}
        </div>
      </div>
      {cta && <div className={classes.submitButton}>{cta}</div>}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '1rem',
    margin: '1rem 0 0.5rem 0'
  },
  title: {
    color: theme.customColors.textDarkGrey
  },
  priceValue: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: theme.palette.secondary.dark
  },
  submitButton: {
    '& > a,button': {
      width: '100%'
    }
  }
}));
