import { z } from 'zod';

import { ApiClient } from 'features/apiProvider';

export const userProfileResponseSchema = z.object({
  id: z.number(),
  username: z.string(),
  phone: z
    .string()
    .nullable()
    .transform((val) => val ?? ''),
  email: z.string(),
  firstName: z
    .string()
    .nullable()
    .transform((val) => val ?? ''),
  lastName: z
    .string()
    .nullable()
    .transform((val) => val ?? ''),
  emailMarketingAgreement: z.boolean(),
  phoneMarketingAgreement: z.boolean(),
  wallet: z.number(),
  moneybox: z.number(),
  nip: z.string().nullable(),
  needFv: z.boolean(),
  recommendedCode: z.string()
});

export type UserProfileResponse = z.output<typeof userProfileResponseSchema>;

export const fetchProfile = async (apiClient: ApiClient['apiClient']): Promise<UserProfileResponse> => {
  const response = await apiClient.get('frontend/secure/client/info-about-me').json();
  return userProfileResponseSchema.parse(response);
};
