import { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core';

import { NavigationLink } from 'common/components';

import { userMenuRoutes } from 'routing/constants/userMenuRoutes';

export const MobileMenuUser = forwardRef<HTMLDivElement>((_, ref): JSX.Element => {
  const classes = useStyle();

  return (
    <div ref={ref} className={classes.wrapper}>
      {userMenuRoutes.map(({ path, icon, label }, index) => (
        <NavigationLink
          path={path as string}
          icon={icon}
          label={label}
          key={(path as string) || `route-${index}`}
          wrapperClassName={classes.menuElement}
        />
      ))}
    </div>
  );
});

const useStyle = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    width: '100%',
    minHeight: '100vh',
    padding: '1rem 2rem 1.5rem',
    overflow: 'auto',
    backgroundColor: theme.palette.secondary.main
  },
  menuElement: {
    color: theme.customColors.white,
    '& > img': {
      filter: theme.filters.white
    }
  }
}));
