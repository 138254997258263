import { SelectedMeal, SelectedMeals } from 'common/types';
import { nanoid } from 'nanoid';

import { Basket } from '../types';

export const mapBasketMealsToSelectedMeals = (basketMeals: Basket): SelectedMeals => {
  const entities: [number, SelectedMeal[]][] = basketMeals.basketMeals.map((basketMealType) => {
    const { mealType } = basketMealType;

    // Basket has only one meal
    return [mealType.id, [{ id: nanoid(), mealId: mealType.meals[0] }]];
  });

  return new Map(entities);
};
