import { CalendarDate } from 'common/types';
import { maxDaysIntervalToStartOrder } from '../constants';
import { CreateDietFormData } from '../types';
import { calculatePredefinedDateRange } from './calculateDateRange';
import { getDietLenghtOptions } from './getDietLenghtOptions';

interface GetDietLengthProps {
  withoutWeekends: CreateDietFormData['withoutWeekends'];
  dietLengthId: CreateDietFormData['dietLength'];
  customDeliveryDates: CreateDietFormData['customDeliveryDates'];
  firstDeliveryDate: CreateDietFormData['continuousFirstDeliveryDate'];
  firstDeliveryAt: CalendarDate;
  disabledDays: CalendarDate[];
}

export const getDietLength = ({
  withoutWeekends,
  dietLengthId,
  customDeliveryDates,
  firstDeliveryDate,
  firstDeliveryAt,
  disabledDays
}: GetDietLengthProps): number => {
  const dietLengthOption = getDietLenghtOptions(withoutWeekends).find((o) => o.id === dietLengthId);

  if (dietLengthOption?.type === 'continuous') {
    const maxDate = firstDeliveryAt.add(maxDaysIntervalToStartOrder, 'd');
    const predefinedDateRange = calculatePredefinedDateRange({
      firstDate: firstDeliveryDate,
      length: dietLengthOption.days,
      disabledDays,
      maxDate,
      withoutWeekends
    });
    return predefinedDateRange.length;
  }

  return customDeliveryDates.length;
};
