import { SvgIcon, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { FunctionComponent, SVGProps } from 'react';

interface SummarySectionHeaderProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title: string;
  className?: string;
}

export const SummarySectionHeader = ({ icon, title, className }: SummarySectionHeaderProps) => {
  const classes = useStyle();
  return (
    <div className={clsx(classes.root, className)}>
      <SvgIcon component={icon} className={classes.icon} />
      <Typography component="h3" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: '0.5rem'
  },
  title: {
    fontWeight: 'bold'
  }
});
