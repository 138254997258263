import { zodResolver } from '@hookform/resolvers/zod';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { Dialog } from 'common/components';
import { useVisibility } from 'common/hooks';
import { CaloricCalculatorDataForm, caloricCalculatorDataFormSchema } from 'features/orders/types';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { calculateCalorific } from './calculateCalorific';
import { CaloricCalculatorForm } from './CaloricCalculatorForm';
import { CalorificSummary } from './CalorificSummary';

interface CaloricCalculatorProps {
  onSelect: (newValue: number) => void;
}

export const CaloricCalculator = ({ onSelect }: CaloricCalculatorProps) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [isVisible, show, hide] = useVisibility();
  const [actualStep, setActualStep] = useState<'config' | 'summary'>('config');
  const [calculatedCalories, setCalculatedCalories] = useState(0);

  const caloricCalculatorForm = useForm<CaloricCalculatorDataForm>({
    defaultValues: {
      sex: 'female',
      goal: 'loseWeight',
      exerciseActivity: 1,
      weight: '' as unknown as number,
      age: '' as unknown as number,
      height: '' as unknown as number
    },
    resolver: zodResolver(caloricCalculatorDataFormSchema),
    mode: 'onChange'
  });

  const handleSubmit: SubmitHandler<CaloricCalculatorDataForm> = (data) => {
    setCalculatedCalories(calculateCalorific(data));
    setActualStep('summary');
  };

  const handleBack = () => setActualStep('config');

  const handleClose = () => {
    if (actualStep === 'summary') {
      setActualStep('config');
    } else {
      hide();
      caloricCalculatorForm.reset();
    }
  };
  const handleSelect = () => {
    onSelect(calculatedCalories);
    hide();
    caloricCalculatorForm.reset();
    setActualStep('config');
  };
  return (
    <>
      <div className={classes.buttonCalculatorWraper}>
        <Button onClick={show} classes={{ label: classes.buttonCalculatorLabel }}>
          <Trans i18nKey="creatDietPage.caloriesSection.caloricCalculatorTitle">
            Nie wiesz jaką kaloryczność wybrać? Skorzystaj z naszego
            <span className={classes.buttonCalculatorCta}> kalkulatora kalorii</span>
          </Trans>
        </Button>
      </div>
      <Dialog
        classes={{ content: classes.content }}
        open={isVisible}
        onClose={handleClose}
        title={<Typography variant="h3">{t('creatDietPage.caloriesSection.caloricCalculator.title')}</Typography>}
        maxWidth="sm"
      >
        {actualStep === 'config' ? (
          <FormProvider {...caloricCalculatorForm}>
            <CaloricCalculatorForm onSubmit={caloricCalculatorForm.handleSubmit(handleSubmit)} />
          </FormProvider>
        ) : (
          <CalorificSummary onSelect={handleSelect} onBack={handleBack} calories={calculatedCalories} />
        )}
      </Dialog>
    </>
  );
};

const useStyle = makeStyles({
  content: {
    marginTop: '2.625rem'
  },
  buttonCalculatorLabel: {
    textTransform: 'none',
    display: 'block'
  },
  buttonCalculatorWraper: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonCalculatorCta: {
    textDecoration: 'underline'
  }
});
