import { z } from 'zod';

import { ApiClient } from 'features/apiProvider';

import { ChangePasswordFormData } from '../types';

interface ChangePasswordRequest extends ApiClient {
  requestData: ChangePasswordFormData;
}

const changePasswordResponseSchema = z.object({
  messages: z.array(z.string())
});

type ChangePasswordResponse = z.TypeOf<typeof changePasswordResponseSchema>;

export const changePassword = async ({
  requestData,
  apiClient
}: ChangePasswordRequest): Promise<ChangePasswordResponse> => {
  const response = await apiClient
    .post('v3/account/change_password', {
      json: {
        current_password: requestData.currentPassword,
        password: requestData.newPassword,
        password_second: requestData.repeatPassword
      }
    })
    .json();

  return changePasswordResponseSchema.parse(response);
};
