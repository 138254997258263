import { Button, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { ButtonGroupDescription } from './ButtonGroupDescription';
import { Trans } from 'react-i18next';

interface ButtonGroupWithTooltipProps {
  value?: number;
  options: {
    label: string;
    value: number;
    description: string;
  }[];
  onChangeValue: (newValue: number) => void;
  descriptionTestId?: string;
}

export const ButtonGroupWithTooltip = ({
  value,
  options,
  onChangeValue,
  descriptionTestId
}: ButtonGroupWithTooltipProps) => {
  const classes = useStyle();

  const handleModeClick = (newValue: number) => () => onChangeValue(newValue);

  const selectedOption = options.find((singleOpt) => singleOpt.value === value);

  return (
    <>
      <Grid container spacing={2}>
        {options.map((singleOption) => (
          <Grid item key={singleOption.value} xs>
            <Button
              key={singleOption.value}
              variant="contained"
              className={clsx({
                [classes.button]: true,
                active: value === singleOption.value
              })}
              onClick={handleModeClick(singleOption.value)}
              aria-pressed={value === singleOption.value}
            >
              <Trans components={{ br: <br /> }}>{singleOption.label}</Trans>
            </Button>
          </Grid>
        ))}
      </Grid>
      {selectedOption && (
        <ButtonGroupDescription content={selectedOption.description} data-testid={descriptionTestId} />
      )}
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  button: {
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 1.19,
    letterSpacing: 'normal',
    boxShadow: theme.boxShadow.lightGrey,
    width: '100%',
    minHeight: '2.5rem',
    backgroundColor: theme.customColors.white,
    textTransform: 'none',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      lineHeight: 'normal'
    },

    '&.active': {
      backgroundColor: theme.palette.primary.main,
      position: 'relative',

      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: `calc(50% - 0.75rem)`,
        bottom: `-1.5rem`,
        width: 0,
        height: 0,
        borderLeft: `0.75rem solid transparent`,
        borderRight: `0.75rem solid transparent`,
        borderBottom: `0.75rem solid black`,
        clear: 'both'
      },

      '&:hover': {
        backgroundColor: theme.customColors.white,
        boxShadow: theme.boxShadow.lightGrey,

        '&.active': {
          backgroundColor: theme.palette.primary.main
        }
      }
    }
  },

  tooltipWrapper: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.customColors.white,
    marginTop: '1.5rem',
    padding: '1rem 1.5rem',
    borderRadius: `0.313rem`,
    fontSize: '1rem',
    lineHeight: 1.19,
    textAlign: 'center',
    boxShadow: theme.boxShadow.lightGrey,
    letterSpacing: 'normal',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      lineHeight: 1.29,
      letterSpacing: '0.013rem'
    }
  }
}));
