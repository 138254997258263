import { useState } from 'react';
import { FormTextFieldProps, FormTextField } from '../FormTextField';
import { TextFieldProps } from '../../TextField';
import { ShowPasswordButton } from './ShowPasswordButton';

interface FormPasswordTextFieldProps extends Omit<FormTextFieldProps, 'type' | 'InputProps'> {
  InputProps?: Omit<TextFieldProps['InputProps'], 'endAdornment'>;
}

export const FormPasswordTextField = ({ InputProps, ...rest }: FormPasswordTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword((prevState) => !prevState);
  return (
    <FormTextField
      type={showPassword ? 'text' : 'password'}
      {...rest}
      InputProps={{
        ...InputProps,
        endAdornment: <ShowPasswordButton isVisible={showPassword} onClick={togglePasswordVisibility} />
      }}
    />
  );
};
