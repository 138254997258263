import type { CartEventData, CartGAEventData } from '../types';
import { calculateTransactionValue } from './calculateTransactionValue';
import { getDietCalories } from './getDietCalories';
import { getMeals } from './getMeals';
import { getPathType } from './getPathType';
import { parseEcommerce } from './parseEcommerce';

export const parseCartEventData = ({
  discountCode,
  moneybox,
  dietLength,
  selectedMeals,
  transactionValue,
  deliveryPrice,
  selectedPackage,
  selectedVariant,
  mealMode,
  selectedBasket,
  mealPrices,
  sizesData
}: CartEventData): CartGAEventData => {
  return {
    path_type: getPathType(mealMode),
    diet_name: selectedVariant?.name,
    diet_type: selectedPackage?.name,
    diet_calorie: getDietCalories({ selectedMeals, mealMode, selectedBasket, sizesData }),
    meal_type: getMeals({ selectedMeals, sizesData }),
    diet_duration: dietLength,
    value_discount: discountCode?.value,
    coupon: discountCode?.name,
    moneybox_value: moneybox,
    ecommerce: parseEcommerce({
      dietLength,
      selectedPackage,
      selectedVariant,
      selectedMeals,
      mealPrices,
      sizesData,
      deliveryPrice,
      transactionValue: calculateTransactionValue(transactionValue, discountCode?.value)
    })
  };
};
