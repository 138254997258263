import { MealModes, SelectedMeals, DietPackage } from 'common/types';
import { defaultPackageId, defaultWithoutWeekend } from '../../constants';
import type { Basket, DeliveryTimeConfig, DietConfiguration } from '../../types';
import { getDietLenghtOptions } from '../getDietLenghtOptions';
import { mapBasketMealsToSelectedMeals } from '../mapBasketMealsToSelectedMeals';
import { InitialFormState } from './initialFormState';

const undefinedId = -1;

export const baseCalculateInitialFormState = (
  data: DietConfiguration,
  deliveryTimeConfig: DeliveryTimeConfig
): InitialFormState => {
  const basket: Basket | undefined = data.baskets.values().next().value;
  const dietPackage: DietPackage | undefined =
    data.packages.get(defaultPackageId) ?? data.packages.values().next().value;

  const dietLengthOptions = getDietLenghtOptions(defaultWithoutWeekend);
  const dietLengthOption =
    dietLengthOptions.find((o) => {
      return o.type === 'custom';
    }) ?? dietLengthOptions[0];

  return {
    basket: basket?.id ?? undefinedId,
    mealMode: MealModes.comfort,
    meals: basket ? mapBasketMealsToSelectedMeals(basket) : (new Map() as SelectedMeals),
    dietPackage: dietPackage?.id ?? undefinedId,
    dietVariant: dietPackage?.defaultVariant ?? undefinedId,
    withoutWeekends: defaultWithoutWeekend,
    dietLength: dietLengthOption.id,
    customDeliveryDates: [deliveryTimeConfig.firstDeliveryAt],
    continuousFirstDeliveryDate: deliveryTimeConfig.firstDeliveryAt,
    paymentType: undefined
  };
};
