import dayjs from 'dayjs';
import { memo } from 'react';
import { CalendarDate } from 'common/types';
import { Weekday } from '../Weekday';
import { DaysGridRow } from './DaysGridRow';

export const getDayOfWeek = (date: CalendarDate): number => (date.day() + 6) % 7;

interface WeekdaysProps {
  locale: string;
}

export const Weekdays = memo(({ locale }: WeekdaysProps) => {
  const now = dayjs().startOf('month').locale(locale);
  const offset = getDayOfWeek(now.startOf('month'));

  const weekdays = Array.from({ length: 7 }, (v, i) => i).map((i) => {
    const day = now.add(i - offset, 'd');
    return {
      value: day.format('dd.'),
      label: day.format('dddd')
    };
  });

  return (
    <DaysGridRow>
      {weekdays.map(({ value, label }) => (
        <Weekday key={label} value={value} label={label} />
      ))}
    </DaysGridRow>
  );
});

Weekdays.displayName = 'MemoizedWeekdays';
