import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

import iconUser from '../images/icon-user.svg';

export const User = () => {
  const classes = useStyle();

  return (
    <NavLink to={RoutesDefinition.user} activeClassName="active" className={classes.userIcon}>
      <img alt="Przejdź do mojego konta" src={iconUser} />
    </NavLink>
  );
};

const useStyle = makeStyles((theme) => ({
  userIcon: {
    display: 'flex',
    filter: theme.filters.ligthGrey,
    margin: '0 1.5rem',

    '&.active': {
      filter: theme.filters.primaryMain
    }
  }
}));
