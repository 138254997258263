import { cancelOrder } from 'features/user/api';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { NotifyDialog } from 'common/components';
import { useVisibility } from 'common/hooks';
import { useApi } from 'features/apiProvider';
import { AccountLayout } from 'layouts';
import type { Id } from 'common/types';
import { useOrderHistory } from 'services/orderHistory/useOrderHistory';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { ListItem } from './components/ListItem';

export const OrderHistoryPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { getApiClient } = useApi();
  const [isVisible, show, hide] = useVisibility();

  const { data, historyItems, nextPage, isLoading: historyLoading, error, refetch } = useOrderHistory({ limit: 20 });

  const { mutate, isLoading } = useMutation({
    mutationKey: 'cancelOrderItem',
    mutationFn: cancelOrder,
    onError: show,
    onSuccess: () => {
      show();
      refetch();
    }
  });

  const handleCancelOrder = (id: Id) => mutate({ apiClient: getApiClient(), id });

  const loading = isLoading || historyLoading;

  return (
    <AccountLayout isLoading={loading} title={t('orderHistoryPage.title')}>
      <NotifyDialog
        isOpen={isVisible}
        variant={error ? 'error' : 'success'}
        onClose={hide}
        message={error ? 'orderHistoryPage.item.cancel.errorRequest' : 'orderHistoryPage.item.cancel.successRequest'}
      />
      {loading && (
        <div className={classes.loader}>
          <CircularProgress size={40} color="primary" />
        </div>
      )}
      {!!historyItems.length && data && (
        <InfiniteScroll
          className={classes.scrollContainer}
          hasMore={data.page < data.pageCount}
          next={nextPage}
          loader={
            <div className={classes.loader}>
              <CircularProgress size={40} color="primary" />
            </div>
          }
          dataLength={data.items.length}
        >
          {historyItems.map((item) => (
            <ListItem key={item.id} item={item} onCancelOrder={() => handleCancelOrder(item.id)} />
          ))}
        </InfiniteScroll>
      )}
    </AccountLayout>
  );
};

const useStyles = makeStyles(() => ({
  loader: {
    display: 'flex',
    height: '3rem',
    justifyContent: 'center'
  },
  scrollContainer: {
    gap: '0.75rem',
    display: 'flex',
    flexDirection: 'column'
  }
}));
