import { ReactNode } from 'react';
import { SnackbarProvider as NotistackProvider, closeSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'common/icons/icon-times.svg';
import { ReactComponent as CheckIcon } from 'common/icons/icon-check.svg';
import { ErrorSnackbar } from './ErrorSnackbar';

declare module 'notistack' {
  interface VariantOverrides {
    refreshableError: true;
  }
}

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const classes = useStyles();

  return (
    <NotistackProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      preventDuplicate
      iconVariant={{
        success: (
          <button type="button" className={classes.closeButton} onClick={() => closeSnackbar()}>
            <CheckIcon className={classes.icon} />
          </button>
        ),
        error: (
          <button type="button" className={classes.closeButton} onClick={() => closeSnackbar()}>
            <CloseIcon className={classes.icon} />
          </button>
        )
      }}
      Components={{
        refreshableError: ErrorSnackbar
      }}
      autoHideDuration={5000}
      className={classes.custom}
      classes={{
        anchorOriginTopCenter: classes.provider
      }}
    >
      {children}
    </NotistackProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  custom: {
    '&.notistack-MuiContent-error': {
      backgroundColor: '#F34D52'
    },
    '&.notistack-MuiContent-success': {
      backgroundColor: '#3CB76D'
    }
  },
  closeButton: {
    all: 'unset',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  icon: {
    width: '1rem',
    height: '1rem',
    marginRight: '0.5rem'
  },
  provider: {
    [theme.breakpoints.up('sm')]: {
      top: '7vh'
    },
    [theme.breakpoints.up('md')]: {
      top: '8vh'
    }
  }
}));
