import { InfoMessage } from 'common/components';
import { useTranslation } from 'react-i18next';

export const InfoAboutDeliveryDiscount = () => {
  const { t } = useTranslation();
  return (
    <InfoMessage title={t('creatDietPage.deliveryDiscount.header')}>
      {t('creatDietPage.deliveryDiscount.description')}
    </InfoMessage>
  );
};
