import { ReactNode } from 'react';
import { Container, makeStyles, Typography } from '@material-ui/core';
import { TFuncKey, Trans, useTranslation } from 'react-i18next';

import successIcon from 'common/icons/icon-check-circle.svg';
import errorIcon from 'common/icons/icon-error-circle.svg';
import clsx from 'clsx';
import { Dialog } from '../Dialog';

export interface NotifyDialogProps {
  message: TFuncKey;
  isOpen: boolean;
  cta?: ReactNode;
  onClose: () => void;
  variant: 'error' | 'success';
}

export const NotifyDialog = ({ message, isOpen, onClose, variant, cta }: NotifyDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const title = variant === 'error' ? 'notificationDialog.error.title' : 'notificationDialog.success.title';

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="notify-dialog-message">
      <div className={classes.content}>
        <img src={variant === 'error' ? errorIcon : successIcon} alt="" className={clsx(classes.icon, variant)} />
        <Typography variant="h5" className={clsx(classes.title, variant)}>
          {t(title)}
        </Typography>

        <Container maxWidth="lg">
          <Typography id="notify-dialog-message" variant="body2" align="center" className={classes.message}>
            <Trans i18nKey={message} />
          </Typography>
        </Container>
        {cta && <div className={classes.ctaWrapper}>{cta}</div>}
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '2.5rem'
  },
  ctaWrapper: {
    marginTop: '2rem'
  },
  icon: {
    width: '4rem',
    marginBottom: theme.spacing(2),

    '&.success': {
      filter:
        'brightness(0) saturate(100%) invert(38%) sepia(88%) saturate(1885%) hue-rotate(112deg) brightness(98%) contrast(97%)'
    }
  },
  title: {
    marginBottom: theme.spacing(2),

    '&.error': {
      color: theme.customColors.errors
    },
    '&.success': {
      color: theme.customColors.green
    }
  },
  message: {
    whiteSpace: 'break-spaces'
  }
}));
