import { RouteProps } from 'react-router-dom';

import { UserDataPage } from 'pages/userPage/UserDataPage';
import { AddressesPage } from 'pages/userPage/addresses/AddressesPage';
import { ApprovalsPage } from 'pages/userPage/ApprovalsPage';
import { OrderHistoryPage } from 'pages/userPage/OrderHistoryPage';
import { InvoicesPage } from 'pages/userPage/InvoicesPage';
import { ChangePasswordPage } from 'pages/userPage/ChangePasswordPage';

import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

import pinIcon from 'common/icons/icon-pin.svg';
import invoiceIcon from 'common/icons/icon-receipt.svg';
import historyIcon from 'common/icons/icon-time.svg';
import approvalsIcon from 'common/icons/icon-clipboard.svg';
import detailsIcon from 'common/icons/icon-id.svg';
import passwordIcon from 'common/icons/icon-lock.svg';

export const userMenuRoutes: (RouteProps & { label: string; icon: string })[] = [
  {
    label: 'userPage.userMenu.details',
    icon: detailsIcon,
    path: RoutesDefinition.userDetails,
    component: UserDataPage,
    exact: true
  },
  {
    label: 'userPage.userMenu.addresses',
    icon: pinIcon,
    path: RoutesDefinition.userAdresses,
    component: AddressesPage,
    exact: true
  },
  {
    label: 'userPage.userMenu.approvals',
    icon: approvalsIcon,
    path: RoutesDefinition.userApprovals,
    component: ApprovalsPage,
    exact: true
  },
  {
    label: 'userPage.userMenu.history',
    icon: historyIcon,
    path: RoutesDefinition.orderHistory,
    component: OrderHistoryPage,
    exact: true
  },
  {
    label: 'userPage.userMenu.invoices',
    icon: invoiceIcon,
    path: RoutesDefinition.userInvoices,
    component: InvoicesPage,
    exact: true
  },
  {
    label: 'userPage.userMenu.changePassword',
    icon: passwordIcon,
    path: RoutesDefinition.changePassword,
    component: ChangePasswordPage,
    exact: true
  }
];
