import { SelectedMeals } from 'common/types';
import { isEqual } from 'lodash';

import { AvailableBaskets } from '../../../types';

export const matchSelectedMealsToPredefiniedBasket = (
  currentSelectedMeals: SelectedMeals,
  baskets: AvailableBaskets
) => {
  const currentSelectedMealsIds: number[] = [];

  currentSelectedMeals.forEach((value) => {
    const mealsIds = value.reduce((acc, current) => {
      return [...acc, current.mealId];
    }, [] as number[]);

    currentSelectedMealsIds.push(...mealsIds);
  });

  const predefiniedBasket = Array.from(baskets.values()).find((singleBasket) => {
    const basketMealsId = singleBasket.basketMeals.reduce((acc, current) => {
      return [...acc, ...current.mealType.meals];
    }, [] as number[]);

    return isEqual(currentSelectedMealsIds, basketMealsId);
  });

  return predefiniedBasket;
};
