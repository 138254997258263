import type { SelectedMeals } from 'common/types';
import { flatMap } from 'lodash';
import { SizeData } from '../api/fetchSizesData';

export const calculateCalorific = (selectedMeals: SelectedMeals, sizesData: SizeData[]) => {
  const allSelectedPortions = flatMap(Array.from(selectedMeals.values()));

  const calorificBySizeId = sizesData.reduce((acc: Map<number, number>, currentPortion) => {
    currentPortion.sizes.forEach((size) => {
      acc.set(size.id, size.calorific);
    });
    return acc;
  }, new Map());

  const sum = allSelectedPortions.reduce((acc, { mealId }) => {
    const currentMealCalorie = calorificBySizeId.get(mealId);
    if (currentMealCalorie) {
      return acc + currentMealCalorie;
    }

    return acc;
  }, 0);

  return sum;
};
