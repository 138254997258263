import { Id } from 'common/types';
import { AddressMenuList } from './AddressMenuList';
import { MobileAddressMenu } from './MobileAddressMenu';

interface AddressMenuProps {
  onClose: VoidFunction;
  name: string;
  id: Id;
}

export const AddressMenu = ({ onClose, name, id }: AddressMenuProps) => {
  return (
    <>
      <AddressMenuList id={id} name={name} onClose={onClose} />
      <MobileAddressMenu id={id} name={name} onClose={onClose} />
    </>
  );
};
