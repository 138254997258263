import { useRef, useCallback, useEffect, ReactNode } from 'react';

interface ContentProps {
  onResize: (width: number) => void;
  children: ReactNode;
}

export const Content = ({ onResize, children }: ContentProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const handleResize = useCallback(() => {
    if (containerRef.current) {
      onResize(containerRef.current.clientWidth);
    }
  }, [onResize]);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return <div ref={containerRef}>{children}</div>;
};
