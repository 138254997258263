import { Id } from 'common/types';
import { ApiClient } from 'features/apiProvider';

interface CreateProfileRequest extends ApiClient {
  data: {
    id: Id;
    name: string;
  };
}

export const updateProfile = async ({ data: { id, name }, apiClient }: CreateProfileRequest) => {
  await apiClient
    .patch(`v3/diet/${id}`, {
      json: {
        name
      }
    })
    .json();
};
