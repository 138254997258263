import {
  IconButton,
  makeStyles,
  Popper as MuiPopper,
  PopperPlacementType,
  Theme,
  ClickAwayListener
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type Placement = 'left' | 'right';

interface InfoTooltipProps {
  children: ReactNode;
  className?: string;
  placement?: Placement;
}

export const InfoTooltip = ({ children, className, placement = 'left' }: InfoTooltipProps) => {
  const { t } = useTranslation();
  const classes = useStyle({ placement });
  const popperAnchor = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = () => {
    setAnchorEl(popperAnchor.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let popperPlacement: PopperPlacementType;
  switch (placement) {
    case 'left':
      popperPlacement = 'left-start';
      break;
    case 'right':
      popperPlacement = 'bottom-start';
      break;
    default:
      popperPlacement = 'bottom-end';
      break;
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.wrapper}>
        <IconButton aria-label={t('popup.open')} onClick={handleClick}>
          <InfoIcon fontSize="small" color="secondary" />
        </IconButton>
        <MuiPopper open={open} anchorEl={anchorEl} className={classes.popover} placement={popperPlacement}>
          <IconButton aria-label={t('popup.close')} onClick={handleClose} className={classes.popoverClose}>
            <CloseIcon fontSize="small" color="inherit" />
          </IconButton>
          <div className={clsx(classes.popoverContent, className)}>{children}</div>
        </MuiPopper>
        {/* Popper position to this element */}
        <div className={classes.hiddenBox} ref={popperAnchor} />
      </div>
    </ClickAwayListener>
  );
};

const useStyle = makeStyles<Theme, { placement: Placement }>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: (props) => (props.placement === 'right' ? 'row-reverse' : 'row')
  },
  popoverClose: {
    position: 'absolute',
    top: '0',
    color: theme.customColors.white,

    [theme.breakpoints.down('md')]: {
      right: 0
    },

    [theme.breakpoints.up('md')]: {
      right: (props) => (props.placement === 'left' ? '0' : 'auto')
    }
  },
  popover: {
    zIndex: 1
  },
  popoverContent: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '22rem'
    },
    zIndex: 22,
    backgroundColor: theme.palette.secondary.dark,
    color: theme.customColors.white,
    padding: '1.5rem 2.5rem',

    '& > p': {
      fontSize: '0.875rem'
    }
  },
  hiddenBox: {
    height: 'calc(100% + 1rem)'
  }
}));
