import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@material-ui/core';
import { Button, FormTextField } from 'common/components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageProfileFormData, manageProfileSchema, manageProfileSchemaFormMaxFieldsLength } from '../types';

interface ManageProfileFormProps {
  mode: 'create' | 'edit';
  defaultValue?: string;
  onSubmit: SubmitHandler<ManageProfileFormData>;
}

export const ManageProfileForm = ({ mode, defaultValue = '', onSubmit }: ManageProfileFormProps) => {
  const { t } = useTranslation();
  const formMethods = useForm<ManageProfileFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(manageProfileSchema),
    defaultValues: {
      name: defaultValue
    }
  });
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormTextField
              name="name"
              placeholder={t('profile.manageProfileForm.name.placeholder')}
              label={t('profile.manageProfileForm.name.label')}
              fullWidth
              inputProps={{
                maxLength: manageProfileSchemaFormMaxFieldsLength.name
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button mode="primary" type="submit" fullWidth>
              {t(
                mode === 'create' ? 'profile.manageProfileForm.submit.create' : 'profile.manageProfileForm.submit.edit'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
