import { zodResolver } from '@hookform/resolvers/zod';
import { makeStyles } from '@material-ui/core';
import { Button, Checkbox } from 'common/components';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { approvalsFormSchema, ApprovalsFormSchema } from '../types';

interface ApprovalsFormProps {
  defaultValues: {
    subscriptionEmail: boolean;
    subscriptionPhone: boolean;
  };
  onSubmit: (value: ApprovalsFormSchema) => void;
  isLoading: boolean;
}

export const ApprovalsForm = ({ defaultValues, onSubmit, isLoading }: ApprovalsFormProps) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const formMethods = useForm<ApprovalsFormSchema>({
    reValidateMode: 'onChange',
    resolver: zodResolver(approvalsFormSchema),
    defaultValues
  });

  return (
    <FormProvider {...formMethods}>
      <form className={classes.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Checkbox
          className={classes.checkboxBox}
          name="subscriptionEmail"
          label={t('approvalsPage.form.email.label')}
          checkedAlt={t('approvalsPage.form.email.checked')}
          unCheckedAlt={t('approvalsPage.form.email.unChecked')}
        />
        <Checkbox
          className={classes.checkboxBox}
          name="subscriptionPhone"
          label={t('approvalsPage.form.phone.label')}
          checkedAlt={t('approvalsPage.form.phone.checked')}
          unCheckedAlt={t('approvalsPage.form.phone.unChecked')}
        />
        <Button
          type="submit"
          mode="secondary"
          className={classes.submit}
          disabled={!formMethods.formState.isDirty || isLoading}
        >
          {t('approvalsPage.form.submit')}
        </Button>
      </form>
    </FormProvider>
  );
};

const useStyle = makeStyles(() => ({
  form: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
      fontSize: '0.875rem'
    }
  },
  checkboxBox: {
    margin: '1.5rem 0 1.5rem -3px'
  },
  submit: {
    width: '10rem'
  }
}));
