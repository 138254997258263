import { Container, makeStyles, Typography, SvgIcon, Grid } from '@material-ui/core';
import { TFuncKey, Trans, useTranslation } from 'react-i18next';

import { useMobileScreenSizeDetector } from 'common/hooks';
import { ReactNode } from 'react';
import { ReactComponent as Icon } from './icon.svg';
import { Dialog } from '../Dialog';

interface ConfirmationDialogProps {
  title: TFuncKey;
  description: TFuncKey;
  isOpen: boolean;
  onClose: VoidFunction;
  cancelButton: NonNullable<ReactNode>;
  confirmButton: NonNullable<ReactNode>;
}

export const ConfirmationDialog = ({
  title,
  description,
  isOpen,
  onClose,
  cancelButton,
  confirmButton
}: ConfirmationDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMobileScreenSizeDetector();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-message"
      classes={{
        footer: classes.footer
      }}
      footer={
        <Grid
          container
          direction={isMobile ? 'column-reverse' : 'row'}
          justify="center"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item md={4} xs={12}>
            {cancelButton}
          </Grid>
          <Grid item md={4} xs={12}>
            {confirmButton}
          </Grid>
        </Grid>
      }
    >
      <div className={classes.content}>
        <SvgIcon className={classes.icon} component={Icon} viewBox="0 0 64 64" />
        <Typography variant="h3" className={classes.title}>
          {t(title) as string}
        </Typography>

        <Container maxWidth="lg">
          <Typography id="confirmation-dialog-message" variant="body2" align="center" className={classes.description}>
            <Trans i18nKey={description} />
          </Typography>
        </Container>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '1rem',
    marginTop: '2rem'
  },
  icon: {
    marginBottom: theme.spacing(2),
    fontSize: '4rem'
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.customColors.orangeLight
  },
  description: {
    whiteSpace: 'break-spaces'
  },
  footer: {
    marginBottom: '3rem'
  },
  ctaButton: {
    opacity: 0.5
  }
}));
