import * as z from 'zod';

export const manageProfileSchemaFormMaxFieldsLength = {
  name: 32
};

export const manageProfileSchema = z.object({
  name: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .min(1, 'errors.required')
        .max(manageProfileSchemaFormMaxFieldsLength.name, 'profile.manageProfileForm.name.validation.max')
        .safeParse(val);
      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    })
});

export type ManageProfileFormData = z.output<typeof manageProfileSchema>;
