import { ApiClient } from 'features/apiProvider';
import { NewAddress } from '../types';

interface CreateAddressesRequest extends ApiClient {
  data: NewAddress[];
}

export const createAddresses = async ({ data, apiClient }: CreateAddressesRequest): Promise<void> => {
  await apiClient.post('v3/addresses', {
    json: data
  });
};
