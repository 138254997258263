import { makeStyles } from '@material-ui/core';
import { Loader } from 'common/components/Loader';

export const FullScreenLoader = () => {
  const classes = useStyle();

  return (
    <div className={classes.loader}>
      <Loader />
    </div>
  );
};

const useStyle = makeStyles(() => ({
  loader: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0, 0.5)',
    zIndex: 20,
    '& > div': {
      top: 0,
      height: '100%',
      marginTop: '0',
      alignItems: 'center'
    }
  }
}));
