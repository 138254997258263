import { Theme, useMediaQuery } from '@material-ui/core';
import { ReactNode } from 'react';

interface HiddenOnMobileProps {
  children?: ReactNode;
}

export const HiddenOnMobile = ({ children }: HiddenOnMobileProps) => {
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  if (hidden) {
    return null;
  }
  return <>{children}</>;
};
