import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Button, Dialog } from 'common/components';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { useTranslation } from 'react-i18next';

interface ResetSelectedMealsProps {
  open: boolean;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
}

export const ResetSelectedMeals = ({ open, onCancel, onConfirm }: ResetSelectedMealsProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyle();
  const isMobile = useMobileScreenSizeDetector();
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      footer={
        <Grid
          container
          direction={isMobile ? 'column-reverse' : 'row'}
          justify="flex-end"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item md={3} xs={12}>
            <Button mode="secondary" fullWidth onClick={onCancel}>
              {t('creatDietPage.resetSelectedMeals.cancel')}
            </Button>
          </Grid>
          <Grid item md={3} xs={12}>
            <Button fullWidth onClick={onConfirm}>
              {t('creatDietPage.resetSelectedMeals.change')}
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Typography component="h2" variant="h3" className={classes.title}>
        {t('creatDietPage.resetSelectedMeals.title')}
      </Typography>
      <Typography variant="body2">{t('creatDietPage.resetSelectedMeals.description')}</Typography>
    </Dialog>
  );
};

const useStyle = makeStyles({
  title: {
    marginBottom: '1rem'
  }
});
