import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import selectedIcon from 'common/icons/icon-check.svg';

interface MarkedCircleProps {
  imageAlt: string;
  containerClassName?: string;
}

export const MarkedCircle = ({ imageAlt, containerClassName }: MarkedCircleProps) => {
  const classes = useStyle();

  return (
    <div className={clsx(classes.markedIcon, containerClassName)}>
      <img src={selectedIcon} alt={imageAlt} />
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  markedIcon: {
    width: '1rem',
    height: '1rem',
    backgroundColor: theme.customColors.white,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
