import { Country, country } from 'config';
import { z } from 'zod';
import { taxNumberValidation } from '../utils';

const maxLengthMap: Record<Country, number> = {
  czech: 12,
  poland: 10,
  germany: 20
};

const minLengthMap: Record<Country, number> = {
  czech: 8,
  poland: 10,
  germany: 10
};

const taxNumberLength = {
  min: minLengthMap[country],
  max: maxLengthMap[country]
};

export const invoicesFormSchema = z.object({
  taxNumber: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const taxNumberValidationResult = z
        .string()
        .min(taxNumberLength.min, 'invoicesPage.form.taxNumber.error')
        .max(taxNumberLength.max, 'invoicesPage.form.taxNumber.error')
        .refine(taxNumberValidation, {
          message: 'invoicesPage.form.taxNumber.error'
        })
        .safeParse(val);

      if (!taxNumberValidationResult.success) {
        ctx.addIssue(taxNumberValidationResult.error.issues[0]);
      }
    })
});

export type InvoicesFormSchema = z.output<typeof invoicesFormSchema>;
