import { ReactNode } from 'react';
import { Grid, makeStyles, CircularProgress } from '@material-ui/core';

export type SummaryRowCssClasses = {
  label: string;
};

interface SummaryRowProps {
  label: string;
  children: ReactNode;
  isLoading?: boolean;
  classes?: Partial<SummaryRowCssClasses>;
}

export const SummaryRow = ({ label, children, classes: outerClasses, isLoading = false }: SummaryRowProps) => {
  const classes = useStyle();

  return (
    <Grid container className={classes.group} spacing={2}>
      <Grid item xs={8} sm={7} className={classes.title}>
        <span className={outerClasses?.label}>{label}</span>
      </Grid>
      <Grid container item xs={4} sm={5} justify="flex-end">
        {isLoading ? <CircularProgress size={13} /> : children}
      </Grid>
    </Grid>
  );
};
const useStyle = makeStyles((theme) => ({
  group: {
    textAlign: 'right',
    fontSize: '1rem',
    lineHeight: '1.5rem'
  },
  title: {
    color: theme.orderSummaryBox.color,
    textAlign: 'left',
    fontSize: 'inherit'
  }
}));
