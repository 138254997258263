import { Dayjs } from 'dayjs';
import { Id, MealModes, CalendarDate } from 'common/types';
import { Address } from 'features/addresses/types';

import { DOTTED_DAY_MONTH_YEAR, YEAR_MONTH_DAY } from 'common/constants';
import { flatMap } from 'lodash';
import { CreateDietFormData, DietLengthOption, OrderNewBagsData, OrderNewDietData } from '../types';

import { getDietLenghtOptions } from '../utils';
import { calculatePredefinedDateRange } from '../utils/calculateDateRange';
import { SizeData } from './fetchSizesData';

interface BagItem {
  variant: Id;
  package: Id;
  basket: Id;
  size: Id;
  mealType: Id;
}

interface DayConfiguration {
  deliveryDate: string;
  address: Id;
  bagItems: BagItem[];
}

interface CreateOrderRequestDataProps {
  sizesData: SizeData[];
  mealMode: MealModes;
  selectedMeals: CreateDietFormData['meals'];
  dietLengthId: Id;
  customDeliveryDates: CreateDietFormData['customDeliveryDates'];
  withoutWeekends: CreateDietFormData['withoutWeekends'];
  firstDeliveryDate: CreateDietFormData['continuousFirstDeliveryDate'];
  name: string;
  variantId: Id;
  packageId: Id;
  basketId: Id;
  standardDeliveryAddress: Address;
  festiveDeliveryAddress: Address;
  selectedDietID: Id | null;
  maxDate: CalendarDate;
  disabledDays: CalendarDate[];
}

const getDeliveryDates = ({
  dietLengthId,
  withoutWeekends,
  firstDeliveryDate,
  customDeliveryDates,
  disabledDays,
  maxDate
}: {
  dietLengthId: Id;
  withoutWeekends: CreateDietFormData['withoutWeekends'];
  firstDeliveryDate: CreateDietFormData['continuousFirstDeliveryDate'];
  customDeliveryDates: CreateDietFormData['customDeliveryDates'];
  maxDate: CalendarDate;
  disabledDays: CalendarDate[];
}) => {
  const selectedDietLengthOption = getDietLenghtOptions(withoutWeekends).find(
    (o) => o.id === dietLengthId
  ) as DietLengthOption;

  if (selectedDietLengthOption.type === 'custom') {
    return customDeliveryDates;
  }
  return calculatePredefinedDateRange({
    disabledDays,
    firstDate: firstDeliveryDate,
    length: selectedDietLengthOption.days,
    withoutWeekends,
    maxDate
  });
};

const createBagItems = ({
  selectedMeals,
  sizesData,
  variantId,
  packageId,
  basketId
}: {
  sizesData: SizeData[];
  selectedMeals: CreateDietFormData['meals'];
  variantId: Id;
  packageId: Id;
  basketId: Id;
}): BagItem[] => {
  const bagItems: BagItem[] = [];

  const bagItemsBySizeId = sizesData.reduce((acc: Map<number, BagItem>, currentPortion) => {
    currentPortion.sizes.forEach((size) => {
      acc.set(size.id, {
        basket: basketId,
        variant: variantId,
        package: packageId,
        mealType: currentPortion.id,
        size: size.id
      });
    });
    return acc;
  }, new Map());

  const allSelectedPortions = flatMap(Array.from(selectedMeals.values()));

  allSelectedPortions.forEach(({ mealId }) => {
    const bagItem = bagItemsBySizeId.get(mealId);
    if (bagItem) {
      bagItems.push(bagItem);
    }
  });

  return bagItems;
};

const createOrderNewBagsData = ({
  deliveryDates,
  selectedDietID,
  standardDeliveryAddress,
  festiveDeliveryAddress,
  mealMode,
  bagItems
}: {
  deliveryDates: Dayjs[];
  selectedDietID: Id;
  standardDeliveryAddress: Address;
  festiveDeliveryAddress: Address;
  mealMode: MealModes;
  bagItems: BagItem[];
}): OrderNewBagsData => {
  return {
    items: deliveryDates.map((deliveryDate) => ({
      type: 'Bag',
      bag: {
        deliveryDate: deliveryDate.format(YEAR_MONTH_DAY),
        diet: selectedDietID,
        address: deliveryDate.isWeekend() ? festiveDeliveryAddress.id : standardDeliveryAddress.id,
        bagItemConfiguration: bagItems
      }
    })),
    flow: mealMode === MealModes.flexi ? 'FLEXI' : 'COMFORT'
  };
};

const createOrderNewDietData = ({
  name,
  variantId,
  packageId,
  basketId,
  deliveryDates,
  standardDeliveryAddress,
  festiveDeliveryAddress,
  bagItems,
  mealMode
}: {
  name: string;
  variantId: Id;
  packageId: Id;
  basketId: Id;
  deliveryDates: Dayjs[];
  standardDeliveryAddress: Address;
  festiveDeliveryAddress: Address;
  bagItems: BagItem[];
  mealMode: MealModes;
}): OrderNewDietData => {
  const daysConfiguration: DayConfiguration[] = deliveryDates.map((deliveryDate) => {
    return {
      deliveryDate: deliveryDate.format(DOTTED_DAY_MONTH_YEAR),
      address: deliveryDate.isWeekend() ? festiveDeliveryAddress.id : standardDeliveryAddress.id,
      bagItems
    };
  });
  return {
    items: [
      {
        type: 'Diet',
        diet: {
          name,
          defaultVariant: variantId,
          defaultPackage: packageId,
          defaultBasket: basketId,
          daysConfiguration
        }
      }
    ],
    flow: mealMode === MealModes.flexi ? 'FLEXI' : 'COMFORT'
  };
};
export const createOrderRequestData = ({
  selectedMeals,
  sizesData,
  basketId,
  variantId,
  packageId,
  customDeliveryDates,
  dietLengthId,
  firstDeliveryDate,
  name,
  withoutWeekends,
  standardDeliveryAddress,
  festiveDeliveryAddress,
  mealMode,
  selectedDietID,
  disabledDays,
  maxDate
}: CreateOrderRequestDataProps): OrderNewDietData | OrderNewBagsData => {
  const bagItems = createBagItems({
    sizesData,
    selectedMeals,
    basketId,
    packageId,
    variantId
  });

  const deliveryDates = getDeliveryDates({
    customDeliveryDates,
    dietLengthId,
    firstDeliveryDate,
    withoutWeekends,
    disabledDays,
    maxDate
  });

  if (selectedDietID !== null) {
    return createOrderNewBagsData({
      standardDeliveryAddress,
      festiveDeliveryAddress,
      bagItems,
      deliveryDates,
      mealMode,
      selectedDietID
    });
  }
  return createOrderNewDietData({
    standardDeliveryAddress,
    festiveDeliveryAddress,
    bagItems,
    basketId,
    deliveryDates,
    mealMode,
    name,
    packageId,
    variantId
  });
};
