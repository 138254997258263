import { FormControl, InputLabel, FormHelperText, Input, InputProps } from '@material-ui/core';
import { forwardRef, ReactNode } from 'react';
import InputMask, { Props as ReactInputMaskProps } from 'react-input-mask';

export interface MaskTextFieldProps extends ReactInputMaskProps {
  label?: string;
  error?: boolean;
  helperText?: ReactNode;
  inputProps?: InputProps;
}

export const MaskTextField = forwardRef<HTMLInputElement, MaskTextFieldProps>(
  ({ error = false, label, id, helperText, inputMode, inputProps, ...rest }, ref) => {
    return (
      <FormControl fullWidth error={error}>
        {label && (
          <InputLabel htmlFor={id} shrink>
            {label}
          </InputLabel>
        )}
        <InputMask id={id} {...rest} inputRef={ref}>
          <Input disableUnderline inputMode={inputMode} {...inputProps} />
        </InputMask>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);
