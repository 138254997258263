import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { OrderFlowStep } from '../types';

export const mapOrderFlowNextStepToUrl = (orderFlowStep: OrderFlowStep): string => {
  switch (orderFlowStep) {
    case OrderFlowStep.PersonalData:
      return RoutesDefinition.personalDataOrder;
    case OrderFlowStep.DeliveryAddresses:
      return RoutesDefinition.deliveryAddressesOrder;
    case OrderFlowStep.DeliveryInfo:
      return RoutesDefinition.deliveryInfoOrder;
    default:
      return RoutesDefinition.orderSummary;
  }
};
