import { makeStyles } from '@material-ui/core';
import { CalendarDate } from 'common/types';
import clsx from 'clsx';
import { ReactNode } from 'react';

export interface HeaderProps {
  currentDate: CalendarDate;
  locale: string;
  dateFormat: string;
  className?: string;
  labelClassName?: string;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
}

export const Header = ({
  currentDate,
  dateFormat,
  locale,
  leftSection,
  rightSection,
  className,
  labelClassName
}: HeaderProps): JSX.Element => {
  const formattedDate = currentDate.locale(locale).format(dateFormat);
  const classes = useStyle();

  return (
    <header className={clsx(classes.container, className)}>
      {leftSection}
      <span className={clsx(classes.label, labelClassName)}>{`${formattedDate
        .slice(0, 1)
        .toUpperCase()}${formattedDate.slice(1)}`}</span>
      {rightSection}
    </header>
  );
};

const useStyle = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '2.5rem',
    marginBottom: '1rem'
  },
  label: {
    flex: 1,
    paddingRight: '1rem',
    paddingLeft: '1rem',
    textAlign: 'center',
    color: 'black',
    fontSize: '0.95rem',
    fontWeight: 'bold'
  }
});
