import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Button, FormTextField } from 'common/components';
import { country } from 'config';
import { ResetPasswordFormData, resetPasswordFormSchema } from 'features/authentication/types';
import { useEffect } from 'react';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

interface ResetPasswordFormProps {
  isProcessing?: boolean;
  onSubmit: SubmitHandler<ResetPasswordFormData>;
  isApiError: boolean;
}

export function ResetPasswordForm({ isProcessing, onSubmit, isApiError }: ResetPasswordFormProps): JSX.Element {
  const classes = useStyle();
  const { t } = useTranslation();

  const formMethods = useForm<ResetPasswordFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(resetPasswordFormSchema),
    defaultValues: {
      email: ''
    }
  });

  useEffect(() => {
    if (isApiError) {
      formMethods.setError('email', { message: t('errors.passwordResetError') });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiError]);

  return (
    <FormProvider {...formMethods}>
      <form className={classes.form} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div>
          <FormTextField
            name="email"
            type="text"
            placeholder={t('resetPasswordPage.resetPasswordForm.email.placeholder')}
            label={t('resetPasswordPage.resetPasswordForm.email.label')}
            fullWidth
          />
          <Button className={classes.submitButton} type="submit" disabled={isProcessing}>
            {t('resetPasswordPage.resetPasswordForm.submitButton')}
          </Button>
          <div className={classes.subFormBox}>
            {country === 'germany' ? (
              <u>
                <Trans i18nKey="resetPasswordPage.loginDescription">
                  Masz już konto? <NavLink to={RoutesDefinition.login}>Zaloguj się</NavLink>
                </Trans>
              </u>
            ) : (
              <Trans i18nKey="resetPasswordPage.loginDescription">
                Masz już konto? <NavLink to={RoutesDefinition.login}>Zaloguj się</NavLink>
              </Trans>
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

const useStyle = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(6)}`
    }
  },
  subFormBox: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '1rem',
    whiteSpace: 'pre',
    '& a': {
      fontWeight: 'bold',
      textDecoration: 'none',
      color: theme.palette.secondary.dark
    }
  },
  submitButton: {
    margin: `${theme.spacing(3)} 0 ${theme.spacing(5)} 0`,
    width: '100%'
  }
}));
