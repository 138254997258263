import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { noop } from 'lodash';
import { DaysGrid, Header, Navigation } from '../components';
import { getMonthRange } from '../getMonthRange';
import { CalendarProps } from '../types';
import { useCalendarControl } from '../useCalendarControl';

export const CalendarList = ({
  locale,
  visibleDate,
  onIntervalChange,
  minDate,
  maxDate,
  onClick = noop,
  className,
  calculateDayStyle,
  calculateDisabledDates: outerCalculateDisabledDates
}: CalendarProps): JSX.Element => {
  const classes = useStyle();
  const isMobile = useMobileScreenSizeDetector();
  const { start, end } = getMonthRange({ visibleDate, locale, showFullWeeks: isMobile });

  const { calculateDisabledDates, canNavigateToNexInterval, canNavigateToPreviousInterval } = useCalendarControl({
    firstDate: start,
    lastDate: end,
    calculateDisabledDates: outerCalculateDisabledDates,
    maxDate,
    minDate
  });

  const handlePrevMonth = () => {
    onIntervalChange(visibleDate.subtract(1, 'M'));
  };

  const handleNextMonth = () => {
    onIntervalChange(visibleDate.add(1, 'M'));
  };

  const showPrevMonth = canNavigateToPreviousInterval && !isMobile;
  const showNextMonth = canNavigateToNexInterval && !isMobile;

  return (
    <div className={clsx(classes.container, className)}>
      {showPrevMonth && (
        <div className={classes.tile}>
          <Header currentDate={visibleDate.subtract(1, 'M')} locale={locale} dateFormat="MMMM YYYY" />
          <DaysGrid
            current={visibleDate.subtract(1, 'M')}
            locale={locale}
            onClick={onClick}
            showFullWeeks={false}
            checkIfDayIsDisabled={calculateDisabledDates}
            calculateDayStyle={calculateDayStyle}
          />
        </div>
      )}
      <div
        className={clsx({
          [classes.tile]: true,
          [classes.previousMonthExists]: showPrevMonth,
          [classes.nextMonthExits]: showNextMonth
        })}
      >
        <Navigation
          currentDate={visibleDate}
          locale={locale}
          dateFormat="MMMM YYYY"
          disablePrevButton={!canNavigateToPreviousInterval}
          disableNextButton={!canNavigateToNexInterval}
          onPrevButtonClick={handlePrevMonth}
          onNextButtonClick={handleNextMonth}
          iconColor="primary"
        />
        <DaysGrid
          current={visibleDate}
          locale={locale}
          onClick={onClick}
          showFullWeeks={isMobile}
          checkIfDayIsDisabled={calculateDisabledDates}
          calculateDayStyle={calculateDayStyle}
        />
      </div>
      {showNextMonth && (
        <div className={classes.tile}>
          <Header currentDate={visibleDate.add(1, 'M')} locale={locale} dateFormat="MMMM YYYY" />
          <DaysGrid
            current={visibleDate.add(1, 'M')}
            locale={locale}
            onClick={onClick}
            showFullWeeks={false}
            checkIfDayIsDisabled={calculateDisabledDates}
            calculateDayStyle={calculateDayStyle}
          />
        </div>
      )}
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'center'
  },
  tile: {
    flex: '0 0 21.875rem',
    [theme.breakpoints.down('sm')]: {
      flex: 1
    }
  },
  previousMonthExists: {
    marginLeft: '7.5rem'
  },
  nextMonthExits: {
    marginRight: '7.5rem'
  }
}));
