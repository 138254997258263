import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { Logo } from 'layouts/components/Logo';
import { useTranslation } from 'react-i18next';
import { country } from 'config';
import { useSession } from 'features/authentication';
import { germanWordpressRoutes } from 'routing/constants/germanWordpressRoutes';
import { NavigationSection } from './NavigationSection';
import { MobileMenu } from './MobileMenu';
import { HiddenOnDesktop, HiddenOnMobile } from 'common/components';

export const LeftSection = (): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { logout } = useSession();

  const handleLogoClick = () => {
    logout();
  };

  return (
    <>
      <HiddenOnDesktop>
        <MobileMenu />
      </HiddenOnDesktop>
      {/* Germans want that the app is redirected to wordpress and user is logged out */}
      {country === 'germany' ? (
        <a href={germanWordpressRoutes.home} className={classes.logo} onClick={handleLogoClick}>
          <Logo
            alt={t('logoLink')}
            classes={{
              img: classes.img
            }}
          />
        </a>
      ) : (
        <Link to={RoutesDefinition.dashboard} className={classes.logo}>
          <Logo
            alt={t('logoLink')}
            classes={{
              img: classes.img
            }}
          />
        </Link>
      )}

      <HiddenOnMobile>
        <NavigationSection />
      </HiddenOnMobile>
    </>
  );
};

const useStyle = makeStyles(() => ({
  logo: {
    lineHeight: 0
  },
  img: {
    marginRight: '1.5rem',
    maxHeight: '3rem'
  }
}));
