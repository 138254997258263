import { getFormattedDate } from 'common/utils/getFormattedDate';
import { useApi } from 'features/apiProvider';
import { useQuery } from 'react-query';
import { fetchMealsPrices } from './fetchMealPrices';

export const useMealPrices = () => {
  const { getApiClient } = useApi();
  const apiClient = getApiClient();
  const date = getFormattedDate();
  const queryKey = ['mealPrices', date];

  const response = useQuery({ queryKey, queryFn: () => fetchMealsPrices(apiClient, date) });
  return response;
};
