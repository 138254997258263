import { Grid, Button, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { MoneyBox } from 'layouts/components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BaseDashboardLayoutProps, BaseDashboardLayout } from './BaseDashboardLayout';

interface SubPagelayoutProps extends Omit<BaseDashboardLayoutProps, 'header'> {
  onBack?: VoidFunction;
}
export const SubPageLayout = ({ children, onBack, ...rest }: SubPagelayoutProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();
  const handleBack = useCallback(() => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  }, [history, onBack]);

  return (
    <BaseDashboardLayout
      {...rest}
      header={
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Button className={classes.button} startIcon={<ArrowBackIcon />} onClick={handleBack}>
              {t('backButton')}
            </Button>
          </Grid>
          <Grid item container justify="flex-end" xs={6}>
            <MoneyBox />
          </Grid>
        </Grid>
      }
    >
      {children}
    </BaseDashboardLayout>
  );
};

const useStyle = makeStyles((theme) => ({
  button: {
    color: theme.customColors.white,
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    letterSpacing: '0.0125rem'
  }
}));
