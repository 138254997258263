import { makeStyles, IconButton, SvgIcon } from '@material-ui/core';
import { useVisibility } from 'common/hooks';
import { Address } from 'features/addresses/types';
import { useDietConfiguration } from 'features/orders/contexts';
import { TFuncKey, useTranslation } from 'react-i18next';
import { DeliveryDayType } from '../deliveryDays';
import { ChangeDeliveryAddressDialog } from './ChangeDeliveryAddressDialog';
import { ReactComponent as EditIcon } from './edit.svg';

interface ChangeDeliveryAddressProps {
  deliveryDays: DeliveryDayType;
  defaultAddress: Address;
}

export const ChangeDeliveryAddress = ({ deliveryDays, defaultAddress }: ChangeDeliveryAddressProps) => {
  const classes = useStyle();
  const [isVisible, show, hide] = useVisibility();
  const { t } = useTranslation();
  const { setFestiveDeliveryAddress, setStandardDeliveryAddress } = useDietConfiguration();

  const handleChangeAddress = (selectedAddress: Address) => {
    if (deliveryDays === 'bussinesDays') {
      setStandardDeliveryAddress(selectedAddress);
    } else {
      setFestiveDeliveryAddress(selectedAddress);
    }
    hide();
  };

  const title: TFuncKey =
    deliveryDays === 'bussinesDays'
      ? 'creatDietPage.changeDeliveryAddress.changeAddressForBussinesDaysTitle'
      : 'creatDietPage.changeDeliveryAddress.changeAddressForWeekendDaysTitle';
  return (
    <>
      <IconButton className={classes.editButton} onClick={show} aria-label={t(title)}>
        <SvgIcon viewBox="0 0 24 24" className={classes.editButtonIcon} component={EditIcon} />
      </IconButton>
      <ChangeDeliveryAddressDialog
        title={t(title)}
        defaultAddress={defaultAddress}
        onClose={hide}
        isVisible={isVisible}
        onChange={handleChangeAddress}
      />
    </>
  );
};

const useStyle = makeStyles({
  editButton: {
    marginRight: '-0.5rem'
  },
  editButtonIcon: {
    fontSize: '1rem'
  }
});
