import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

import iconLogout from 'common/icons/icon-logout.svg';
import { NavigationButton } from 'common/components';
import { useSession } from 'features/authentication';

import arrowRight from 'common/icons/icon-arrow-right.svg';

import { ProfileManagment } from 'features/profiles';
import { CreateDiet } from '../CreateDiet';
import { NavigationSection } from '../NavigationSection';

import iconUser from '../../images/icon-user.svg';

interface MobileMenuDefaultProps {
  setUserMenuMode: () => void;
}

export const MobileMenuDefault = ({ setUserMenuMode }: MobileMenuDefaultProps): JSX.Element => {
  const classes = useStyle();
  const { logout } = useSession();

  const { t } = useTranslation();

  return (
    <div className={classes.contentWrapper}>
      <ProfileManagment />

      <NavigationSection />
      <CreateDiet />

      <div className={classes.mobileMenuDivider} />

      <NavigationButton
        label={t('menuItems.account')}
        onClick={setUserMenuMode}
        icon={iconUser}
        actionIcon={arrowRight}
      />

      <NavigationButton label={t('menuItems.logout')} onClick={logout} icon={iconLogout} />
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1rem 2rem 1.5rem',
    minHeight: '100vh',
    overflow: 'auto'
  },
  mobileMenuDivider: {
    width: '100%',
    borderTop: `0.063rem solid ${theme.customColors.white}`,
    margin: '2rem 0'
  }
}));
