import { makeStyles } from '@material-ui/core';

export const useNavigationElementsStyle = makeStyles((theme) => ({
  basicLink: {
    fontSize: '1.125rem',
    letterSpacing: '0.019rem',
    lineHeight: 1.33,
    margin: '0 0 2rem',
    width: '100%',
    color: theme.customColors.textLightGrey,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    border: '0',
    padding: '0',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  menuElementIcon: {
    marginRight: '1rem'
  },
  actionIcon: {
    marginLeft: 'auto'
  }
}));
