import { z } from 'zod';

import { ApiClient } from 'features/apiProvider';

const createOrderSchema = z.number();

interface CreateOrderRequest extends ApiClient {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  orderData: any;
}

export const createOrder = async ({ apiClient, orderData }: CreateOrderRequest) => {
  const response = await apiClient
    .put('v3/order/cart', {
      json: orderData
    })
    .json();

  return createOrderSchema.parse(response);
};
