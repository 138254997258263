import { Id } from 'common/types';
import { DeliveryPeriodOptions } from 'features/addresses/constants';
import { createContext, useContext } from 'react';

export type ChangeDefaultDeliveryPeriodHandler = (data: { id: Id; type: DeliveryPeriodOptions }) => void;

interface AddressManagmentContextShape {
  onChangeDefaultDeliveryPeriod: ChangeDefaultDeliveryPeriodHandler;
  onDeleteAddress: (id: Id) => void;
}

interface AddressManagmentProviderProps {
  children: React.ReactNode;
  onChangeDefaultDeliveryPeriod: ChangeDefaultDeliveryPeriodHandler;
  onDeleteAddress: (id: Id) => void;
}

const AddressManagmentContext = createContext<AddressManagmentContextShape | undefined>(undefined);

export const AddressManagmentProvider = ({
  children,
  onChangeDefaultDeliveryPeriod,
  onDeleteAddress
}: AddressManagmentProviderProps): JSX.Element => {
  const value = {
    onChangeDefaultDeliveryPeriod,
    onDeleteAddress
  };
  return <AddressManagmentContext.Provider value={value}>{children}</AddressManagmentContext.Provider>;
};

export const useAddressManagment = () => {
  const context = useContext(AddressManagmentContext);
  if (context === undefined) {
    throw new Error('useAddressManagment must be used within a AddressManagmentContext');
  }
  return context;
};
