import { useWatch } from 'react-hook-form';
import { useDietConfiguration } from '../contexts';
import { CreateDietFormData } from '../types';
import { getDietLength } from '../utils';

export const useDietLenght = (): number => {
  const {
    deliveryTimeConfig: { disabledDays, firstDeliveryAt }
  } = useDietConfiguration();
  const withoutWeekends = useWatch<CreateDietFormData, 'withoutWeekends'>({
    name: 'withoutWeekends'
  });
  const dietLengthId = useWatch<CreateDietFormData, 'dietLength'>({
    name: 'dietLength'
  });
  const customDeliveryDates = useWatch<CreateDietFormData, 'customDeliveryDates'>({
    name: 'customDeliveryDates'
  });
  const firstDeliveryDate = useWatch<CreateDietFormData, 'continuousFirstDeliveryDate'>({
    name: 'continuousFirstDeliveryDate'
  });

  return getDietLength({
    customDeliveryDates,
    dietLengthId,
    disabledDays,
    firstDeliveryAt,
    firstDeliveryDate,
    withoutWeekends
  });
};
