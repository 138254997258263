import { useTranslation } from 'react-i18next';

import { Checkbox } from 'common/components';
import { memo } from 'react';

export const WeekendsSection = memo((): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Checkbox
      name="withoutWeekends"
      label={t('creatDietPage.weekendsSection.checkboxLabel')}
      checkedAlt={t('creatDietPage.weekendsSection.weekendsSelectedImageAlt')}
      unCheckedAlt={t('creatDietPage.weekendsSection.weekendsUnselectedImageAlt')}
    />
  );
});
