import { useController } from 'react-hook-form';
import { ButtonGroupProps, ButtonGroup } from '../ButtonGroup';

interface FormButtonGroupFieldProps<OptionType, Value>
  extends Omit<ButtonGroupProps<OptionType, Value>, 'onChangeValue' | 'value'> {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
}

export const FormButtonGroupField = <OptionType, Value>({
  name,
  getOptionValue,
  ...rest
}: FormButtonGroupFieldProps<OptionType, Value>) => {
  const {
    field: { onChange, value }
  } = useController({
    name
  });

  const handleChange = (newValue: OptionType) => {
    if (newValue !== null) {
      onChange(getOptionValue(newValue));
    }
  };
  return <ButtonGroup value={value} onChangeValue={handleChange} getOptionValue={getOptionValue} {...rest} />;
};
