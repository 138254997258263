import { Grid, makeStyles, SvgIcon, Typography } from '@material-ui/core';
import { Button, FormButtonGroupField, FormSelectField, FormTextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as userIcon } from 'common/icons/icon-user.svg';
import { ReactComponent as targetIcon } from 'common/icons/icon-target.svg';
import { FormEventHandler } from 'react';
import { useFormContext } from 'react-hook-form';
import { ageFieldLength, heightFieldLength, weightFieldLength } from 'features/orders/types';

interface CaloricCalculatorFormProps {
  onSubmit: FormEventHandler<HTMLFormElement>;
}

export const CaloricCalculatorForm = ({ onSubmit }: CaloricCalculatorFormProps) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const {
    formState: { isValid }
  } = useFormContext();
  const sexOptions = [
    {
      label: t('creatDietPage.caloriesSection.caloricCalculator.form.sex.female'),
      value: 'female'
    },
    {
      label: t('creatDietPage.caloriesSection.caloricCalculator.form.sex.male'),
      value: 'male'
    }
  ];
  const goalOptions = [
    {
      label: t('creatDietPage.caloriesSection.caloricCalculator.form.goal.loseWeight'),
      value: 'loseWeight'
    },
    {
      label: t('creatDietPage.caloriesSection.caloricCalculator.form.goal.eatHealthyDiet'),
      value: 'eatHealthyDiet'
    },
    {
      label: t('creatDietPage.caloriesSection.caloricCalculator.form.goal.buildMuscleMass'),
      value: 'buildMuscleMass'
    }
  ];
  const exerciseActivityOptions = [
    { value: 1, label: t('creatDietPage.caloriesSection.caloricCalculator.exerciseActivityOption.rarely') },
    { value: 1.2, label: t('creatDietPage.caloriesSection.caloricCalculator.exerciseActivityOption.lightly') },
    { value: 1.4, label: t('creatDietPage.caloriesSection.caloricCalculator.exerciseActivityOption.often') },
    { value: 1.6, label: t('creatDietPage.caloriesSection.caloricCalculator.exerciseActivityOption.regularly') },
    { value: 1.8, label: t('creatDietPage.caloriesSection.caloricCalculator.exerciseActivityOption.hard') },
    { value: 2, label: t('creatDietPage.caloriesSection.caloricCalculator.exerciseActivityOption.active') }
  ];

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormButtonGroupField
            name="sex"
            options={sexOptions}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.container}>
            <SvgIcon component={targetIcon} viewBox="0 0 24 24" />
            <Typography variant="h4">{t('creatDietPage.caloriesSection.caloricCalculator.form.goal.title')}</Typography>
          </div>
          <FormSelectField name="goal" options={goalOptions} getValueFromOption={(option) => option.value} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.container}>
            <SvgIcon component={userIcon} viewBox="0 0 24 24" />
            <Typography variant="h4">
              {t('creatDietPage.caloriesSection.caloricCalculator.exerciseActivityOption.headerTitle')}
            </Typography>
          </div>
          <FormSelectField
            name="exerciseActivity"
            className={classes.forms}
            label={t('creatDietPage.caloriesSection.caloricCalculator.exerciseActivityOption.title')}
            options={exerciseActivityOptions}
            getValueFromOption={(option) => option.value}
          />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name="height"
                label={t('creatDietPage.caloriesSection.caloricCalculator.form.height.label')}
                placeholder={t('creatDietPage.caloriesSection.caloricCalculator.form.height.placeholder')}
                InputProps={{
                  inputProps: {
                    maxLength: heightFieldLength.max
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name="weight"
                label={t('creatDietPage.caloriesSection.caloricCalculator.form.weight.label')}
                placeholder={t('creatDietPage.caloriesSection.caloricCalculator.form.weight.placeholder')}
                InputProps={{
                  inputProps: {
                    maxLength: weightFieldLength.max
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                name="age"
                label={t('creatDietPage.caloriesSection.caloricCalculator.form.age.label')}
                placeholder={t('creatDietPage.caloriesSection.caloricCalculator.form.age.placeholder')}
                InputProps={{
                  inputProps: {
                    maxLength: ageFieldLength.max
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Button type="submit" mode="primary" fullWidth className={classes.button} disabled={!isValid}>
        {t('creatDietPage.caloriesSection.caloricCalculator.calorificSummary.calculate')}
      </Button>
    </form>
  );
};

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '1rem'
  },
  button: {
    marginTop: '4rem',
    marginBottom: '1.5rem'
  },
  forms: {
    marginBottom: '1rem'
  }
}));
