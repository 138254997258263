import { CircularProgress, makeStyles } from '@material-ui/core';

export const Loader = (): JSX.Element => {
  const classes = useStyle();

  return (
    <div className={classes.loaderWrapper}>
      <CircularProgress size={100} />
    </div>
  );
};

const useStyle = makeStyles(() => ({
  loaderWrapper: {
    position: 'absolute',
    top: '10rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));
