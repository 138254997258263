import { sendLoginWithSocialGAEvent } from 'common/utils/gtm/sendLoginWithSocialGAEvent';
import { useApi } from 'features/apiProvider';
import { useSession } from 'features/authentication';
import { useSnackbar } from 'notistack';
import { useGetInvitationCode } from 'pages/RegisterPage/hooks/useGetInvitationCode';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { authenticateWithGoogle } from './authenticateWithGoogle';

export const useAuthenticateWithGoogle = ({ type }: { type: 'login' | 'register' }) => {
  const { getApiClient } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const invitationCode = useGetInvitationCode();
  const { login } = useSession();
  const history = useHistory();

  const apiClient = getApiClient();

  const sendAuthenticateRequest = ({ accessToken }: { accessToken: string }) => {
    return authenticateWithGoogle({ accessToken, apiClient });
  };

  const mutation = useMutation({
    mutationFn: sendAuthenticateRequest,
    onSuccess: (response) => {
      // default save token for longer
      login({ token: response.token, refreshToken: response.refresh_token, rememberMe: true });

      sendLoginWithSocialGAEvent({ type, medium: 'google' });
      if (invitationCode) {
        history.push(`${RoutesDefinition.createDiet}?invitationCode=${invitationCode}`);
      }
    },
    onError: () => {
      enqueueSnackbar(t('snackbarError.title'), {
        variant: 'refreshableError',
        onExited: () => {
          // mutation.mutate({ authToken });
        }
      });
    }
  });
  return mutation;
};
