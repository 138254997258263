import { CalendarDate, MealModes, SelectedMeals } from 'common/types';
import { defaultWithoutWeekend, getDietLenghtOptions } from 'features/orders';
import { InitialFormState } from './initialFormState';

export const calculateInitialFormStateForAddBag = ({
  addBagToDate,
  disabledDays,
  firstDeliveryAt,
  formData
}: {
  formData: InitialFormState;
  firstDeliveryAt: CalendarDate;
  disabledDays: CalendarDate[];
  addBagToDate: CalendarDate;
}): InitialFormState => {
  const isDisabledDate = disabledDays.some((disabledDay) => disabledDay.isSame(addBagToDate, 'd'));
  const dietLengthOptions = getDietLenghtOptions(defaultWithoutWeekend);
  const dietLengthOption = dietLengthOptions.find((o) => {
    return o.type === 'custom';
  });
  let { dietLength, customDeliveryDates } = formData;
  if (dietLengthOption) {
    dietLength = dietLengthOption.id;
    if (addBagToDate.isSameOrAfter(firstDeliveryAt) && !isDisabledDate) {
      customDeliveryDates = [addBagToDate];
    } else {
      customDeliveryDates = [firstDeliveryAt];
    }
  }

  return {
    ...formData,
    dietLength,
    customDeliveryDates,
    meals: new Map() as SelectedMeals,
    mealMode: MealModes.flexi
  };
};
