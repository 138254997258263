import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link, makeStyles } from '@material-ui/core';

interface BackLinkProps {
  to: string | RouterLinkProps['to'];
  label?: string;
  external?: boolean;
  onClick?: VoidFunction;
}

/**
 * @deprecated
 */
export const BackLink = ({ to, label, external, onClick }: BackLinkProps) => {
  const classes = useStyle();
  return external ? (
    <a href={to.toString()} className={classes.root} onClick={onClick}>
      <ArrowBackIcon /> {label && <span className={classes.label}>{label}</span>}
    </a>
  ) : (
    <Link component={RouterLink} to={to} className={classes.root} color="inherit" underline="none" onClick={onClick}>
      <ArrowBackIcon /> {label && <span className={classes.label}>{label}</span>}
    </Link>
  );
};

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit'
  },
  label: {
    marginLeft: '0.5rem',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    letterSpacing: '0.0125rem'
  }
}));
