import * as z from 'zod';

import { ApiClient } from 'features/apiProvider';

import { Profile, profileSchema } from '../types';

const profilesSchema = z.array(profileSchema);

export const fetchProfiles = async ({ apiClient }: ApiClient): Promise<Profile[]> => {
  const response = await apiClient.get('v3/diet/list').json();
  return profilesSchema.parse(response);
};
