import { getPurchaseDataFromLocalStorage, ORDER_FIRST_DELIVERY_KEY } from 'common/utils/gtm/sendPurchaseGAEvent';
import dayjs from 'dayjs';

interface Props {
  orderId: number;
}

export const sendUserComAddToCart = async ({ orderId }: Props) => {
  const API_KEY = process.env.REACT_APP_USER_COM_API_KEY;
  const cartData = getPurchaseDataFromLocalStorage();
  const firstDelivery = localStorage.getItem(ORDER_FIRST_DELIVERY_KEY);
  localStorage.removeItem(ORDER_FIRST_DELIVERY_KEY);

  if (!API_KEY || !window.userengage || !cartData) {
    return;
  }

  window.userengage('product_event', {
    product_id: `diet_${orderId}`,
    event_type: 'add to cart',
    name: orderId,
    diet_name: cartData.diet_name,
    diet_type: cartData.diet_type,
    path_type: cartData.path_type,
    diet_calorie: cartData.diet_calorie.split('/')[0],
    meal_type: cartData.meal_type.split(', '),
    diet_duration: cartData.diet_duration,
    coupon: cartData.coupon,
    value: cartData.ecommerce.value,
    shipping: cartData.ecommerce.shipping,
    moneybox_value: cartData.moneybox_value,
    value_discount: cartData.value_discount,
    diet_start_date: dayjs(firstDelivery).format('YYYY-MM-DD')
  });
};
