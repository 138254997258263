import { Checkbox, CheckboxProps, FormControlLabel, makeStyles } from '@material-ui/core';

import checkboxEmpty from 'common/icons/icon-checkbox-empty.svg';
import checkboxError from 'common/icons/icon-checkbox-error.svg';
import { MarkedCircle } from 'common/icons/MarkedCircle';
import { ChangeEvent, ReactNode } from 'react';

interface ButtonProps extends CheckboxProps {
  checkedAlt: string;
  unCheckedAlt: string;
  error: boolean;
}

const Button = ({ error, checkedAlt, unCheckedAlt, ...rest }: ButtonProps) => {
  const classes = useStyle();

  return (
    <Checkbox
      {...rest}
      className={classes.checkbox}
      checkedIcon={<MarkedCircle containerClassName={classes.selectedIcon} imageAlt={checkedAlt} />}
      icon={<img src={error ? checkboxError : checkboxEmpty} alt={unCheckedAlt} />}
    />
  );
};

interface CheckboxBaseProps {
  checkedAlt: string;
  unCheckedAlt: string;
  error?: boolean;
  value: boolean;
  onChange: (_: ChangeEvent<Record<string, unknown>>, checked: boolean) => void;
  className?: string;
  label?: ReactNode;
}

export const CheckboxBase = ({
  checkedAlt,
  unCheckedAlt,
  error,
  value,
  onChange,
  className = '',
  label,
  ...rest
}: CheckboxBaseProps) => {
  const classes = useStyle();

  return (
    <FormControlLabel
      checked={value}
      value={value}
      onChange={onChange}
      className={`${classes.controlLabel} ${className}`}
      control={<Button error={!!error} checkedAlt={checkedAlt} unCheckedAlt={unCheckedAlt} {...rest} />}
      label={<span className={classes.clickable}>{label}</span>}
    />
  );
};

const useStyle = makeStyles((theme) => ({
  checkbox: {
    '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
      background: 'transparent',
      backgroundColor: 'rgba(0,0,0,0)'
    },

    '&:hover': {
      background: 'transparent',
      backgroundColor: 'rgba(0,0,0,0)'
    },

    '& > .MuiIconButton-label': {
      width: '1.5rem',
      height: '1.5rem'
    }
  },
  selectedIcon: {
    width: '1.5rem',
    height: '1.5rem',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      background: 'transparent'
    }
  },
  controlLabel: {
    marginLeft: '-3px',
    display: 'flex',
    justifyItems: 'flex-start',
    pointerEvents: 'none',

    '& > .MuiFormControlLabel-label': {
      marginLeft: '0.5rem',
      lineHeight: 'normal',
      letterSpacing: '0.013rem',
      fontWeight: 'bold'
    },
    '&> .MuiCheckbox-root': {
      padding: '3px',
      pointerEvents: 'auto'
    }
  },
  clickable: {
    pointerEvents: 'auto'
  }
}));
