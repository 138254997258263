import { ApiClient } from 'features/apiProvider';

import { AuthorizationResponse } from '../types';

interface RegisterParams extends ApiClient {
  data: {
    email: string;
    password: string;
    emailMarketingAgreement: boolean;
    phoneMarketingAgreement: boolean;
    rulesAgreement: boolean;
    referrerCode?: string;
    phone?: string;
    verificationCode?: string;
  };
}

export const register = async ({
  apiClient,
  data: {
    email,
    password,
    rulesAgreement,
    emailMarketingAgreement,
    phoneMarketingAgreement,
    referrerCode,
    phone,
    verificationCode
  }
}: RegisterParams) => {
  const response = await apiClient
    .post('frontend/registration', {
      json: {
        data: {
          email,
          plainPassword: {
            first: password,
            second: password
          },
          rulesAgreement,
          processingPersonalDataAgreement: rulesAgreement,
          emailMarketingAgreement,
          phoneMarketingAgreement,
          referrerCode,
          phone,
          verificationCode
        }
      }
    })
    .json();

  return AuthorizationResponse.parse(response);
};
