import * as z from 'zod';

import { ApiClient } from 'features/apiProvider';

const SizeResponseSchema = z.object({
  id: z.number(),
  nameForClient: z.string(),
  calorific: z.number(),
  sequence: z.number()
});

const SizesDataSchema = z.array(
  z.object({
    id: z.number(),
    name: z.string(),
    sizes: z.array(SizeResponseSchema)
  })
);

type SizesDataResponse = z.TypeOf<typeof SizesDataSchema>;
export type SizeData = SizesDataResponse[number];

export const fetchSizesData = async (apiClient: ApiClient['apiClient']): Promise<SizesDataResponse> => {
  const response = await apiClient.get('v3/diet/sizes_data').json();
  return SizesDataSchema.parse(response);
};
