import type { SelectedMeals } from 'common/types';
import type { SizeData } from 'features/orders/api/fetchSizesData';

import { MealTypeConfigurator } from './MealTypeConfigurator';
import { AddMealHandler, ChangeMealHandler, RemoveMealHandler } from './types';

interface MealsConfiguratorProps {
  selectedMeals: SelectedMeals;
  onMealsChange: (selectedMeals: SelectedMeals) => void;
  label: string;
  sizesData: SizeData[];
}

export const MealsConfigurator = ({ selectedMeals, onMealsChange, label, sizesData }: MealsConfiguratorProps) => {
  const handleAdd: AddMealHandler = ({ typeId, meal }) => {
    const selectedMealTypeMeals = selectedMeals.get(typeId) || [];

    if (selectedMealTypeMeals === undefined) {
      return;
    }

    const changedValues = new Map(selectedMeals);
    changedValues.set(typeId, [...selectedMealTypeMeals, meal]);

    onMealsChange(changedValues);
  };

  const handleRemove: RemoveMealHandler = ({ typeId, id }) => {
    const selectedMealTypeMeals = selectedMeals.get(typeId) || [];

    if (selectedMealTypeMeals === undefined) {
      return;
    }

    const changedValues = new Map(selectedMeals);
    changedValues.set(
      typeId,
      selectedMealTypeMeals.filter((singleMeal) => singleMeal.id !== id)
    );

    onMealsChange(changedValues);
  };

  const handleChange: ChangeMealHandler = ({ id, typeId, newMealId }) => {
    const selectedMealTypeMeals = selectedMeals.get(typeId) || [];

    if (selectedMealTypeMeals === undefined) {
      return;
    }

    const changedMeals = selectedMealTypeMeals.map((singleMeal) => {
      if (singleMeal.id !== id) {
        return singleMeal;
      }

      return {
        ...singleMeal,
        mealId: newMealId
      };
    });

    const changedValues = new Map(selectedMeals);
    changedValues.set(typeId, changedMeals);

    onMealsChange(changedValues);
  };

  return (
    <div role="list" aria-label={label}>
      {sizesData.map((sizeData) => {
        return (
          <MealTypeConfigurator
            key={sizeData.id}
            sizeData={sizeData}
            selectedMeals={selectedMeals.get(sizeData.id) || []}
            onAdd={handleAdd}
            onRemove={handleRemove}
            onChange={handleChange}
          />
        );
      })}
    </div>
  );
};
