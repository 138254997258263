import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getPaymentUrl } from 'common/api';
import { useApi } from 'features/apiProvider';

import { payForOrderProcess, removeOrderItemsFromCart } from '../api';
import { OrderData, PaymentType } from '../types';

interface PayForOrderRequest {
  continueUrlGetter: (orderId: number) => string;
  orderData: OrderData;
  onOrderCreationFail: () => unknown;
  paymentType: PaymentType;
}

interface HandleOrderCreationSuccessProps {
  paymentType: PaymentType;
  orderId: number;
  continueUrlGetter: PayForOrderRequest['continueUrlGetter'];
}

/**
 * Order flow:
 * First of all we are creating an order and adding it to the cart, then we get order details,
 * decide which payment type it is and after that we will
 * call payForOrder method - all in payForOrderProcess mutation.
 * If either method fails, no paid order will be created on the backend.
 * When it fails we should clear cart orderItems and display user a monit which will be called by using `onOrderCreationFail` prop
 * When order creation&payment will end successful we will based on the payment type:
 * - if moneybox refetch moneybox and redirect to thanks page
 * - if payU payment, get payU url and redirect there. If getting payU url fails then we will redirect to fail page which will redirect us to the history page
 */

export const useOrderPaymentProcess = () => {
  const history = useHistory();
  const { getApiClient } = useApi();

  const apiClient = getApiClient();

  const [paymentUrlLoading, setPaymentUrlLoading] = useState(false);

  const { mutate: payForOrderMutation, isLoading: payForOrderLoading } = useMutation({
    mutationFn: payForOrderProcess
  });

  const { mutate: clearCartOrderItems, isLoading: clearCartLoading } = useMutation({
    mutationFn: removeOrderItemsFromCart
  });

  const handleOrderCreationSuccess = async ({
    paymentType,
    orderId,
    continueUrlGetter
  }: HandleOrderCreationSuccessProps) => {
    const redirectTo = continueUrlGetter(orderId);

    if (paymentType === PaymentType.MONEYBOX) {
      history.push(redirectTo);
      return;
    }

    setPaymentUrlLoading(true);

    try {
      const paymentUrl = await getPaymentUrl({
        apiClient,
        orderId,
        continueUrl: `${window.location.origin}${redirectTo}`,
        cancelUrl: `${window.location.origin}${redirectTo}?error=501`
      });
      setPaymentUrlLoading(false);
      window.location.href = paymentUrl;
    } catch (e) {
      history.push(`${continueUrlGetter(0)}?error=501`);
    }
  };

  const handleOrderCreationError = (errorCallback: PayForOrderRequest['onOrderCreationFail']) => async () => {
    await clearCartOrderItems({ apiClient });
    errorCallback();
  };

  const payForOrder = ({ continueUrlGetter, orderData, onOrderCreationFail, paymentType }: PayForOrderRequest) => {
    payForOrderMutation(
      {
        apiClient,
        orderData,
        paymentType
      },
      {
        onSuccess: ({ orderId }) => handleOrderCreationSuccess({ paymentType, orderId, continueUrlGetter }),
        onError: handleOrderCreationError(onOrderCreationFail)
      }
    );
  };

  return {
    payForOrder,
    isLoading: paymentUrlLoading || payForOrderLoading || clearCartLoading
  };
};
