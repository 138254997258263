import { MealsSection, SummaryRow, SummarySection } from 'common/components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BagIcon } from 'common/icons/icon-bag.svg';
import { DietPackage, DietVariant } from 'common/types';

interface DietSummaryProps {
  selectedPackage: DietPackage | undefined;
  selectedVariant: DietVariant | undefined;
  calorific: number;
  selectedMeals: string[];
}

export const DietSummary = ({ selectedPackage, selectedVariant, calorific, selectedMeals }: DietSummaryProps) => {
  const { t } = useTranslation();
  return (
    <SummarySection
      icon={BagIcon}
      title={t('creatDietPage.summaryBox.sections.diet.title')}
      collapsedSummary={t('creatDietPage.summaryBox.sections.diet.collapsedSummarySimple', {
        calories: calorific,
        dietName: selectedPackage?.name ?? ''
      })}
    >
      <SummaryRow label={t('creatDietPage.summaryBox.calories')}>{calorific} kcal</SummaryRow>
      <MealsSection names={selectedMeals} />
      <SummaryRow label={t('creatDietPage.summaryBox.package')}>{selectedPackage?.name ?? ''}</SummaryRow>
      <SummaryRow label={t('creatDietPage.summaryBox.defaultDiet')}>{selectedVariant?.name ?? ''}</SummaryRow>
    </SummarySection>
  );
};
