import { SubmitHandler, useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormRow } from 'common/components';

import { useDiscountCodeVerificator } from 'features/orders';
import { useEffect } from 'react';
import { CreateDietFormData, DiscountCodeFormData } from '../../types';
import { ActivatedDiscountCode, DiscountCodeForm } from './components';

export const DiscountCodeSection = () => {
  const { t } = useTranslation();
  const discountCode = useWatch<CreateDietFormData, 'discountCode'>({
    name: 'discountCode'
  });
  const { register } = useFormContext<CreateDietFormData>();

  useEffect(() => {
    register('discountCode');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { defaultValue, isError, verify } = useDiscountCodeVerificator();

  const handleSubmit: SubmitHandler<DiscountCodeFormData> = ({ value }) => {
    verify(value);
  };

  return (
    <FormRow title={t('creatDietPage.discountCodeSection.title')}>
      {discountCode === null ? (
        <DiscountCodeForm defaultValue={defaultValue} onSubmit={handleSubmit} isWrongCode={isError} />
      ) : (
        <ActivatedDiscountCode value={discountCode} />
      )}
    </FormRow>
  );
};
