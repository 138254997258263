import { makeStyles } from '@material-ui/core';
import { HiddenOnMobile } from 'common/components';
import { Stepper } from './Stepper';
import { LastStepControls } from './LastStepControls';
import { FirstStepControl } from './FirstStepControl';

interface OnboardControlsProps {
  steps: number;
  activeStep: number;
  onNext: VoidFunction;
  onBack: VoidFunction;
  onOnboardingClose: () => void;
}

export const OnboardControls = ({ steps, activeStep, onNext, onBack, onOnboardingClose }: OnboardControlsProps) => {
  const classes = useStyle();

  let component;
  switch (activeStep) {
    case 0:
      component = (
        <HiddenOnMobile>
          <FirstStepControl onClick={onNext} />
        </HiddenOnMobile>
      );
      break;
    case steps - 1:
      component = <LastStepControls onClose={onOnboardingClose} onBack={onBack} />;
      break;
    default:
      component = <Stepper steps={steps} activeStep={activeStep} onNext={onNext} onBack={onBack} />;
      break;
  }

  return <div className={classes.root}>{component}</div>;
};

const useStyle = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '2.5rem'
  }
});
