import { Grid, makeStyles } from '@material-ui/core';
import { HiddenOnMobile, Theme } from 'common/components';
import { country } from 'config';
import { sample } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { BaseLayout } from './BaseLayout';
import { Footer } from './components';

const polishHeroImagesPaths = Array.from({ length: 6 }, (v, i) => `/guestLayout/pl/hero${i + 1}.jpg`);
const generalHeroImagesPaths = Array.from({ length: 2 }, (v, i) => `/guestLayout/general/hero${i + 1}.jpg`);

interface GuestLayoutProps {
  children?: ReactNode;
  isLoading?: boolean;
}

export const GuestLayout = ({ children, isLoading }: GuestLayoutProps) => {
  const imageUrl = useMemo(() => {
    const imagesPaths = country === 'poland' ? polishHeroImagesPaths : generalHeroImagesPaths;
    return sample(imagesPaths) ?? '/guestLayout/general/hero1.jpg';
  }, []);

  const classes = useStyle({ url: imageUrl });
  return (
    <BaseLayout isLoading={isLoading} footer={<Footer />}>
      <Grid container alignItems="stretch" className={classes.container}>
        <HiddenOnMobile>
          <Grid item md={6} className={classes.imgWrapper}>
            <img src={imageUrl} alt="" className={classes.img} />
          </Grid>
        </HiddenOnMobile>
        <Grid container item xs={12} md={6}>
          <Grid item xs="auto" md={1} lg={2} />
          <Grid item container justify="center" xs={12} md={10} lg={8} className={classes.contenWrapper}>
            {children}
          </Grid>
          <Grid item xs="auto" md={1} lg={2} />
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

const useStyle = makeStyles<Theme, { url: string }>((theme) => ({
  container: {
    minHeight: '100vh',
    background: theme.customColors.white,
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
      background: theme.customColors.mainBg
    }
  },
  imgWrapper: {
    height: '100vh'
  },
  img: {
    objectPosition: 'center',
    objectFit: 'cover',
    width: '100%',
    height: '100%'
  },
  contenWrapper: {
    paddingTop: '6.25rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '5rem'
    }
  }
}));
