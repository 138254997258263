import { Dayjs } from 'dayjs';
import TagManager from 'react-gtm-module';
import { CartEventData, PurchaseEventData } from './types';
import { parseCartEventData } from './utils/parseCartEventData';

const TRANSACTION_DATA_KEY = 'transaction_data';
export const ORDER_FIRST_DELIVERY_KEY = 'order_first_delivery';

export const sendPurchaseGAEvent = ({ transactionId }: { transactionId: number }) => {
  const data: PurchaseEventData | undefined = getPurchaseDataFromLocalStorage();
  localStorage.removeItem(TRANSACTION_DATA_KEY);
  if (data) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'purchase',
        ...data,
        ecommerce: {
          transaction_id: transactionId,
          ...data.ecommerce
        },
        amp_testID: localStorage.getItem('amp_testID') || undefined
      }
    });
  }
};

export const setPurchaseDataInLocalStorage = (data: CartEventData & { firstDelivery: Dayjs; firstOrder: number }) => {
  localStorage.setItem(
    TRANSACTION_DATA_KEY,
    JSON.stringify({ ...parseCartEventData(data), first_order: data.firstOrder })
  );
  localStorage.setItem(ORDER_FIRST_DELIVERY_KEY, data.firstDelivery.toISOString());
};

export const getPurchaseDataFromLocalStorage = (): PurchaseEventData | undefined => {
  const transactionData = localStorage.getItem(TRANSACTION_DATA_KEY);
  if (!transactionData) {
    return undefined;
  }

  const retrievedData = JSON.parse(transactionData);

  return parseJsonData(retrievedData);
};

// we need this here as JSON strips undefined from it's keys so here we need to send keys with undefined as values
const parseJsonData = (data: PurchaseEventData): PurchaseEventData => ({
  diet_name: data.diet_name,
  diet_type: data.diet_type,
  path_type: data.path_type,
  diet_calorie: data.diet_calorie,
  meal_type: data.meal_type,
  diet_duration: data.diet_duration,
  value_discount: data.value_discount,
  coupon: data.coupon,
  first_order: data.first_order,
  moneybox_value: data.moneybox_value,
  ecommerce: data.ecommerce
});
