import { ApiClient } from 'features/apiProvider';
import { AuthorizationResponse } from 'features/authentication/types';

interface Params extends ApiClient {
  accessToken: string;
}

export const authenticateWithGoogle = async ({ accessToken, apiClient }: Params) => {
  const response = await apiClient
    .post('v5/account/auth/google', {
      json: { access_token: accessToken, platform: 'WEB' }
    })
    .json();

  return AuthorizationResponse.parse(response);
};
