import { Grid, makeStyles } from '@material-ui/core';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { CreateDietFormData } from 'features/orders/types';
import { useDietConfiguration } from 'features/orders/contexts';
import { convertKcalToKj } from 'common/utils/convertKcalToKj';
import { MealBox } from './MealBox';

export const ComfortMealSection = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const {
    config: { baskets }
  } = useDietConfiguration();

  const selectedBasketId = useWatch<CreateDietFormData, 'basket'>({
    name: 'basket'
  });
  const {
    field: { onChange, value }
  } = useController<CreateDietFormData, 'meals'>({
    name: 'meals'
  });

  const handleSelectMeal = (mealTypeId: number, mealId: number) => () => {
    const selectedMeals = value.get(mealTypeId);
    if (selectedMeals === undefined) {
      return;
    }
    const newMeals = selectedMeals.length ? [] : [{ id: nanoid(), mealId }];
    const newValues = new Map(value);
    newValues.set(mealTypeId, newMeals);
    onChange(newValues);
  };

  return (
    <Grid container className={classes.mealsContainer} spacing={2} data-testid="comfort-meal-selection">
      {baskets.get(selectedBasketId)?.basketMeals.map(({ id, mealType }) => {
        return (
          <MealBox
            active={!!value.get(mealType.id)?.length}
            key={id}
            title={mealType.name}
            details={t('creatDietPage.mealBoxAboutPrefix', {
              kcal: mealType.size.calorific ?? 0,
              kj: convertKcalToKj(mealType.size?.calorific || 0)
            })}
            onMealSelect={handleSelectMeal(mealType.id, mealType.size.id ?? 0)}
          />
        );
      })}
    </Grid>
  );
};

const useStyle = makeStyles(() => ({
  mealsContainer: {
    marginBottom: '2.5rem'
  }
}));
