import { z } from 'zod';
import { emailRegex } from 'common/utils/emailRegexp';

export const loginFormSchema = z.object({
  email: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.emailRequired')
        .regex(emailRegex, 'errors.incorrectEmail')
        .safeParse(val);

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    }),
  password: z
    .string()
    .transform((val) => val.trim())
    .superRefine((val, ctx) => {
      const fieldValidationResult = z
        .string()
        .nonempty('errors.passwordRequired')
        .min(6, { message: 'errors.incorrectPassword' })
        .safeParse(val);

      if (!fieldValidationResult.success) {
        ctx.addIssue(fieldValidationResult.error.issues[0]);
      }
    }),
  rememberMe: z.boolean()
});

export type LoginFormData = z.infer<typeof loginFormSchema>;
