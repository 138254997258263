import * as z from 'zod';
import { CalendarDate, Id } from 'common/types';
import { ApiClient } from 'features/apiProvider';
import { SelectedProfileId } from 'features/profiles';
import { YEAR_MONTH_DAY } from 'common/constants';
import { round } from 'lodash';

interface DietDayPriceRequestParamsProps extends ApiClient {
  requestData: {
    dietLength: number;
    discountCode: number | null;
    package: number;
    sizeIds: Id[];
    startDate?: CalendarDate;
    weekendsIncluded: boolean;
    selectedProfile: SelectedProfileId;
    days: CalendarDate[];
  };
}

export const fetchUserOrderDietPriceSchema = z.object({
  beforeDiscount: z.number(),
  afterDiscount: z.number(),
  discount: z.number(),
  deliveryDiscount: z.number(),
  total: z.number(),
  delivery: z.object({ beforeDiscount: z.number(), afterDiscount: z.number() })
});

export type UserOrderDietPrice = z.TypeOf<typeof fetchUserOrderDietPriceSchema>;

export interface FetchDietPriceResponse {
  totalPrice: number;
  priceToPay: number;
  pricePerDay: number;
  dailyPriceOfDelivery: number;
  discounts: {
    code: number;
    delivery: number;
  };
  delivery: {
    beforeDiscount: number;
    afterDiscount: number;
  };
}

const dietPriceTransformer = (responseData: UserOrderDietPrice): FetchDietPriceResponse => {
  const { total, discount, beforeDiscount, deliveryDiscount, delivery } =
    fetchUserOrderDietPriceSchema.parse(responseData);
  const dayPrice = beforeDiscount;
  const parsedTotal = round(total, 2);
  const totalPriceBeforeDiscount = parsedTotal + discount;
  return {
    totalPrice: totalPriceBeforeDiscount,
    priceToPay: parsedTotal,
    pricePerDay: dayPrice,
    dailyPriceOfDelivery: responseData.delivery.afterDiscount,
    discounts: {
      code: discount,
      delivery: deliveryDiscount
    },
    delivery
  };
};

export const fetchDietDayPrice = async ({
  requestData,
  apiClient,
  signal
}: DietDayPriceRequestParamsProps): Promise<FetchDietPriceResponse> => {
  if (
    requestData.package <= 0 ||
    requestData.dietLength === 0 ||
    requestData.sizeIds.length === 0 ||
    requestData.startDate === undefined
  ) {
    return Promise.resolve({
      totalPrice: 0,
      priceToPay: 0,
      pricePerDay: 0,
      dailyPriceOfDelivery: 0,
      discounts: {
        code: 0,
        delivery: 0
      },
      delivery: {
        beforeDiscount: 0,
        afterDiscount: 0
      }
    });
  }

  const response = await apiClient
    .patch('frontend/diet-price', {
      json: {
        ...requestData,
        diet: requestData.selectedProfile,
        startDate: requestData.startDate.format(YEAR_MONTH_DAY),
        days: requestData.days.map((d) => d.format(YEAR_MONTH_DAY))
      },
      signal
    })
    .json();

  const parsedResponse = fetchUserOrderDietPriceSchema.parse(response);

  return dietPriceTransformer(parsedResponse);
};
