import { zodResolver } from '@hookform/resolvers/zod';
import { useLocationQuery } from 'common/hooks';
import {
  calculateInitialFormState,
  CreateDietFormData,
  createDietFormSchema,
  extendDietFormSchema,
  useDietConfiguration
} from 'features/orders';
import { ReactNode, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface CreateDietFormProviderProps {
  children?: ReactNode;
}

export const CreateDietFormProvider = ({ children }: CreateDietFormProviderProps) => {
  const locationQuery = useLocationQuery();
  const { deliveryTimeConfig, config, isNewOrder } = useDietConfiguration();
  const schema = useMemo(() => (isNewOrder ? createDietFormSchema : extendDietFormSchema), [isNewOrder]);

  const createDietForm = useForm<CreateDietFormData>({
    reValidateMode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      dietName: '',
      discountCode: null,
      ...calculateInitialFormState({ data: config, urlSearchParams: locationQuery, deliveryTimeConfig })
    }
  });

  return <FormProvider {...createDietForm}>{children}</FormProvider>;
};
