import { Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Button, Dialog, HiddenOnDesktop, HiddenOnMobile, Paper, SelectInput } from 'common/components';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { Id } from 'common/types';
import { AddressSummary, LeavingBagSummary } from 'features/addresses';
import { Address } from 'features/addresses/types';
import { useUser } from 'features/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AddressOption {
  value: Id;
  label: string;
}

interface ChangeDeliveryAddressDialogProps {
  isVisible: boolean;
  onClose: VoidFunction;
  title: string;
  defaultAddress: Address;
  onChange: (newAddress: Address) => void;
}

export const ChangeDeliveryAddressDialog = ({
  isVisible,
  onClose,
  title,
  defaultAddress,
  onChange
}: ChangeDeliveryAddressDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const isMobile = useMobileScreenSizeDetector();
  const { addresses } = useUser();
  const [selectedAddress, setSelectedAddress] = useState(defaultAddress);

  useEffect(() => {
    setSelectedAddress(defaultAddress);
  }, [defaultAddress]);

  useEffect(() => {
    if (!isVisible) {
      setSelectedAddress(defaultAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const options: AddressOption[] = addresses.data.map((address) => ({
    value: address.id,
    label: address.name
  }));

  const handleSelect = (option: AddressOption | null) => {
    if (option === null) {
      return;
    }
    const newAddress = addresses.data.find((address) => address.id === option.value);
    if (newAddress) {
      setSelectedAddress(newAddress);
    }
  };

  const handleChange = () => {
    onChange(selectedAddress);
  };

  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
      classes={{
        paperWidthMd: classes.root,
        header: classes.header
      }}
      title={
        <HiddenOnMobile>
          <Typography className={classes.title}>{title}</Typography>
        </HiddenOnMobile>
      }
      footer={
        isMobile && (
          <Button fullWidth mode="primary" onClick={handleChange}>
            {t('creatDietPage.changeDeliveryAddress.choose')}
          </Button>
        )
      }
    >
      <HiddenOnDesktop>
        <Typography className={clsx(classes.title, classes.mobileTitle)}>{title}</Typography>
      </HiddenOnDesktop>
      <SelectInput
        menuPosition="fixed"
        onChange={handleSelect}
        options={options}
        value={options.find((o) => o.value === selectedAddress.id)}
      />
      <Paper className={classes.addressSummary}>
        <AddressSummary data={selectedAddress} />
        <LeavingBagSummary className={classes.leavingBagSummary} data={selectedAddress} />
      </Paper>
      <Typography variant="body2" align="center" color="textSecondary">
        {t('creatDietPage.changeDeliveryAddress.info')}
      </Typography>
      <HiddenOnMobile>
        <div className={classes.desktopButtonsSection}>
          <Button fullWidth mode="secondary" onClick={onClose}>
            {t('creatDietPage.changeDeliveryAddress.cancel')}
          </Button>
          <Button fullWidth mode="primary" onClick={handleChange}>
            {t('creatDietPage.changeDeliveryAddress.choose')}
          </Button>
        </div>
      </HiddenOnMobile>
    </Dialog>
  );
};

const useStyle = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '1rem'
  },
  mobileTitle: {
    marginBottom: '1rem'
  },
  root: {
    maxWidth: '27rem',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  header: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0rem'
    }
  },
  leavingBagSummary: {
    marginTop: '0.5rem'
  },
  addressSummary: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  desktopButtonsSection: {
    marginTop: '2rem',
    marginBottom: '1.5rem',
    '&>*:first-child': {
      marginBottom: '1rem'
    }
  }
}));
