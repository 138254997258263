import { Id } from 'common/types';
import ReactSelect, { StylesConfig, SingleValue } from 'react-select';
import { Profile, SelectedProfileId } from 'features/profiles';
import { useTheme } from '@material-ui/core';
import { ProfileSelectOption } from './ProfileSelectOption';
import { SelectOption } from './types';

interface ProfileSelectProps {
  items: Profile[];
  selected: SelectedProfileId;
  onChange: (id: Id) => void;
}

export const ProfileSelect = ({ items, selected, onChange }: ProfileSelectProps) => {
  const options: SelectOption[] = items.map((orderItem) => ({ label: orderItem.name, value: orderItem.id }));
  const value = options.find((item) => item.value === selected);

  const theme = useTheme();
  const customStyles: StylesConfig<SelectOption, false> = {
    control: (provided, state) => {
      return {
        ...provided,
        boxShadow: state.isDisabled ? 'none' : theme.boxShadow.lightGrey,
        borderColor: 'transparent',
        cursor: 'pointer',
        fontSize: '1rem',
        zIndex: state.menuIsOpen ? 10 : 1,
        '&:hover': {
          borderColor: 'transparent'
        }
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      padding: '0.5rem 0.9rem'
    }),
    singleValue: (provided) => ({
      ...provided
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: theme.palette.primary.main,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '0',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      width: '100%',
      cursor: 'pointer',
      fontSize: '1rem',
      display: 'flex',
      alignItems: 'center',
      minHeight: '2rem',
      position: 'relative',
      '&:focus, &:active, &:hover': {
        backgroundColor: 'transparent'
      },
      '&:not(:last-child):after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 8,
        right: 0,
        height: 1,
        background: theme.customColors.whisperGrey
      },
      '&:not(:last-child)': {
        marginBottom: '0.5rem'
      }
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      zIndex: 25
    }),
    menuList: (provided) => ({
      ...provided,
      padding: '1rem',
      boxShadow: theme.boxShadow.lightGrey,
      backgroundColor: theme.customColors.white
    })
  };

  const handleChange = (data: SingleValue<SelectOption>) => {
    if (data !== null) {
      onChange(data.value);
    }
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <ReactSelect<SelectOption>
      options={options}
      value={value}
      onChange={handleChange}
      menuShouldBlockScroll
      menuShouldScrollIntoView
      isSearchable={false}
      components={{
        IndicatorSeparator: () => null,
        Option: ProfileSelectOption
      }}
      styles={customStyles}
    />
  );
};
