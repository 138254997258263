import { ButtonBase, Collapse, IconButton, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState, ReactNode, FunctionComponent, SVGProps } from 'react';
import clsx from 'clsx';
import { SummarySectionHeader } from './SummarySectionHeader';

const TRANSITION_DURATION = 200;

interface SummarySectionProps {
  title: string;
  children: ReactNode;
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  collapsedSummary: ReactNode;
}

export const SummarySection = ({ title, children, icon, collapsedSummary }: SummarySectionProps) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const handleToggle = () => setIsExpanded((prevState) => !prevState);
  const classes = useStyle();
  return (
    <>
      <ButtonBase
        className={classes.root}
        focusRipple={false}
        disableRipple
        component="div"
        aria-expanded={isExpanded}
        onClick={handleToggle}
      >
        <div className={classes.header}>
          <SummarySectionHeader icon={icon} title={title} />
          <IconButton className={classes.collapseButton}>
            <ExpandMoreIcon
              className={clsx(classes.icon, {
                [classes.expandedIcon]: isExpanded
              })}
            />
          </IconButton>
        </div>
      </ButtonBase>
      <Collapse classes={{ wrapperInner: classes.content }} in={isExpanded} timeout={TRANSITION_DURATION}>
        {children}
      </Collapse>
      <Collapse classes={{ wrapperInner: classes.content }} in={!isExpanded} timeout={TRANSITION_DURATION}>
        <div className={classes.collapsedSummary}>{collapsedSummary}</div>
      </Collapse>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  header: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
    '&>*:first-child': {
      flex: 1
    }
  },
  collapseButton: {
    marginRight: '-1rem'
  },
  icon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: TRANSITION_DURATION
    }),
    color: theme.customColors.black
  },
  expandedIcon: {
    transform: 'rotate(180deg)'
  },
  collapsedSummary: {
    color: theme.customColors.textDarkGrey
  },
  content: {
    marginBottom: '1rem'
  }
}));
