import { makeStyles } from '@material-ui/core';

interface DietLengthContentProps {
  title: string;
  subtitle?: string;
}

export const DietLengthContent = ({ title, subtitle }: DietLengthContentProps) => {
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
    </div>
  );
};

const useStyle = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  title: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: 'normal'
  },
  subtitle: {
    marginTop: '0.25rem',
    fontSize: '0.75rem',
    lineHeight: 'normal'
  }
}));
