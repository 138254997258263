import { z } from 'zod';

export const discountCodeFormMaxFieldsMaxLength = {
  value: 32
};

export const discountCodeFormSchema = z.object({
  value: z
    .string()
    .transform((input) => input.trim())
    .superRefine((val, ctx) => {
      const fieldValidationParseResult = z
        .string()
        .min(1, 'errors.discountCode.required')
        .max(discountCodeFormMaxFieldsMaxLength.value, 'errors.discountCode.toLong')
        .safeParse(val);
      if (!fieldValidationParseResult.success) {
        ctx.addIssue(fieldValidationParseResult.error.issues[0]);
      }
    })
});

export type DiscountCodeFormData = z.TypeOf<typeof discountCodeFormSchema>;
