import { makeStyles } from '@material-ui/core';

export interface WeekdayProps {
  value: string;
  label: string;
}

export const Weekday = ({ value, label }: WeekdayProps) => {
  const classes = useStyle();
  return (
    <abbr aria-label={label} className={classes.label}>
      {value}
    </abbr>
  );
};

const useStyle = makeStyles(() => ({
  label: {
    fontWeight: 'bold',
    fontSize: '0.59375rem',
    textTransform: 'uppercase',
    width: '100%',
    flex: 1,
    textAlign: 'center'
  }
}));
