import { CalendarDate } from 'common/types';

interface GetMonthRangeArgs {
  visibleDate: CalendarDate;
  locale: string;
  showFullWeeks: boolean;
}
interface GetMonthRangeReturn {
  start: CalendarDate;
  end: CalendarDate;
}

export const getMonthRange = ({ visibleDate, locale, showFullWeeks }: GetMonthRangeArgs): GetMonthRangeReturn => {
  const localizedCurrentDate = visibleDate.locale(locale);
  let start = localizedCurrentDate.startOf('month');
  let end = localizedCurrentDate.endOf('month');
  if (showFullWeeks) {
    start = start.startOf('week');
    end = end.endOf('week');
  }
  return {
    start,
    end
  };
};
