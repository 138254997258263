import TagManager from 'react-gtm-module';

export const sendUserDataGAEvent = (user?: { id: number; hasActiveDiet: boolean }) => {
  const data: { dataLayer: Record<string, string | number | undefined> } = {
    dataLayer: {
      event: 'user_data',
      user_id: user?.id,
      active_diet: user?.hasActiveDiet ? 'yes' : 'no',
      login_status: user?.id ? 'logged-in' : 'not-logged-in',
      amp_testID: localStorage.getItem('amp_testID') || undefined
    }
  };
  TagManager.dataLayer(data);
};
