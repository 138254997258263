import { useState } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Dialog, HiddenOnDesktop } from 'common/components';
import checkedCircle from 'common/icons/icon-check-circle.svg';
import { useTranslation } from 'react-i18next';
import { country } from 'config';
import animatedPhone from './images/phone-animated.gif';
import cashPhone from './images/cash-animated.gif';
import calendarPhone from './images/calendar-animated.gif';

import { OnboardStep, OnboardControls, OnboardStepProps, FirstStepControl } from './components';

const onboardingKey = process.env.REACT_APP_ONBOARDING_KEY || 'onboarding';

export const Onboarding = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(!localStorage.getItem(onboardingKey));
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();

  const classes = useStyle({ currentStep });

  const handleNextSlide = () => {
    if (currentStep + 1 < steps.length) {
      setCurrentStep((prevState) => prevState + 1);
    }
  };
  const handlePreviousSlide = () => {
    if (currentStep - 1 >= 0) {
      setCurrentStep((prevState) => prevState - 1);
    }
  };

  const handleCloseModal = () => {
    localStorage.setItem(onboardingKey, 'true');
    setIsOpen(false);
  };

  const stepsRaw: OnboardStepProps[] = [
    {
      icon: checkedCircle,
      title: 'onboarding.step1.title',
      content: 'onboarding.step1.content',
      cta: (
        <HiddenOnDesktop>
          <FirstStepControl className={classes.firstSlideButton} onClick={handleNextSlide} />
        </HiddenOnDesktop>
      )
    },
    {
      icon: animatedPhone,
      title: 'onboarding.step3.title',
      content: 'onboarding.step3.content'
    },
    {
      icon: cashPhone,
      title: 'onboarding.step4.title',
      content: 'onboarding.step4.content'
    },
    {
      icon: calendarPhone,
      title: 'onboarding.step5.title',
      content: 'onboarding.step5.content'
    }
  ];

  const countryHasMobileApp = country !== 'czech';

  const steps = stepsRaw.filter((step) => (!countryHasMobileApp ? step.title !== 'onboarding.step3.title' : true));

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseModal}
      classes={{
        paper: classes.modalContent,
        footer: classes.footer
      }}
      footer={
        <OnboardControls
          activeStep={currentStep}
          steps={steps.length}
          onOnboardingClose={handleCloseModal}
          onNext={handleNextSlide}
          onBack={handlePreviousSlide}
        />
      }
      aria-labelledby="onboarding-title"
    >
      <Typography id="onboarding-title" variant="srOnly">
        {t('onboarding.step1.title')}
      </Typography>
      <div className={classes.slider}>
        <div className={classes.contentWrapper}>
          {steps.map(({ title, ...rest }) => (
            <OnboardStep {...rest} title={title} key={title} />
          ))}
        </div>
      </div>
    </Dialog>
  );
};

const useStyle = makeStyles<Theme, { currentStep: number }>((theme) => ({
  modalContent: {
    backgroundColor: theme.palette.primary.main
  },
  footer: {
    backgroundColor: theme.palette.primary.main
  },
  slider: {
    overflow: 'hidden'
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    transition: `transform 0.3s ease`,
    transform: (props) => `translateX(${props.currentStep * -100}%)`
  },
  firstSlideButton: {
    marginTop: '4rem'
  }
}));
