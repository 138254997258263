import { MouseEvent, useState } from 'react';
import { Grid, makeStyles, Typography, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import lunchIcon from 'common/icons/icon-lunch.svg';

import checkboxEmpty from 'common/icons/icon-checkbox-empty.svg';

import { MarkedCircle } from 'common/icons/MarkedCircle';
import iconMore from '../images/icon-more.svg';

interface DietBoxProps {
  onSelect: (newDietId: number) => void;
  image: string;
  title: string;
  description: string;
  selected: boolean;
  available: boolean;
  id: number;
  onMoreClick: (dietVariantId: number) => void;
}

export const DietBox = ({
  image,
  description,
  title,
  selected: active = false,
  available,
  onSelect,
  id,
  onMoreClick
}: DietBoxProps) => {
  const classes = useStyle();
  const [src, setSrc] = useState(image);
  const [hasImageLoadingError, setHasImageLoadingError] = useState(false);
  const { t } = useTranslation();

  const handleMore = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onMoreClick(id);
  };

  const handleSelect = () => {
    if (available) {
      onSelect(id);
    }
  };

  const handleImageError = () => {
    setHasImageLoadingError(true);
    setSrc(lunchIcon);
  };

  return (
    <Grid item xs={12} md={6} className={classes.wrapper}>
      <button
        type="button"
        onClick={handleSelect}
        className={clsx({
          [classes.dietContainer]: true,
          active,
          blocked: !available
        })}
        aria-pressed={active}
        value={title}
        disabled={!available}
      >
        <div className={classes.imageSection}>
          <div className={classes.selectedStatus}>
            {active ? <MarkedCircle imageAlt="" /> : <img src={checkboxEmpty} alt="" />}
          </div>
          <img
            src={src}
            alt=""
            style={hasImageLoadingError ? { width: '2rem', height: '2rem', margin: 'auto' } : {}}
            onError={handleImageError}
          />
        </div>
        <div className={classes.dietDetailsContainer}>
          <Typography variant="h6" className={classes.dietBoxName}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            className={clsx({
              [classes.dietBoxDescription]: true,
              active
            })}
          >
            {description}
          </Typography>
        </div>
      </button>
      <IconButton
        className={classes.moreButton}
        onClick={handleMore}
        aria-label={t('creatDietPage.dietBoxShowDetails', { name: title })}
      >
        <img src={iconMore} alt="" />
      </IconButton>
    </Grid>
  );
};

const useStyle = makeStyles((theme) => ({
  wrapper: {
    position: 'relative'
  },
  dietContainer: {
    boxShadow: theme.boxShadow.lightGrey,
    cursor: 'pointer',
    borderRadius: '0.25rem',
    width: '100%',
    height: '100%',
    backgroundColor: theme.customColors.white,
    overflow: 'hidden',
    display: 'flex',
    padding: 0,
    border: 'none',

    '&.active': {
      backgroundColor: theme.palette.primary.main
    },

    '&.blocked': {
      opacity: 0.5,
      cursor: 'unset'
    }
  },
  imageSection: {
    width: '120px',
    height: '120px',
    display: 'flex',
    flexShrink: 0,
    position: 'relative',

    '& > img': {
      objectFit: 'cover',
      width: '100%',
      height: '100%'
    }
  },
  dietDetailsContainer: {
    width: '100%',
    padding: '1rem',
    textAlign: 'left',
    position: 'relative'
  },
  dietBoxName: {
    color: theme.customColors.black,
    lineHeight: 'normal',
    marginBottom: '0.5rem',
    paddingRight: '1rem'
  },
  dietBoxDescription: {
    letterSpacing: '0.013rem',
    lineHeight: 1.29,
    color: theme.customColors.textDarkGrey,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',

    '&.active': {
      color: theme.palette.secondary.main
    }
  },
  moreButton: {
    [theme.breakpoints.down('sm')]: {
      top: '1rem',
      right: '1rem'
    },
    padding: 0,
    minWidth: 'unset',
    top: '1.5rem',
    right: '1.5rem',
    position: 'absolute',

    '&:hover': {
      background: 'transparent'
    },

    '&.active': {
      background: 'transparent',

      '&:hover': {
        background: 'transparent'
      }
    }
  },
  selectedStatus: {
    top: '0.5rem',
    left: '0.5rem',
    position: 'absolute',

    '& > img': {
      width: '1rem',
      height: '1rem',
      filter: theme.filters.white
    }
  }
}));
