import { idSchema } from 'common/types';
import { z } from 'zod';

const resultsSchema = z.object({
  id: idSchema,
  first_name: z.string().nullable(),
  last_name: z.string().nullable()
});

const aggregatesSchema = z.object({
  is_diet_active: z.boolean().optional(),
  next_bag_delivery: z.string().optional(),
  last_bag_delivery: z.string().optional()
});

export const clientDataSchema = z.object({ results: resultsSchema, aggregates: aggregatesSchema });

export type Aggregate = 'is_diet_active' | 'next_bag_delivery' | 'last_bag_delivery';

export type ClientData = z.output<typeof clientDataSchema>;
