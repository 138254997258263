import { Dialog, makeStyles, Paper, Typography } from '@material-ui/core';
import { useCallback, useEffect } from 'react';
import { ReactComponent as CloseIcon } from 'common/icons/icon-times.svg';
import { ReactComponent as CheckIcon } from 'common/icons/icon-check-circle.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export const SuccessChangeDataModal = ({ isOpen, onClose }: Props) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const handleExited = useCallback(() => {
    const rootDiv = document.getElementById('root');
    rootDiv?.classList.remove(classes.rootBackdrop);
  }, [classes.rootBackdrop]);

  const handleEntering = () => {
    const rootDiv = document.getElementById('root');
    rootDiv?.classList.add(classes.rootBackdrop);
  };

  useEffect(() => {
    return () => {
      if (isOpen) {
        handleExited();
      }
    };
  }, [handleExited, isOpen]);

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={onClose} onEntering={handleEntering} onExiting={handleExited}>
      <Paper className={classes.root}>
        <div className={classes.content}>
          <button className={classes.closeButton} onClick={onClose} type="button">
            <CloseIcon />
          </button>
          <CheckIcon className={classes.icon} />
          <Typography className={classes.title}>{t('personalDataPage.success-change-modal.title')}</Typography>
          <Typography className={classes.text}>{t('personalDataPage.success-change-modal.content')}</Typography>
        </div>
      </Paper>
    </Dialog>
  );
};

const useStyle = makeStyles((theme) => ({
  rootBackdrop: {
    [theme.breakpoints.up('xs')]: {
      overflow: 'hidden',
      filter: 'blur(7px)'
    }
  },
  text: {
    fontSize: '1rem',
    textAlign: 'center'
  },
  title: {
    color: theme.customColors.green,
    fontWeight: 700,
    fontSize: '1.25rem',
    textAlign: 'center',
    paddingTop: '1rem',
    paddingBottom: '0.5rem'
  },
  root: {
    width: 'calc(100vw - 6rem)',
    maxWidth: '49rem',
    position: 'relative',
    padding: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      position: 'fixed',
      inset: 0,
      backgroundColor: theme.customColors.white
    }
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      justifyContent: 'center'
    }
  },
  icon: {
    width: '4rem',
    height: '4rem'
  },
  closeButton: {
    all: 'unset',
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 20,
    right: 0,
    top: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
