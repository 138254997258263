import { Collapse, IconButton, makeStyles, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useVisibility } from 'common/hooks';

interface MealsSectionProps {
  names: string[];
}

export const MealsSection = ({ names }: MealsSectionProps) => {
  const [isVisible, show, hide] = useVisibility();
  const classes = useStyles({ expanded: isVisible });

  const { t } = useTranslation();

  const handleExpandClick = () => {
    if (isVisible === true) {
      hide();
    } else {
      show();
    }
  };

  const collapseClasses = {
    wrapperInner: classes.collapseWrapperInner
  };

  return (
    <div className={classes.container}>
      <span className={classes.label}>{t('creatDietPage.summaryBox.meals')}</span>
      <div className={classes.extendWrapper}>
        {names.length}
        <IconButton
          className={clsx(classes.expandIcon, {
            [classes.expandIconOpen]: isVisible
          })}
          onClick={handleExpandClick}
          aria-expanded={isVisible}
          aria-label={t('collapse.showButton')}
        >
          <ExpandMoreIcon color="primary" />
        </IconButton>
      </div>
      <Collapse
        in={isVisible}
        timeout="auto"
        className={classes.collapseContent}
        unmountOnExit
        classes={collapseClasses}
      >
        {names.map((singleName, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span className={classes.mealName} key={i}>
            {singleName}
            {i !== names.length - 1 && <>,&nbsp;</>}
          </span>
        ))}
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles<Theme, { expanded: boolean }>((theme) => ({
  container: {
    display: 'grid',
    padding: '0.5rem 0',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    width: '100%'
  },
  label: {
    color: theme.orderSummaryBox.color,
    fontSize: '1rem'
  },
  extendWrapper: {
    height: '1rem',
    display: 'flex',
    alignItems: 'center'
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    marginRight: '-1rem',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandIconOpen: {
    transform: 'rotate(180deg)'
  },
  collapseContent: {
    gridColumn: '1/3',
    textAlign: 'right',
    fontSize: '1rem',
    '& > div': {
      padding: '0.5rem 0 1rem 0'
    }
  },
  collapseWrapperInner: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  mealName: {
    whiteSpace: 'nowrap'
  }
}));
