import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MoneyBox, BackButton } from 'layouts/components';
import { useMobileScreenSizeDetector } from 'common/hooks';

interface HeaderProps {
  name: string;
}

export const Header = ({ name }: HeaderProps) => {
  const { t } = useTranslation();
  const isMobile = useMobileScreenSizeDetector();
  const classes = useStyle();

  return (
    <Grid container alignItems="center">
      <Grid item xs={1} md={4}>
        <BackButton label={isMobile ? undefined : t('backButton')} />
      </Grid>
      <Grid item xs={11} md={8} container justify={isMobile ? 'center' : 'flex-end'}>
        {isMobile ? (
          <Typography component="h1" className={classes.name}>
            {name}
          </Typography>
        ) : (
          <MoneyBox />
        )}
      </Grid>
    </Grid>
  );
};

const useStyle = makeStyles((theme) => ({
  name: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.customColors.white,
    lineHeight: '1.5rem'
  }
}));
