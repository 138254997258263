import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { ErrorPage } from './pages/ErrorPage';

interface ErrorBoundaryProps {
  children: ReactNode;
}

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  return <Sentry.ErrorBoundary fallback={({ error }) => <ErrorPage error={error} />}>{children}</Sentry.ErrorBoundary>;
};
