import { CircularProgress, Grid } from '@material-ui/core';
import { NotifyDialog } from 'common/components';
import { useVisibility } from 'common/hooks';
import { useApi } from 'features/apiProvider';
import { ApprovalsForm, useUser } from 'features/user';
import { updateMarketingSubscriptions } from 'features/user/api';
import { ApprovalsFormSchema } from 'features/user/types';
import { AccountLayout } from 'layouts';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

export const ApprovalsPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { getApiClient } = useApi();
  const { profile } = useUser();
  const [isVisible, show, hide] = useVisibility();

  const { mutate, isLoading, isError } = useMutation({
    mutationKey: 'updateSubscriptions',
    mutationFn: updateMarketingSubscriptions,
    onSuccess: () => {
      profile.refetch();
      show();
    },
    onError: () => {
      show();
    }
  });

  const onUpdateApprovalsSubmit = ({ subscriptionEmail, subscriptionPhone }: ApprovalsFormSchema) => {
    mutate({
      apiClient: getApiClient(),
      requestData: {
        emailMarketingAgreement: subscriptionEmail,
        phoneMarketingAgreement: subscriptionPhone
      }
    });
  };

  return (
    <AccountLayout isLoading={isLoading} title={t('approvalsPage.title')}>
      <Grid container>
        <Grid item xs={12} sm={9} md={12}>
          {profile.isLoading || profile.isRefetching ? (
            <CircularProgress size={50} color="secondary" />
          ) : (
            <>
              <Trans i18nKey="approvalsPage.subtitle" components={{ p: <p /> }} />
              <ApprovalsForm
                defaultValues={{
                  subscriptionEmail: profile.subscriptionEmail,
                  subscriptionPhone: profile.subscriptionPhone
                }}
                onSubmit={onUpdateApprovalsSubmit}
                isLoading={isLoading}
              />
            </>
          )}
        </Grid>
      </Grid>

      <NotifyDialog
        isOpen={isVisible}
        message={isError ? 'approvalsPage.form.state.errorRequest' : 'approvalsPage.form.state.successRequest'}
        onClose={hide}
        variant={isError ? 'error' : 'success'}
      />
    </AccountLayout>
  );
};
