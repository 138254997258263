import { makeStyles } from '@material-ui/core';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from 'common/components';
import { checkGoogleLoginExisting } from 'common/utils/checkGoogleLoginExisting';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useAuthenticateWithGoogle } from 'services/authentication/google';
import { ReactComponent as GoogleLogo } from '../images/google-logo.svg';

interface Props {
  type?: 'login' | 'register';
}

export const GoogleLogin = ({ type = 'login' }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const { mutate: authenticateWithGoogle } = useAuthenticateWithGoogle({ type });

  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      authenticateWithGoogle({ accessToken: access_token });
    },
    onError: () => {
      enqueueSnackbar(t('snackbarError.title'), { variant: 'error' });
    }
  });

  if (!checkGoogleLoginExisting()) {
    return null;
  }

  return (
    <Button mode="secondary" className={classes.root} onClick={() => googleLogin()}>
      <GoogleLogo className={classes.img} />
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderColor: theme.customColors.textLightGrey
  },
  img: {
    width: '1.25rem',
    height: '1.25rem'
  }
}));
