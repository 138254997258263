import { Experiments } from './types';

export const fetchExperimentsForUser = async (user_id?: number): Promise<Experiments> => {
  const API_KEY = String(process.env.REACT_APP_AMPLITUDE_KEY);

  if (!API_KEY || !user_id) {
    return {};
  }

  const url = new URL('v1/vardata', 'https://api.lab.eu.amplitude.com');
  url.searchParams.append('user_id', String(user_id));

  const response = await fetch(url.href, {
    headers: new Headers({
      Authorization: `Api-Key ${API_KEY}`
    })
  });
  const data = await response.json();

  return data;
};
