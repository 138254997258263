import { ReactNode } from 'react';
import { makeStyles, CircularProgress } from '@material-ui/core';

export type MobileSummaryRowCssClasses = {
  label: string;
};

interface MobileSummaryRowProps {
  label: string;
  children: ReactNode;
  isLoading?: boolean;
  classes?: Partial<MobileSummaryRowCssClasses>;
}

export const MobileSummaryRow = ({
  label,
  children,
  classes: outerClasses,
  isLoading = false
}: MobileSummaryRowProps) => {
  const classes = useStyle();

  return (
    <div className={classes.row}>
      <div className={classes.title}>
        <span className={outerClasses?.label}>{label}</span>
      </div>
      <div className={classes.content}>{isLoading ? <CircularProgress size={13} /> : children}</div>
    </div>
  );
};
const useStyle = makeStyles((theme) => ({
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'flex-end',
    fontSize: 'inherit',
    gap: '0.5rem'
  },
  content: {
    textAlign: 'end',
    justifyItems: 'flex-end'
  },
  title: {
    color: theme.orderSummaryBox.color,
    textAlign: 'left',
    fontSize: 'inherit'
  }
}));
