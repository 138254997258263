import { StepIconProps, makeStyles, SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as CheckIcon } from './icon-check.svg';

export const StepIcon = ({ icon, active, completed, error, ref, ...rest }: StepIconProps) => {
  const classes = useStyle();
  const className = clsx(classes.root, {
    [classes.active]: active,
    [classes.complated]: completed
  });
  return (
    <div {...rest} className={className}>
      {completed ? <SvgIcon component={CheckIcon} viewBox="0 0 12 12" className={classes.completedIcon} /> : icon}
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    width: '1.5rem',
    height: '1.5rem',
    borderWidth: '0.125rem',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: '1.25rem'
  },
  active: {
    backgroundColor: theme.palette.primary.main
  },
  complated: {
    backgroundColor: theme.palette.primary.main
  },
  completedIcon: {
    fontSize: '0.875rem'
  }
}));
