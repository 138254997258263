import { useCallback, useState } from 'react';
import { Grid } from '@material-ui/core';
import { CalendarDate } from 'common/types';
import { noop } from 'lodash';
import { Content } from './Content';
import { Navigation } from '../components';
import { CalendarProps } from '../types';
import { Weekdays } from './Weekdays';
import { DayTiles } from './DayTiles';
import { useCalendarControl } from '../useCalendarControl';

const MIN_NUMBER_OF_TILES = 7;
const TILE_WIDTH = 50;

const calculateTileNumber = (containerWidth: number) => {
  return Math.max(Math.floor(containerWidth / TILE_WIDTH), MIN_NUMBER_OF_TILES);
};

export const RowCalendar = ({
  visibleDate,
  locale,
  maxDate,
  minDate,
  onIntervalChange,
  onClick = noop,
  calculateDayStyle,
  calculateDisabledDates: outerCalculateDisabledDates,
  className
}: CalendarProps) => {
  const [tileCount, setTileCount] = useState(0);
  const visibleDates = calculateVisibleDates(visibleDate, tileCount);
  const { calculateDisabledDates, canNavigateToNexInterval, canNavigateToPreviousInterval } = useCalendarControl({
    firstDate: visibleDates[0],
    lastDate: visibleDates[visibleDates.length - 1],
    calculateDisabledDates: outerCalculateDisabledDates,
    maxDate,
    minDate
  });

  const handleResize = useCallback((containerWidth: number) => {
    setTileCount(calculateTileNumber(containerWidth));
  }, []);

  const handleNextMonthClick = () => {
    onIntervalChange(visibleDate.add(tileCount, 'd'));
  };

  const handlePrevMonthClick = () => {
    onIntervalChange(visibleDate.subtract(tileCount, 'd'));
  };

  return (
    <div className={className}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Navigation
            currentDate={visibleDate}
            onNextButtonClick={handleNextMonthClick}
            onPrevButtonClick={handlePrevMonthClick}
            disableNextButton={!canNavigateToNexInterval}
            disablePrevButton={!canNavigateToPreviousInterval}
            locale={locale}
            dateFormat="dddd, DD.MM.YYYY"
            iconColor="primary"
          />
        </Grid>
      </Grid>
      <Content onResize={handleResize}>
        <Weekdays dates={visibleDates} locale={locale} />
        <DayTiles
          dates={visibleDates}
          locale={locale}
          onClick={onClick}
          calculateDayStyle={calculateDayStyle}
          checkIfDayIsDisabled={calculateDisabledDates}
        />
      </Content>
    </div>
  );
};

const calculateVisibleDates = (currentDate: CalendarDate, tileCount: number) => {
  const startDate = currentDate.subtract(Math.floor(tileCount / 2), 'd');
  const days = [];
  for (let i = 0; i < tileCount; i += 1) {
    days.push(startDate.add(i, 'd'));
  }
  return days;
};
