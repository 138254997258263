import { components, OptionProps } from 'react-select';
import { IconButton, makeStyles, SvgIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MouseEventHandler } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { ReactComponent as EditIcon } from './edit.svg';
import { SelectOption } from '../types';

export const ProfileSelectOption = (props: OptionProps<SelectOption, false>) => {
  const { label, isSelected, data } = props;
  const { t } = useTranslation();
  const classes = useStyle();
  const history = useHistory();

  const handleEdit: MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.stopPropagation();
    history.push(RoutesDefinition.editProfile.replace(':id', data.value.toString()));
  };
  return (
    <components.Option {...props}>
      <div className={classes.optionContent}>
        {t(isSelected ? 'profile.managment.selectProfile.selectedLabel' : 'profile.managment.selectProfile.label', {
          label
        })}
      </div>
      <IconButton className={classes.editButton} onClick={handleEdit}>
        <SvgIcon className={classes.editButtonIcon} viewBox="0 0 16 16" component={EditIcon} />
      </IconButton>
    </components.Option>
  );
};

const useStyle = makeStyles(() => ({
  optionContent: {
    flex: 1,
    borderRadius: 4,
    paddingLeft: 8,
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:focus, &:active, &:hover': {
      backgroundColor: '#fff9d380'
    }
  },
  editButton: {
    padding: 10
  },
  editButtonIcon: {
    fontSize: '0.8125rem'
  }
}));
