import { useQuery } from 'react-query';
import { fetchExperimentsForUser } from './fetchExperimentsForUser';
import { Experiments } from './types';

export const useExperimentsForUserQuery = (user_id?: number) => {
  const key = ['user_experiments', user_id];

  const result = useQuery<Experiments>({
    queryKey: key,
    queryFn: async () => fetchExperimentsForUser(user_id)
  });

  return { isLoading: result.isLoading, isError: result.isError, experiments: result.data };
};
