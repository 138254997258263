interface FormatAddressArgs {
  buildingNr: string;
  placeNr: string;
  street: string;
}

export const formatAddress = ({ buildingNr, placeNr, street }: FormatAddressArgs) => {
  const formattedAddress = [buildingNr, placeNr].filter((v) => v.length > 0).join('/');
  return `${street} ${formattedAddress}`;
};
