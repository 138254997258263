import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';

export const usePreventScrollingBodyWhenMenuIsOpen = (isOpen: boolean) => {
  const classes = useStyle();

  useEffect(() => {
    return () => {
      const rootDiv = document.getElementById('root');
      const body = document.getElementsByTagName('body');
      rootDiv?.classList.remove(classes.root);
      body[0]?.classList.remove(classes.body);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName('body');
    const rootDiv = document.getElementById('root');
    if (isOpen) {
      rootDiv?.classList.add(classes.root);
      body[0]?.classList.add(classes.body);
      window.scrollTo(0, 0);
    } else {
      rootDiv?.classList.remove(classes.root);
      body[0]?.classList.remove(classes.body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
};

const useStyle = makeStyles({
  body: {
    overflow: 'hidden'
  },
  root: {
    maxHeight: 'fill-available',
    overflow: 'hidden'
  }
});
