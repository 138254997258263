import { useMutation } from 'react-query';
import { setDefaultDeliveryPeriod } from 'features/addresses/api';
import { useUser } from 'features/user';
import { useNotifyDialog } from 'common/contexts';
import { TFuncKey } from 'react-i18next';
import { DeliveryPeriodOptions } from 'features/addresses/constants';

export const useSetDefaultDeliveryPeriod = () => {
  const { addresses } = useUser();
  const { showSuccessDialog, showErrorDialog } = useNotifyDialog();
  return useMutation({
    mutationFn: setDefaultDeliveryPeriod,
    onSuccess: (_, params) => {
      addresses.refetch();
      let message: TFuncKey;
      switch (params.period) {
        case DeliveryPeriodOptions.BUSINESS_DAYS:
          message = 'addressesPage.successes.setAsDefaultForBusinessDays';
          break;
        case DeliveryPeriodOptions.WEEKEND_DAYS:
          message = 'addressesPage.successes.setAsDefaultForWeekendDays';
          break;
        default:
          message = 'addressesPage.successes.setAsDefaultForAllDays';
      }
      showSuccessDialog({ message });
    },
    onError: () =>
      showErrorDialog({
        message: 'addressesPage.errors.setDefaultPeriodForDelivery'
      })
  });
};
