import { IconButton, makeStyles, PropTypes } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Header, HeaderProps } from '../Header';

export interface NavigationProps extends Omit<HeaderProps, 'leftSection' | 'rightSection'> {
  onPrevButtonClick: VoidFunction;
  onNextButtonClick: VoidFunction;
  disablePrevButton?: boolean;
  disableNextButton?: boolean;
  iconColor?: PropTypes.Color;
}

export const Navigation = ({
  onPrevButtonClick,
  onNextButtonClick,
  disablePrevButton,
  disableNextButton,
  iconColor,
  ...rest
}: NavigationProps): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <Header
      {...rest}
      leftSection={
        <IconButton
          aria-label={t('calendar.previousPeriod')}
          onClick={onPrevButtonClick}
          disabled={disablePrevButton}
          className={classes.button}
          color={iconColor}
        >
          <ChevronLeftIcon />
        </IconButton>
      }
      rightSection={
        <IconButton
          aria-label={t('calendar.nextPeriod')}
          onClick={onNextButtonClick}
          disabled={disableNextButton}
          className={classes.button}
          color={iconColor}
        >
          <ChevronRightIcon />
        </IconButton>
      }
    />
  );
};

const useStyle = makeStyles({
  button: {
    padding: '0.5rem'
  }
});
