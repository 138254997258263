import { ChangeEvent, ReactNode } from 'react';
import { makeStyles, FormHelperText } from '@material-ui/core';
import { useController, useFormContext } from 'react-hook-form';

import { CheckboxBase } from './CheckboxBase';

import { FormErrorMessage } from '../FormErrorMessage';

interface CheckboxProps {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  label?: ReactNode;
  checkedAlt: string;
  unCheckedAlt: string;
  className?: string;
}

export const Checkbox = ({ name, label, checkedAlt, unCheckedAlt, className = '', ...rest }: CheckboxProps) => {
  const classes = useStyle();

  const {
    formState: { errors }
  } = useFormContext();

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name
  });

  const handleChange = (_: ChangeEvent<Record<string, unknown>>, checked: boolean) => {
    onChange(checked);
  };

  return (
    <>
      <CheckboxBase
        checkedAlt={checkedAlt}
        unCheckedAlt={unCheckedAlt}
        error={!!error}
        value={value}
        onChange={handleChange}
        className={className}
        label={label}
        {...rest}
      />

      {!!error && (
        <FormHelperText error={!!error} className={classes.errorMessage}>
          <FormErrorMessage errors={errors} name={name} />
        </FormHelperText>
      )}
    </>
  );
};

const useStyle = makeStyles(() => ({
  errorMessage: {
    '&.MuiFormHelperText-root.Mui-error': {
      marginLeft: '2.5rem'
    }
  }
}));
