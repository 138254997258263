import { YEAR_MONTH_DAY } from 'common/constants';
import { Id } from 'common/types';
import dayjs from 'dayjs';
import { ApiClient } from 'features/apiProvider';
import { NewAddress } from '../types';

interface EditAddressesRequest extends ApiClient {
  data: NewAddress;
  id: Id;
  hasPlannedDeliveries: boolean;
}

export const editAddress = async ({
  data,
  id,
  apiClient,
  hasPlannedDeliveries
}: EditAddressesRequest): Promise<void> => {
  await apiClient.patch(`v3/addresses/${id}${hasPlannedDeliveries ? `/${dayjs().format(YEAR_MONTH_DAY)}/true` : ''}`, {
    json: data
  });
};
