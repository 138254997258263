import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { Paper } from 'common/components';
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

interface FormCardProps {
  children: ReactNode;
  title: string;
  className?: string;
}

export const FormCard = ({ children, title, className }: FormCardProps) => {
  const classes = useStyle();
  return (
    <>
      <Paper className={clsx(classes.paper, className)}>
        <Typography align="center" component="h1" variant="h1" className={classes.title}>
          {title}
        </Typography>
        {children}
      </Paper>
      <div className={classes.rodo}>
        <Trans i18nKey="rodo" />
      </div>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  paper: {
    borderRadius: '0.75rem',
    boxShadow: 'none',
    backgroundColor: theme.customColors.mainBg,
    padding: '3rem',
    marginBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      padding: '1rem'
    }
  },
  title: {
    marginBottom: '3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      lineHeight: '3.375rem'
    }
  },
  contenWrapper: {
    paddingTop: '6.25rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '5rem'
    }
  },
  rodo: {
    textAlign: 'justify',
    color: theme.customColors.textDarkGrey,
    fontSize: '0.75rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem'
    }
  }
}));
