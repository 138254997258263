import { ApiClient } from 'features/apiProvider';

import { AuthorizationResponse } from '../types';

interface LoginFacebookParams extends ApiClient {
  token: string;
}

export const loginFacebookRequest = async ({ token, apiClient }: LoginFacebookParams) => {
  const response = await apiClient
    .post('frontend/facebook', {
      json: { accessToken: JSON.stringify(token) }
    })
    .json();

  return AuthorizationResponse.parse(response);
};
