import { z } from 'zod';

import { ApiClient } from 'features/apiProvider';

import { dateTimeSchema, idSchema } from 'common/types';

const paymentStatusSchema = z.object({
  id: idSchema,
  systemValue: z.enum([
    'ORDER_NOT_PAID',
    'ORDER_PREPAID',
    'ORDER_PARTIAL_PAID',
    'ORDER_CANCELED',
    'ORDER_NOT_FINISHED',
    'ORDER_IN_CART',
    'ORDER_FINISHED'
  ]),
  value: z.string().nullable()
});

const bagDetailsSchema = z.object({
  firstDeliveryDate: z.string().nullable(),
  lastDeliveryDate: z.string().nullable(),
  count: z.number(),
  variant: z.object({ name: z.string() }).nullable(),
  package: z.object({ name: z.string() }).nullable()
});

const flowSchema = z
  .object({
    id: idSchema,
    value: z.string(),
    valueTranslation: z.object({}).nullable(),
    systemValue: z.enum(['CLASSIC', 'FLEXI', 'EXPRESS'])
  })
  .nullable();

const orderHistoryItemSchema = z.object({
  id: idSchema,
  dietName: z.string().nullable(),
  bagDetails: bagDetailsSchema,
  createdAt: dateTimeSchema,
  needFv: z.boolean(),
  active: z.boolean(),
  paymentDate: z.string().nullable(),
  isResign: z.boolean(),
  paymentStatus: paymentStatusSchema,
  waitingForBankWirePayment: z.boolean(),
  flow: flowSchema
});

const orderHistorySchema = z.object({
  page: z.number(),
  pageCount: z.number(),
  totalItemCount: z.number(),
  items: z.array(orderHistoryItemSchema)
});

export type OrderHistory = z.infer<typeof orderHistorySchema>;
export type OrderHistoryItem = z.infer<typeof orderHistoryItemSchema>;
export type PaymentStatus = z.infer<typeof paymentStatusSchema>;
interface FetchOrderHistoryRequestProps extends ApiClient {
  page: number;
  limit?: number;
}

export const fetchOrderHistory = async ({
  apiClient,
  page,
  limit = 10
}: FetchOrderHistoryRequestProps): Promise<OrderHistory> => {
  const response = await apiClient
    .get(`v4/secure/order/fetch-history`, {
      searchParams: {
        page,
        limit
      }
    })
    .json();

  return orderHistorySchema.parse(response);
};
