import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface BackButtonProps {
  label?: string;
}

/**
 * @deprecated
 */
export const BackButton = ({ label }: BackButtonProps) => {
  const classes = useStyle();
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <button className={classes.root} type="button" onClick={handleGoBack}>
      <ArrowBackIcon /> {label && <span className={classes.label}>{label}</span>}
    </button>
  );
};

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer'
  },
  label: {
    marginLeft: '0.5rem',
    fontWeight: 'bold',
    fontSize: '0.875rem',
    letterSpacing: '0.0125rem'
  }
}));
