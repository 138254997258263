import { Grid } from '@material-ui/core';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ImageUrl } from 'common/types';
import { useMobileScreenSizeDetector } from 'common/hooks';
import { FormRow } from 'common/components';

import { CreateDietFormData } from 'features/orders/types';
import { useDietConfiguration } from 'features/orders/contexts';

import { country } from 'config';
import { DietBox } from './DietBox';

interface DietSectionProps {
  onDietPreview: (dietVarinatId: number) => void;
}

interface DietVariantOption {
  id: number;
  name: string;
  title: string;
  image: ImageUrl;
}

export const DietSection = ({ onDietPreview }: DietSectionProps) => {
  const { t } = useTranslation();
  const isMobile = useMobileScreenSizeDetector();

  const {
    config: { variants, packages }
  } = useDietConfiguration();

  const {
    field: { onChange, value: selectedDietVariant }
  } = useController<CreateDietFormData, 'dietVariant'>({
    name: 'dietVariant'
  });

  const packageId = useWatch<CreateDietFormData, 'dietPackage'>({
    name: 'dietPackage'
  });

  const packageVariants = packages.get(packageId)?.variants || [];
  const getOptions = () => {
    const options: DietVariantOption[] = [];

    variants.forEach((variant) => {
      if (packageVariants.includes(variant.id)) {
        options.push({
          id: variant.id,
          name: variant.name,
          title: variant.summary,
          image: variant.image
        });
      }
    });
    return options;
  };

  const titleId = 'dietVariantTitle';

  return (
    <FormRow
      hidden={country === 'germany'}
      title={t('creatDietPage.dietSelectionSectionTitle')}
      subtitle={t('creatDietPage.dietSelectionSectionSubtitle')}
      TitleProps={{
        id: titleId
      }}
    >
      <Grid container spacing={isMobile ? 2 : 4} role="group" aria-labelledby={titleId}>
        {getOptions().map(({ name, image, title, id }) => {
          const handleOnChange = () => {
            onChange(id);
          };

          const handleMoreClick = () => {
            onDietPreview(id);
          };

          return (
            <DietBox
              id={id}
              key={id}
              image={image}
              description={title}
              title={name}
              onSelect={handleOnChange}
              available
              selected={id === selectedDietVariant}
              onMoreClick={handleMoreClick}
            />
          );
        })}
      </Grid>
    </FormRow>
  );
};
