import { Divider, IconButton, makeStyles, MenuItem, MenuList, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { Id } from 'common/types';
import { country } from 'config';
import { DeliveryPeriodOptions } from 'features/addresses/constants';
import { useAddressManagment } from 'pages/userPage/addresses/AddressesPage/contexts';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';

interface AddressMenuListProps {
  onClose: VoidFunction;
  name: string;
  id: Id;
}

export const AddressMenuList = ({ onClose, name, id }: AddressMenuListProps) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { onChangeDefaultDeliveryPeriod, onDeleteAddress } = useAddressManagment();

  const handleSetAsDefaultForWeekendDays = () => {
    onChangeDefaultDeliveryPeriod({ id, type: DeliveryPeriodOptions.WEEKEND_DAYS });
    onClose();
  };
  const handleSetAsDefaultForBussinesDays = () => {
    onChangeDefaultDeliveryPeriod({ id, type: DeliveryPeriodOptions.BUSINESS_DAYS });
    onClose();
  };
  const handleSetAsDefaultForAllDays = () => {
    onChangeDefaultDeliveryPeriod({ id, type: DeliveryPeriodOptions.ALL_DAYS });
    onClose();
  };
  const handleDeleteAddress = () => {
    onDeleteAddress(id);
    onClose();
  };
  return (
    <>
      <div className={classes.header}>
        <Typography variant="body2" className={classes.name} noWrap>
          {name}
        </Typography>
        <IconButton onClick={onClose} className={classes.icon} aria-label={t('addressesPage.addressCard.menu.close')}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <MenuList className={classes.menu}>
        <MenuItem className={classes.menuItem} onClick={handleSetAsDefaultForAllDays}>
          {t('addressesPage.addressCard.menu.setAsDefaultForAllDays')}
        </MenuItem>
        {['poland', 'czech'].includes(country) && (
          <>
            <MenuItem className={classes.menuItem} onClick={handleSetAsDefaultForBussinesDays}>
              {t('addressesPage.addressCard.menu.setAsDefaultForBussinesDays')}
            </MenuItem>
            <MenuItem className={classes.menuItem} onClick={handleSetAsDefaultForWeekendDays}>
              {t('addressesPage.addressCard.menu.setAsDefaultForWeekendDays')}
            </MenuItem>
          </>
        )}
        <li>
          <MenuItem
            component={RouterLink}
            to={RoutesDefinition.editAdresses.replace(':id', id.toString())}
            className={clsx(classes.menuItem, classes.edit)}
          >
            {t('addressesPage.addressCard.menu.edit')}
          </MenuItem>
        </li>
        <MenuItem className={clsx(classes.menuItem, classes.deleteMenuItem)} onClick={handleDeleteAddress}>
          {t('addressesPage.addressCard.menu.delete')}
        </MenuItem>
      </MenuList>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    marginBottom: theme.spacing(3),
    padding: '0 1rem'
  },
  icon: {
    padding: '0.5rem',
    color: theme.customColors.black
  },
  name: {
    flex: 1,
    fontWeight: 'bold'
  },
  menu: {
    padding: '0.5rem 0 1rem 0'
  },
  menuItem: {
    fontSize: '0.875rem',
    padding: '0.5rem 1rem',
    whiteSpace: 'initial',
    color: theme.customColors.black,
    '&:hover': {
      backgroundColor: 'transparent',
      fontWeight: 'bold'
    }
  },
  edit: {
    color: theme.customColors.black,
    '&:hover': {
      textDecoration: 'none'
    }
  },
  deleteMenuItem: {
    color: theme.customColors.red
  }
}));
