import { TFunction } from 'react-i18next';
import { OrderHistoryItem } from 'services/orderHistory/fetchOrderHistory';

export const getSelectedDietName = (item: OrderHistoryItem, t: TFunction) => {
  if (item.flow?.systemValue === 'EXPRESS') {
    return 'SELECT';
  }
  if (item.bagDetails.package?.name || item.bagDetails.variant?.name) {
    return `${item.bagDetails.package?.name || ''} ${item.bagDetails.variant?.name || ''}`;
  }
  return t('orderHistoryPage.item.dietUnknown');
};
