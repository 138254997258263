import React, { forwardRef, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Typography } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import { useTranslation } from 'react-i18next';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';

interface ErrorSnackbarProps extends CustomContentProps {}

export const ErrorSnackbar = forwardRef<HTMLDivElement, ErrorSnackbarProps>(({ id, message }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const classes = useStyles();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent className={classes.wrapper} ref={ref} role="alert">
      <Typography>{message}</Typography>
      <Button onClick={handleDismiss} className={classes.retryButton}>
        {t('snackbarError.retryButton')}
        <LoopIcon />
      </Button>
    </SnackbarContent>
  );
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.customColors.red,
    borderRadius: '0.325rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.customColors.white,
    padding: '0.5rem 1rem',

    '& > p ': {
      fontSize: '1rem'
    }
  },
  retryButton: {
    textTransform: 'none',
    fontSize: '1rem',
    color: theme.customColors.white,
    fontWeight: 'bold',
    padding: 0,
    '& svg': {
      marginLeft: '0.5rem',
      fontWeight: 'normal'
    }
  }
}));
