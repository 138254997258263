import { makeStyles } from '@material-ui/core';

export const useButtonStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    padding: '0.563rem',
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 'normal',
    minWidth: 'unset',
    borderWidth: '0.125rem',
    borderStyle: 'solid',

    '&.Mui-disabled': {
      borderWidth: '0.125rem'
    }
  },
  primary: {
    backgroundColor: theme.customColors.button.primary.background,
    borderColor: theme.customColors.button.primary.borderColor,
    color: theme.customColors.button.primary.color,

    '&:hover': {
      backgroundColor: theme.palette.primary.main
    },

    '&.Mui-disabled': {
      backgroundColor: `${theme.customColors.button.primary.disabled.background} !important`,
      borderColor: theme.customColors.button.primary.disabled.borderColor,
      color: theme.customColors.button.primary.disabled.color
    }
  },
  secondary: {
    backgroundColor: theme.customColors.button.secondary.background,
    borderColor: theme.customColors.button.secondary.borderColor,
    color: theme.customColors.button.secondary.color,

    '&:hover': {
      backgroundColor: theme.customColors.white
    },

    '&.Mui-disabled': {
      backgroundColor: theme.customColors.button.secondary.disabled.background,
      borderColor: theme.customColors.button.secondary.disabled.borderColor,
      color: theme.customColors.button.secondary.disabled.color
    }
  }
}));
