import { impersonatorTokenKey, refreshSessionKey, sessionKey } from '../constants';

export const removeSessionToken = () => {
  localStorage.removeItem(sessionKey);
  sessionStorage.removeItem(sessionKey);
  localStorage.removeItem(refreshSessionKey);
  localStorage.removeItem(impersonatorTokenKey);
};

export const getSessionDetails = () => {
  const tokenFromSessionStorage = sessionStorage.getItem(sessionKey);
  const impersonatorToken = localStorage.getItem(impersonatorTokenKey);
  return {
    token: tokenFromSessionStorage || localStorage.getItem(sessionKey),
    rememberMe: !tokenFromSessionStorage,
    refreshToken: localStorage.getItem(refreshSessionKey),
    impersonatorToken: impersonatorToken !== null ? impersonatorToken : undefined
  };
};
