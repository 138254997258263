import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { GridCalendar, CheckIfDayIsDisabledHandler, CalculateDayStyleHandler } from 'common/components';
import { CalendarDate } from 'common/types';
import { country } from 'config';
import { localeCode } from 'config/constants/localeCode';
import { maxDaysIntervalToStartOrder } from 'features/orders';
import { useDietConfiguration } from 'features/orders/contexts';
import { CreateDietFormData } from 'features/orders/types';
import { calculatePredefinedDateRange, getDietLenghtOptions } from 'features/orders/utils';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useController, useWatch } from 'react-hook-form';

interface ContinuousDeliveryDatesCalendarProps {
  currentVisibleMonth: CalendarDate;
  onMonthChange: (newMonth: CalendarDate) => void;
}

export const ContinuousDeliveryDatesCalendar = ({
  currentVisibleMonth,
  onMonthChange
}: ContinuousDeliveryDatesCalendarProps) => {
  const classes = useStyle();

  const {
    deliveryTimeConfig: { firstDeliveryAt, disabledDays },
    daysWithDeliveries
  } = useDietConfiguration();

  const {
    field: { value, onChange }
  } = useController<CreateDietFormData, 'continuousFirstDeliveryDate'>({
    name: 'continuousFirstDeliveryDate'
  });

  const withoutWeekends = useWatch<CreateDietFormData, 'withoutWeekends'>({
    name: 'withoutWeekends'
  });

  const dietLengthId = useWatch<CreateDietFormData, 'dietLength'>({
    name: 'dietLength'
  });

  const selectedDietLengthOption = useMemo(
    () => getDietLenghtOptions(withoutWeekends).find((o) => o.id === dietLengthId),
    [dietLengthId, withoutWeekends]
  );

  const [selectedDays, setSelectedDays] = useState<CalendarDate[]>([]);

  const maxDate = useMemo(() => firstDeliveryAt.add(maxDaysIntervalToStartOrder, 'd'), [firstDeliveryAt]);
  const checkIfDayIsDisabled = useCallback(
    (day: CalendarDate) => {
      const isDisabledByAPI = disabledDays.some((disabledDay) => disabledDay.isSame(day, 'd'));
      if (country === 'germany') {
        return isDisabledByAPI || day.isWeekend();
      }
      return isDisabledByAPI;
    },
    [disabledDays]
  );
  useEffect(() => {
    if (selectedDietLengthOption?.type === 'continuous') {
      const calculatedDates = calculatePredefinedDateRange({
        firstDate: value,
        length: selectedDietLengthOption?.days,
        disabledDays,
        maxDate,
        withoutWeekends
      });
      setSelectedDays(calculatedDates);

      if (!calculatedDates[0].isSame(value)) {
        onChange(calculatedDates[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dietLengthId, disabledDays, maxDate, selectedDietLengthOption, value, withoutWeekends]);

  const calculatedDisabledDates: CheckIfDayIsDisabledHandler = useCallback(
    (day) => {
      const isDisabledDate = checkIfDayIsDisabled(day);
      if (isDisabledDate) {
        return true;
      }
      return withoutWeekends ? day.isWeekend() : false;
    },
    [withoutWeekends, checkIfDayIsDisabled]
  );

  const calculateDayStyle: CalculateDayStyleHandler = (day) => {
    const isDisabledDate = checkIfDayIsDisabled(day);
    const isDayWithDelivery = daysWithDeliveries.some((dayWithDelivery) => dayWithDelivery.isSame(day, 'd'));
    if (isDisabledDate) {
      return {};
    }
    if (selectedDays[0]?.isSame(day, 'd')) {
      return {
        button: classes.firstDeliveryDateButton,
        label: isDayWithDelivery ? clsx(classes.dayWithDelivery, classes.inversedDayWithDelivery) : undefined
      };
    }
    if (selectedDays.some((v) => v.isSame(day, 'd'))) {
      return {
        button: classes.dateInRangeButton,
        label: isDayWithDelivery ? clsx(classes.dayWithDelivery, classes.inversedDayWithDelivery) : undefined
      };
    }
    return {
      label: isDayWithDelivery ? classes.dayWithDelivery : undefined
    };
  };

  return (
    <GridCalendar
      minDate={firstDeliveryAt}
      maxDate={maxDate}
      visibleDate={currentVisibleMonth}
      onIntervalChange={onMonthChange}
      locale={localeCode}
      calculateDisabledDates={calculatedDisabledDates}
      calculateDayStyle={calculateDayStyle}
      onClick={onChange}
    />
  );
};

const useStyle = makeStyles((theme) => ({
  firstDeliveryDateButton: {
    backgroundColor: theme.customColors.deliveryCalendar.firstDeliveryDate.background
  },
  dateInRangeButton: {
    backgroundColor: theme.customColors.deliveryCalendar.inDateRangeDate.background
  },
  dayWithDelivery: {
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 12,
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundColor: '#FFE123'
    }
  },
  inversedDayWithDelivery: {
    '&:after': {
      backgroundColor: theme.customColors.black
    }
  }
}));
