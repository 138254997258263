import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export const sessionKey = 'payment-status';

interface OrderPaymentStatusContextShape {
  start: VoidFunction;
  end: VoidFunction;
  isProcessingAnotherOrder: boolean;
}

interface OrderPaymentStatusProviderProps {
  children: React.ReactNode;
}

const OrderPaymentStatusContext = createContext<OrderPaymentStatusContextShape | undefined>(undefined);

/**
 * The provider that monitors the payment process in all tabs, because sending many requests of order at once
 * sums them to one order. Thanks to that we can dispaly error warning when such situation appears.
 * It does not protect against make simulant requests in the different browsers.
 */
export const OrderPaymentStatusProvider = ({ children }: OrderPaymentStatusProviderProps): JSX.Element => {
  const [isProcessingAnotherOrder, setIsProcessingAnotherOrder] = useState<boolean>(() => {
    return localStorage.getItem(sessionKey) === 'true';
  });

  const start = useCallback(() => {
    localStorage.setItem(sessionKey, 'true');
  }, []);

  const end = useCallback(() => {
    localStorage.removeItem(sessionKey);
  }, []);

  const handleStorageChange = (ev: StorageEvent) => {
    if (ev.key !== sessionKey) {
      return;
    }
    setIsProcessingAnotherOrder(ev.newValue === 'true');
  };

  useEffect(() => {
    const isOnIOS = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? 'pagehide' : 'beforeunload';

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener(eventName, end);
    return () => {
      localStorage.removeItem(sessionKey);
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener(eventName, end);
    };
  }, [end]);

  const value = useMemo(
    () => ({
      start,
      end,
      isProcessingAnotherOrder
    }),
    [end, isProcessingAnotherOrder, start]
  );
  return <OrderPaymentStatusContext.Provider value={value}>{children}</OrderPaymentStatusContext.Provider>;
};

export const useOrderPaymentStatus = () => {
  const context = useContext(OrderPaymentStatusContext);

  if (context === undefined) {
    throw new Error('useOrderPaymentStatus must be used within a PaymentStatusContext');
  }
  return context;
};
