import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import hexToRgba from 'hex-to-rgba';

interface ThemeProviderProps {
  children: React.ReactNode;
}

const lightPeriwinkle = '#dadde3';
const yellow = '#fee123';
const brightYellow = '#fff5c6';
const darkGrey = '#706f7a';
const brightGrey = '#b8b7bd';
const whisperGrey = '#e9e9e9';
const dimGrey = '#6d6d6d';
const white = '#ffffff';
const black = '#000000';
const brightRed = '#f44336';
const sunYellow = '#fee122';
const eggShell = '#fff5c6';
const backgroundWhite = '#f6f6f6';
const greyishPurple = '#7b7a86';
const green = '#04ae30';
const orange = '#e58c1a';
const orangeLight = '#ffae00';
const red = '#db2525';

export const customThemeParts = {
  customColors: {
    mainBg: backgroundWhite,
    textDarkGrey: darkGrey,
    textLightGrey: lightPeriwinkle,
    white,
    black,
    orangeLight,
    green,
    red,
    whisperGrey,
    dimGrey,
    yellow,
    errors: brightRed,
    calendarBrightYellow: brightYellow,
    grey: '#706f7c',
    button: {
      primary: {
        borderColor: yellow,
        color: black,
        background: yellow,

        disabled: {
          background: brightYellow,
          borderColor: brightYellow,
          color: darkGrey
        }
      },
      secondary: {
        borderColor: darkGrey,
        color: darkGrey,
        background: white,

        disabled: {
          background: white,
          borderColor: brightGrey,
          color: brightGrey
        }
      }
    },
    calendar: {
      borderColor: lightPeriwinkle,
      selectedDaysBorderColor: sunYellow,
      resetCalendarColor: darkGrey
    },
    status: {
      green,
      grey: darkGrey,
      orange
    },
    rowCalendar: {
      fadeOut: {
        background: {
          from: 'rgba(238, 238, 238, 0)',
          to: backgroundWhite
        },
        width: '5.0625rem'
      }
    },
    dietCalendar: {
      legend: {
        color: greyishPurple
      },
      rate: {
        background: backgroundWhite
      },
      configurationAvailable: {
        background: sunYellow
      },
      menuNotReady: {
        background: eggShell
      },
      configurationNotAvailable: {
        background: lightPeriwinkle
      },
      deliveryDisabled: {
        color: lightPeriwinkle
      }
    },
    deliveryCalendar: {
      firstDeliveryDate: {
        background: sunYellow
      },
      inDateRangeDate: {
        background: eggShell
      },
      selectedDate: {
        background: sunYellow
      }
    },
    changeDeliveryDateCalendar: {
      selectedDate: {
        background: sunYellow
      }
    },
    calorificsSlider: {
      individualBarBackground: yellow
    },
    rating: {
      emptyIcon: darkGrey,
      icon: yellow,
      comment: {
        borderColor: '#7f7f7f'
      }
    }
  },
  dialog: {
    backdrop: {
      background: hexToRgba(lightPeriwinkle, 0.4)
    },
    footer: {
      boxShadow: '0 0.125rem 0.375rem 0 rgba(175, 175, 187, 0.2)'
    }
  },
  orderHistory: {
    status: {
      active: green,
      inactive: darkGrey,
      cancelled: red
    }
  },
  orderSummaryBox: {
    color: dimGrey
  },
  filters: {
    black:
      'brightness(0) saturate(100%) invert(0%) sepia(97%) saturate(13%) hue-rotate(122deg) brightness(101%) contrast(102%)',
    ligthGrey:
      'brightness(0) saturate(100%) invert(88%) sepia(7%) saturate(146%) hue-rotate(182deg) brightness(102%) contrast(89%)',
    primaryMain:
      'brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(7494%) hue-rotate(333deg) brightness(103%) contrast(99%)',
    darkGrey:
      'brightness(0) saturate(100%) invert(47%) sepia(6%) saturate(545%) hue-rotate(206deg) brightness(91%) contrast(87%)',
    white:
      'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(2%) hue-rotate(278deg) brightness(105%) contrast(101%)',
    red: 'brightness(0) saturate(100%) invert(45%) sepia(86%) saturate(6073%) hue-rotate(347deg) brightness(86%) contrast(96%)'
  },
  boxShadow: {
    lightGrey: `0 0.125rem 0.375rem 0 ${lightPeriwinkle}`
  }
};

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 600,
    md: 920,
    lg: 1440
  }
});

export const themeDefinition = createMuiTheme({
  palette: {
    primary: {
      main: yellow
    },
    secondary: {
      main: black
    }
  },
  breakpoints,
  typography: {
    fontFamily: ['Helvetica', '"Open Sans"', 'sans-serif'].join(',')
  },
  spacing: (factor) => `${0.5 * factor}rem`,
  zIndex: {
    modal: 999999
  },
  overrides: {
    MuiTypography: {
      colorSecondary: {
        color: dimGrey
      },
      gutterBottom: {
        marginBottom: '0.5rem'
      },
      h1: {
        fontSize: '2.5rem',
        lineHeight: '3.375rem',
        fontWeight: 'bold',
        color: black,
        [breakpoints.down('sm')]: {
          fontSize: '1.75rem',
          lineHeight: '2.375rem'
        }
      },
      h2: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
        fontWeight: 'bold'
      },
      h3: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
        fontWeight: 'bold',
        [breakpoints.down('sm')]: {
          fontSize: '0.875rem'
        }
      },
      h4: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontWeight: 'bold',
        [breakpoints.down('sm')]: {
          fontSize: '0.75rem'
        }
      },
      h5: {
        fontSize: '0.875rem',
        lineHeight: '1.11rem',
        fontWeight: 'bold'
      },
      body1: {
        fontSize: '0.875rem',
        lineHeight: '1.3125rem',
        [breakpoints.down('sm')]: {
          fontSize: '0.75rem'
        }
      },
      body2: {
        fontSize: '1rem',
        lineHeight: '1rem'
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 'normal',
        lineHeight: '1.5rem',
        [breakpoints.down('sm')]: {
          fontSize: '0.875rem',
          lineHeight: '1.3125rem'
        }
      },
      subtitle2: {
        fontSize: '1.125rem',
        fontWeight: 'normal',
        lineHeight: '1.5rem',
        whiteSpace: 'pre-line'
      }
    },
    MuiSelect: {
      root: {
        padding: '0.625rem 1rem',
        fontSize: '0.875rem'
      },
      select: {
        '&:focus': {
          backgroundColor: customThemeParts.customColors.white
        }
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: customThemeParts.customColors.white,
        borderRadius: '0.25rem',
        boxShadow: `0 0.125rem 0.375rem 0 ${customThemeParts.customColors.textLightGrey}`,

        '&.Mui-focused': {
          backgroundColor: customThemeParts.customColors.white
        },
        '&:hover': {
          backgroundColor: customThemeParts.customColors.white
        },
        '&:before': {
          display: 'none'
        },
        '&:after': {
          display: 'none'
        }
      }
    },
    MuiCard: {
      root: {
        boxShadow: `0 2px 6px 0 ${lightPeriwinkle}`
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: lightPeriwinkle
      }
    },
    MuiFormHelperText: {
      root: {
        '&$error': {
          marginLeft: 0,
          color: red
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: hexToRgba(lightPeriwinkle, 0.4)
      }
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: black
        },
        '#root': {
          background: backgroundWhite
        }
      }
    },
    MuiStepper: {
      root: {
        background: 'transparent',
        padding: 0
      }
    },
    MuiStep: {
      root: {
        [breakpoints.down('xs')]: {
          flex: 1
        }
      },
      horizontal: {
        '&:first-child': {
          paddingLeft: 0
        },
        '&:last-child': {
          paddingRight: 0
        }
      }
    },
    MuiStepLabel: {
      label: {
        color: darkGrey,
        lineHeight: '1.25rem',
        fontSize: '0.875rem'
      },
      active: {
        color: darkGrey
      },
      completed: {
        color: darkGrey
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '0.875rem',
        fontWeight: 'bold',
        color: black,
        letterSpacing: '0.0125rem',
        '&$focused': {
          color: black
        },
        '&$disabled': {
          color: black
        },
        '&$error': {
          color: black
        }
      },
      shrink: {
        transform: 'none'
      },
      formControl: {
        position: 'static'
      }
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: '0.5rem'
        }
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: white,
        boxShadow: `0 0.125rem 0.375rem 0 ${lightPeriwinkle}`,
        borderRadius: '0.25rem',
        '&$disabled': {
          boxShadow: 'none'
        },
        '&$error': {
          border: `1px solid ${red}`
        }
      },
      input: {
        padding: '0.625rem 1rem',
        '&::placeholder': {
          color: darkGrey,
          opacity: 1
        },
        '&$disabled': {
          opacity: 1,
          color: darkGrey
        },
        '&:read-only': {
          opacity: 1,
          color: darkGrey
        }
      },
      multiline: {
        padding: '0.625rem 1rem'
      },
      adornedEnd: {
        paddingRight: '0.5rem'
      },
      adornedStart: {
        paddingLeft: '0.5rem'
      },
      inputAdornedStart: {
        paddingLeft: '0.5rem'
      },
      inputAdornedEnd: {
        paddingRight: '0.5rem'
      }
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '72rem',
        margin: '0 auto'
      }
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: '50rem'
      }
    }
  },
  ...customThemeParts
});

export type Theme = typeof themeDefinition;

export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <MuiThemeProvider theme={themeDefinition}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
);
