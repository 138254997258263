import { PaymentStatus } from 'services/orderHistory/fetchOrderHistory';

export const getStatus = (
  paymentStatus: PaymentStatus
): 'active' | 'inactive' | 'cancelled' | 'partial' | undefined => {
  const paymentStatusForActiveStatus = ['ORDER_PAID', 'ORDER_PREPAID', 'ORDER_REALIZED'];
  const paymentStatusForInactiveStatus = ['ORDER_NOT_PAID', 'ORDER_NOT_FINISHED', 'ORDER_IN_CART', 'ORDER_FINISHED'];
  const paymentStatusForPartialPaid = ['ORDER_PARTIAL_PAID'];
  const paymentStatusForCancelledStatus = ['ORDER_CANCELED'];
  if (paymentStatusForPartialPaid.includes(paymentStatus.systemValue)) {
    return 'partial';
  }
  if (paymentStatusForActiveStatus.includes(paymentStatus.systemValue)) {
    return 'active';
  }
  if (paymentStatusForInactiveStatus.includes(paymentStatus.systemValue)) {
    return 'inactive';
  }
  if (paymentStatusForCancelledStatus.includes(paymentStatus.systemValue)) {
    return 'cancelled';
  }
  return undefined;
};
