import { Backdrop, CircularProgress, makeStyles, Theme } from '@material-ui/core';

interface FullScreenLoaderProps {
  open: boolean;
}

export const FullScreenLoader = ({ open }: FullScreenLoaderProps) => {
  const loaderSize = 100;
  const classes = useStyle({ loaderSize });
  return (
    <Backdrop open={open} className={classes.root}>
      <CircularProgress size={loaderSize} data-testid="page-loader" className={classes.progress} />
    </Backdrop>
  );
};

const useStyle = makeStyles<Theme, { loaderSize: number }>((theme) => ({
  root: {
    zIndex: 10,
    background: theme.customColors.white,
    position: 'absolute'
  },
  progress: ({ loaderSize }) => ({
    position: 'fixed',
    top: window.innerHeight / 2 - loaderSize / 2
  })
}));
