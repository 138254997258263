import { useFormContext } from 'react-hook-form';
import { useProfiles } from 'features/profiles';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { useNotifyDialog } from 'common/contexts';
import { setPurchaseDataInLocalStorage } from 'common/utils/gtm/sendPurchaseGAEvent';
import { useUser } from 'features/user';
import { CreateDietFormData } from '../types';
import { useDietConfiguration, useOrderPaymentStatus, useOrderPrice } from '../contexts';
import { createOrderRequestData } from '../api';
import { maxDaysIntervalToStartOrder } from '../constants';
import { getCalendarType, getDietLength, getFirstDeliveryDateForCustomDeliveryDates } from '../utils';
import { useOrderPaymentProcess } from './useOrderPaymentProcess';

export const usePaymentOrder = () => {
  const { getValues } = useFormContext<CreateDietFormData>();
  const {
    mealPrices,
    sizesData,
    deliveryTimeConfig: { disabledDays, firstDeliveryAt },
    config: { packages, variants, baskets },
    standardDeliveryAddress,
    festiveDeliveryAddress
  } = useDietConfiguration();
  const {
    profile: { hasPreviousOrders }
  } = useUser();
  const { isProcessingAnotherOrder, start, end } = useOrderPaymentStatus();
  const { selectedProfileId } = useProfiles();
  const { totalPrice, discounts, delivery } = useOrderPrice();
  const { showErrorDialog } = useNotifyDialog();
  const { isLoading, payForOrder: payForOrderMutation } = useOrderPaymentProcess();

  const continueUrlGetter = (orderId: number) => {
    return RoutesDefinition.paymentOrderThanks.replace(':orderId', String(orderId));
  };

  const payForOrder = () => {
    const orderValues = getValues();

    if (standardDeliveryAddress === undefined || festiveDeliveryAddress === undefined) {
      showErrorDialog({ message: 'mealsAddingPage.errors.noDeliveryAddresses' });
      return;
    }
    if (isProcessingAnotherOrder) {
      showErrorDialog({ message: 'mealsAddingPage.errors.startPaymentOnAntoherTab' });
      return;
    }
    start();
    const orderData = createOrderRequestData({
      sizesData,
      selectedMeals: orderValues.meals,
      variantId: orderValues.dietVariant,
      packageId: orderValues.dietPackage,
      name: orderValues.dietName,
      basketId: orderValues.basket,
      customDeliveryDates: orderValues.customDeliveryDates,
      dietLengthId: orderValues.dietLength,
      firstDeliveryDate: orderValues.continuousFirstDeliveryDate,
      withoutWeekends: orderValues.withoutWeekends,
      standardDeliveryAddress,
      festiveDeliveryAddress,
      mealMode: orderValues.mealMode,
      selectedDietID: selectedProfileId,
      disabledDays,
      maxDate: firstDeliveryAt.add(maxDaysIntervalToStartOrder, 'd')
    });

    const selectedMeals = orderValues.meals;
    const selectedPackage = packages.get(orderValues.dietPackage);
    const selectedVariant = variants.get(orderValues.dietVariant);
    const selectedBasket = baskets.get(orderValues.basket);
    const discountName = orderValues.discountCode?.name;

    setPurchaseDataInLocalStorage({
      mealPrices,
      sizesData,
      discountCode: discountName ? { name: discountName, value: discounts.code } : undefined,
      moneybox: discounts.moneybox,
      firstOrder: hasPreviousOrders ? 0 : 1,
      selectedMeals,
      selectedPackage,
      selectedVariant,
      selectedBasket,
      mealMode: orderValues.mealMode,
      transactionValue: totalPrice,
      deliveryPrice: delivery.afterDiscount,
      dietLength: getDietLength({
        dietLengthId: orderValues.dietLength,
        withoutWeekends: orderValues.withoutWeekends,
        customDeliveryDates: orderValues.customDeliveryDates,
        disabledDays,
        firstDeliveryAt,
        firstDeliveryDate: orderValues.continuousFirstDeliveryDate
      }),
      firstDelivery:
        getCalendarType({ withoutWeekends: orderValues.withoutWeekends, dietLengthId: orderValues.dietLength }) !==
        'custom'
          ? orderValues.continuousFirstDeliveryDate
          : getFirstDeliveryDateForCustomDeliveryDates(orderValues.customDeliveryDates)
    });

    payForOrderMutation({
      orderData,
      paymentType: orderValues.paymentType,
      continueUrlGetter,
      onOrderCreationFail: () => {
        showErrorDialog({
          message: 'mealsAddingPage.errors.orderCreateError'
        });
        end();
      }
    });
  };

  return { payForOrder, isLoading };
};
