import { Container, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { FullScreenLoader } from './FullScreenLoader';

export interface BaseLayoutProps {
  children?: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
  isLoading?: boolean;
  fullHeight?: boolean;
  className?: string;
}

export const BaseLayout = ({
  children,
  header,
  footer,
  isLoading = false,
  fullHeight = false,
  className
}: BaseLayoutProps) => {
  const classes = useStyle({ fullHeight });
  return (
    <Container disableGutters maxWidth={false} className={clsx(classes.root, className)}>
      {header && <div className={classes.header}>{header}</div>}
      <main className={classes.main}>
        {isLoading && <FullScreenLoader open />}
        {children}
      </main>
      {footer && <footer className={classes.footer}>{footer}</footer>}
    </Container>
  );
};

const useStyle = makeStyles<Theme, { fullHeight: boolean }>(() => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column'
  },
  main: {
    flex: 1,
    position: 'relative'
  },
  header: {
    position: ({ fullHeight }) => `${fullHeight ? 'sticky' : 'static'}`,
    top: 0,
    zIndex: 24
  },
  footer: {
    position: ({ fullHeight }) => `${fullHeight ? 'sticky' : 'static'}`,
    bottom: 0,
    zIndex: 23
  }
}));
