import { useTranslation } from 'react-i18next';
import { ButtonBase, makeStyles, Typography } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

interface CustomDeliveryFooterProps {
  onCalendarReset: VoidFunction;
  selectedDaysNumber: number;
}

export const CustomDeliveryCalendarFooter = ({
  onCalendarReset,
  selectedDaysNumber
}: CustomDeliveryFooterProps): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();
  const showResetButton = selectedDaysNumber > 0;
  return (
    <div className={classes.wrapper} arial-label="calendar-footer-wrapper">
      {selectedDaysNumber !== undefined && (
        <div className={classes.selectedDaysWrapper}>
          <Typography variant="h6">{selectedDaysNumber}</Typography>
        </div>
      )}
      {showResetButton && (
        <ButtonBase className={classes.resetCalendar} onClick={onCalendarReset} arial-label="clear-button">
          {t('calendar.resetCalendar')} <ClearRoundedIcon />
        </ButtonBase>
      )}
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  selectedDaysWrapper: {
    border: `0.125rem solid ${theme.customColors.calendar.selectedDaysBorderColor}`,
    borderRadius: '0.25rem',
    width: '2.5rem',
    height: '2.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  resetCalendar: {
    marginLeft: 'auto',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: 1.29,
    letterSpacing: '0.013rem',
    color: theme.customColors.calendar.resetCalendarColor,

    '& > svg': {
      margin: '0 0.5rem 0 1rem'
    }
  }
}));
