import { createContext, ReactNode, useContext } from 'react';
import ky, { NormalizedOptions, Options } from 'ky';
import { KyInstance } from 'ky/distribution/types/ky';

import { useSession } from 'features/authentication';
import { KyHeadersInit } from 'ky/distribution/types/options';

interface ApiContextProviderProps {
  children: ReactNode;
}

type ApiClientGetter = (default401Handler?: boolean) => KyInstance;

interface ApiContextShape {
  getApiClient: ApiClientGetter;
}

const ApiContext = createContext<ApiContextShape | undefined>(undefined);

export const ApiContextProvider = ({ children }: ApiContextProviderProps): JSX.Element => {
  const { token, logout, impersonatorToken, isImpersonated } = useSession();

  const unauthorizedHandler = async (request: Request, options: NormalizedOptions, response: Response) => {
    if (response.status === 401) {
      logout();
    }
  };

  const getApiClient: ApiClientGetter = (default401Handler = true) => {
    const config: Options = {
      prefixUrl: process.env.REACT_APP_API_URI as string,
      timeout: 60000,
      hooks: {
        afterResponse: default401Handler ? [unauthorizedHandler] : []
      }
    };
    const headers: KyHeadersInit = {
      'X-BRAND': process.env.REACT_APP_BRAND_ID
    };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    if (isImpersonated()) {
      headers['X-IMPERSONATOR'] = impersonatorToken as string;
    }
    config.headers = headers;

    return ky.create(config);
  };

  const contextValue = {
    getApiClient
  };

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const userCtx = useContext(ApiContext);
  if (userCtx === undefined) {
    throw new Error('useApi must be used within a ApiProvider');
  }
  return userCtx;
};
