import { TFuncKey, useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useNavigationElementsStyle } from 'common/styles/navigationElementsStyle';

interface NavigationButtonProps {
  label: string;
  actionIcon?: string;
  icon?: string;
  onClick: () => void;
  wrapperClassName?: string;
}

export function NavigationButton({
  label,
  actionIcon,
  icon,
  onClick,
  wrapperClassName
}: NavigationButtonProps): JSX.Element {
  const classes = useNavigationElementsStyle();

  const { t, i18n } = useTranslation();

  const translatedLabel = i18n.exists(label) ? t(label as TFuncKey) : label;

  return (
    <button type="button" onClick={onClick} className={clsx(classes.basicLink, wrapperClassName)}>
      <>
        {icon && <img className={classes.menuElementIcon} src={icon} alt="Ikona elementu menu" />}
        {translatedLabel}
        {actionIcon && <img src={actionIcon} alt="Przejdź do menu" className={classes.actionIcon} />}
      </>
    </button>
  );
}
