import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';
import { forwardRef } from 'react';
import { MenuState } from './types';

interface MobileMenuButtonProps {
  status: MenuState;
  onClick: (status: MenuState) => void;
}

interface ButtonProps {
  onClick: (status: MenuState) => void;
}

const OpenMenuButton = forwardRef<HTMLButtonElement, ButtonProps>(({ onClick }, ref): JSX.Element => {
  const { t } = useTranslation();
  const handleClick = () => onClick('main-menu');
  return (
    <IconButton ref={ref} aria-label={t('menu')} onClick={handleClick} color="inherit">
      <MenuIcon />
    </IconButton>
  );
});

const CloseMenuButton = forwardRef<HTMLButtonElement, ButtonProps>(({ onClick }, ref): JSX.Element => {
  const { t } = useTranslation();
  const handleClick = () => onClick('hidden');
  return (
    <IconButton ref={ref} aria-label={t('close')} onClick={handleClick} color="inherit">
      <CloseIcon />
    </IconButton>
  );
});

const BackButton = forwardRef<HTMLButtonElement, ButtonProps>(({ onClick }, ref): JSX.Element => {
  const { t } = useTranslation();
  const handleClick = () => onClick('main-menu');
  return (
    <IconButton ref={ref} aria-label={t('backButton')} onClick={handleClick} color="inherit">
      <ArrowBackIcon />
    </IconButton>
  );
});

export const MobileMenuButton = forwardRef<HTMLDivElement, MobileMenuButtonProps>(({ onClick, status }, ref) => {
  let Button;
  switch (status) {
    case 'user-menu':
      Button = BackButton;
      break;
    case 'main-menu':
      Button = CloseMenuButton;
      break;
    default:
      Button = OpenMenuButton;
  }
  return (
    <div ref={ref}>
      <Button onClick={onClick} />
    </div>
  );
});
