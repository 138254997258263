import { CalendarDate } from 'common/types';
import { CheckIfDayIsDisabledHandler } from './types';

const dummyCalculateDisabledDates: CheckIfDayIsDisabledHandler = () => false;

interface UseCalendarControlArgs {
  firstDate: CalendarDate;
  lastDate: CalendarDate;
  minDate?: CalendarDate;
  maxDate?: CalendarDate;
  calculateDisabledDates?: CheckIfDayIsDisabledHandler;
}

interface UseCalendarControlReturn {
  canNavigateToPreviousInterval: boolean;
  canNavigateToNexInterval: boolean;
  calculateDisabledDates: CheckIfDayIsDisabledHandler;
}

export const useCalendarControl = ({
  firstDate,
  lastDate,
  maxDate,
  minDate,
  calculateDisabledDates: outerCalculateDisabledDates = dummyCalculateDisabledDates
}: UseCalendarControlArgs): UseCalendarControlReturn => {
  const calculateDisabledDates: CheckIfDayIsDisabledHandler = (date) => {
    const isBeforeMinDate = minDate ? date.isBefore(minDate, 'd') : false;
    const isAfterMaxDate = maxDate ? date.isAfter(maxDate, 'd') : false;
    const isOutOfRange = isBeforeMinDate || isAfterMaxDate;
    return isOutOfRange || outerCalculateDisabledDates(date);
  };
  return {
    canNavigateToPreviousInterval: minDate?.isBefore(firstDate, 'date') ?? true,
    canNavigateToNexInterval: maxDate?.isAfter(lastDate, 'date') ?? true,

    calculateDisabledDates
  };
};
