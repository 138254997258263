import { useController, useFormContext } from 'react-hook-form';
import { FormEvent } from 'react';
import { FormErrorMessage } from '../FormErrorMessage';
import { TextField, TextFieldProps } from '../TextField';

export interface FormTextFieldProps extends Omit<TextFieldProps, 'onChange' | 'inputRef' | 'value' | 'error'> {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  defaultValue?: string;
}

export const FormTextField = ({
  name,
  fullWidth = true,
  defaultValue,
  multiline,
  ...rest
}: FormTextFieldProps): JSX.Element => {
  const {
    formState: { errors }
  } = useFormContext();

  const {
    field: { ref, value, ...fieldProps },
    fieldState: { error }
  } = useController({
    name,
    defaultValue
  });
  const { inputProps } = rest;

  if (multiline && inputProps?.maxLength) {
    inputProps.onInput = (event: FormEvent<HTMLTextAreaElement>): void => {
      const { target } = event;
      if (target instanceof HTMLTextAreaElement && target.value && target.value.length > inputProps.maxLength) {
        target.value = target.value.slice(0, inputProps.maxLength);
      }
    };
  }

  return (
    <TextField
      {...rest}
      {...fieldProps}
      id={name}
      inputProps={inputProps}
      inputRef={ref}
      error={!!error}
      fullWidth={fullWidth}
      multiline={multiline}
      helperText={<FormErrorMessage errors={errors} name={name} />}
    />
  );
};
