import { IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { NavigationButton } from './NavigationButton';

interface LastStepControlsProps {
  onBack: VoidFunction;
  onClose: VoidFunction;
}

export const LastStepControls = ({ onBack, onClose }: LastStepControlsProps): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <IconButton className={classes.backButton} aria-label={t('onboarding.stepperPreviousStepImage')} onClick={onBack}>
        <ChevronLeftIcon />
      </IconButton>
      <div className={classes.closeButtonWrapper}>
        <NavigationButton onClick={onClose}>{t('onboarding.submitButton')}</NavigationButton>
      </div>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    marginRight: '2.5rem'
  },
  backButton: {
    flex: 0,
    padding: '0.5rem',
    color: theme.customColors.black
  },
  closeButtonWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  }
}));
