import { StepLabel as MuiStepLabel, StepLabelProps } from '@material-ui/core';
import { StepIcon } from './StepIcon';

export const StepLabel = ({ children, ...rest }: Omit<StepLabelProps, 'StepIconComponent'>) => {
  return (
    <MuiStepLabel {...rest} StepIconComponent={StepIcon}>
      {children}
    </MuiStepLabel>
  );
};
