import { Address } from 'features/addresses/types';
import { AddressSummary, LeavingBagSummary } from 'features/addresses';
import { Typography, makeStyles } from '@material-ui/core';
import { TFuncKey, useTranslation } from 'react-i18next';
import { useUser } from 'features/user';
import { SummarySection } from 'common/components';
import { ReactComponent as DeliveryIcon } from './delivery.svg';
import { ChangeDeliveryAddress } from './ChangeDeliveryAddress';
import { DeliveryDayType } from './deliveryDays';

interface AddressDeliverySummaryProps {
  data: Address;
  deliveryDays: DeliveryDayType;
}

export const AddressDeliverySummary = ({ data, deliveryDays }: AddressDeliverySummaryProps) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { addresses } = useUser();
  const title: TFuncKey =
    deliveryDays === 'bussinesDays'
      ? 'creatDietPage.summaryBox.sections.addressForBussinesDays'
      : 'creatDietPage.summaryBox.sections.addressForWeekendDays';
  const canChangeDeliveryAddress = addresses.data.length > 1;

  return (
    <SummarySection icon={DeliveryIcon} title={t(title)} collapsedSummary={data.name}>
      <div className={classes.header}>
        <Typography noWrap className={classes.name}>
          {data.name}
        </Typography>
        {canChangeDeliveryAddress && <ChangeDeliveryAddress deliveryDays={deliveryDays} defaultAddress={data} />}
      </div>
      <div className={classes.address}>
        <AddressSummary data={data} />
      </div>
      <LeavingBagSummary data={data} className={classes.leavingBagSummary} />
    </SummarySection>
  );
};

const useStyle = makeStyles({
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 'bold',
    flex: 1
  },
  address: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    margin: '0.5rem 0',
    maxWidth: 320
  },
  leavingBagSummary: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 250
  }
});
