import { useController } from 'react-hook-form';

import { useDietConfiguration } from 'features/orders/contexts';
import { CreateDietFormData } from 'features/orders/types';
import { useTranslation } from 'react-i18next';
import { MealsConfigurator } from './MealsConfigurator';

export const FlexiMealSection = () => {
  const { sizesData } = useDietConfiguration();
  const { t } = useTranslation();

  const {
    field: { value, onChange }
  } = useController<CreateDietFormData, 'meals'>({
    name: 'meals'
  });

  return (
    <MealsConfigurator
      onMealsChange={onChange}
      selectedMeals={value}
      sizesData={sizesData}
      label={t('creatDietPage.flexiMealSection')}
    />
  );
};
