import { CalendarDate } from 'common/types';
import { makeStyles } from '@material-ui/core';
import { Weekday } from '../components';

interface WeekdaysProps {
  locale: string;
  dates: CalendarDate[];
}

export const Weekdays = ({ locale, dates }: WeekdaysProps) => {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      {dates.map((date) => {
        const localedDate = date.locale(locale);
        return <Weekday key={date.unix()} label={localedDate.format('dddd')} value={localedDate.format('dd.')} />;
      })}
    </div>
  );
};

const useStyle = makeStyles({
  root: {
    display: 'flex',
    marginBottom: '0.5rem'
  }
});
