import { CaloricCalculatorDataForm } from 'features/orders/types';

export const calculateCalorific = (data: CaloricCalculatorDataForm): number => {
  const calculateCaloricRequirements = () => {
    if (data.sex === 'female') {
      const caloricRequirement = 655 + 9.6 * data.weight + 1.85 * data.height - 4.7 * data.age;
      return caloricRequirement * data.exerciseActivity;
    }
    const caloricRequirement = 66.5 + 13.7 * data.weight + 5 * data.height - 6.8 * data.age;
    return caloricRequirement * data.exerciseActivity;
  };
  const calculateCaloriesBasedonGoal = () => {
    const caloricRequirements = calculateCaloricRequirements();
    let goalFactor = 0;
    if (data.goal === 'loseWeight') {
      goalFactor = -0.2 * caloricRequirements;
    }
    if (data.goal === 'buildMuscleMass') {
      goalFactor = 0.2 * caloricRequirements;
    }
    return caloricRequirements + goalFactor;
  };
  function showCaloricRequirement() {
    const basedOnGoal = calculateCaloriesBasedonGoal();
    if (basedOnGoal <= 1050) {
      return 1000;
    }
    if (basedOnGoal > 1050 && basedOnGoal <= 1300) {
      return 1250;
    }
    if (basedOnGoal > 1300 && basedOnGoal <= 1600) {
      return 1500;
    }
    if (basedOnGoal > 1600 && basedOnGoal <= 1800) {
      return 1750;
    }
    if (basedOnGoal > 1800 && basedOnGoal <= 2100) {
      return 2000;
    }
    if (basedOnGoal > 2100 && basedOnGoal <= 2600) {
      return 2500;
    }
    return 3000;
  }
  return showCaloricRequirement();
};
