import { useController } from 'react-hook-form';
import { FormControlLabel, makeStyles, Radio, RadioGroup } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { MarkedCircle } from '../../icons/MarkedCircle';
import checkboxEmpty from '../../icons/icon-checkbox-empty.svg';

export interface FormRadioGroupFieldProps<OptionType> {
  name: `${string}` | `${string}.${string}` | `${string}.${number}`;
  defaultValue?: string;
  options: OptionType[];
  getOptionAlt: (option: OptionType) => string;
  getOptionLabel: (option: OptionType) => string | JSX.Element;
  getOptionValue: (option: OptionType) => string;
  className?: string;
}

export const FormRadioGroupField = <OptionType,>({
  name,
  defaultValue,
  getOptionAlt,
  getOptionValue,
  getOptionLabel,
  options,
  ...rest
}: FormRadioGroupFieldProps<OptionType>): JSX.Element => {
  const {
    field: { onChange, value, ...restField }
  } = useController({ name, defaultValue });
  const classes = useStyle();

  const handleChange = (_event: ChangeEvent<HTMLInputElement>, newValue: string) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  if (!value && defaultValue) {
    onChange(defaultValue);
  }

  return (
    <RadioGroup {...rest} {...restField} name={name} defaultValue={defaultValue} onChange={handleChange} value={value}>
      {options.map((option: OptionType) => {
        return (
          <FormControlLabel
            key={getOptionValue(option)}
            value={getOptionValue(option)}
            label={getOptionLabel(option)}
            control={
              <Radio
                className={classes.radio}
                disableRipple
                color="default"
                checkedIcon={<MarkedCircle imageAlt={getOptionAlt(option)} containerClassName={classes.checked} />}
                icon={<img src={checkboxEmpty} alt={getOptionAlt(option)} />}
              />
            }
          />
        );
      })}
    </RadioGroup>
  );
};

const useStyle = makeStyles((theme) => ({
  radio: {
    '&:hover': {
      background: 'transparent'
    }
  },
  checked: {
    width: '24px',
    height: '24px',
    backgroundColor: theme.customColors.yellow
  }
}));
