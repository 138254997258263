import { useApi } from 'features/apiProvider';
import { useSession } from 'features/authentication';
import { useQuery } from 'react-query';
import { fetchClient } from './fetchClient';
import { Aggregate } from './types';

export const useClient = (aggregates: Aggregate[]) => {
  const { getApiClient } = useApi();
  const { token } = useSession();
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: 'v5/client',
    queryFn: () => fetchClient({ apiClient: getApiClient(), aggregates }),
    enabled: !!token
  });

  return { clientData: data, error, isLoading, refetch };
};
