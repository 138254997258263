import { Button as MUIButton, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { ButtonProps as MUIButtonProps } from '@material-ui/core/Button/Button';
import { useButtonStyles } from './styles';

export interface ButtonProps extends Omit<MUIButtonProps, 'variant'> {
  mode?: 'primary' | 'secondary';
  isLoading?: boolean;
}

export const Button = ({
  className,
  children,
  mode = 'primary',
  disabled,
  isLoading,
  ...rest
}: ButtonProps): JSX.Element => {
  const classes = useButtonStyles();

  return (
    <MUIButton
      variant="outlined"
      className={clsx(
        {
          [classes.button]: true,
          [classes.primary]: mode === 'primary',
          [classes.secondary]: mode === 'secondary'
        },
        className
      )}
      disabled={disabled || isLoading}
      {...rest}
    >
      {isLoading ? <CircularProgress size={14} color="inherit" /> : children}
    </MUIButton>
  );
};
