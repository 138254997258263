import { useApi } from 'features/apiProvider';
import { useState } from 'react';

import { useQuery } from 'react-query';
import { useSession } from 'features/authentication';
import { OrderHistory, OrderHistoryItem, fetchOrderHistory } from './fetchOrderHistory';

interface Params {
  limit?: number;
}

export const useOrderHistory = (params?: Params) => {
  const { getApiClient } = useApi();
  const [page, setPage] = useState(1);
  const [historyItems, setHistoryItems] = useState<OrderHistoryItem[]>([]);
  const { token } = useSession();

  const {
    data,
    refetch: queryRefetch,
    error,
    isLoading
  } = useQuery<OrderHistory>({
    queryKey: ['order-history', page, params?.limit],
    keepPreviousData: true,
    queryFn: () => fetchOrderHistory({ apiClient: getApiClient(), limit: params?.limit, page }),
    onSuccess: ({ items }) => {
      if (items) {
        setHistoryItems((prev) => [...prev, ...items]);
      }
    },
    enabled: !!token
  });

  const nextPage = () => {
    setPage((prev) => prev + 1);
    queryRefetch();
  };

  const refetch = () => {
    setPage(1);
    setHistoryItems([]);
    queryRefetch();
  };

  return { data, historyItems, error, isLoading, refetch, nextPage };
};
