import { undefinedUserId } from 'common/constants';
import { jwtDecode } from 'jwt-decode';
import { DecodedJwtToken } from '../types';

export const decodeJwtToken = (token: string): DecodedJwtToken => {
  try {
    return jwtDecode<DecodedJwtToken>(token);
  } catch (e) {
    return {
      id: undefinedUserId,
      exp: 0
    };
  }
};
