import { ChangeEvent, memo, useEffect } from 'react';
import { Grid, makeStyles, RadioGroup } from '@material-ui/core';
import { dietLenghtOptions } from 'features/orders';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getDietLenghtOptions } from 'features/orders/utils';
import { CreateDietFormData } from 'features/orders/types';
import { DietLengthOption } from './DietLengthOption';

export const DietLengthSection = memo(() => {
  const { t } = useTranslation();
  const classes = useStyle();

  const {
    field: { onChange, value: currentDietLength }
  } = useController<CreateDietFormData, 'dietLength'>({
    name: 'dietLength'
  });

  const withoutWeekends = useWatch<CreateDietFormData, 'withoutWeekends'>({
    name: 'withoutWeekends'
  });
  const optionsNew = getDietLenghtOptions(withoutWeekends);

  const handleChange = (_: ChangeEvent<HTMLInputElement>, value: string) => {
    const id = Number(value);
    onChange(id);
  };

  useEffect(() => {
    const previousSelectedOption = dietLenghtOptions.find((o) => o.id === currentDietLength);
    let newOption;
    if (previousSelectedOption?.type === 'custom') {
      newOption = optionsNew.find((o) => o.type === 'custom');
    } else {
      newOption = optionsNew.find((o) => o.type === 'continuous' && o.weeks === previousSelectedOption?.weeks);
    }
    if (newOption) {
      onChange(newOption.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withoutWeekends]);

  return (
    <RadioGroup value={String(currentDietLength)} onChange={handleChange} className={classes.radioGroup}>
      <Grid container spacing={2}>
        {optionsNew.map((option) => {
          let title = t('creatDietPage.calendarSection.options.custom');
          let subtitle = '';
          if (option.type === 'continuous') {
            title = t('creatDietPage.calendarSection.options.predefined', { count: option.weeks });
            subtitle = t('creatDietPage.calendarSection.options.day', { count: option.days });
          }
          return (
            <DietLengthOption
              key={option.id}
              title={title}
              subtitle={subtitle}
              value={String(option.id)}
              selected={option.id === currentDietLength}
            />
          );
        })}
      </Grid>
    </RadioGroup>
  );
});

const useStyle = makeStyles(() => ({
  radioGroup: {
    marginBottom: '2rem'
  }
}));
