import { ErrorMessage } from '@hookform/error-message';
import { DeepMap, FieldError, FieldValues, Message } from 'react-hook-form';
import { TFuncKey, useTranslation } from 'react-i18next';

interface TranslateErrorProps {
  message: Message;
}

interface FormErrorMessageProps {
  errors: DeepMap<FieldValues, FieldError>;
  name: string;
}

export const FormErrorMessage = ({ errors, name }: FormErrorMessageProps): JSX.Element | null => {
  const { t } = useTranslation();

  const translateError = ({ message }: TranslateErrorProps) => {
    return t(message as TFuncKey) as string;
  };

  return <ErrorMessage errors={errors} name={name} render={translateError} />;
};
