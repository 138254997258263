import { Trans, useTranslation } from 'react-i18next';
import { Typography, makeStyles, SvgIcon } from '@material-ui/core';
import { ReactComponent as EmptyIcon } from './icon.svg';

export const EmptyList = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <SvgIcon component={EmptyIcon} viewBox="0 0 88 88" className={classes.icon} />
      <Typography className={classes.title} variant="h3" component="h2">
        {t('addressesPage.emptyList.title')}
      </Typography>
      <Typography className={classes.description} variant="body1">
        <Trans i18nKey="addressesPage.emptyList.description" components={{ br: <br /> }} />
      </Typography>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: '100%'
  },
  icon: {
    marginBottom: theme.spacing(2),
    fontSize: '5.5rem',
    color: theme.customColors.textDarkGrey
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  description: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    color: theme.customColors.textDarkGrey
  }
}));
