import {
  InputLabelProps as StandardInputLabelProps,
  InputProps as StandardInputProps,
  makeStyles,
  TextField as MuiTextField,
  StandardTextFieldProps
} from '@material-ui/core';
import clsx from 'clsx';

export interface TextFieldProps extends Omit<StandardTextFieldProps, 'InputLabelProps' | 'InputProps'> {
  InputLabelProps?: Partial<Omit<StandardInputLabelProps, 'shrink' | 'disableAnimation'>>;
  InputProps?: Partial<Omit<StandardInputProps, 'disableUnderline'>>;
}

export const TextField = ({ InputProps = {}, InputLabelProps = {}, ...rest }: TextFieldProps) => {
  const classes = useStyle();
  const inputClassName = clsx(InputProps.className, {
    [classes.readonly]: InputProps.readOnly
  });
  return (
    <MuiTextField
      {...rest}
      InputProps={{ ...InputProps, disableUnderline: true, className: inputClassName }}
      InputLabelProps={{ ...InputLabelProps, shrink: true, disableAnimation: true }}
    />
  );
};

const useStyle = makeStyles({
  readonly: {
    boxShadow: 'none'
  }
});
