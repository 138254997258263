import { useTranslation } from 'react-i18next';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { NavigationButton } from './NavigationButton';

interface FirstStepControlsProps {
  onClick: VoidFunction;
  className?: string;
}

export const FirstStepControl = ({ onClick, className }: FirstStepControlsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <NavigationButton className={className} onClick={onClick} endIcon={<ChevronRightIcon />}>
      {t('onboarding.initialButton')}
    </NavigationButton>
  );
};
