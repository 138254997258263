import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { CalendarDate } from 'common/types';
import { mergeWith } from 'lodash';
import { CalculateDayStyleHandler, CheckIfDayIsDisabledHandler, DayTileStyle } from '../../types';
import { DayTile } from '../DayTile';
import { getMonthRange } from '../../getMonthRange';
import { Weekdays } from './Weekdays';

interface DaysGridProps {
  current: CalendarDate;
  locale: string;
  showFullWeeks: boolean;
  onClick: (selectedDate: CalendarDate) => void;
  checkIfDayIsDisabled: CheckIfDayIsDisabledHandler;
  calculateDayStyle?: CalculateDayStyleHandler;
  className?: string;
}

export const DaysGrid = ({
  current,
  locale,
  onClick,
  showFullWeeks,
  checkIfDayIsDisabled,
  calculateDayStyle: outsideCalculateDayStyle = dummyCalculateDayStyle,
  className
}: DaysGridProps) => {
  const classes = useStyle();
  const weeks = getWeekArray({
    currentDate: current,
    locale
  });

  const shouldDisplayDate = (date: CalendarDate) => {
    if (showFullWeeks) {
      return true;
    }
    return date.isSame(current, 'M');
  };

  const calculateDayStyle = ({
    date,
    isFirstWeek,
    islastDay
  }: {
    date: CalendarDate;
    isFirstWeek: boolean;
    islastDay: boolean;
  }): DayTileStyle => {
    return mergeWith(
      {
        button: clsx({
          [classes.tile]: true,
          [classes.dayTileButton]: true,
          [classes.topDayTileButton]: isFirstWeek,
          [classes.lastDayTileInWeek]: islastDay
        })
      },
      outsideCalculateDayStyle(date),
      (base, additional) => {
        return clsx(base, additional);
      }
    );
  };

  return (
    <div className={className}>
      <div className={classes.weekdays}>
        <Weekdays locale={locale} />
      </div>
      {weeks.map((week, weekIndex) => {
        return (
          <div key={week[0].week()} style={{ display: 'flex' }}>
            {week.map((date, dayIndex) => {
              const isFirstWeek = weekIndex === 0;
              const isLastWeek = weekIndex === weeks.length - 1;
              if (shouldDisplayDate(date)) {
                const islastDay = week.length - 1 === dayIndex;
                return (
                  <DayTile
                    key={date.unix()}
                    value={date}
                    locale={locale}
                    onClick={onClick}
                    disabled={checkIfDayIsDisabled(date)}
                    styles={calculateDayStyle({ date, isFirstWeek, islastDay })}
                  />
                );
              }
              return (
                <div
                  key={date.unix()}
                  className={clsx({
                    [classes.tile]: true,
                    [classes.emptyTile]: true,
                    [classes.emptyTileFirstRow]: isFirstWeek,
                    [classes.emptyTileLastRow]: isLastWeek
                  })}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const dummyCalculateDayStyle: CalculateDayStyleHandler = () => ({});

const getWeekArray = ({ currentDate, locale }: { currentDate: CalendarDate; locale: string }) => {
  const { start, end } = getMonthRange({ visibleDate: currentDate, locale, showFullWeeks: true });

  let count = 0;
  let current = start;
  const nestedWeeks: CalendarDate[][] = [];

  while (current.isBefore(end)) {
    const weekNumber = Math.floor(count / 7);
    nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
    nestedWeeks[weekNumber].push(current);

    current = current.add(1, 'day');
    count += 1;
  }

  return nestedWeeks;
};

const useStyle = makeStyles((theme) => ({
  weekdays: {
    marginBottom: '0.5rem'
  },
  tile: {
    borderWidth: 0,
    borderLeftWidth: 1,
    flexBasis: '14.2857%',
    borderStyle: 'solid',
    borderColor: theme.customColors.calendar.borderColor,
    flex: 1
  },
  emptyTile: {
    borderLeftColor: 'transparent'
  },
  emptyTileFirstRow: {
    borderBottomWidth: 1
  },
  emptyTileLastRow: {
    '&:first-of-type': {
      borderLeftColor: theme.customColors.calendar.borderColor
    }
  },
  dayTileButton: {
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  topDayTileButton: {
    borderTopWidth: 1
  },
  lastDayTileInWeek: {
    borderRightWidth: 1
  }
}));
