import { makeStyles } from '@material-ui/core';
import { Address } from 'features/addresses/types';
import { Paper } from 'common/components';
import { AddressDetails } from './AddressDetails';
import { AddressMenu } from './AddressMenu';

interface AddressCardProps {
  data: Address;
  onOpenMenu: (id: number) => void;
  onCloseMenu: VoidFunction;
  isOpenMenu: boolean;
}

export const AddressCard = ({ data, onOpenMenu, onCloseMenu, isOpenMenu }: AddressCardProps) => {
  const classes = useStyle();
  const handleOpenMenu = () => onOpenMenu(data.id);
  return (
    <Paper className={classes.root}>
      {isOpenMenu ? (
        <AddressMenu id={data.id} name={data.name} onClose={onCloseMenu} />
      ) : (
        <AddressDetails data={data} onOpenMenu={handleOpenMenu} />
      )}
    </Paper>
  );
};

const useStyle = makeStyles({
  root: {
    padding: 0,
    minHeight: '200px'
  }
});
