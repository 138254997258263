import { z } from 'zod';

export const dictionaryTagSchema = z.object({
  id: z.number(),
  value: z.string(),
  systemValue: z.string().nullable()
});

export type DictionaryTag = z.TypeOf<typeof dictionaryTagSchema>;

export type DictionaryTagType = 'under_the_door_type' | 'package_leaving_option' | 'ecommerce_order_payment_type';
