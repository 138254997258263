interface Props {
  user: {
    id: number;
    email: string;
  };
}

export const sendUserComAuth = async ({ user }: Props) => {
  const API_KEY = process.env.REACT_APP_USER_COM_API_KEY;
  if (!API_KEY || !window.UE) {
    return;
  }

  await window.UE.resetAuth({
    apiKey: API_KEY,
    email: user.email,
    user_id: `${user.id}`,
    widget: { state: 'hidden' }
  });
};
