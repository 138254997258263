import * as z from 'zod';
import { country } from '../../../config';
import { getPackageLeavingConfig } from '../utils/getPackageLeavingConfig';

export const deliveryDetailsSchemaFormMaxFieldsLength = {
  name: 30,
  floorNr: 10,
  district: 255,
  gateKey: 255,
  intercomKey: 255,
  otherPackageLeavingOption: 400,
  companyName: 20,
  intercomName: 20
};

export const addressName = {
  min: 2,
  max: 255
};

export const deliveryDetailsSchema = z.object({
  name: z.string().min(addressName.min, 'addresses.deliveryDetails.name.validation.required').max(addressName.max),
  deliveryHourTo: z.string(),
  deliveryHourFrom: z.string().nullable(),
  floorNr: z.string(),
  district: z.string(),
  gateKey: z.string(),
  intercomKey: z.string(),
  packageLeavingOption: z.number().positive('errors.required'),
  otherPackageLeavingOption: z.string(),
  companyName: z.string(),
  intercomName: z.string(),
  underTheDoorType: z.string().nullable()
});

export type DeliveryDetailsFormData = z.infer<typeof deliveryDetailsSchema>;

const deliveryDetailsFieldValidation: { schema: z.ZodSchema; path: keyof DeliveryDetailsFormData }[] = [
  {
    schema: z
      .string()
      .min(1, 'addresses.deliveryDetails.name.validation.required')
      .max(deliveryDetailsSchemaFormMaxFieldsLength.name, 'addresses.deliveryDetails.name.validation.max'),
    path: 'name'
  },
  {
    schema: z.string().regex(/\d{2}:\d{2}/, 'errors.required'),
    path: 'deliveryHourTo'
  },
  {
    schema: z
      .string()
      .regex(/\d{2}:\d{2}/)
      .nullable(),
    path: 'deliveryHourFrom'
  },
  {
    schema: z
      .string()
      .max(
        deliveryDetailsSchemaFormMaxFieldsLength.floorNr,
        'orderAdditionalDeliveryInformationPage.floorNr.validation.max'
      ),
    path: 'floorNr'
  },
  {
    schema: z
      .string()
      .max(
        deliveryDetailsSchemaFormMaxFieldsLength.district,
        'orderAdditionalDeliveryInformationPage.district.validation.max'
      ),
    path: 'district'
  },
  {
    schema: z
      .string()
      .max(
        deliveryDetailsSchemaFormMaxFieldsLength.gateKey,
        'orderAdditionalDeliveryInformationPage.gateKey.validation.max'
      ),
    path: 'gateKey'
  },
  {
    schema: z
      .string()
      .max(
        deliveryDetailsSchemaFormMaxFieldsLength.intercomKey,
        'orderAdditionalDeliveryInformationPage.intercomKey.validation.max'
      ),
    path: 'intercomKey'
  },
  {
    schema: z.number().positive('errors.required'),
    path: 'packageLeavingOption'
  },
  {
    schema: z
      .string()
      .max(
        deliveryDetailsSchemaFormMaxFieldsLength.otherPackageLeavingOption,
        'addresses.deliveryDetails.otherPackageLeavingOption.validation.max'
      ),
    path: 'otherPackageLeavingOption'
  }
];

export const deliveryDetailsFormSchema = deliveryDetailsSchema.superRefine((values, ctx) => {
  const fieldValidation = [...deliveryDetailsFieldValidation];
  const config = getPackageLeavingConfig(+values.packageLeavingOption, values.underTheDoorType, country);

  if (config.intercomRequired) {
    if (['poland', 'czech'].includes(country)) {
      fieldValidation.push({
        schema: z
          .string()
          .min(1, 'errors.required')
          .max(deliveryDetailsSchemaFormMaxFieldsLength.intercomKey, 'addresses.deliveryDetails.intercomKey.max'),
        path: 'intercomKey'
      });
    }
    if (['germany', 'czech'].includes(country)) {
      fieldValidation.push({
        schema: z
          .string()
          .min(1, 'errors.required')
          .max(deliveryDetailsSchemaFormMaxFieldsLength.intercomName, 'addresses.deliveryDetails.intercomName.max'),
        path: 'intercomName'
      });
    }
  }

  if (config.otherPackageLeavingOptionRequired) {
    fieldValidation.push({
      schema: z
        .string()
        .min(1, 'errors.required')
        .max(
          deliveryDetailsSchemaFormMaxFieldsLength.otherPackageLeavingOption,
          'addresses.deliveryDetails.otherPackageLeavingOption.validation.max'
        ),
      path: 'otherPackageLeavingOption'
    });
  }

  fieldValidation.forEach(({ path, schema }) => {
    const fieldValidationResult = schema.safeParse(values[path]);
    if (!fieldValidationResult.success) {
      ctx.addIssue({
        code: 'custom',
        message: fieldValidationResult.error.issues[0].message,
        path: [path]
      });
    }
  });
});
