import { InputBaseComponentProps, makeStyles, Theme, Grid } from '@material-ui/core';

import { FormTextField, FormRow } from 'common/components';
import { country } from 'config';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreateDietFormData, createDietFormMaxFieldsMaxLength } from '../types';

const inputProps: InputBaseComponentProps = {
  maxLength: createDietFormMaxFieldsMaxLength.dietName
};

export const DietNameSection = () => {
  const { t } = useTranslation();
  const classes = useStyle({ hidden: country === 'germany' });
  const { setValue } = useFormContext<CreateDietFormData>();

  useEffect(() => {
    if (country === 'germany') {
      setValue('dietName', 'Ernährungsplan 1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid item xs={12} md={6} className={classes.hidableElement}>
      <FormRow title={t('creatDietPage.dietNameSection.title')}>
        <FormTextField
          name="dietName"
          type="text"
          placeholder={t('creatDietPage.dietNameSection.inputPlaceholder')}
          inputProps={inputProps}
        />
      </FormRow>
    </Grid>
  );
};

const useStyle = makeStyles<Theme, { hidden?: boolean }>(() => ({
  hidableElement: {
    display: ({ hidden }) => (hidden ? 'none' : 'block')
  }
}));
