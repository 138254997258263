import { useNotifyDialog } from 'common/contexts';
import { useMutation } from 'react-query';
import { changePassword } from 'features/user/api';

export const useChangePassword = () => {
  const { showErrorDialog, showSuccessDialog } = useNotifyDialog();
  return useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      showSuccessDialog({ message: 'changePasswordPage.changePasswordForm.state.successRequest' });
    },
    onError: () => {
      showErrorDialog({ message: 'changePasswordPage.changePasswordForm.state.errorRequest' });
    }
  });
};
