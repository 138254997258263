import { useState, useRef, useEffect } from 'react';
import { makeStyles, Slide } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';

import { useLocation } from 'react-router-dom';
import { MobileMenuDefault } from './MobileMenuDefault';
import { MobileMenuUser } from './MobileMenuUser';
import { MobileMenuButton } from './MobileMenuButton';
import { MenuState } from './types';
import { usePreventScrollingBodyWhenMenuIsOpen } from './usePreventScrollingBodyWhenMenuIsOpen';

const popperModifiers = {
  computeStyle: {
    gpuAcceleration: false,
    enabled: false
  }
};

export const MobileMenu = (): JSX.Element => {
  const [menuState, setMenuState] = useState<MenuState>('hidden');
  const location = useLocation();

  const isOpen = menuState !== 'hidden';
  const isUserMenu = menuState === 'user-menu';
  const isMainMenu = menuState === 'main-menu';

  const anchorRef = useRef(null);

  const classes = useStyle();
  usePreventScrollingBodyWhenMenuIsOpen(isOpen);

  useEffect(() => {
    setMenuState('hidden');
  }, [location]);

  const handleOpenUserMenu = () => setMenuState('user-menu');

  return (
    <>
      <MobileMenuButton ref={anchorRef} status={menuState} onClick={setMenuState} />
      <Popper
        className={classes.popper}
        anchorEl={anchorRef.current}
        open={isOpen}
        transition
        placement="bottom"
        disablePortal
        modifiers={popperModifiers}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <div className={classes.popperContentWrapper}>
              {isMainMenu && <MobileMenuDefault setUserMenuMode={handleOpenUserMenu} />}
              <Slide direction="right" in={isUserMenu} mountOnEnter unmountOnExit>
                <MobileMenuUser />
              </Slide>
            </div>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  menuButton: {
    marginRight: '0.938rem',
    padding: 0,
    margin: 0,
    border: 'none',
    backgroundColor: theme.palette.secondary.main,

    '& > img': {
      alignSelf: 'baseline',
      filter: theme.filters.ligthGrey
    }
  },
  popper: {
    left: 0,
    top: 0,
    transform: `translate3d(0, 5rem, 0px)`,
    zIndex: 1,
    backgroundColor: theme.palette.secondary.main,
    minHeight: 'calc(100vh - 5rem)',
    height: '100%',
    width: '100vw',
    display: 'flex',
    overflowY: 'auto'
  },
  popperContentWrapper: {
    flex: 1,
    position: 'relative',
    overflow: 'auto'
  }
}));
