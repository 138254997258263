import { useSnackbar } from 'notistack';
import { createContext, ReactNode, useContext } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { useUser } from 'features/user';
import { useProfiles } from 'features/profiles';
import { CalendarDate } from 'common/types';
import { getMealsIDs } from 'common/utils/getMealsIDs';
import { useApi } from 'features/apiProvider';
import { fetchDietDayPrice, FetchDietPriceResponse } from '../api/fetchDietDayPrice';
import { CreateDietFormData } from '../types';
import { getDietLenghtOptions, getDietLength, getFirstDeliveryDateForCustomDeliveryDates } from '../utils';
import { useDietConfiguration } from './DietConfigurationContext';

interface OrderPriceSummary {
  priceToPay: number;
  totalPrice: number;
  pricePerDay: number;
  needsPayment: boolean;
  dailyPriceOfDelivery: number;
  discounts: {
    code: number;
    delivery: number;
    moneybox: number;
  };
  delivery: {
    beforeDiscount: number;
    afterDiscount: number;
  };
}

interface OrderPriceContextShape extends OrderPriceSummary {
  isLoading: boolean;
}

const initialContextData: OrderPriceSummary = {
  priceToPay: 0,
  totalPrice: 0,
  pricePerDay: 0,
  needsPayment: true,
  dailyPriceOfDelivery: 0,
  discounts: {
    code: 0,
    delivery: 0,
    moneybox: 0
  },
  delivery: {
    beforeDiscount: 0,
    afterDiscount: 0
  }
};

const OrderPriceContext = createContext<OrderPriceContextShape | undefined>(undefined);

interface OrderPriceProviderProps {
  children: ReactNode;
}

export const OrderPriceProvider = ({ children }: OrderPriceProviderProps) => {
  const { getApiClient } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const {
    profile: { moneybox }
  } = useUser();
  const { t } = useTranslation();
  const { selectedProfileId } = useProfiles();
  const queryClient = useQueryClient();

  const {
    deliveryTimeConfig: { disabledDays, firstDeliveryAt },
    config: { packages }
  } = useDietConfiguration();

  const selectedMeals = useWatch<CreateDietFormData, 'meals'>({ name: 'meals' });
  const selectedDietPackage = useWatch<CreateDietFormData, 'dietPackage'>({ name: 'dietPackage' });
  const continuousFirstDeliveryDate = useWatch<CreateDietFormData, 'continuousFirstDeliveryDate'>({
    name: 'continuousFirstDeliveryDate'
  });
  const withoutWeekends = useWatch<CreateDietFormData, 'withoutWeekends'>({
    name: 'withoutWeekends'
  });
  const dietLengthId = useWatch<CreateDietFormData, 'dietLength'>({
    name: 'dietLength'
  });
  const customDeliveryDates = useWatch<CreateDietFormData, 'customDeliveryDates'>({
    name: 'customDeliveryDates'
  });
  const discountCode = useWatch<CreateDietFormData, 'discountCode'>({
    name: 'discountCode'
  });

  const { data, refetch, isFetching } = useQuery<FetchDietPriceResponse, unknown, OrderPriceSummary>({
    queryFn: ({ signal }) => {
      queryClient.cancelQueries('orderPrice');
      const dietLengthOption = getDietLenghtOptions(withoutWeekends).find((o) => o.id === dietLengthId);
      let startDate: CalendarDate;
      let days: CalendarDate[];
      if (dietLengthOption?.type === 'continuous') {
        startDate = continuousFirstDeliveryDate;
        days = [];
      } else {
        startDate = getFirstDeliveryDateForCustomDeliveryDates(customDeliveryDates);
        days = customDeliveryDates;
      }
      return fetchDietDayPrice({
        apiClient: getApiClient(),
        signal,
        requestData: {
          dietLength: getDietLength({
            dietLengthId,
            withoutWeekends,
            customDeliveryDates,
            firstDeliveryDate: continuousFirstDeliveryDate,
            disabledDays,
            firstDeliveryAt
          }),
          discountCode: discountCode?.id ?? null,
          weekendsIncluded: !withoutWeekends,
          package: packages.get(selectedDietPackage)?.id ?? 0,
          startDate,
          sizeIds: getMealsIDs({ selectedMeals }),
          selectedProfile: selectedProfileId,
          days
        }
      });
    },
    queryKey: [
      'orderPrice',
      customDeliveryDates,
      dietLengthId,
      discountCode?.id,
      continuousFirstDeliveryDate,
      selectedDietPackage,
      getMealsIDs({ selectedMeals }),
      withoutWeekends
    ],
    select: (response): OrderPriceSummary => {
      const fromMoneybox = moneybox > response.priceToPay ? response.priceToPay : moneybox;
      const priceToPayAfterMoneyboxDiscount = Math.abs(response.priceToPay - fromMoneybox);
      return {
        dailyPriceOfDelivery: response.dailyPriceOfDelivery,
        delivery: response.delivery,
        discounts: {
          ...response.discounts,
          moneybox: fromMoneybox
        },
        needsPayment: priceToPayAfterMoneyboxDiscount !== 0,
        pricePerDay: response.pricePerDay,
        priceToPay: priceToPayAfterMoneyboxDiscount,
        totalPrice: response.totalPrice
      };
    },
    onError: () => {
      enqueueSnackbar(t('creatDietPage.summaryBox.fetchingError'), {
        variant: 'refreshableError',
        onExited: () => {
          refetch();
        }
      });
    }
  });

  const baseValue = data ?? initialContextData;

  return (
    <OrderPriceContext.Provider value={{ ...baseValue, isLoading: isFetching }}>{children}</OrderPriceContext.Provider>
  );
};

export const useOrderPrice = () => {
  const context = useContext(OrderPriceContext);

  if (context === undefined) {
    throw new Error('useOrderPrice must be used within a OrderPriceContext');
  }
  return context;
};
