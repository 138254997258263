import { ApiClient } from 'features/apiProvider';
import { DeliveryPeriodOptions } from '../constants';
import { Address } from '../types';

interface SetDefaultDeliveryPeriodRequest extends ApiClient {
  data: Address;
  period: DeliveryPeriodOptions;
}

export const setDefaultDeliveryPeriod = async ({ data, period, apiClient }: SetDefaultDeliveryPeriodRequest) => {
  let periodData;
  switch (period) {
    case DeliveryPeriodOptions.BUSINESS_DAYS:
      periodData = {
        isStandardByDefault: true
      };
      break;
    case DeliveryPeriodOptions.WEEKEND_DAYS:
      periodData = {
        isSaturdayByDefault: true
      };
      break;
    default:
      periodData = {
        isSaturdayByDefault: true,
        isStandardByDefault: true
      };
  }
  await apiClient.patch(`v3/addresses/${data.id}`, {
    json: {
      ...data,
      packageLeavingOption: data.packageLeavingOption.id,
      ...periodData
    }
  });
};
