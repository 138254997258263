/** default selected on start package id */
export const defaultPackageId = Number(process.env.REACT_APP_DEFAULT_PACKAGE_ID);

/**
 * Default selected diet length. Id was provided in ticket. If this option is missing in list of available days then select the lowes one
 */
export const defaultDietLengthInDays = Number(process.env.REACT_APP_DEFAULT_DIET_LENGTH_IN_DAYS);

/**
 * Default selected status without weekends option
 */
export const defaultWithoutWeekend = !!Number(process.env.REACT_APP_DEFAULT_WITHOUT_WEEKENDS);

/**
 * Config for default selected package diet. Key is a package id and value is a diet variant it
 */

const defaultVariantsForPackageKeys = Object.keys(process.env).filter((singleKey) =>
  singleKey.includes('REACT_APP_DEFAULT_VARIANT_FOR_PACKAGE_ID_')
);

export const defaultDietForPackage: Record<string, number> = defaultVariantsForPackageKeys.reduce((prev, current) => {
  const packageId = current.replace('REACT_APP_DEFAULT_VARIANT_FOR_PACKAGE_ID_', '');

  return {
    ...prev,
    [packageId]: Number(process.env[current])
  };
}, {});

/**
 * Number of days that user can make order from the first delivery date
 */
export const maxDaysIntervalToStartOrder = Number(process.env.REACT_APP_MAX_DAYS_INTERVAL_TO_START_ORDER) ?? 150;

/**
 * Maximum number of days that user can select in any days mode in the order calendar
 */
export const maxDaysToMarkInOrderCalendar =
  Number(process.env.REACT_APP_MAX_DAYS_NUMBER_TO_SELECT_IN_ORDER_CALENDAR) ?? 100;

export const maximumCalorificValueToDisplay = Number(process.env.REACT_APP_MAXIMUM_CALORIFIC_VALUE_TO_DISPLAY) ?? 4000;
