import { useEffect, useState } from 'react';
import { useOrderHistory } from 'services/orderHistory/useOrderHistory';

export const useUserPreviousOrders = () => {
  const [hasPreviousPaidOrders, setHasPreviousPaidOrders] = useState(false);
  const { data } = useOrderHistory();

  useEffect(() => {
    if (!data || !data.items.length) {
      setHasPreviousPaidOrders(false);
    } else {
      const someHavePaidStatus = data.items.some((item) => item.paymentStatus.systemValue === 'ORDER_PREPAID');
      setHasPreviousPaidOrders(someHavePaidStatus);
    }
  }, [data]);

  return hasPreviousPaidOrders;
};
