import { Typography, makeStyles, Divider } from '@material-ui/core';
import { ButtonLink } from 'common/components';
import { useTranslation } from 'react-i18next';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { useProfiles } from '../../contexts';
import { ProfileSelect } from './ProfileSelect';

export const ProfileManagment = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  const { profiles, setProfile, selectedProfileId } = useProfiles();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.cardTitle} variant="h4">
        {t('profile.managment.header')}:
      </Typography>
      <ProfileSelect items={profiles} selected={selectedProfileId} onChange={setProfile} />
      <ButtonLink className={classes.addProfile} mode="secondary" fullWidth to={RoutesDefinition.createProfile}>
        {t('profile.managment.add')}
      </ButtonLink>

      <Divider className={classes.divider} />
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '& div': {
        boxShadow: 'none'
      }
    }
  },
  cardTitle: {
    marginBottom: '1rem'
  },
  addProfile: {
    marginTop: '1rem'
  },
  divider: {
    margin: `1.5rem 0`
  }
}));
