import { Time, transformTimeToDayjs } from 'common/types';
import dayjs from 'dayjs';
import { TIME_FORMAT } from 'common/constants';
import { AddressDeliveryDataResponse, AddressDeliveryTime, AddressDeliveryTimeResponse } from './types';

export const mapDeliveryTimeToOptions = (
  addressDeliveryData?: AddressDeliveryDataResponse
): { timeFrom?: Time; timeTo: Time }[] => {
  if (!addressDeliveryData) {
    return [];
  }

  const options: { timeFrom?: Time; timeTo: Time }[] = addressDeliveryData.showSinceToOption
    ? mapDeliveryShowSinceTo(addressDeliveryData.deliveryTimes)
    : mapDeliveryShowToOnly(addressDeliveryData.deliveryTimes[0]);

  return options;
};

const mapDeliveryShowSinceTo = (deliveryTimes: AddressDeliveryTime[]): { timeFrom?: Time; timeTo: Time }[] => {
  const options: { timeFrom?: Time; timeTo: Time }[] = deliveryTimes
    .sort((a, b) => (transformTimeToDayjs(a.timeTo).isBefore(transformTimeToDayjs(b.timeTo)) ? -1 : 1))
    .map(({ timeFrom, timeTo }) => ({ timeFrom, timeTo }));

  return options;
};

const mapDeliveryShowToOnly = ({
  timeTo,
  timeFrom,
  interval,
  step
}: AddressDeliveryTimeResponse): { timeTo: string }[] => {
  const options: { timeTo: string }[] = [];
  if (timeFrom) {
    const parsedTimeFrom = transformTimeToDayjs(timeFrom);
    const parsedInterval = interval.match(/(\d{2}):(\d{2})/);
    const parsedStep = step.match(/(\d{2}):(\d{2})/);

    if (parsedInterval === null || parsedStep === null) {
      return options;
    }
    const [, intervalHours, intervalMinutes] = parsedInterval;
    const [, stepHours, stepMinutes] = parsedStep;

    const intervalDuration = dayjs.duration({
      hours: Number(intervalHours),
      minutes: Number(intervalMinutes)
    });

    const stepDuration = dayjs.duration({
      hours: Number(stepHours),
      minutes: Number(stepMinutes)
    });

    let current = parsedTimeFrom.add(intervalDuration);

    const end = transformTimeToDayjs(timeTo);

    while (current.isSameOrBefore(end, 'minute')) {
      options.push({ timeTo: current.format(TIME_FORMAT) });
      current = current.add(stepDuration);
    }
  }
  return options;
};
