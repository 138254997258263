import { FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { DietLengthContent } from './DietLengthContent';
import { DietlengthRadio } from './DietLengthRadio';

interface DietLengthOptionProps {
  title: string;
  subtitle: string;
  value: string;
  selected: boolean;
}

export const DietLengthOption = ({ title, subtitle, value, selected }: DietLengthOptionProps) => {
  const classes = useStyle();

  return (
    <Grid item xs={6}>
      <FormControlLabel
        className={clsx({
          [classes.optionContainer]: true,
          active: selected
        })}
        value={value}
        control={<DietlengthRadio optionName={title} />}
        label={<DietLengthContent title={title} subtitle={subtitle} />}
      />
    </Grid>
  );
};

const useStyle = makeStyles((theme) => ({
  optionContainer: {
    padding: '1rem 0.75rem',
    width: '100%',
    height: '100%',
    borderRadius: '0.25rem',
    boxShadow: theme.boxShadow.lightGrey,
    margin: 0,
    backgroundColor: theme.customColors.white,

    '&.active': {
      backgroundColor: theme.palette.primary.main
    }
  }
}));
