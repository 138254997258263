import { makeStyles } from '@material-ui/core';
import { Button, ButtonLink, ConfirmationDialog } from 'common/components';
import { useNotifyDialog } from 'common/contexts';
import { Id } from 'common/types';
import { country } from 'config';
import { useApi } from 'features/apiProvider';
import { useUser } from 'features/user';
import { AccountLayout } from 'layouts';
import { useState } from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { AddressesList } from './components';
import { AddressManagmentProvider, ChangeDefaultDeliveryPeriodHandler } from './contexts';
import { useDeleteAddress, useSetDefaultDeliveryPeriod } from './hooks';

export const AddressesPage = (): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();

  const { addresses, profile } = useUser();
  const { showErrorDialog } = useNotifyDialog();
  const { getApiClient } = useApi();
  const [addressToDelete, setAddressToDelete] = useState<Id>();

  const setDefaultDeliveryPeriod = useSetDefaultDeliveryPeriod();

  const deleteAddress = useDeleteAddress();

  const handleAddressDefault: ChangeDefaultDeliveryPeriodHandler = ({ id, type }) => {
    const address = addresses.data.find((a) => a.id === id);
    if (address === undefined) {
      return;
    }
    setDefaultDeliveryPeriod.mutate({ data: address, period: type, apiClient: getApiClient() });
  };

  const handleDeleteAddress = (id: Id) => {
    const address = addresses.data.find((a) => a.id === id);
    if (address === undefined) {
      return;
    }
    if (!(address.isStandardByDefault || address.isSaturdayByDefault)) {
      setAddressToDelete(address.id);
      return;
    }

    let errorMessage: TFuncKey;
    if (address.isStandardByDefault && address.isSaturdayByDefault) {
      errorMessage = 'addressesPage.errors.deleteDefaultForAllDays';
    } else if (address.isStandardByDefault) {
      errorMessage = 'addressesPage.errors.deleteDefaultForBusinessDays';
    } else {
      errorMessage = 'addressesPage.errors.deleteDefaultForWeekendDays';
    }
    showErrorDialog({
      message: errorMessage
    });
  };
  const handleCancelDeleteAddress = () => {
    if (!deleteAddress.isLoading) {
      setAddressToDelete(undefined);
    }
  };

  const handleConfirmDeleteAddress = () => {
    if (addressToDelete) {
      handleCancelDeleteAddress();
      deleteAddress.mutate({ id: addressToDelete, apiClient: getApiClient() });
    }
  };

  return (
    <AccountLayout
      isLoading={
        addresses.isLoading || profile.isLoading || setDefaultDeliveryPeriod.isLoading || deleteAddress.isLoading
      }
      title={t('addressesPage.title')}
      cta={
        <div className={classes.actionButtons}>
          {country === 'czech' && (
            <ButtonLink mode="secondary" className={classes.noTextWrap} to={RoutesDefinition.addNewDeliveryPoint}>
              {t('addressesPage.add-delivery-point')}
            </ButtonLink>
          )}
          <ButtonLink mode="secondary" className={classes.noTextWrap} to={RoutesDefinition.addNewAdresses}>
            {t('addressesPage.add')}
          </ButtonLink>
        </div>
      }
    >
      <AddressManagmentProvider
        onChangeDefaultDeliveryPeriod={handleAddressDefault}
        onDeleteAddress={handleDeleteAddress}
      >
        <AddressesList items={addresses.data} />
      </AddressManagmentProvider>
      <ConfirmationDialog
        title="addressesPage.deleteAddressConfirmation.title"
        description="addressesPage.deleteAddressConfirmation.description"
        isOpen={addressToDelete !== undefined}
        onClose={handleCancelDeleteAddress}
        cancelButton={
          <Button
            className={classes.ctaButton}
            mode="secondary"
            fullWidth
            onClick={handleConfirmDeleteAddress}
            isLoading={deleteAddress.isLoading}
          >
            {t('addressesPage.deleteAddressConfirmation.confirm')}
          </Button>
        }
        confirmButton={
          <Button fullWidth onClick={handleCancelDeleteAddress} disabled={deleteAddress.isLoading}>
            {t('addressesPage.deleteAddressConfirmation.cancel')}
          </Button>
        }
      />
    </AccountLayout>
  );
};

const useStyle = makeStyles((theme) => ({
  addAddressMobile: {
    marginTop: '1.5rem'
  },
  ctaButton: {
    opacity: 0.5
  },
  actionButtons: {
    display: 'flex',
    gap: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0.75rem',
      width: '100%',
      gap: '0.75rem',
      justifyContent: 'space-between',
      '&>a': {
        flexGrow: 1
      }
    }
  },
  noTextWrap: {
    whiteSpace: 'nowrap'
  }
}));
