import { Grid } from '@material-ui/core';
import { useApi } from 'features/apiProvider';
import { ChangePasswordForm } from 'features/user';
import { ChangePasswordFormData } from 'features/user/types';
import { AccountLayout } from 'layouts';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useChangePassword } from './hooks';

export const ChangePasswordPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { getApiClient } = useApi();
  const { isLoading, mutate } = useChangePassword();

  const handleSubmit: SubmitHandler<ChangePasswordFormData> = (data) => {
    mutate({
      apiClient: getApiClient(),
      requestData: data
    });
  };

  return (
    <AccountLayout isLoading={isLoading} title={t('changePasswordPage.title')}>
      <Grid item md={5} xs={12}>
        <ChangePasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
      </Grid>
    </AccountLayout>
  );
};
