import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode, Children } from 'react';

const noop = () => undefined;

interface DaysGridRowProps {
  children: ReactNode;
  containerClassName?: string;
  getItemClassName?: (index: number) => string | undefined;
}

export const DaysGridRow = ({ children, containerClassName, getItemClassName = noop }: DaysGridRowProps) => {
  const classes = useStyle();
  return (
    <div className={clsx(classes.container, containerClassName)}>
      {Children.map(children, (child, index) => {
        const itemClassName = getItemClassName(index);
        return <div className={clsx(classes.item, itemClassName)}>{child}</div>;
      })}
    </div>
  );
};

const useStyle = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'stretch'
  },
  item: {
    display: 'flex',
    flex: 1,
    flexBasis: '14.2857%',
    maxWidth: '14.2857%'
  }
}));
