import { Grid, makeStyles, Typography } from '@material-ui/core';
import { HiddenOnMobile } from 'common/components';
import { MainDashboardLayout } from 'layouts';
import { MainDashboardLayoutProps } from 'layouts/DashboardLayout';
import { ReactNode } from 'react';
import { SideNavigation } from './SideNavigation';

interface AccountLayoutProps extends MainDashboardLayoutProps {
  title: string;
  cta?: ReactNode;
}

export const AccountLayout = ({ children, isLoading, title, cta }: AccountLayoutProps) => {
  const classes = useStyle();
  return (
    <MainDashboardLayout isLoading={isLoading}>
      <Grid container spacing={4}>
        <HiddenOnMobile>
          <Grid item md={4}>
            <SideNavigation />
          </Grid>
        </HiddenOnMobile>

        <Grid item md={8} sm={12} xs={12}>
          <div className={classes.container}>
            <header className={classes.header}>
              <Grid container>
                <Grid item xs={12} md={5} lg={7}>
                  <Typography className={classes.title} variant="h2" component="h1">
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs="auto" md={7} lg={5} justify="flex-end" container>
                  {cta}
                </Grid>
              </Grid>
            </header>
            <div className={classes.content}>{children}</div>
          </div>
        </Grid>
      </Grid>
    </MainDashboardLayout>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    flex: 0,
    marginTop: '0.725rem',
    marginBottom: '1.5rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '2rem',
      marginTop: '0'
    }
  },
  title: {
    flex: 1,
    margin: 0
  },
  cta: {
    flex: 0
  }
}));
