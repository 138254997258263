import { sendUserDataGAEvent } from 'common/utils/gtm/sendUserDataGAEvent';
import { useSession } from 'features/authentication';
import { useUser } from 'features/user';
import { ReactNode, useEffect } from 'react';
import { useClient } from 'services/client/useClient';
import { sendUserComAuth } from 'services/userCom/sendUserComAuth';

interface GtmUserIdProps {
  children: ReactNode;
}

export const GtmUserId = ({ children }: GtmUserIdProps): JSX.Element => {
  const { profile } = useUser();
  const { clientData } = useClient(['is_diet_active']);

  const { isLogged } = useSession();
  useEffect(() => {
    if (isLogged && clientData) {
      sendUserComAuth({ user: { email: profile.email, id: profile.id } });
    }
  }, [profile.id, isLogged, clientData, profile.email]);

  useEffect(() => {
    if (isLogged && clientData) {
      sendUserDataGAEvent({ id: profile.id, hasActiveDiet: clientData?.aggregates.is_diet_active || false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, isLogged, profile.id, window?.location?.href]);

  return <>{children}</>;
};
