import { makeStyles, Button } from '@material-ui/core';
import { ButtonProps as MUIButtonProps } from '@material-ui/core/Button/Button';

export const NavigationButton = ({ children, ...rest }: Omit<MUIButtonProps, 'variant' | 'classes' | 'color'>) => {
  const classes = useStyle();
  return (
    <Button variant="contained" classes={classes} {...rest}>
      {children}
    </Button>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.customColors.black,
    color: theme.customColors.white,
    textTransform: 'none',
    width: '12.5rem',
    padding: '0.5rem 0.825rem 0.5rem 1rem',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.customColors.black
    }
  },
  iconSizeMedium: {
    '&> *:first-child': {
      fontSize: '1.5rem'
    }
  }
}));
