import { NotifyDialogProps, NotifyDialog } from 'common/components';
import { useState, createContext, useContext, useMemo, useCallback } from 'react';

interface NotifyDialogState extends Pick<NotifyDialogProps, 'message' | 'cta' | 'variant'> {}

type ShowNotifyDialogHandler = (data: Omit<NotifyDialogState, 'variant'>) => void;

interface DietManagementContextShape {
  showErrorDialog: ShowNotifyDialogHandler;
  showSuccessDialog: ShowNotifyDialogHandler;
  hide: VoidFunction;
}

interface NotifyDialogProviderProps {
  children: React.ReactNode;
}

const NotifyDialogContext = createContext<DietManagementContextShape | undefined>(undefined);

export const NotifyDialogProvider = ({ children }: NotifyDialogProviderProps): JSX.Element => {
  const [dialogParams, setDialogParams] = useState<NotifyDialogState>();

  const handleClose = useCallback(() => setDialogParams(undefined), []);

  const showErrorDialog: ShowNotifyDialogHandler = useCallback(
    (data) => setDialogParams({ ...data, variant: 'error' }),
    []
  );
  const showSuccessDialog: ShowNotifyDialogHandler = useCallback(
    (data) => setDialogParams({ ...data, variant: 'success' }),
    []
  );
  const value = useMemo(
    () => ({
      showErrorDialog,
      showSuccessDialog,
      hide: handleClose
    }),
    [showErrorDialog, showSuccessDialog, handleClose]
  );
  return (
    <NotifyDialogContext.Provider value={value}>
      {children}
      {dialogParams && <NotifyDialog isOpen onClose={handleClose} {...dialogParams} />}
    </NotifyDialogContext.Provider>
  );
};

export const useNotifyDialog = () => {
  const context = useContext(NotifyDialogContext);
  if (context === undefined) {
    throw new Error('useNotifyDialog must be used within a NotifyDialogContext');
  }
  return context;
};
