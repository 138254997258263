import { Id } from 'common/types';
import { useApi } from 'features/apiProvider';
import { useSession } from 'features/authentication';
import { BaseLayout } from 'layouts';
import { useSnackbar } from 'notistack';
import { createContext, ReactNode, useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryObserverResult, useQuery } from 'react-query';
import { fetchProfiles } from '../api';
import { Profile, SelectedProfileId } from '../types';

interface ProfilesContextShape {
  profiles: Profile[];
  selectedProfileId: SelectedProfileId;
  setProfile: (id: Id) => void;
  isLoading: boolean;
  refetch: () => Promise<QueryObserverResult<Profile[], unknown>>;
}

interface UserDietsContextProviderProps {
  children: ReactNode;
}

const ProfilesContext = createContext<ProfilesContextShape | undefined>(undefined);

export const ProfilesProvider = ({ children }: UserDietsContextProviderProps) => {
  const { token } = useSession();
  const { getApiClient } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [selectedProfile, setSelectedProfile] = useState<SelectedProfileId>(null);

  const profiles = useQuery({
    queryKey: 'profiles',
    queryFn: () => fetchProfiles({ apiClient: getApiClient() }),
    enabled: !!token,
    onError: () => {
      enqueueSnackbar(t('errors.fetchingOrderList'), { variant: 'refreshableError' });
    },
    onSuccess: (data) => {
      if (selectedProfile !== null) {
        return;
      }
      const localSelectedDietID = parseInt(window.localStorage.getItem('selectedDietID') ?? '', 10);
      if (data.find(({ id }) => id === localSelectedDietID) !== undefined) {
        setSelectedProfile(localSelectedDietID);
      } else {
        setSelectedProfile(data[0]?.id ?? null);
      }
    }
  });

  const handleSetProfile = useCallback((id: number) => {
    window.localStorage.setItem('selectedDietID', String(id));

    setSelectedProfile(id);
  }, []);

  const ctxValue: ProfilesContextShape = {
    profiles: profiles.data ?? [],
    selectedProfileId: selectedProfile,
    isLoading: profiles.isLoading,
    setProfile: handleSetProfile,
    refetch: profiles.refetch
  };

  return (
    <ProfilesContext.Provider value={ctxValue}>
      {profiles.isLoading ? <BaseLayout isLoading /> : children}
    </ProfilesContext.Provider>
  );
};

export const useProfiles = () => {
  const profiles = useContext(ProfilesContext);
  if (profiles === undefined) {
    throw new Error('useProfiles must be used within a ProfilesContext');
  }
  return profiles;
};
