import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DiscountCode } from 'common/types';
import { Button, TextField } from 'common/components';

import iconChecked from './images/icon-checked-circle.svg';

interface ActivatedDiscountCodeProps {
  value: DiscountCode;
}

export const ActivatedDiscountCode = ({ value }: ActivatedDiscountCodeProps) => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <TextField
        type="text"
        value={value.name}
        InputProps={{
          inputProps: {
            className: classes.input
          },
          readOnly: true,
          endAdornment: <img src={iconChecked} alt="" />
        }}
      />
      <Button mode="secondary" className={classes.button} disabled>
        {t('creatDietPage.discountCodeSection.activeButton')}
      </Button>
    </div>
  );
};

const useStyle = makeStyles((theme) => ({
  input: {
    color: 'currentColor !important',
    opacity: '1 !important'
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: `1fr ${theme.spacing(13)}`,
    gap: theme.spacing(2)
  },
  button: {
    height: theme.spacing(5)
  }
}));
