import { Grid } from '@material-ui/core';
import { FormTextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { FormPostCodeField } from './PostCodeMask';

import { streetFieldsLength, buildingNrFieldsLength, cityFieldsLength, placeNrFieldsLength } from '../types';

interface PartialAddressVerificationFormProps {
  disabled?: boolean;
  className?: string;
}

export const PartialAddressVerificationForm = ({ disabled, className }: PartialAddressVerificationFormProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4} className={className}>
      <Grid item xs={12} sm={12}>
        <FormTextField
          name="street"
          type="text"
          label={t('addresses.addressVerification.street.label')}
          placeholder={t('addresses.addressVerification.street.placeholder')}
          inputProps={{
            maxLength: streetFieldsLength.max
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="buildingNr"
          type="text"
          label={t('addresses.addressVerification.buildingNr.label')}
          placeholder={t('addresses.addressVerification.buildingNr.placeholder')}
          inputProps={{
            maxLength: buildingNrFieldsLength.max
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="placeNr"
          type="text"
          label={t('addresses.addressVerification.placeNr.label')}
          placeholder={t('addresses.addressVerification.placeNr.placeholder')}
          inputProps={{
            maxLength: placeNrFieldsLength.max
          }}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormPostCodeField disabled={disabled} label={t('addresses.addressVerification.postCode.label')} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          name="city"
          type="text"
          label={t('addresses.addressVerification.city.label')}
          placeholder={t('addresses.addressVerification.city.placeholder')}
          inputProps={{
            maxLength: cityFieldsLength.max
          }}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};
