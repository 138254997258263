import { z } from 'zod';
import { sortBy } from 'lodash';

import { Id, DietPackage, DietVariantResponseSchema, DietPackageResponseSchema, DietVariant } from 'common/types';

import { ApiClient } from 'features/apiProvider';
import { AvailableDietPackages, AvailableVariants, defaultDietForPackage, DietConfiguration } from 'features/orders';
import { SimpleBasket, SimpleBasketMeal } from '../types';

const photosUrl = process.env.REACT_APP_IMAGE_DIR;

type DietVariantResponse = z.TypeOf<typeof DietVariantResponseSchema>;

type DietPackageResponse = z.TypeOf<typeof DietPackageResponseSchema>;

const BasketMealResponseSchema = z.object({
  id: z.number(),
  mealType: z.object({
    id: z.number(),
    name: z.string(),
    position: z.number()
  }),
  size: z.object({
    id: z.number(),
    calorific: z.number(),
    nameForClient: z.string(),
    name: z.string()
  })
});

const BasketsResponseSchema = z.object({
  id: z.number(),
  name: z.string(),
  position: z.number(),
  active: z.boolean(),
  basketMeals: z.array(BasketMealResponseSchema)
});

type BasketsResponse = z.TypeOf<typeof BasketsResponseSchema>;

const fetchAvailableProductsResponseSchema = z.object({
  baskets: z.array(BasketsResponseSchema),
  packages: z.array(DietPackageResponseSchema),
  variants: z.array(DietVariantResponseSchema)
});

const dietVariantsTransformer = (data: DietVariantResponse[]): AvailableVariants => {
  const activeVariants = data.filter((variant) => variant.active);
  const sortedActiveVariantsByPosition = sortBy(activeVariants, (variant) => variant.position);
  const entites: [number, DietVariant][] = sortedActiveVariantsByPosition.map((variant) => {
    const variantId = Number(variant.id);
    return [
      variantId,
      {
        id: variantId,
        name: variant.name,
        summary: variant.title,
        description: variant.clientDescription,
        image: `${photosUrl}${variant.image}`
      }
    ];
  });
  return new Map(entites);
};

const dietPackagesTransformer = (
  data: DietPackageResponse[],
  dietVariants: AvailableVariants
): AvailableDietPackages => {
  const sortedPackagesByOrder = sortBy(data, (p) => p.order);
  const parsedPackages = sortedPackagesByOrder.map((p) => {
    const variants = p.variants.reduce((acc, v) => {
      if (dietVariants.has(v.id)) {
        acc.push(v.id);
      }
      return acc;
    }, [] as Id[]);

    const defaultVariantFromConfig = defaultDietForPackage[p.id];
    const defaultVariant = variants.includes(defaultVariantFromConfig) ? defaultVariantFromConfig : variants[0];

    return {
      id: p.id,
      name: p.name,
      description: p.clientDescription,
      variants,
      defaultVariant
    };
  });

  const entites: [Id, DietPackage][] = parsedPackages.map((p) => [p.id, p]);
  return new Map(entites);
};

const basketMealTypesTransfomer = (data: BasketsResponse['basketMeals']): SimpleBasketMeal[] => {
  const sortedMealTypesByPosition = sortBy(data, (m) => m.mealType.position);
  return sortedMealTypesByPosition.map((m) => ({
    name: m.mealType.name,
    size: {
      id: m.size.id,
      name: m.size.nameForClient,
      calories: m.size.calorific
    }
  }));
};

const basketsTransformer = (data: BasketsResponse[]): Map<Id, SimpleBasket> => {
  const activeBaskets = data.filter((b) => b.active);
  const sortedActiveBasketsByPosition = sortBy(activeBaskets, (b) => b.position);
  const parsedBaskets = sortedActiveBasketsByPosition.map((b) => {
    return {
      id: b.id,
      calorific: Number(b.name),
      basketMeals: basketMealTypesTransfomer(b.basketMeals)
    };
  });
  const entites: [Id, SimpleBasket][] = parsedBaskets.map((b) => [b.id, b]);
  return new Map(entites);
};

export const fetchAvailableProducts = async (
  apiClient: ApiClient['apiClient']
): Promise<Pick<DietConfiguration, 'variants' | 'packages'> & { baskets: Map<Id, SimpleBasket> }> => {
  const response = await apiClient.get('frontend/configurator').json();

  const parsedResponse = fetchAvailableProductsResponseSchema.parse(response);

  const variants = dietVariantsTransformer(parsedResponse.variants);
  return {
    baskets: basketsTransformer(parsedResponse.baskets),
    packages: dietPackagesTransformer(parsedResponse.packages, variants),
    variants
  };
};
