import { CircularProgress } from '@material-ui/core';
import { NotifyDialog } from 'common/components';
import { useVisibility } from 'common/hooks';
import { useApi } from 'features/apiProvider';
import { InvoicesForm, useUser } from 'features/user';
import { updateInvoices } from 'features/user/api';
import { InvoicesFormSchema } from 'features/user/types/invoicesFormSchema';
import { AccountLayout } from 'layouts';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

export const InvoicesPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { getApiClient } = useApi();
  const [isVisible, show, hide] = useVisibility();

  const { profile } = useUser();

  const { mutate, isLoading, isError } = useMutation({
    mutationKey: 'updateInvoices',
    mutationFn: updateInvoices,
    onSuccess: () => {
      show();
      profile.refetch();
    },
    onError: () => {
      show();
    }
  });

  const onUpdateInvoicesSubmit = ({ taxNumber, enable }: InvoicesFormSchema & { enable: boolean }) => {
    mutate({ apiClient: getApiClient(), requestData: { needFv: enable, nip: taxNumber } });
  };

  return (
    <AccountLayout isLoading={isLoading} title={t('invoicesPage.title')}>
      {profile.isLoading || profile.isRefetching ? (
        <CircularProgress size={50} color="secondary" />
      ) : (
        <InvoicesForm
          enableInvoices={profile.enableInvoices}
          defaultValue={profile.taxNumber}
          isLoading={isLoading}
          onSubmit={onUpdateInvoicesSubmit}
        />
      )}
      <NotifyDialog
        isOpen={isVisible}
        message={isError ? 'invoicesPage.form.state.errorRequest' : 'invoicesPage.form.state.successRequest'}
        onClose={hide}
        variant={isError ? 'error' : 'success'}
      />
    </AccountLayout>
  );
};
