import { makeStyles, SvgIcon } from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { ReactComponent as WarningIcon } from './icon.svg';

interface InfoMessageProps {
  title?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const InfoMessage = ({ className, children, title }: InfoMessageProps) => {
  const classes = useStyle();
  return (
    <>
      <div className={clsx(classes.root, className)}>
        {title && <div className={classes.title}>{title}</div>}
        <div className={classes.container}>
          <SvgIcon className={classes.icon} component={WarningIcon} viewBox="0 0 24 24" />
          <div className={classes.description}>{children}</div>
        </div>
      </div>
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    borderRadius: '0.25rem',
    backgroundColor: theme.customColors.black,
    color: theme.customColors.white,
    padding: '0.5rem 0.75rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0.5rem'
    }
  },
  container: {
    display: 'flex'
  },
  icon: {
    marginRight: '0.5rem'
  },
  title: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    letterSpacing: '0',
    paddingLeft: '2rem'
  },
  description: {
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    letterSpacing: '0',
    flex: 1
  }
}));
