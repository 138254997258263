import { makeStyles } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { InfoTooltip } from 'common/components';

export const InvoiceSection = () => {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>{t('creatDietPage.summaryBox.invoice.title')}</p>
      <InfoTooltip className={classes.tooltipContent} placement="left">
        <Trans i18nKey="creatDietPage.summaryBox.invoice.content" components={{ p: <p />, h6: <h6 /> }} />
      </InfoTooltip>
    </div>
  );
};

const useStyle = makeStyles(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '1.5rem',
    borderRadius: '0.25rem'
  },
  title: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    margin: '0'
  },
  tooltipContent: {
    '& > h6': {
      margin: '0',
      paddingBottom: '1.5rem',
      fontSize: '0.875rem',
      fontWeight: 'bold'
    },
    '& > p': {
      margin: 0
    }
  }
}));
