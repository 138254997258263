import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { FormRow } from 'common/components';
import { MealModes } from 'common/types';
import { country } from 'config';
import { ButtonGroupWithTooltip } from './ButtonGroupWithTooltip';
import { CreateDietFormData } from '../types';
import { mapBasketMealsToSelectedMeals } from '../utils';
import { ComfortMealSection } from './ComfortMealSection';
import { FlexiMealSection } from './FlexiMealSection';
import { useDietConfiguration } from '../contexts';

export const MealModeSection = (): JSX.Element => {
  const classes = useStyle();
  const { t } = useTranslation();
  const {
    config: { baskets }
  } = useDietConfiguration();

  const { setValue } = useFormContext<CreateDietFormData>();

  const selectedBasketId = useWatch<CreateDietFormData, 'basket'>({
    name: 'basket'
  });

  const {
    field: { onChange, value }
  } = useController({
    name: 'mealMode'
  });

  const availableOptions = [
    {
      label: t('creatDietPage.mealModeComfort'),
      value: MealModes.comfort,
      description: t('creatDietPage.mealModeComfortDescription')
    },
    {
      label: t('creatDietPage.mealModeFlexi'),
      value: MealModes.flexi,
      description: t('creatDietPage.mealModeFlexiDescrption')
    }
  ];
  const handleMealModeChange = (mealMode: MealModes) => {
    onChange(mealMode);
    const selectedBasket = baskets.get(selectedBasketId);
    if (selectedBasket) {
      setValue('meals', mapBasketMealsToSelectedMeals(selectedBasket));
    }
  };

  return (
    <FormRow title={t('creatDietPage.mealModeSectionTitle')} hidden={country === 'germany'}>
      <div className={classes.mealModeWrapper}>
        <ButtonGroupWithTooltip options={availableOptions} onChangeValue={handleMealModeChange} value={value} />
      </div>
      {value === MealModes.comfort ? <ComfortMealSection /> : <FlexiMealSection />}
    </FormRow>
  );
};

const useStyle = makeStyles(() => ({
  mealModeWrapper: {
    marginBottom: '1.5rem'
  },
  calorificSwitch: {
    display: 'flex',
    alignItems: 'center'
  },
  mealsWrapper: {
    marginTop: '2.34375rem'
  }
}));
