import { FormControl, InputLabel, FormHelperText, makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';
import { Props as ReactSelectProps } from 'react-select';
import { SelectInput } from './SelectInput';
import { IsMultiSelect } from './SelectInput/types';

export interface SelectFieldProps<OptionType> extends ReactSelectProps<OptionType, IsMultiSelect> {
  label?: string;
  error?: boolean;
  helperText?: ReactNode;
}

export const SelectField = <OptionType,>({
  error = false,
  label,
  id,
  helperText,
  ...rest
}: SelectFieldProps<OptionType>) => {
  const classes = useStyle();
  return (
    <FormControl fullWidth error={error}>
      {label && (
        <InputLabel htmlFor={id} shrink className={classes.label}>
          {label}
        </InputLabel>
      )}
      <SelectInput {...rest} error={error} />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

const useStyle = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1)
  }
}));
