import { Grid } from '@material-ui/core';

import { HiddenOnDesktop } from 'common/components';
import { InfoAboutDeliveryDiscount } from 'features/orders';
import { CaloriesSection } from '../CaloriesSection';
import { DietSection } from '../DietSection';
import { MealModeSection } from '../MealModeSection';
import { PackageSection } from '../PackageSection';
import { DietNameSection } from '../DietNameSection';
import { DeliveryDatesSection } from '../DeliveryDatesSection';
import { DiscountCodeSection } from '../DiscountCodeSection';
import { useExperimentsFromUrl } from 'services/experiments/useExperimentsFromUrl';

interface OrderFormProps {
  onDietPreview: (id: number) => void;
  isNewOrder: boolean;
  isDeliveryDiscount: boolean;
}
// TODO: Consider how to use form, because DiscountCodeSection also is wrapped in the form tag
export const OrderForm = ({ onDietPreview, isNewOrder, isDeliveryDiscount }: OrderFormProps): JSX.Element => {
  const { getVariant } = useExperimentsFromUrl();
  const variant = getVariant({ experimentId: 'test_konfigurator' });

  return (
    <>
      {variant === 'pakiet-up' ? (
        <>
          <PackageSection alternate />
          <DietSection onDietPreview={onDietPreview} />
          <CaloriesSection />
          <MealModeSection />
        </>
      ) : (
        <>
          <CaloriesSection />
          <MealModeSection />
          <PackageSection />
          <DietSection onDietPreview={onDietPreview} />
        </>
      )}
      <DeliveryDatesSection />
      <Grid container spacing={4}>
        {isNewOrder && <DietNameSection />}
        {isDeliveryDiscount && (
          <HiddenOnDesktop>
            <Grid item xs={12}>
              <InfoAboutDeliveryDiscount />
            </Grid>
          </HiddenOnDesktop>
        )}
        <Grid item xs={12} md={6}>
          <DiscountCodeSection />
        </Grid>
      </Grid>
    </>
  );
};
