import { makeStyles, Slider, Theme } from '@material-ui/core';

interface CaloriesSliderProps {
  options: { label: string; value: number }[];
  value: number;
  onChange: (newValue: number) => void;
  individualCalorific?: string;
}

export const CaloriesSlider = ({ options, value, onChange, individualCalorific }: CaloriesSliderProps): JSX.Element => {
  const classes = useStyle({ individualCalorific });

  const handleChange = (_: React.ChangeEvent<Record<string, unknown>>, newValue: number | number[]) => {
    onChange(Array.isArray(newValue) ? newValue[0] : newValue);
  };

  return (
    <div className={classes.wrapper}>
      <Slider
        aria-labelledby="calorific-slider-header"
        marks={options}
        step={null}
        className={classes.slider}
        value={value}
        onChange={handleChange}
        track={false}
      />
      {individualCalorific && <div className={classes.individualCalorifics}>{individualCalorific}</div>}
    </div>
  );
};

const useStyle = makeStyles<Theme, { individualCalorific?: string }>((theme) => ({
  wrapper: {
    position: 'relative'
  },
  slider: {
    margin: '1.875rem 0 0 0',

    [theme.breakpoints.down('sm')]: {
      padding: '1.25rem 0'
    },

    '& > .MuiSlider-track, & > .MuiSlider-rail': {
      color: theme.customColors.textLightGrey,
      opacity: 1,
      borderRadius: `0.063rem`
    },

    '& > .MuiSlider-mark': {
      width: '0.5rem',
      height: '0.5rem',
      borderRadius: `50%`,
      backgroundColor: theme.customColors.textDarkGrey,
      marginTop: `-0.188rem`,
      marginLeft: `-0.188rem`
    },

    '& > .MuiSlider-thumb': {
      width: '1.5rem',
      height: '1.5rem',
      marginTop: '-0.688rem',
      marginLeft: '-0.75rem',
      boxShadow: 'none'
    },

    '& > .MuiSlider-markLabel': {
      top: `-1.5rem`,
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      letterSpacing: '0.013rem',
      color: theme.customColors.textDarkGrey,

      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
        lineHeight: 1.33,
        letterSpacing: '0.025rem',
        top: `-1rem`
      }
    },

    '& > .MuiSlider-markLabelActive': {
      color: (props) => (props.individualCalorific ? theme.customColors.textDarkGrey : theme.palette.secondary.main)
    }
  },
  individualCalorifics: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1.5rem',
    height: '1.5rem',
    top: '2rem',
    left: '2.5%',
    width: '95%',
    whiteSpace: 'nowrap',
    backgroundColor: theme.customColors.calorificsSlider.individualBarBackground,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: 1.33,
    letterSpacing: '0.019rem',

    [theme.breakpoints.down('sm')]: {
      top: '2.438rem'
    }
  }
}));
