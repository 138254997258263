import { Time } from 'common/types';
import { z } from 'zod';

const addressDeliveryTimeSchemaResponse = z.object({
  timeFrom: z.string().optional(),
  timeTo: z.string(),
  interval: z.custom<Time>(),
  step: z.custom<Time>()
});

export const addressDeliveryTimeSchema = z.object({
  timeFrom: z.string().optional(),
  timeTo: z.string()
});

export const verifyAddress = z.object({
  showSinceOption: z.boolean(),
  showSinceToOption: z.boolean(),
  deliveryTimes: z.array(addressDeliveryTimeSchema)
});

export const verifyAddressResponse = z.object({
  showSinceOption: z.boolean(),
  showSinceToOption: z.boolean(),
  deliveryTimes: z.array(addressDeliveryTimeSchemaResponse)
});

export type AddressDeliveryTime = z.infer<typeof addressDeliveryTimeSchema>;

export type AddressDeliveryData = z.infer<typeof verifyAddress>;

export type AddressDeliveryTimeResponse = z.infer<typeof addressDeliveryTimeSchemaResponse>;

export type AddressDeliveryDataResponse = z.infer<typeof verifyAddressResponse>;
