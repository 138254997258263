import { makeStyles } from '@material-ui/core';
import { Button } from 'common/components';
import { sendLoginWithSocialGAEvent } from 'common/utils/gtm/sendLoginWithSocialGAEvent';

import { useApi } from 'features/apiProvider';
import { useSnackbar } from 'notistack';
import { useGetInvitationCode } from 'pages/RegisterPage/hooks/useGetInvitationCode';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { loginFacebookRequest } from '../api';
import { useSession } from '../contexts';
import fbIcon from '../images/f-logo-rgb-blue.png';

interface FacebookLoginProps {
  type: 'login' | 'register';
}

export const FacebookLogin = ({ type }: FacebookLoginProps) => {
  const classes = useStyle();
  const { login } = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { getApiClient } = useApi();
  const [fbToken, setFbToken] = useState('');
  const invitationCode = useGetInvitationCode();
  const history = useHistory();

  const apiClient = getApiClient();

  const { mutate: authTokenRequest } = useMutation({
    mutationFn: loginFacebookRequest,
    onSuccess: (response) => {
      // default save token for longer
      login({ token: response.token, refreshToken: response.refresh_token, rememberMe: true });

      sendLoginWithSocialGAEvent({ type, medium: 'facebook' });
      if (invitationCode) {
        history.push(`${RoutesDefinition.createDiet}?invitationCode=${invitationCode}`);
      }
    },
    onError: () => {
      enqueueSnackbar(t('snackbarError.title'), {
        variant: 'refreshableError',
        onExited: () => {
          authTokenRequest({ token: fbToken, apiClient });
        }
      });
    }
  });

  const handleLogin = () => {
    FB.login(
      (response) => {
        if (response.status === 'connected') {
          const token = response.authResponse.accessToken;
          if (!token) {
            throw new Error('no access token provided');
          }
          setFbToken(token);
        }
      },
      {
        scope: 'public_profile,email'
      }
    );
  };

  useEffect(() => {
    if (fbToken) {
      authTokenRequest({ token: fbToken, apiClient });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbToken]);

  return (
    <Button mode="secondary" className={classes.root} onClick={handleLogin}>
      <img
        className={classes.img}
        src={fbIcon}
        alt={t(type === 'login' ? 'loginPage.facebookLogin' : 'registerPage.facebookRegister')}
      />
    </Button>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    borderWidth: 1,
    borderColor: theme.customColors.textLightGrey
  },
  img: {
    width: '1.25rem',
    height: '1.25rem'
  }
}));
