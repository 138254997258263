import { TFuncKey, useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { NavigationLink } from 'common/components/NavigationLink/NavigationLink';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import { useMobileScreenSizeDetector } from 'common/hooks';

const navigationSectionRoutesList = [
  {
    label: 'menuItems.calendar',
    to: RoutesDefinition.dashboard,
    exact: true
  },
  {
    label: 'menuItems.inviteFriends',
    to: RoutesDefinition.inviteNewFriends,
    exact: true
  }
];

export const NavigationSection = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyle();

  const isMobile = useMobileScreenSizeDetector();

  return (
    <>
      {navigationSectionRoutesList.map(({ label, to, exact }) => {
        const translatedLabel = t(label as TFuncKey) as string;

        if (isMobile) {
          return <NavigationLink key={to} label={translatedLabel as string} path={to} />;
        }

        return (
          <NavLink exact={exact} key={to} to={to} className={classes.basicLink}>
            {translatedLabel}
          </NavLink>
        );
      })}
    </>
  );
};

const useStyle = makeStyles((theme) => ({
  basicLink: {
    fontSize: '1rem',
    letterSpacing: '0.013rem',
    color: theme.customColors.textLightGrey,
    padding: '0.5rem',
    margin: '0 0.5rem',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    borderWidth: '0 0 0.25rem 0',
    borderColor: `rgba(0,0,0,0)`,

    '&.active': {
      fontWeight: 'bold',
      color: theme.customColors.white,
      borderBottom: `0.25rem solid ${theme.palette.primary.main}`
    }
  }
}));
