import { Button, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import checkboxEmpty from 'common/icons/icon-checkbox-empty.svg';

import { MarkedCircle } from 'common/icons/MarkedCircle';

interface MealBoxProps {
  active: boolean;
  title: string;
  details: string;
  onMealSelect: () => void;
}

export const MealBox = ({ active, title, details, onMealSelect }: MealBoxProps) => {
  const classes = useStyle();
  return (
    <Grid item container xs={6} md={4}>
      <Button
        onClick={onMealSelect}
        className={clsx({
          [classes.container]: true,
          active
        })}
        aria-pressed={active}
      >
        {active ? <MarkedCircle imageAlt="" /> : <img src={checkboxEmpty} alt="" />}

        <div className={classes.detailsContainer}>
          <p className={classes.detailRow}>{title}</p>
          <p className={classes.detailRow}>{details}</p>
        </div>
      </Button>
    </Grid>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    boxShadow: theme.boxShadow.lightGrey,
    padding: '1rem 1rem 1rem 1.188rem',
    cursor: 'pointer',
    textTransform: 'none',
    borderRadius: '0.25rem',
    width: '100%',
    height: '100%',
    backgroundColor: theme.customColors.white,

    [theme.breakpoints.down('sm')]: {
      padding: '1rem 0.75rem'
    },

    '&:hover': {
      backgroundColor: theme.customColors.white
    },

    '&.active': {
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    },

    '& > .MuiButton-label': {
      display: 'flex',
      justifyContent: 'flex-start',

      '& > img': {
        width: '1rem'
      }
    }
  },
  detailsContainer: {
    marginLeft: '1rem'
  },
  detailRow: {
    margin: 0,
    fontSize: '0.75rem',
    lineHeight: 'normal',
    textAlign: 'left',

    '&:first-of-type': {
      fontWeight: 'bold',
      marginBottom: '0.25rem'
    }
  }
}));
