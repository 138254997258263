import { AppBar, Container, makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';

interface BaseDashboardHeaderProps {
  children: NonNullable<ReactNode>;
}

export const BaseDashboardHeader = ({ children }: BaseDashboardHeaderProps) => {
  const classes = useStyle();
  return (
    <AppBar position="relative" color="secondary" elevation={0} className={classes.root}>
      <Container maxWidth="lg">{children}</Container>
    </AppBar>
  );
};

const useStyle = makeStyles((theme) => ({
  root: {
    height: '6rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '5rem',
      paddingTop: '1.25rem',
      paddingBottom: '1.25rem'
    }
  }
}));
