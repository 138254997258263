import { Theme, useMediaQuery } from '@material-ui/core';
import { ReactNode } from 'react';

interface HiddenOnDesktopProps {
  children: ReactNode;
}

export const HiddenOnDesktop = ({ children }: HiddenOnDesktopProps) => {
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (hidden) {
    return null;
  }
  return <>{children}</>;
};
