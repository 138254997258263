import { ApiClient } from 'features/apiProvider';
import { AddressVerificationFormData } from 'features/addresses/types';
import { VerifiedAddress } from 'features/addresses/types/verifiedAddress';
import { mapDeliveryTimeToOptions } from './utils';
import { verifyAddressResponse } from './types';

interface VerifyAddressRequest extends ApiClient {
  data: AddressVerificationFormData;
}

export const verifyAddress = async ({ data, apiClient }: VerifyAddressRequest): Promise<VerifiedAddress> => {
  const result = await apiClient
    .post('v3/addresses/check', {
      json: data
    })
    .json();

  const parsedData = verifyAddressResponse.parse(result);

  return {
    ...data,
    deliveryHourOptions: mapDeliveryTimeToOptions(parsedData)
  };
};
