import { country } from 'config';

const currencyCodes: Record<string, string> = {
  pl: 'PLN',
  de: 'EUR',
  cs: 'CZK'
};

export const formatPrice = (value: number, language: string) => {
  const isFractionalRemoved = country === 'czech' && Number.isInteger(value);
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyCodes[language],
    // we remove factional in Czech, but in case there is a wrong value (with fractions) returned from BE we should display it
    maximumFractionDigits: isFractionalRemoved ? 0 : undefined,
    useGrouping: false
  }).format(value);
};
