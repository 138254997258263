import { CalendarDate } from 'common/types';

interface CalculatePredefineDateRangeArgs {
  firstDate: CalendarDate;
  length: number;
  withoutWeekends: boolean;
  disabledDays: CalendarDate[];
  maxDate: CalendarDate;
}

export const calculatePredefinedDateRange = ({
  disabledDays,
  firstDate,
  length,
  withoutWeekends,
  maxDate
}: CalculatePredefineDateRangeArgs): CalendarDate[] => {
  const dayRange: CalendarDate[] = [];

  let counter = 0;

  while (dayRange.length < length) {
    const date = firstDate.add(counter, 'd');

    if (maxDate && date.isAfter(maxDate)) {
      break;
    }

    const isDisabledDate = disabledDays.some((disabledDay) => disabledDay.isSame(date, 'd'));

    if (withoutWeekends) {
      if (!(isDisabledDate || date.isWeekend())) {
        dayRange.push(date);
      }
    } else if (!isDisabledDate) {
      dayRange.push(date);
    }

    counter += 1;
  }

  return dayRange;
};
