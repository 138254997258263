import { MealModes } from 'common/types';
import { SelectedMeals } from 'common/types/selectedMeals';
import { SizeData } from 'features/orders/api/fetchSizesData';

import { Basket } from 'features/orders/types/basket';
import { calculateCalorific } from 'features/orders/utils/calculateCalorifics';

export const getDietCalories = ({
  selectedMeals,
  mealMode,
  sizesData,
  selectedBasket
}: {
  selectedBasket: Basket | undefined;
  selectedMeals: SelectedMeals;
  mealMode: MealModes;
  sizesData: SizeData[];
}) => {
  const calories = calculateCalorific(selectedMeals, sizesData);
  if (mealMode === MealModes.comfort) {
    return `${calories}/${selectedBasket?.calorific}`;
  }
  return `${calories}/${calories}`;
};
