import { CircularProgress, makeStyles, Theme } from '@material-ui/core';
import { useUser } from 'features/user';
import { NavLink } from 'react-router-dom';
import { RoutesDefinition } from 'routing/constants/RoutesDefinition';
import icon from 'common/icons/moneybox.svg';
import { country } from 'config';

export const MoneyBox = (): JSX.Element => {
  const {
    profile: { isLoading, isError, moneybox }
  } = useUser();

  const classes = useStyle({
    hasBoxValue: !!moneybox,
    isError
  });
  const isFractionalRemoved = country === 'czech' && Number.isInteger(moneybox);
  const moneyboxFormatted = moneybox.toFixed(isFractionalRemoved ? 0 : 2).replace('.', ',');

  if (isLoading) {
    return <CircularProgress size={20} data-testid="moneybox-loader" />;
  }

  return (
    <NavLink
      to={RoutesDefinition.moneybox}
      className={classes.root}
      activeClassName="active"
      data-testid="moneybox-link"
    >
      <span>{isError ? '?,??' : moneyboxFormatted}</span>
      <img src={icon} alt="" />
    </NavLink>
  );
};

const useStyle = makeStyles<Theme, { hasBoxValue: boolean; isError: boolean }>((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    textDecoration: 'none',
    filter: theme.filters.white,
    '&.active': {
      filter: theme.filters.primaryMain
    },

    '&>span': {
      color: (props) =>
        (props.isError && theme.customColors.red) ||
        (props.hasBoxValue && theme.customColors.white) ||
        theme.customColors.textLightGrey,
      letterSpacing: '0.019rem',
      fontWeight: 'bold'
    },
    '& img': {
      marginLeft: '0.5rem',
      filter: (props) =>
        (props.isError && theme.filters.red) || (props.hasBoxValue && theme.filters.white) || theme.filters.ligthGrey
    }
  }
}));
