import { useUser } from 'features/user';
import { useMobileScreenSizeDetector } from 'common/hooks';
// TODO: it should be in common types when it is used in two features
import { UserProfile } from 'features/user/types';
import { Address } from 'features/addresses/types';
import { OrderFlowStep } from '../types';

const isRequiredFillPersonalData = ({ firstName, lastName, phone }: UserProfile) => {
  return [firstName, lastName, phone].some((data) => data.length === 0);
};

const isRequiredFillDeliveryAddresses = (addresses: Address[]) => {
  return addresses.length === 0;
};

const calculateNextStepForDietConfigurator = ({
  requiredFillPersonalData,
  requiredFillDeliveryAddresses,
  isMobile
}: {
  requiredFillPersonalData: boolean;
  requiredFillDeliveryAddresses: boolean;
  isMobile: boolean;
}): OrderFlowStep => {
  if (requiredFillPersonalData) {
    return OrderFlowStep.PersonalData;
  }
  if (requiredFillDeliveryAddresses) {
    return OrderFlowStep.DeliveryAddresses;
  }
  if (isMobile) {
    return OrderFlowStep.Summary;
  }
  return OrderFlowStep.Payment;
};

const calculateNextStepForPersonalData = ({
  requiredFillDeliveryAddresses,
  isMobile
}: {
  requiredFillDeliveryAddresses: boolean;
  isMobile: boolean;
}): OrderFlowStep => {
  if (requiredFillDeliveryAddresses) {
    return OrderFlowStep.DeliveryAddresses;
  }
  if (isMobile) {
    return OrderFlowStep.Summary;
  }
  return OrderFlowStep.Payment;
};

const calculateNextStepForDeliveryAddresses = (): OrderFlowStep => {
  return OrderFlowStep.DeliveryInfo;
};

const calculateNextStepForDeliveryInfo = ({ isMobile }: { isMobile: boolean }): OrderFlowStep => {
  if (isMobile) {
    return OrderFlowStep.Summary;
  }
  return OrderFlowStep.Payment;
};

const calculateNextStepForSummary = (): OrderFlowStep => {
  return OrderFlowStep.Payment;
};
const calculateNextStepForPayment = (): OrderFlowStep => {
  return OrderFlowStep.Payment;
};

export const useOrderFlow = (): {
  calculateNextStep: (currentStep: OrderFlowStep) => OrderFlowStep;
} => {
  const isMobile = useMobileScreenSizeDetector();
  const { profile, addresses } = useUser();

  const calculateNextStep = (currentStep: OrderFlowStep): OrderFlowStep => {
    const requiredFillDeliveryAddresses = isRequiredFillDeliveryAddresses(addresses.data);
    const requiredFillPersonalData = isRequiredFillPersonalData(profile);
    switch (currentStep) {
      case OrderFlowStep.DietConfigurator:
        return calculateNextStepForDietConfigurator({
          isMobile,
          requiredFillDeliveryAddresses,
          requiredFillPersonalData
        });
      case OrderFlowStep.PersonalData:
        return calculateNextStepForPersonalData({
          isMobile,
          requiredFillDeliveryAddresses
        });
      case OrderFlowStep.DeliveryAddresses:
        return calculateNextStepForDeliveryAddresses();
      case OrderFlowStep.DeliveryInfo:
        return calculateNextStepForDeliveryInfo({ isMobile });
      case OrderFlowStep.Summary:
        return calculateNextStepForSummary();
      default:
        return calculateNextStepForPayment();
    }
  };
  return {
    calculateNextStep
  };
};
