import { lazy } from 'react';

import { useZendeskZopim, useSnackbarDismiss } from 'common/hooks';

import { useSession } from 'features/authentication';
import { Onboarding } from 'features/onboarding';

const ProtectedRouting = lazy(() => import('./routing/DashboardRouting'));
const PublicRouting = lazy(() => import('./routing/GuestRouting'));

function App(): React.ReactElement {
  const { isLogged } = useSession();
  useSnackbarDismiss();
  useZendeskZopim();

  if (!isLogged) {
    return <PublicRouting />;
  }

  if (process.env.NODE_ENV === 'test') {
    return <ProtectedRouting />;
  }

  return (
    <>
      <ProtectedRouting />
      <Onboarding />
    </>
  );
}

export default App;
