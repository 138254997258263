import { Grid, makeStyles, Theme } from '@material-ui/core';
import { CalendarDate } from 'common/types';
import { CreateDietFormData } from 'features/orders';
import { useDietConfiguration } from 'features/orders/contexts';
import { getDietLenghtOptions } from 'features/orders/utils';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from 'common/components';
import { useAllowWeekends } from 'features/orders/hooks/useAllowWeekends';
import { country } from 'config';
import { ContinuousDeliveryDatesCalendar, CustomDeliveryDatesCalendar } from './DeliveryDatesCalendar';

import { DietLengthSection } from './DietLengthSection';
import { WeekendsSection } from './WeekendsSection';

export const DeliveryDatesSection = (): JSX.Element => {
  const { t } = useTranslation();
  const allowWeekends = useAllowWeekends();
  const classes = useStyle({ hidden: country === 'germany' });

  const {
    deliveryTimeConfig: { firstDeliveryAt }
  } = useDietConfiguration();

  const [visibleMonth, setVisibleMonth] = useState<CalendarDate>(firstDeliveryAt);

  const withoutWeekends = useWatch<CreateDietFormData, 'withoutWeekends'>({
    name: 'withoutWeekends'
  });

  const dietLengthId = useWatch<CreateDietFormData, 'dietLength'>({
    name: 'dietLength'
  });

  const selectedDietLengthOption = getDietLenghtOptions(withoutWeekends).find((o) => o.id === dietLengthId);
  const isCustomDietLenghtOption = selectedDietLengthOption?.type === 'custom';

  const showWithWeekendsSection = !(isCustomDietLenghtOption || !allowWeekends);
  return (
    <FormRow
      title={t('creatDietPage.calendarSection.title')}
      subtitle={
        country === 'germany'
          ? 'Wähle beliebig viele Tage im Kalender aus. KEIN ABO, KEINE AUTOMATISCHE VERLÄNGERUNG!'
          : undefined
      }
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} className={classes.hidableElement}>
          <DietLengthSection />
          {showWithWeekendsSection ? <WeekendsSection /> : null}
        </Grid>
        <Grid item xs={12} md={6}>
          {isCustomDietLenghtOption ? (
            <CustomDeliveryDatesCalendar currentVisibleMonth={visibleMonth} onMonthChange={setVisibleMonth} />
          ) : (
            <ContinuousDeliveryDatesCalendar currentVisibleMonth={visibleMonth} onMonthChange={setVisibleMonth} />
          )}
        </Grid>
      </Grid>
    </FormRow>
  );
};

const useStyle = makeStyles<Theme, { hidden?: boolean }>(() => ({
  hidableElement: {
    display: ({ hidden }) => (hidden ? 'none' : 'block')
  }
}));
