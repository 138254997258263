import { ApiClient } from 'features/apiProvider';

interface UpdateMarketingSubscriptionsParams extends ApiClient {
  requestData: {
    phoneMarketingAgreement: boolean;
    emailMarketingAgreement: boolean;
  };
}

export const updateMarketingSubscriptions = ({ requestData, apiClient }: UpdateMarketingSubscriptionsParams) => {
  return apiClient.patch('v4/secure/client/update-marketing-agreement', { json: { data: requestData } });
};
